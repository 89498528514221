import {
  createPromiseClient,
  PromiseClient,
  Transport,
} from '@connectrpc/connect'
import { Interval } from 'luxon'
import { Timestamp } from '@/services/timestamp_pb'
import { CapacityService as Service } from 'rfs/frontend/proto/capacity_connect'
import {
  SupplyDemandRequest,
  ResourceGroup,
} from 'rfs/frontend/proto/capacity_pb'
import {
  TimeSeriesRequest,
  TimeSeriesResponse,
} from 'rfs/frontend/proto/tsdb_pb'
import { TimeSeriesFetch } from './charts.service'
import { Resolution } from 'rfs/frontend/proto/resolution_pb'

export class CapacityService {
  private capacityClient: PromiseClient<typeof Service>

  constructor(transport: Transport) {
    this.capacityClient = createPromiseClient(Service, transport)
  }

  public async fetchSupplyDemandTimeSeries(
    resourceGroup: ResourceGroup,
    interval: Interval,
    resolution?: Resolution
  ): Promise<TimeSeriesResponse> {
    return this.capacityClient.supplyDemandTimeSeries(
      new SupplyDemandRequest({
        resourceGroup,
        start: Timestamp.fromDateTime(interval.start),
        end: Timestamp.fromDateTime(interval.end),
        resolution,
      })
    )
  }
}
