<template>
  <div
    class="c-AbstractChart chartjs-window-resizing"
    role="region"
    aria-label="Chart"
  >
    <!-- Top Bar -->
    <div class="top-bar d-flex align-center">
      <!-- Title -->
      <h2 class="text-h6 pr-2">{{ chartDef.title }}</h2>

      <!-- Tooltip -->
      <ce-tooltip
        v-if="chartDef.tooltip"
        :text="chartDef.tooltip"
        type="info"
      />

      <!-- "After title" slot -->
      <div v-if="$slots['after-title']" class="pl-2">
        <slot name="after-title" />
      </div>

      <v-spacer></v-spacer>

      <!-- Menu -->
      <chart-menu :menu-options="menu.options" v-on="menu.eventHandlers" />
    </div>

    <!-- Chart -->
    <div class="chart-area position-relative" :style="{ height: chartHeight }">
      <!-- Loading -->
      <centered-spinner
        v-if="isLoading"
        :size="16"
        class="mt-n8"
        style="pointer-events: none"
      />

      <component
        :ref="REF_CHART"
        :id="chartDef.id"
        :is="component"
        :data="chartData"
        :options="chartOptions"
        update-mode="none"
      />
    </div>

    <!-- "Right side" slot -->
    <div v-if="$slots['right-side']" class="right-side pl-6">
      <slot name="right-side" />
    </div>
  </div>
</template>

<script lang="ts">
// Import the module that initializes Chart.js and registers all the modules, plugins
import '@/utils/chartjs/register'

import { defineComponent, type DefineComponent, type PropType } from 'vue'
import { type Chart, type ChartData, ChartOptions } from 'chart.js'
import { Bar as BarChart, Line as LineChart } from 'vue-chartjs'
import { DEFAULT_HEIGHT } from '@/constants/infiniteScrollChart'
import { isBarChart } from '@/utils/charts'
import { getChartAsDataUrl, getVisibleData } from '@/utils/chartjs'
import { downloadCsv, type CsvRow } from '@/utils/csv'
import { downloadBlob } from '@/utils/download'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import CeTooltip from '@/components/CeTooltip.vue'
import ChartMenu from '@/components/common/ChartMenu.vue'
import type { ChartMenuOption } from '@/components/common/ChartMenu'
import type { ChartDefinition } from '@/types/charts'

enum EventName {
  DOWNLOAD_PNG = 'download-png',
  DOWNLOAD_CSV = 'download-csv',
}

type EventHandler = () => void | Promise<void>

type ChartMenuPropsAndEventHandlers = {
  options: ChartMenuOption<EventName>[]
  eventHandlers: Partial<Record<EventName, EventHandler>>
}

export default defineComponent({
  name: 'AbstractChart',
  props: {
    chartDef: {
      type: Object as PropType<ChartDefinition>,
      required: true,
    },
    chartData: {
      type: Object as PropType<ChartData>,
      required: true,
    },
    chartOptions: {
      type: Object as PropType<ChartOptions>,
      required: true,
    },
    customDownloadFileName: {
      type: Function as PropType<() => string>,
      required: false,
    },
    customDownloadCsv: {
      type: Function as PropType<(visibleData: ChartData) => CsvRow[]>,
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    BarChart,
    LineChart,
    ChartMenu,
    CeTooltip,
    CenteredSpinner,
  },
  data() {
    return { REF_CHART: 'REF_CHART' }
  },
  computed: {
    component(): string {
      return isBarChart(this.chartDef) ? 'bar-chart' : 'line-chart'
    },
    /**
     * ChartJS has no API to set a specific height. We have to set the height on
     * the container DOM element with `defaults.maintainAspectRatio = false`
     */
    chartHeight(): string {
      return `${this.chartDef.height ?? DEFAULT_HEIGHT}px`
    },
    /**
     * List of menu options.
     *
     * How to add a new option:
     * 1) Create a new `EventName` enum option
     * 2) Create a event handler for the new enum option
     */
    menu(): ChartMenuPropsAndEventHandlers {
      return {
        options: [
          { text: 'Download PNG', event: EventName.DOWNLOAD_PNG },
          ...(this.customDownloadCsv
            ? [{ text: 'Download CSV', event: EventName.DOWNLOAD_CSV }]
            : []),
        ],
        eventHandlers: {
          [EventName.DOWNLOAD_PNG]: this.downloadPng,
          ...(this.customDownloadCsv
            ? { [EventName.DOWNLOAD_CSV]: this.downloadCsv }
            : {}),
        },
      }
    },
  },
  methods: {
    /** * Returns a file name for the download features. */
    getFilename(): string {
      return (
        this.customDownloadFileName?.() ??
        this.chartDef.fileName ??
        this.chartDef.title ??
        this.chartDef.id
      )
    },
    /**
     * Returns the current Chartjs instance.
     * https://vue-chartjs.org/guide/#access-to-chart-instance
     *
     * Important: it can't be a computed property because the chartjs instance
     * is discarded every time the chart changes/updates.
     */
    getChartInstance(): Chart {
      const chartInstance = (
        this.$refs[this.REF_CHART] as
          | undefined
          | (DefineComponent & { chart: Chart })
      )?.chart

      if (!chartInstance)
        throw new Error(`chart instance not found for "${this.chartDef.id}"`)

      return chartInstance
    },
    async downloadPng(): Promise<void> {
      try {
        const blob = await (
          await fetch(getChartAsDataUrl(this.getChartInstance()))
        ).blob()

        downloadBlob(blob, this.getFilename())
      } catch (err) {
        console.error('AbstractChart.downloadPng: %o', err)
      }
    },
    async downloadCsv(): Promise<void> {
      try {
        const visibleData = getVisibleData(this.getChartInstance())
        const rows = this.customDownloadCsv?.(visibleData)

        if (!rows) throw new Error('no rows returned')

        await downloadCsv(rows, this.getFilename())
      } catch (err) {
        console.error('AbstractChart.downloadCsv: %o', err)
      }
    },
  },
})
</script>

<style lang="scss">
.c-AbstractChart {
  padding-bottom: 1rem;

  // The CSS below facilitates the placement of content on the right side of
  // the chart by leveraging CSS Grid. This enables the parent component to
  // render any content in the 'right-side' slot adjacent to the chart.
  display: grid;
  grid-template-columns: 1fr 0fr;
  grid-template-rows: auto auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  .top-bar {
    grid-area: 1 / 1 / 2 / 2;
  }
  .chart-area {
    grid-area: 2 / 1 / 3 / 2;
  }
  .right-side {
    grid-area: 2 / 2 / 3 / 3;
  }
}
</style>
