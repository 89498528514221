import { PlainMessage } from '@bufbuild/protobuf'
import { get } from 'lodash-es'

import { TEXT_NO_VALUE } from '@/constants/formatText'
import { ResourceType } from '@/constants/resourceType'
import { numberNoDecimals } from '@/utils/formatText'
import { getResourceTypeDisplayName, getUnqualifiedId } from '@/model/resource'
import {
  AnyRow,
  FilterFields,
  Filters,
  FilterFieldsMinMax,
  FilterResourceList,
  Header,
  Options,
} from './DataTable'
import {
  addKvaSuffix,
  addKwSuffix,
  addKwhSuffix,
  addMwSuffix,
  addMwhSuffix,
  addPercentageSuffix,
  hasFilterValue,
  newFilterFieldsMinMax,
  MULTIPLIER_KW,
  MULTIPLIER_MW,
  MULTIPLIER_PERCENT,
  IS_LOADING,
} from './helper'
import {
  FilterBy,
  FilterBy_Range as FilterByRange,
  OrderBy,
} from 'rfs/frontend/proto/pagination_pb'
import { ListValue, Value } from '@/services/query.service'
import { Format } from '@/utils/format'
import { formatDt } from '@/utils/time'

export enum ColumnType {
  /** A number with no decimal */
  INTEGER,
  /** A number with two decimal places */
  DECIMAL,
  /** Values are shown as "NN.NN%" */
  PERCENT,
  /** Values are shown as "YYYY-MM-DD hh:mm:ss" */
  DATETIME,
  /** Multiply by 1,000 and use one decimal place. */
  KILO_VA,
  /** Multiply by 1,000 and use one decimal place. */
  KILO_W,
  /** Multiply by 1,000,000 and use one decimal place. */
  MEGA_W,
  /** Multiply by 1,000 and use one decimal place. */
  KILO_WH,
  /** Multiply by 1,000,000 and use one decimal place. */
  MEGA_WH,
  /** It's expected the row contains '<row>.resource' available */
  RESOURCE_ROUTE_LINK,
  /** Value is a TimeSeries rendered in a sparkline chart */
  SPARKLINE,
  /** Value is a `ResourceType` */
  RESOURCE_TYPE,
  /** Value is a `ResourceType.SUBSTATION` */
  SUBSTATION,
  /** Value is a `ResourceType.FEEDER` */
  FEEDER,
}

/** Constant for `cellProps` that prevents long lines from wrapping. */
export const NO_WRAP_CELL = { class: ['text-no-wrap'] }

// TODO(andrew): create an API in `@/utils/format` for these

const numberWithDecimals = new Intl.NumberFormat('en-US', {
  useGrouping: true,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

const numberForKiloAndMega = new Intl.NumberFormat('en-US', {
  useGrouping: true,
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
})

/**
 * Formats cell value based on the column type.
 * Values are assumed to be in a reasonable type for formatting. For example,
 * a value for `ColumnType.PERCENT` is of type `number`.
 */
export function getCellValue(row: AnyRow, header: Header): any {
  const value = get(row, header.key)

  if (value === IS_LOADING) {
    return value
  } else if (value == null || Number.isNaN(value)) {
    return TEXT_NO_VALUE
  }

  switch (header.valueType) {
    case ColumnType.INTEGER:
      if (value === 0) return TEXT_NO_VALUE
      return numberNoDecimals.format(value)
    case ColumnType.DECIMAL:
      return numberWithDecimals.format(value)
    case ColumnType.PERCENT:
      return Format.fmtPercent(value)
    case ColumnType.DATETIME:
      return formatDt(value)
    case ColumnType.KILO_VA:
    case ColumnType.KILO_W:
    case ColumnType.KILO_WH:
      return numberForKiloAndMega.format(value / 1_000)
    case ColumnType.MEGA_W:
    case ColumnType.MEGA_WH:
      return numberForKiloAndMega.format(value / 1_000_000)
    case ColumnType.RESOURCE_TYPE:
      return getResourceTypeDisplayName(value)
    default:
      return value
  }
}

/** Convert a DataTable orderBy option into an RFS OrderBy message. */
export function convertOrderBy(
  order: Options['orderBy']
): PlainMessage<OrderBy> {
  return { property: order.column, descending: order.descending }
}

/**
 * Create the filters for all filterable columns that use `valueType`.
 */
export function newFiltersMap(headers: Header[]): Filters<string> {
  return headers
    .filter((header) => header.filterable !== false)
    .reduce((acc, header) => {
      return header.valueType !== undefined
        ? acc.set(header.key, newFilterField(header.valueType))
        : acc
    }, new Map())
}

export function newFilterField(valueType?: ColumnType): FilterFields | null {
  if (valueType == null) return null

  switch (valueType) {
    case ColumnType.INTEGER:
    case ColumnType.DECIMAL:
      return newFilterFieldsMinMax()
    case ColumnType.PERCENT:
      return addPercentageSuffix(newFilterFieldsMinMax(MULTIPLIER_PERCENT))
    case ColumnType.DATETIME:
      return { type: 'date', label: 'YYYY-MM-DD', value: null }
    case ColumnType.KILO_VA:
      return addKvaSuffix(newFilterFieldsMinMax(MULTIPLIER_KW))
    case ColumnType.KILO_W:
      return addKwSuffix(newFilterFieldsMinMax(MULTIPLIER_KW))
    case ColumnType.KILO_WH:
      return addKwhSuffix(newFilterFieldsMinMax(MULTIPLIER_KW))
    case ColumnType.MEGA_W:
      return addMwSuffix(newFilterFieldsMinMax(MULTIPLIER_MW))
    case ColumnType.MEGA_WH:
      return addMwhSuffix(newFilterFieldsMinMax(MULTIPLIER_MW))
    case ColumnType.SUBSTATION:
      return newResourceTypeFilter(ResourceType.SUBSTATION)
    case ColumnType.FEEDER:
      return newResourceTypeFilter(ResourceType.FEEDER)
    default:
      return null
  }
}

export function newResourceTypeFilter(
  resourceType: ResourceType
): FilterResourceList {
  return {
    type: 'resources',
    groupKey: { case: 'resourceType', value: resourceType },
    value: [],
  }
}

const ISO_OPTS = { suppressMilliseconds: true, suppressSeconds: true } as const

export function convertFilters(filters: Filters): PlainMessage<FilterBy>[] {
  // Only convert entries that have values
  const entries = Array.from(filters.entries()).filter(([_, field]) =>
    hasFilterValue(field)
  )

  return entries.map(([property, field]) => {
    let criteria: FilterBy['criteria']

    switch (field.type) {
      case 'dropdown':
        criteria = {
          case: 'equals',
          value: Value.fromJson(field.value),
        }
        break
      case 'multiselect': {
        criteria = { case: 'anyOf', value: ListValue.fromJson(field.value) }
        break
      }
      case 'resources': {
        const values = field.value.map((v) => getUnqualifiedId(v.id))
        criteria = { case: 'anyOf', value: ListValue.fromJson(values) }
        break
      }
      case 'date':
        criteria = {
          case: 'inRange',
          value: new FilterByRange({
            min: Value.fromJson(field.value!.startOf('day').toISO(ISO_OPTS)),
            max: Value.fromJson(field.value!.endOf('day').toISO(ISO_OPTS)),
          }),
        }
        break
      case 'min-max':
        criteria = {
          case: 'inRange',
          value: newRangeValue(field),
        }
        break
    }
    return { property, criteria }
  })
}

function newRangeValue(field: FilterFieldsMinMax): FilterByRange {
  const { min, max, multiplier = 1 } = field
  return new FilterByRange({
    min: min.value != null ? Value.fromJson(min.value * multiplier) : undefined,
    max: max.value != null ? Value.fromJson(max.value * multiplier) : undefined,
  })
}
