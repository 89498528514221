<template>
  <v-tabs
    class="c-CeTabs"
    :model-value="tabSelected"
    :height="height"
    color="primary"
    background-color="transparent"
    grow
  >
    <v-tab
      v-for="t in tabs"
      :key="t.id"
      :to="t.disabled ? undefined : t.route"
      :disabled="t.disabled"
      class="text-capitalize text-subtitle-1 text-primary"
      style="font-family: 'Lexend Deca', sans-serif !important"
    >
      {{ t.text }}
    </v-tab>
  </v-tabs>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { RouteRecordNormalized } from 'vue-router'
import { LocationAsRelativeRaw as VueRouterLocation } from 'vue-router'
import { TabId } from '@/config/types'
import * as RouteNames from '@/router/routeNames'

type TabOption = {
  id: TabId
  text: string
  route: VueRouterLocation
  disabled?: boolean
}

const tabRoute: Record<TabId, string> = {
  [TabId.MONITOR]: RouteNames.OVERVIEW,
  [TabId.ANALYZE]: RouteNames.ANALYZE,
  [TabId.CONTROL]: RouteNames.CONTROL_OVERVIEW,
  [TabId.CONNECT]: RouteNames.INTERCONNECT,
}

function hasAnyRouteWithTabId(routes: RouteRecordNormalized[], tabId: TabId) {
  return routes.some((r) => r.meta.tab === tabId)
}

function createTabs(routes: RouteRecordNormalized[]): TabOption[] {
  return Object.values(TabId)
    .map((id) => ({
      id,
      text: id,
      route: { name: tabRoute[id] },
      disabled: !hasAnyRouteWithTabId(routes, id),
    }))
    .filter((tab) => tab.id !== TabId.CONNECT || !tab.disabled)
  // The Connect tab should be completely hidden if it's not enabled
}

export default defineComponent({
  name: 'CeTabs',
  props: {
    height: {
      type: String,
      required: true,
    },
  },
  data() {
    return { tabs: createTabs(this.$router.getRoutes()) }
  },
  computed: {
    tabSelected(): number {
      return this.tabs.findIndex((t) => t.id === this.$route.meta?.tab)
    },
  },
})
</script>

<style lang="scss">
.c-CeTabs {
  // NOTE(rafael): makes the slider go to the top of the tab.
  .v-tab__slider {
    bottom: unset;
    top: 0;
  }
}
</style>
