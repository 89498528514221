import { TEXT_NO_VALUE } from '@/constants/formatText'
import { ResourceType } from '@/constants/resourceType'
import { formatDt } from '@/utils/time'
import { getResourceType, getUnqualifiedId } from '@/model/resource'
import {
  Notification,
  Notification_Response as NotificationResponse,
  Notification_Severity as NotificationSeverity,
  Notification_State as NotificationState,
} from 'rfs/frontend/proto/notification_pb'

export function getResponseLabel(
  response: NotificationResponse | undefined
): string {
  switch (response) {
    case NotificationResponse.ALERT:
      return 'Alert'
    case NotificationResponse.CONSTRAINT:
      return 'Constraint'
    default:
      return 'UNSPECIFIED'
  }
}

export function getSeverityLabel(
  severity: NotificationSeverity | undefined
): string {
  switch (severity) {
    case NotificationSeverity.LOW:
      return 'Low'
    case NotificationSeverity.MEDIUM:
      return 'Medium'
    case NotificationSeverity.HIGH:
      return 'High'
    default:
      return 'UNSPECIFIED'
  }
}

export function getStateLabel(state: NotificationState | undefined): string {
  switch (state) {
    case NotificationState.ACTIVE:
      return 'Active'
    case NotificationState.INACTIVE:
      return 'Inactive'
    case NotificationState.FORECASTED:
      return 'Forecasted'
    default:
      return 'UNSPECIFIED'
  }
}

export function getResourceLabel(
  notification: Notification | undefined
): string {
  if (notification?.resourceId) {
    return getUnqualifiedId(notification.resourceId)
  } else {
    return TEXT_NO_VALUE
  }
}

export function getNotificationTitle(
  notification: Notification | undefined
): undefined | string {
  if (notification === undefined) {
    return undefined
  }
  if (notification.response === NotificationResponse.ALERT) {
    return notification.condition
  } else if (isFromOperatingEnvelope(notification)) {
    return 'Managed charging'
  } else {
    return undefined
  }
}

export function getNotificationDescription(
  notification: Notification | undefined
): undefined | string {
  if (notification === undefined) {
    return undefined
  }
  if (
    isFromOperatingEnvelope(notification) &&
    getResourceType(notification?.resourceId) === ResourceType.TRANSFORMER
  ) {
    const transformer = getUnqualifiedId(notification.resourceId)
    const start = formatDt(notification.startTime)
    const end = formatDt(notification.endTime)
    return `EV charging is forecasted to be constrained on transformer ${transformer} from ${start} until ${end}.`
  } else {
    return undefined
  }
}

function isFromOperatingEnvelope(notification: Notification): boolean {
  return notification.response === NotificationResponse.CONSTRAINT
}
