<template>
  <div class="px-6">
    <ce-title big class="pb-6">Voltage Deviation</ce-title>
    <time-series-chart-group
      :charts
      :data-source
      :interval
      @new-interval="onNewInterval"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, shallowReactive } from 'vue'
import type { Interval } from 'luxon'
import { intervalLast30Days } from '@/utils/time'
import {
  chart,
  newVoltageDeviationDataSource,
} from '@/model/grid/voltageDeviation'
import CeTitle from '@/components/CeTitle.vue'
import TimeSeriesChartGroup from '@/components/common/TimeSeriesChartGroup.vue'

export default defineComponent({
  name: 'VoltageDeviationLeftPanel',
  components: { CeTitle, TimeSeriesChartGroup },
  setup() {
    return { charts: [chart] }
  },
  data() {
    return shallowReactive({
      interval: intervalLast30Days(this.$observationTime()),
    })
  },
  computed: {
    dataSource() {
      return newVoltageDeviationDataSource(this.$services)
    },
  },
  methods: {
    onNewInterval(newInterval: Interval): void {
      this.interval = newInterval
    },
  },
})
</script>
