<template>
  <div class="c-TimeSeriesChartGroup" role="region" aria-label="Chart group">
    <!-- Controls -->
    <!-- custom bottom padding to make height 64px (4 * 16) -->
    <div
      v-if="!hideDateRangePicker"
      class="d-flex align-center"
      style="padding-bottom: 22px"
    >
      <!-- Interval Picker -->
      <interval-picker
        v-if="!hideIntervalPicker"
        class="mr-4"
        :interval
        @interval="emitNewInterval"
      />

      <!-- Interval Date Picker -->
      <date-range-picker
        :max-end-time="internalMaxEndTime"
        :interval
        @interval="emitNewInterval"
      />

      <v-spacer />

      <!-- Resolution and Last Update -->
      <p class="text-caption">{{ lastUpdateAndResolution }}</p>
    </div>
    <!-- Hint for scroll-to-zoom -->
    <p
      v-if="showHintMsg"
      class="text-caption"
      style="position: absolute; margin-top: -22px"
    >
      Hold down Ctrl key when hovering over chart to zoom using mouse wheel.
    </p>

    <!-- A slot for additional non-infinite charts or messages -->
    <slot />

    <!-- Charts -->
    <template v-for="chartDef in charts">
      <component
        v-if="chartVisibility(chartDef)"
        :key="chartDef.id"
        :is="chartComponent(chartDef)"
        :chart-def="chartDef"
        :chart-series="chartSeries(chartDef)"
        :chart-options="chartOptions(chartDef)"
        :chart-formatters="chartFormatters(chartDef)"
        :interval="interval"
        :is-loading="isLoading"
        :disable-scroll="isDisableScroll"
        :max-end-time="internalMaxEndTime"
        :now="now"
        @interval="emitNewInterval"
      >
        <!-- Slot after the chart's title -->
        <template
          v-if="$slots[`after-title-of-${chartDef.id}`]"
          v-slot:after-title
        >
          <slot :name="`after-title-of-${chartDef.id}`" />
        </template>

        <!-- A slot for components to render in the right side of the chart -->
        <template
          v-if="$slots[`right-side-of-${chartDef.id}`]"
          v-slot:right-side
        >
          <slot :name="`right-side-of-${chartDef.id}`" />
        </template>
      </component>

      <!-- A slot for components to render after the chart -->
      <slot :name="chartDef.id" />
    </template>
  </div>
</template>

<script lang="ts">
import { ChartOptions } from 'chart.js'
import { DateTime, Interval } from 'luxon'
import { defineComponent, PropType, shallowReactive } from 'vue'
import { ChartDefinition, ChartFormatters, ChartType } from '@/types/charts'
import {
  ChartResolution,
  resolutionDurations,
  resolutionFromTimeSeries,
} from '@/utils/charts'
import { IntervalBroadcaster } from '@/utils/time/IntervalBroadcaster'
import {
  NamedTimeSeries,
  ITimeSeriesDataSource,
  TimeSeriesMap,
  EventName,
} from '@/model/charts/TimeSeriesDataSource'
import { FixedTimeDataSource } from '@/model/charts/FixedTimeDataSource'
import {
  chartFormattersByChartType,
  createLastUpdateString,
} from '@/model/charts/formatters'
import { Analytics } from '@/utils/analytics'
import {
  createChartGroupId,
  syncPluginOptions,
  persistPluginOptions,
} from '@/utils/chartjs'
import { durationFmt } from '@/utils/time'
import IntervalPicker from './IntervalPicker.vue'
import DateRangePicker from './DateRangePicker.vue'
import TimeSeriesChart from './TimeSeriesChart.vue'
import LoadDurationChart from './LoadDurationChart.vue'
import {
  createVoltageAnnotation,
  createThresholdAnnotation,
} from '@/utils/chartjs/annotations'

type ChartsProp = ChartDefinition[]
type DataSourceProp = ITimeSeriesDataSource

export default defineComponent({
  name: 'TimeSeriesChartGroup',
  props: {
    charts: {
      type: Array as PropType<ChartsProp>,
      required: true,
    },
    dataSource: {
      type: Object as PropType<DataSourceProp>,
      required: true,
    },
    /** An alternative to `dataSource` for components that fetch their own data */
    timeSeriesMap: {
      type: Map as PropType<TimeSeriesMap>,
      required: false,
    },
    interval: {
      type: Object as PropType<Interval>,
      required: true,
    },
    disableScroll: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideDateRangePicker: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxEndTime: {
      type: Object as PropType<undefined | DateTime>,
      required: false,
    },
    now: {
      type: Object as PropType<undefined | DateTime>,
      required: false,
    },
    disableY2AxisSync: {
      type: Boolean,
      required: false,
    },
    hideIntervalPicker: {
      type: Boolean,
      required: false,
    },
    /**
     * Triggers automatic chart reload at specified intervals.
     *
     * NOTE: For the automatic reload to work, the data source must be an
     * instance of `TimeSeriesWithRefreshDataSource`.
     */
    intervalBroadcaster: {
      type: Object as PropType<undefined | IntervalBroadcaster>,
      required: false,
    },
  },
  emits: ['new-interval', 'new-time-series'],
  components: {
    IntervalPicker,
    DateRangePicker,
    TimeSeriesChart,
    LoadDurationChart,
  },
  data() {
    // The data is shallow reactive so that time series data is not wrapped in proxies.
    // When there are thousands of data points, we don't want Vue attaching proxies to them all.
    return shallowReactive({
      // Start out with an empty series list for each chart
      fetchedTimeSeries: new Map() as TimeSeriesMap,
      isLoading: false,
      internalMaxEndTime: this.maxEndTime ?? this.$observationTime(),
      lastUpdate: '',
    })
  },
  computed: {
    chartsTimeSeries(): TimeSeriesMap {
      return this.timeSeriesMap ?? this.fetchedTimeSeries
    },
    // If the data source is FixedTimeDataSource, scrolling is disabled on the chart group.
    // Or if the user has explicitly disabled scrolling.
    isDisableScroll(): boolean {
      return (
        this.dataSource instanceof FixedTimeDataSource || this.disableScroll
      )
    },
    showHintMsg(): boolean {
      return !this.hideDateRangePicker && !this.isDisableScroll
    },
    lastUpdateAndResolution(): string {
      if (this.lastUpdate) {
        return `${this.lastUpdate} \u00B7 ${this.resolutionText}`
      } else {
        return this.resolutionText
      }
    },
    resolutionText(): string {
      const resolution = resolutionFromTimeSeries(this.chartsTimeSeries)
      let value: string

      if (resolution === ChartResolution.Various) {
        value = 'Various'
      } else if (resolution === ChartResolution.Raw) {
        value = 'Raw'
      } else {
        value = durationFmt(resolutionDurations.get(resolution)!)
      }
      return value ? `Resolution: ${value}` : ''
    },
    /* this computed property exists only to be watched */
    computedProps(): [ChartsProp, DataSourceProp, Interval] {
      return [this.charts, this.dataSource, this.interval]
    },
  },
  watch: {
    computedProps: {
      immediate: true,
      handler: function () {
        // Fetch time series data when the time series map is not provided.
        if (this.timeSeriesMap == null) this.fetchTimeSeries()
      },
    },
    dataSource: {
      immediate: true,
      handler: function (
        newDataSource: DataSourceProp,
        oldDataSource: undefined | DataSourceProp
      ) {
        if (oldDataSource) this.unsubscribeFromDataSource(oldDataSource)
        this.subscribeToDataSource(newDataSource)
      },
    },
    intervalBroadcaster: {
      immediate: true,
      handler: function (
        newIntervalBroadcaster: undefined | IntervalBroadcaster,
        oldIntervalBroadcaster: undefined | IntervalBroadcaster
      ) {
        this.unsubscribeFromIntervalBroadcaster(oldIntervalBroadcaster)
        this.subscribeToIntervalBroadcaster(newIntervalBroadcaster)
      },
    },
    maxEndTime(newMaxEndTime: undefined | DateTime): void {
      if (newMaxEndTime) {
        this.internalMaxEndTime = newMaxEndTime
      }
    },
  },
  beforeUnmount(): void {
    this.unsubscribeFromDataSource(this.dataSource)
    this.unsubscribeFromIntervalBroadcaster(this.intervalBroadcaster)
  },
  methods: {
    chartComponent(chartDef: ChartDefinition): string {
      if (chartDef.type === ChartType.LoadDuration) {
        return 'load-duration-chart'
      }
      return 'time-series-chart'
    },
    updateIsLoading(newValue: boolean): void {
      this.isLoading = newValue
    },
    subscribeToDataSource(dataSource: DataSourceProp): void {
      dataSource.subscribe?.(EventName.IS_REFRESHING, this.updateIsLoading)
      dataSource.subscribe?.(
        EventName.HAS_REFRESHED_SUCCESSFULLY,
        this.fetchTimeSeries
      )
    },
    unsubscribeFromDataSource(dataSource: DataSourceProp): void {
      dataSource.unsubscribe?.(EventName.IS_REFRESHING, this.updateIsLoading)
      dataSource.unsubscribe?.(
        EventName.HAS_REFRESHED_SUCCESSFULLY,
        this.fetchTimeSeries
      )
    },
    subscribeToIntervalBroadcaster(
      intervalBroadcaster?: IntervalBroadcaster
    ): void {
      intervalBroadcaster?.subscribe(this.refreshChart)
    },
    unsubscribeFromIntervalBroadcaster(
      intervalBroadcaster?: IntervalBroadcaster
    ): void {
      intervalBroadcaster?.unsubscribe(this.refreshChart)
    },
    chartFormatters(chartDef: ChartDefinition): ChartFormatters {
      return chartFormattersByChartType[chartDef.type]
    },
    chartSeries(chartDef: ChartDefinition): readonly NamedTimeSeries[] {
      // This returns the array of series that will be passed to a specific chart
      return this.chartsTimeSeries.get(chartDef.id) ?? []
    },
    chartOptions(chartDef: ChartDefinition): ChartOptions[] {
      const options: ChartOptions[] = []

      // Only time series charts needs synchronization.
      if (this.chartComponent(chartDef) === 'time-series-chart') {
        options.push(
          syncPluginOptions({
            group: createChartGroupId(this.charts),
            disableY2AxisSync: this.disableY2AxisSync,
          })
        )
      }

      // Persist plugin.
      if (chartDef.persistVisibility) {
        options.push(
          persistPluginOptions({
            chartId: chartDef.id,
            chartSeries: this.chartSeries(chartDef),
          })
        )
      }

      const series = this.chartsTimeSeries.get(chartDef.id)

      if (chartDef.annotations) {
        const { voltageTolerance, threshold } = chartDef.annotations

        if (voltageTolerance && series?.length) {
          options.push(createVoltageAnnotation(voltageTolerance, series))
        }

        if (threshold) {
          options.push(
            createThresholdAnnotation({
              chartId: chartDef.id,
              config: threshold,
              persistVisibility: chartDef.persistVisibility,
            })
          )
        }
      }

      return options
    },
    chartVisibility(chartDef: ChartDefinition): boolean {
      if (chartDef.visible === 'requires-data') {
        const series = this.chartsTimeSeries.get(chartDef.id)
        return series?.some((s) => s.data.length !== 0) ?? false
      }
      return chartDef.visible !== false
    },
    emitNewInterval(newInterval: Interval): void {
      if (!newInterval.equals(this.interval)) {
        this.$emit('new-interval', newInterval)
        Analytics.logSelectTimeRange(newInterval)
      }
    },
    async fetchTimeSeries(): Promise<void> {
      const interval = this.interval
      console.debug('[TSChartGroup] fetchTimeSeries', interval.toString())

      try {
        this.updateIsLoading(true)
        this.fetchedTimeSeries = await this.dataSource.fetchTimeSeries(interval)
        // Tell the parent that we have new data
        this.$emit('new-time-series', this.fetchedTimeSeries)
      } catch (error) {
        console.error('[TSChartGroup] error fetching time series', error)
      } finally {
        this.updateIsLoading(false)
      }

      // Don't need to request the previous interval if scrolling is disabled
      if (this.isDisableScroll) return

      try {
        await this.$nextTick()
        // Also request the next previous visible interval, in case the user scrolls the
        // chart into the past. This is done in the next tick so we can immediately render
        // the chart with the visible data.
        this.fetchedTimeSeries = await this.dataSource.fetchTimeSeries(
          Interval.before(interval.start, interval.toDuration())
        )
      } catch (error) {
        console.error('[TSChartGroup] error fetching prior interval', error)
      }
    },
    /**
     * 1. Updates the "maxEndTime" variable.
     * 2. Invokes the data source's pub/sub refresh method.
     */
    refreshChart(): void {
      const newMaxEndTime = this.internalMaxEndTime.plus({
        milliseconds: this.intervalBroadcaster?.duration,
      })
      this.internalMaxEndTime = newMaxEndTime
      this.dataSource.updateFromCutOffDate?.(newMaxEndTime)
      this.lastUpdate = createLastUpdateString()
    },
  },
})
</script>
