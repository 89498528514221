import type { DateTime, Interval } from 'luxon'
import { BLUE_500, JAFFA, JAM_400 } from '@/constants/colors'
import { Metric } from '@/constants/metrics'
import { ResourceType } from '@/constants/resourceType'
import type { Services } from '@/services'
import { MetricCalculation } from '@/services/charts.service'
import { Format } from '@/utils/format'
import {
  AggregateDataSource,
  FixedTimeDataSource,
  type ITimeSeriesDataSource,
} from '@/model/charts'
import { getUnqualifiedId } from '@/model/resource'
import { limitInterval } from '@/utils/time'
import {
  ChartType,
  type ChartDefinition,
  type HorizontalBarChartData,
} from '@/types/charts'
import { Resolution } from 'rfs/frontend/proto/resolution_pb'
import type { Resource } from 'rfs/pb/resource_pb'
import type {
  DERImpactsRow,
  DERImpactsTableResponse,
} from 'rfs/frontend/proto/analysis_pb'

const SYSTEM_ID = 'aggregation/SYSTEM'

export const chartChargingLoad: ChartDefinition = {
  id: 'insights-charging-load',
  title: 'System EV load',
  type: ChartType.Power,
  isStackedBar: true,
  yAxis: { title: 'Charging (kW)' },
}

export const chartDistributedGeneration: ChartDefinition = {
  id: 'insights-distributed-generation',
  title: 'System PV generation',
  type: ChartType.PowerMW,
  isStackedBar: true,
  yAxis: { title: 'Generation (MW)' },
}

const INSIGHT_RESOLUTION = Resolution.ONE_HOUR
const INSIGHT_CALCULATION = MetricCalculation.SUM

export function newEvInsightDataSource(
  now: DateTime,
  services: Services,
  interval: Interval,
  detected: string[],
  known: string[]
): ITimeSeriesDataSource {
  const metric = Metric.COND_POWER_REAL

  // Known EVs.
  const ds01 = new FixedTimeDataSource(
    limitInterval(interval, now),
    async (request) =>
      services.chartsService.fetchMultiTimeSeries(known, {
        ...request,
        resolution: INSIGHT_RESOLUTION,
      })
  )

  ds01.addChartSeries(chartChargingLoad, {
    metric,
    calculation: INSIGHT_CALCULATION,
    config: { seriesName: 'Known EVs', seriesColor: BLUE_500.hex },
  })

  // // // // // // // // // // // // // // // // // // // // // // // // // //
  // // // // // // // // // // // // // // // // // // // // // // // // // //
  // // // // // // // // // // // // // // // // // // // // // // // // // //

  // Detected EVs.
  const ds02 = new FixedTimeDataSource(
    limitInterval(interval, now),
    async (request) =>
      services.chartsService.fetchMultiTimeSeries(detected, {
        ...request,
        resolution: INSIGHT_RESOLUTION,
      })
  )

  ds02.addChartSeries(chartChargingLoad, {
    metric,
    calculation: INSIGHT_CALCULATION,
    config: { seriesName: 'Detected EVs', seriesColor: JAM_400.hex },
  })

  return new AggregateDataSource(ds01, ds02)
}

export function newEvInsightDataSource2(
  now: DateTime,
  services: Services,
  interval: Interval
): ITimeSeriesDataSource {
  const ds = new FixedTimeDataSource(
    limitInterval(interval, now),
    async (request) =>
      services.chartsService.fetchTimeSeries(SYSTEM_ID, {
        ...request,
        // All chargers in the system.
        resolution: INSIGHT_RESOLUTION,
        aggregation: ResourceType.CHARGER,
      })
  )

  ds.addChartSeries(chartChargingLoad, {
    metric: Metric.COND_POWER_REAL,
    config: { seriesName: 'EV Load', seriesColor: BLUE_500.hex },
  })

  return ds
}

function getFeederEvMap(
  feeders: Resource[],
  chargers: Resource[]
): Map<string, number> {
  const feederEvMap = feeders.reduce((acc, feeder) => {
    acc.set(getUnqualifiedId(feeder.id), 0)
    return acc
  }, new Map<string, number>())

  for (const charger of chargers) {
    const unqualifiedFeederId = charger.upline?.feeder

    if (unqualifiedFeederId) {
      feederEvMap.set(
        unqualifiedFeederId,
        (feederEvMap.get(unqualifiedFeederId) ?? 0) + 1
      )
    }
  }

  return feederEvMap
}

export function getNumberOfEvsByFeeder(
  feeders: Resource[],
  chargers: Resource[]
): number[] {
  return Array.from(getFeederEvMap(feeders, chargers).values())
}

export function getTopFeedersByNumberOfEvs(
  feeders: Resource[],
  chargers: Resource[],
  top: number = 10
): HorizontalBarChartData {
  const sorted = Array.from(getFeederEvMap(feeders, chargers).entries()).sort(
    ([_aId, aNum], [_bId, bNum]) => bNum - aNum
  )

  return {
    bars: sorted.slice(0, top).map(([unqualifiedFeederId, numOfEvs]) => {
      return { label: unqualifiedFeederId, value: numOfEvs }
    }),
  }
}

function getFeederMaxEvLoadMap(
  tableResponse: DERImpactsTableResponse
): Map<string, number> {
  return tableResponse.rows.reduce((acc, row) => {
    acc.set(
      row.id,
      parseFloat(((row.data?.maxEvLoad || 0) / 1_000).toFixed(2)) // W -> kW
    )
    return acc
  }, new Map())
}

export function getMaxEvLoadByFeeder(
  tableResponse: DERImpactsTableResponse
): number[] {
  return Array.from(getFeederMaxEvLoadMap(tableResponse).values())
}

export function getTopFeedersByMaxEvLoad(
  tableResponse: DERImpactsTableResponse,
  top: number = 10
): HorizontalBarChartData {
  const sorted = Array.from(
    getFeederMaxEvLoadMap(tableResponse).entries()
  ).sort(([_aId, aNum], [_bId, bNum]) => bNum - aNum)

  return {
    bars: sorted.slice(0, top).map(([unqualifiedFeederId, maxEvLoad]) => {
      return { label: unqualifiedFeederId, value: maxEvLoad }
    }),
  }
}

function getFeederPvMap(
  feeders: Resource[],
  distributedSolars: Resource[]
): Map<string, number> {
  const feederPvMap = feeders.reduce((acc, feeder) => {
    acc.set(getUnqualifiedId(feeder.id), 0)
    return acc
  }, new Map<string, number>())

  for (const dSolar of distributedSolars) {
    const unqualifiedFeederId = dSolar.upline?.feeder

    if (unqualifiedFeederId) {
      feederPvMap.set(
        unqualifiedFeederId,
        (feederPvMap.get(unqualifiedFeederId) ?? 0) + 1
      )
    }
  }

  return feederPvMap
}

export function getNumberOfPvsByFeeder(
  feeders: Resource[],
  distributedSolars: Resource[]
): number[] {
  return Array.from(getFeederPvMap(feeders, distributedSolars).values())
}

export function getTopFeedersByNumberOfPvs(
  feeders: Resource[],
  distributedSolars: Resource[],
  top: number = 10
): HorizontalBarChartData {
  const sorted = Array.from(
    getFeederPvMap(feeders, distributedSolars).entries()
  ).sort(([_aId, aNum], [_bId, bNum]) => bNum - aNum)

  return {
    bars: sorted.slice(0, top).map(([unqualifiedFeederId, numOfPvs]) => {
      return { label: unqualifiedFeederId, value: numOfPvs }
    }),
  }
}

function getFeederMaxPvGenerationMap(
  tableResponse: DERImpactsTableResponse
): Map<string, number> {
  return tableResponse.rows.reduce((acc, row) => {
    acc.set(
      row.id,
      parseFloat(((row.data?.maxPvProduction || 0) / 1_000).toFixed(2)) // W -> kW
    )
    return acc
  }, new Map())
}

export function getMaxPvGenerationByFeeder(
  tableResponse: DERImpactsTableResponse
): number[] {
  return Array.from(getFeederMaxPvGenerationMap(tableResponse).values())
}

export function getTopFeedersByMaxPvGeneration(
  tableResponse: DERImpactsTableResponse,
  top: number = 10
): HorizontalBarChartData {
  const sorted = Array.from(
    getFeederMaxPvGenerationMap(tableResponse).entries()
  ).sort(([_aId, aNum], [_bId, bNum]) => bNum - aNum)

  return {
    bars: sorted.slice(0, top).map(([unqualifiedFeederId, maxPvGeneration]) => {
      return { label: unqualifiedFeederId, value: maxPvGeneration }
    }),
  }
}

export function newPvInsightDataSource(
  now: DateTime,
  services: Services,
  interval: Interval,
  distributedSolarIds: string[]
): ITimeSeriesDataSource {
  const ds = new FixedTimeDataSource(
    limitInterval(interval, now),
    async (request) =>
      services.chartsService.fetchMultiTimeSeries(distributedSolarIds, {
        ...request,
        resolution: INSIGHT_RESOLUTION,
      })
  )

  ds.addChartSeries(chartDistributedGeneration, {
    metric: Metric.POWER_PRODUCED,
    calculation: INSIGHT_CALCULATION,
    config: {
      seriesName: 'Metered Solar',
      seriesColor: JAFFA.hex,
    },
  })

  return ds
}

export function newPvInsightDataSource2(
  now: DateTime,
  services: Services,
  interval: Interval
): ITimeSeriesDataSource {
  const ds = new FixedTimeDataSource(
    limitInterval(interval, now),
    async (request) =>
      services.chartsService.fetchTimeSeries(SYSTEM_ID, {
        ...request,
        // All solars in the system.
        resolution: INSIGHT_RESOLUTION,
        aggregation: ResourceType.SOLAR_DISTRIBUTED,
      })
  )

  ds.addChartSeries(chartDistributedGeneration, {
    metric: Metric.COND_POWER_REAL,
    unit: '-W',
    config: {
      seriesName: 'Metered Solar',
      seriesColor: JAFFA.hex,
    },
  })

  return ds
}

function getPeakUtilizationPercentage(row: DERImpactsRow): number {
  const result = (row.data?.utilizationRate || 0) * 100 // 0.5 --> 50%
  return parseFloat(result.toFixed(2))
}

export function getPeakUtilizationHistogram(
  tableResponse: DERImpactsTableResponse
): number[] {
  return tableResponse.rows.map(getPeakUtilizationPercentage)
}

export function getTopPeakUtilization(
  tableResponse: DERImpactsTableResponse,
  top: number = 10
): {
  chart: HorizontalBarChartData
  sideData: { items: string[] }
} {
  // The response is sorted by 'data.utilizationRate' descending
  const sliced = tableResponse.rows
    .slice(0, top)
    .map((row): [string, number, string] => [
      row.id,
      getPeakUtilizationPercentage(row),
      Format.fmtPercent(row.data?.evLoadFracAtPeak ?? 0).replace('%', ''),
    ])

  return {
    chart: {
      bars: sliced.map(([unqualifiedId, peakUtilization]) => {
        return { label: unqualifiedId, value: peakUtilization }
      }),
    },
    sideData: {
      items: sliced.map(([_unqualifiedId, _peakUtilization, evLoadAtPeak]) => {
        return evLoadAtPeak
      }),
    },
  }
}
