import type { Services } from '@/services'
import { ORANGE_CAMUS_ENERGY, VIOLET_500 } from '@/constants/colors'
import { ResourceType } from '@/constants/resourceType'
import { Metric } from '@/constants/metrics'
import { TimeSeriesDataSource } from '@/model/charts'
import { type ChartDefinition, ChartType } from '@/types/charts'

export const chart: ChartDefinition = {
  id: 'voltage-deviation-chart',
  title: '',
  type: ChartType.Violations,
  isStackedBar: true,
}

export function newVoltageDeviationDataSource(services: Services) {
  const ds = new TimeSeriesDataSource((request) => {
    request.aggregation = ResourceType.METER_ELECTRICAL
    return services.chartsService.fetchTimeSeries('aggregation/SYSTEM', request)
  })

  ds.addChartSeries(chart, {
    metric: Metric.AGGREGATION_COUNT_VOLTAGE_MIN,
    aggregation: ResourceType.METER_ELECTRICAL,
    config: {
      seriesName: 'Under voltage',
      seriesColor: VIOLET_500.hex,
      minBarLength: 2,
    },
  })
  ds.addChartSeries(chart, {
    metric: Metric.AGGREGATION_COUNT_VOLTAGE_MAX,
    aggregation: ResourceType.METER_ELECTRICAL,
    config: {
      seriesName: 'Over voltage',
      seriesColor: ORANGE_CAMUS_ENERGY.hex,
      minBarLength: 2,
    },
  })

  return ds
}
