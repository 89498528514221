import { RouteRecordRaw } from 'vue-router'

import { RittaConfig } from '@/config'
import { TabId } from '@/config/types'
import Home from '@/views/Home.vue'
import InterconnectPanel from '@/components/connect/InterconnectPanel.vue'
import * as RouteNames from './routeNames'

export const TAB = TabId.CONNECT

export function connectRoutes(config: Readonly<RittaConfig>): RouteRecordRaw[] {
  if (!config.connect?.enabled) {
    return []
  }

  return [
    {
      path: '/connect',
      component: Home,
      children: [
        {
          path: '',
          name: RouteNames.INTERCONNECT,
          meta: { tab: TAB, drawerLeftWide: true, pageTitle: 'Interconnect' },
          components: { leftPanel: InterconnectPanel },
        },
      ],
    },
  ]
}
