<template>
  <full-height-layout class="px-6" :disabled="compare">
    <!-- Title -->
    <template v-slot:top>
      <ce-title big>{{ title }}</ce-title>
    </template>

    <!-- Loading -->
    <centered-spinner v-if="isLoading" />

    <!-- Data table -->
    <ce-data-table
      v-if="!isLoading && !hasLoadingFailed && dataTable"
      :headers="headers"
      :table="dataTable"
      :expanded="expanded"
      :filters="filters"
      :show-select="compare"
      :selected="selected"
      :bg-color="bgColor"
      v-model:search="search"
      :visible-headers="visibleHeaders"
      :fixed-header="!compare"
      :sticky="compare"
      @new-selected="handleNewSelected"
      @new-visible-headers="handleNewVisibleHeaders"
      @custom-select="handleCustomSelect"
      @new-filters="handleNewFilters"
      @reset-filters="handleResetFilters"
      @new-expanded="(newExpanded) => (expanded = newExpanded)"
      @sparkline-chart-mounted="fetchMoreData"
      @search="handleNewSearch"
    >
      <!-- Compare Chart -->
      <template v-slot:above-actions>
        <time-series-chart-group
          v-if="compare"
          :charts="compareChartDefinitions"
          :data-source="compareChartDataSource"
          :interval="compareChartInterval"
          @new-interval="handleNewCompareChartInterval"
          class="pt-6"
        />
      </template>

      <!-- Compare btn -->
      <template v-slot:left-of-actions>
        <div class="d-flex align-center" style="height: 100%">
          <v-spacer />

          <v-btn
            data-test="btn-compare"
            :outlined="compare"
            color="primary"
            class="elevation-0 mr-8"
            @click="() => (compare = !compare)"
          >
            {{ btnCompareLabel }}
          </v-btn>
        </div>
      </template>
    </ce-data-table>
  </full-height-layout>
</template>

<script lang="ts">
import { defineComponent, shallowReactive } from 'vue'
import { Interval } from 'luxon'
import { debounce } from 'lodash-es'
import { GREY6 } from '@/constants/colors'
import { ResourceType } from '@/constants/resourceType'
import {
  AggregateDataSource,
  ITimeSeriesDataSource,
  TimeSeriesDataSource,
} from '@/model/charts'
import type {
  AnyRow,
  VisibleHeaders,
  RowIDSet,
  SelectMenuItem,
} from '@/model/tables/DataTable'
import { createInitialVisibleHeaders } from '@/model/tables/header'
import {
  IS_LOADING,
  fillPlaceholdersWithTimeSeries,
  hasAtLeastOneFilterValue,
  MapTimeSeries,
  MapTimeSeriesValue,
  allChildRowIDs,
} from '@/model/tables/helper'
import {
  createHeaders,
  createSubstationDataTable,
  fetchTimeSeries,
  isFeederRow,
  SubstationDataTable,
  SubstationOrFeederRow,
  SummaryStatsAll,
  createNewFilters,
  fillFiltersWithPlaceholders,
  CustomFilters,
  expandSubstationRows,
  CustomPlaceholdersMinMax,
  createPlaceholders,
} from '@/model/resource/substation/SubstationDataTable'
import { intervalLast24Hours } from '@/utils/time'
import CeTitle from '@/components/CeTitle.vue'
import FullHeightLayout from '@/components/common/FullHeightLayout.vue'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import TimeSeriesChartGroup from '@/components/common/TimeSeriesChartGroup.vue'
import CeDataTable from '@/components/common/CeDataTable.vue'
import {
  allSubstationsDataSource,
  compareChartDefinition,
  newAggregateComparisonChartDataSource,
  newComparisonChartDataSource,
} from '@/model/resource/TelemetryChartData'
import { Resource } from 'rfs/pb/resource_pb'

type SubstationDataTableOrNull = null | SubstationDataTable

const WAIT = 250 // millseconds

const SELECTED_LIMIT = 10

export default defineComponent({
  name: 'SubstationsLeftPanel',
  components: {
    CeTitle,
    FullHeightLayout,
    CenteredSpinner,
    TimeSeriesChartGroup,
    CeDataTable,
  },
  setup() {
    return {
      WAIT,
      title: 'Substations',
      bgColor: GREY6.rgb,
      searchAndActionsElHeight: '5rem',
      compareChartDefinitions: [compareChartDefinition],
      btnCompareLabel: 'Compare load',
      allSubstations: [] as Resource[],
      allFeeders: [] as Resource[],
      allStats: new SummaryStatsAll(),
    }
  },
  data() {
    const headers = createHeaders(this.$rittaConfig.monitor)
    const visibleHeaders = createInitialVisibleHeaders(headers) // TODO(rafael): persist it.

    return shallowReactive({
      headers,
      visibleHeaders,
      compareChartInterval: intervalLast24Hours(this.$observationTime()),
      isLoading: false,
      hasLoadingFailed: false,
      compare: false,
      search: '',
      dataTable: null as SubstationDataTableOrNull,
      timeSeries: new Map() as MapTimeSeries,
      selected: new Set() as RowIDSet,
      expanded: new Set() as RowIDSet,
      filters: createNewFilters(this.$rittaConfig.monitor),
      placeholders: new Map() as CustomPlaceholdersMinMax,
      dataSources: new Map() as Map<string, ITimeSeriesDataSource>,
    })
  },
  computed: {
    tableDependencies(): [MapTimeSeries, CustomFilters] {
      return [this.timeSeries, this.filters]
    },
    hasTouchedFilters(): boolean {
      return hasAtLeastOneFilterValue(this.filters)
    },
    allSubstationsDataSource(): ITimeSeriesDataSource {
      return allSubstationsDataSource(this.allSubstations, this.$services)
    },
    compareChartDataSource(): ITimeSeriesDataSource {
      return this.generateCompareChartDataSource()
    },
  },
  watch: {
    tableDependencies() {
      this.generateDataTable()
    },
    // TODO(rafael): dettach the expansion of the rows from the data table
    // creation. This is causing a bug where when the user tries to
    // collapse rows, the rows end up being expanded again.
    dataTable(newValue: SubstationDataTableOrNull) {
      if (newValue && (this.search || this.hasTouchedFilters)) {
        this.expanded = expandSubstationRows(newValue)
      }
    },
    timeSeries(newValue: MapTimeSeries) {
      this.placeholders = fillPlaceholdersWithTimeSeries(
        this.placeholders,
        newValue
      )
    },
    placeholders(newValue: CustomPlaceholdersMinMax) {
      this.filters = fillFiltersWithPlaceholders(this.filters, newValue)
    },
  },
  async created(): Promise<void> {
    await this.fetchData()
    this.generatePlaceholders()
    this.generateDataTable()
    this.applyDebounce()
  },
  methods: {
    applyDebounce(): void {
      // NOTE(rafael): Slows down the cadence of re-rendering the DOM.
      // The time series data is being fetched when each row gets
      // visible to the user. When the user scroll too fast
      // through the page the debounce will avoid lag.
      this.generateDataTable = debounce(this.generateDataTable, WAIT)
    },
    generateDataTable(): void {
      this.dataTable = createSubstationDataTable(
        this.$rittaConfig,
        this.allSubstations,
        this.allFeeders,
        this.allStats,
        this.timeSeries,
        this.search,
        this.filters
      )
    },
    generatePlaceholders(): void {
      this.placeholders = createPlaceholders(this.allStats)
    },
    generateCompareChartDataSource(): ITimeSeriesDataSource {
      const selectedIds = Array.from(this.selected.keys())
      let selectedDataSource: ITimeSeriesDataSource =
        newAggregateComparisonChartDataSource(this.$services, selectedIds)

      if (this.selected.size === 0) {
        // Nothing to show but the substation total
        return this.allSubstationsDataSource
      } else if (this.selected.size > SELECTED_LIMIT) {
        // More than 10 overwhelms the chart, so just show the totals
        return new AggregateDataSource(
          this.allSubstationsDataSource,
          selectedDataSource
        )
      } else {
        // Don't show selected total for only one selection
        if (this.selected.size === 1) {
          selectedDataSource = TimeSeriesDataSource.emptyDataSource()
        }
        // Chart shows totals and each selected sub/feeder series
        return new AggregateDataSource(
          this.allSubstationsDataSource,
          selectedDataSource,
          ...selectedIds.map((rId) => this.getComparisonDataSource(rId))
        )
      }
    },
    getComparisonDataSource(rId: string): ITimeSeriesDataSource {
      let ds = this.dataSources.get(rId)

      if (!ds) {
        ds = newComparisonChartDataSource(rId, this.$services)
        this.dataSources.set(rId, ds)
      }
      return ds
    },
    handleResetFilters(): void {
      this.expanded = new Set()
      this.filters = fillFiltersWithPlaceholders(
        createNewFilters(this.$rittaConfig.monitor),
        this.placeholders
      )
    },
    handleNewFilters(newValue: CustomFilters): void {
      this.filters = newValue
    },
    handleNewSearch(): void {
      this.generateDataTable()
    },
    handleNewCompareChartInterval(newInterval: Interval): void {
      this.compareChartInterval = newInterval
    },
    handleNewSelected(newSelected: RowIDSet): void {
      this.selected = newSelected
    },
    handleNewVisibleHeaders(newValue: VisibleHeaders): void {
      this.visibleHeaders = newValue
    },
    handleCustomSelect(item: SelectMenuItem): void {
      if (this.dataTable == null) return

      switch (item.value) {
        case ResourceType.SUBSTATION: {
          this.selected = new Set(this.dataTable.rows.map((r) => r.id))
          break
        }
        case ResourceType.FEEDER: {
          this.selected = new Set(allChildRowIDs(this.dataTable))
          break
        }
      }
    },
    updateTimeSeries(id: Resource['id'], newValue: MapTimeSeriesValue): void {
      this.timeSeries = new Map(this.timeSeries).set(id, newValue)
    },
    /** * Some customers (PPL) don't have time series for substations yet. */
    dontFetchTimeSeries(row: SubstationOrFeederRow): boolean {
      return (
        !!this.$rittaConfig.monitor?.substation?.dataTable
          ?.noSubstationCharts && !isFeederRow(row)
      )
    },
    async fetchMoreData(row: AnyRow): Promise<void> {
      this.fetchTimeSeries(row as SubstationOrFeederRow)
    },
    /**
     * When there's no time series for a specific row, fetch the time series
     * and cache it within the component.
     */
    async fetchTimeSeries(row: SubstationOrFeederRow): Promise<void> {
      if (this.dontFetchTimeSeries(row) || this.timeSeries.has(row.id)) return

      const onLoading = (row: SubstationOrFeederRow, newValue: boolean) => {
        newValue && this.updateTimeSeries(row.id, IS_LOADING)
      }

      const newValue = await fetchTimeSeries(
        this.$services,
        this.$observationTime(),
        row,
        onLoading
      )

      this.updateTimeSeries(row.id, newValue)
    },
    /**
     * Fetches all substations and feeders resources and the summary stats
     * for both.
     */
    async fetchData(): Promise<void> {
      this.isLoading = true
      this.hasLoadingFailed = false

      try {
        await Promise.all([this.fetchResources(), this.fetchStats()])
      } catch (err) {
        this.hasLoadingFailed = true
        console.error('SubstationsLeftPanel.fetchData: %o', err)
      } finally {
        this.isLoading = false
      }
    },
    async fetchResources(): Promise<void> {
      const [allSubstations, allFeeders] = await Promise.all([
        this.$services.queryService.getResourcesByType(ResourceType.SUBSTATION),
        this.$services.queryService.getResourcesByType(ResourceType.FEEDER),
      ])
      this.allSubstations = allSubstations
      this.allFeeders = allFeeders
    },
    async fetchStats(): Promise<void> {
      const summaries = await Promise.all([
        this.$services.gridService.allFeederSummary(),
        this.$services.gridService.allSubstationSummary(),
      ])

      this.allStats = new SummaryStatsAll({
        statistics: { ...summaries[0].statistics, ...summaries[1].statistics },
      })
    },
  },
})
</script>
