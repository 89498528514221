export function emitsBoolean(value: boolean) {
  return typeof value === 'boolean'
}

export function emitsString(value: string) {
  return typeof value === 'string'
}

export function emitsNothing(value?: never) {
  return value === undefined
}

export function emitsStringOrNull(value: unknown): value is string | null {
  return typeof value === 'string' || value === null
}

export function emitsAny(_value: any) {
  return true
}
