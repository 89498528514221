import { TextureSource } from '@deck.gl/core'
import { IconLayerProps } from '@deck.gl/layers'
import { load } from '@loaders.gl/core'
import { ImageLoader, ImageLoaderOptions } from '@loaders.gl/images'

import { ResourceType } from '@/constants/resourceType'

import iconBatteryDistributed from '@/assets/imgs/albert_ic_battery_distributed--halo.svg'
import iconBatteryUtility from '@/assets/imgs/albert_ic_battery_utility--halo.svg'
import iconBreaker from '@/assets/imgs/albert_ic_breaker--halo.svg'
import iconCapacitor from '@/assets/imgs/albert_ic_capacitor--halo.svg'
import iconEvCharger1 from '@/assets/imgs/albert_ic_evse--set1.svg'
import iconEvCharger2 from '@/assets/imgs/albert_ic_evse--set2.svg'
import iconEvCharger3 from '@/assets/imgs/albert_ic_evse--set3.svg'
import iconFuse from '@/assets/imgs/albert_ic_fuse--halo.svg'
import iconHydro from '@/assets/imgs/albert_ic_hydro--halo.svg'
import iconMethane from '@/assets/imgs/albert_ic_biogas--halo.svg'
import iconRecloser from '@/assets/imgs/albert_ic_recloser--halo.svg'
import iconRegulator from '@/assets/imgs/albert_ic_regulator--halo.svg'
import iconSectionalizer from '@/assets/imgs/albert_ic_sectionalizer--halo.svg'
import iconSensor from '@/assets/imgs/albert_ic_sensor--halo.svg'
import iconSolarDistributed from '@/assets/imgs/albert_ic_solar_distributed--halo.svg'
import iconSolarUtility from '@/assets/imgs/albert_ic_solar_utility--halo.svg'
import iconSubstation from '@/assets/imgs/albert_ic_substation--halo.svg'
import iconSubstationTD from '@/assets/imgs/albert_ic_substation_td--halo.svg'
import iconSwitch from '@/assets/imgs/albert_ic_switch--halo.svg'
import iconTransformer from '@/assets/imgs/albert_ic_transformer--halo.svg'
import iconTransformerAlert from '@/assets/imgs/albert_ic_transformer--alert.svg'
import iconVehicle from '@/assets/imgs/icon-trucks__black-bg.svg'
import iconWind from '@/assets/imgs/albert_ic_wind--halo.svg'

// It's frustrating the deck.gl won't export these types directly
export type IconMapping = Exclude<IconLayerProps['iconMapping'], string> & {}

// The image size of 96x96 can be scaled down to 32x32 or 24x24s
const IMAGE_SIZE = 96
export const LOADER_OPTIONS: ImageLoaderOptions = {
  imagebitmap: {
    // https://github.com/visgl/deck.gl/issues/2169#issuecomment-704585080
    premultiplyAlpha: 'none',
    resizeWidth: IMAGE_SIZE,
    resizeHeight: IMAGE_SIZE,
    resizeQuality: 'high',
  },
}

/** Resources that use multiple colors for their icons need to use a mask. */
const needsMask = new Set()

const iconAtlas = document.createElement('canvas')
const iconMapping: IconMapping = {}

/** Return the images and a mapping object for use with IconLayer */
export function getIconAtlas(): [TextureSource, IconMapping] {
  return [iconAtlas, iconMapping]
}

export const resourceIcons = [
  [ResourceType.BATTERY_DISTRIBUTED, iconBatteryDistributed],
  [ResourceType.BATTERY_STATION, iconBatteryUtility],
  [ResourceType.BREAKER, iconBreaker],
  [ResourceType.CAPACITOR, iconCapacitor],
  [ResourceType.CHARGER, iconEvCharger1],
  [ResourceType.CHARGER + '-DETECTION', iconEvCharger2],
  [ResourceType.CHARGER + '-PUBLIC', iconEvCharger3],
  [ResourceType.FUSE, iconFuse],
  [ResourceType.HYDRO, iconHydro],
  [ResourceType.METHANE, iconMethane],
  [ResourceType.RECLOSER, iconRecloser],
  [ResourceType.REGULATOR, iconRegulator],
  [ResourceType.SECTIONALIZER, iconSectionalizer],
  [ResourceType.SENSOR_ELECTRICAL, iconSensor],
  [ResourceType.SOLAR_DISTRIBUTED, iconSolarDistributed],
  [ResourceType.SOLAR_FARM, iconSolarUtility],
  [ResourceType.SUBSTATION, iconSubstation],
  [ResourceType.SUBSTATION + '-TD', iconSubstationTD],
  [ResourceType.SWITCH, iconSwitch],
  [ResourceType.TRANSFORMER, iconTransformer],
  [ResourceType.TRANSFORMER + '-ALERT', iconTransformerAlert],
  [ResourceType.VEHICLE, iconVehicle],
  [ResourceType.WIND, iconWind],
]

/**
 * Load all resource icons into the icon atlas texture.
 * This should be called once when the map is initialized.
 */
export async function generateIconAtlas(): Promise<TextureSource> {
  // If the iconAtlas has already been generated, return it
  if (Object.keys(iconMapping).length > 0) {
    return iconAtlas
  }

  // Convert the SVG imports into Image objects
  const allImageData = await Promise.all(
    resourceIcons.map(([_, icon]) => load(icon, ImageLoader, LOADER_OPTIONS))
  )

  // Size the canvas for all images
  iconAtlas.height = IMAGE_SIZE
  iconAtlas.width = IMAGE_SIZE * allImageData.length

  // Render into a canvas
  const context = iconAtlas.getContext('2d')
  if (context == null) throw new Error('Cannot access canvas 2D context')

  for (let i = 0; i < allImageData.length; i++) {
    const [resourceType] = resourceIcons[i]
    // Draw the image into the canvas at the correct position
    context.drawImage(allImageData[i] as CanvasImageSource, IMAGE_SIZE * i, 0)
    // Add an entry to the mapping object.
    iconMapping[resourceType] = makeMapping(i, needsMask.has(resourceType))
  }

  // For debugging...
  // document.body.appendChild(iconAtlas)

  return iconAtlas
}

function makeMapping(index: number, mask: boolean) {
  return {
    x: IMAGE_SIZE * index,
    y: 0,
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
    mask,
  }
}
