import { DateTime, Interval } from 'luxon'
import type { RittaConfig } from '@/config'
import type { Services } from '@/services'
import type { TimeSeriesFetch } from '@/services/charts.service'
import { titleCase } from '@/utils/formatText'
import type { Group } from 'rfs/control/proto/model_pb'
import type { TimeSeries } from 'rfs/frontend/proto/tsdb_pb'

export enum CommunicationStatusType {
  UNSPECIFIED = 'UNSPECIFIED',
  REACHABLE = 'REACHABLE',
  UNREACHABLE = 'UNREACHABLE',
}

export function getCommunicationStatusLabel(
  type: CommunicationStatusType
): string {
  return titleCase(CommunicationStatusType[type])
}

type CommunicationData = {
  latestStatus: CommunicationStatusType
  lastTimeReachable: undefined | number // mills
}

export type CommunicationStatusMap = Map<
  // Resource ID
  string,
  CommunicationData
>

export function isCommunicationStatusEnabled(
  config: Readonly<RittaConfig>,
  group?: null | Group
): boolean {
  const groupId = group?.id
  const groupIds = config.control?.communicationStatus?.groupIds ?? []
  return Boolean(groupId && groupIds.includes(groupId))
}

function createFetchRequest(now: DateTime): TimeSeriesFetch {
  return {
    interval: Interval.fromDateTimes(now.minus({ hours: 24 }), now),
    metrics: [{ metric: 'controls.commandable' }],
  }
}

function findLastReachable(series: undefined | TimeSeries): undefined | number {
  const last = series?.data?.findLast((dp) => dp.y === 1)
  return last?.x
}

function getCommunicationData(
  series: undefined | TimeSeries,
  now: DateTime
): CommunicationData {
  const lastDp = series?.data?.at(-1)

  if (!lastDp) {
    return {
      latestStatus: CommunicationStatusType.UNREACHABLE,
      lastTimeReachable: undefined,
    }
  }

  const isLastDpTooOld =
    DateTime.fromMillis(lastDp.x) < now.minus({ minutes: 5 })

  const isLastDpReachable = lastDp.y === 1

  return {
    latestStatus:
      !isLastDpTooOld && isLastDpReachable
        ? CommunicationStatusType.REACHABLE
        : CommunicationStatusType.UNREACHABLE,
    lastTimeReachable:
      !isLastDpTooOld && isLastDpReachable
        ? lastDp.x
        : findLastReachable(series),
  }
}

export async function fetchCurrentCommunicationStatus(
  services: Services,
  resourceId: string,
  now: DateTime
): Promise<CommunicationStatusType> {
  let result = CommunicationStatusType.UNSPECIFIED

  try {
    const res = await services.chartsService.fetchTimeSeries(
      resourceId,
      createFetchRequest(now)
    )
    result = getCommunicationData(res.series[0], now).latestStatus
  } catch (err) {
    console.error(
      `fetchCurrentCommunicationStatus: something happen for "${resourceId}"`,
      err
    )
  }

  return result
}

export async function fetchMultiCurrentCommunicationStatus(
  services: Services,
  resourceIds: string[],
  now: DateTime
): Promise<CommunicationStatusMap> {
  const newMap: CommunicationStatusMap = new Map()

  try {
    const { series } = await services.chartsService.fetchMultiTimeSeries(
      resourceIds,
      createFetchRequest(now)
    )

    for (const s of series) {
      if (!s.resource) continue
      newMap.set(s.resource, getCommunicationData(s, now))
    }
  } catch (err) {
    console.error('fetchMultiCurrentCommunicationStatus: %o', err)
  }

  return newMap
}
