import { DateTime, Duration, IANAZone, Interval, Settings, Zone } from 'luxon'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { CalendarPeriod } from 'rfs/pb/calendar_pb'
import { Timestamp } from '@/services/timestamp_pb'

/**
 * Formats a numeric timestamp as a string that is Excel-friendly for CSV importing.
 * @param millis - Timestamp value in milliseconds
 * @returns Timestamp in 'yyyy-MM-dd HH:mm:ss' format
 */
export function toExcelTimeString(millis: number): string {
  // TODO(dchen): We should come up with requirements around timezones and add timezone logic to
  // this function
  return DateTime.fromMillis(millis).toFormat('yyyy-MM-dd HH:mm:ss')
}

// intervalToday returns a luxon.Interval representing the [start, end) for the provided timestamp
// `ts` defaults to `now` in the local timezone
export function intervalToday(ts = DateTime.local()): Interval {
  return Interval.fromDateTimes(ts.startOf('day'), ts.endOf('day'))
}

// intervalYesterday returns a luxon.Interval representing the [start, end) for the provided timestamp
// `ts` defaults to `now` in the local timezone
export function intervalYesterday(ts = DateTime.local()): Interval {
  const yesterday = ts.minus({ days: 1 })
  return Interval.fromDateTimes(
    yesterday.startOf('day'),
    yesterday.endOf('day')
  )
}

// intervalTomorrow returns a luxon.Interval representing the [start, end) for the provided timestamp
// `ts` defaults to `now` in the local timezone
export function intervalTomorrow(ts = DateTime.local()): Interval {
  const thisTimeTomorrow = ts.plus(Duration.fromObject({ days: 1 }))
  return Interval.fromDateTimes(
    thisTimeTomorrow.startOf('day'),
    thisTimeTomorrow.endOf('day')
  )
}

// intervalLast7Days returns a luxon.Interval representing the [start, end) for the provided timestamp
// `ts` defaults to `now` in the local timezone
export function intervalLast7Days(ts = DateTime.local()): Interval {
  return Interval.fromDateTimes(
    ts.minus(Duration.fromObject({ days: 7 })).startOf('day'),
    ts.endOf('day')
  )
}

// intervalLast24Hours returns a luxon.Interval representing the [start, end) for the provided timestamp
// `ts` defaults to `now` in the local timezone
export function intervalLast24Hours(ts = DateTime.local()): Interval {
  return Interval.fromDateTimes(
    ts.minus({ hours: 24 }).startOf('hour'),
    ts.endOf('hour')
  )
}

/**
 * Return a 30-day interval from the given date, or the current day if none is specified.
 */
export function intervalLast30Days(ts = DateTime.local()): Interval {
  return Interval.fromDateTimes(
    ts.minus({ days: 30 }).startOf('day'),
    ts.endOf('day')
  )
}

export function intervalLast90Days(ts = DateTime.local()): Interval {
  return Interval.fromDateTimes(
    ts.minus(Duration.fromObject({ days: 90 })).startOf('day'),
    ts.endOf('day')
  )
}

export function intervalLast12Months(ts = DateTime.local()): Interval {
  return Interval.fromDateTimes(
    ts.minus(Duration.fromObject({ months: 12 })).startOf('day'),
    ts.endOf('day')
  )
}

export function intervalMonthToDate(ts = DateTime.local()): Interval {
  return Interval.fromDateTimes(ts.startOf('month'), ts.endOf('day'))
}

export function intervalYearToDate(ts = DateTime.local()): Interval {
  return Interval.fromDateTimes(ts.startOf('year'), ts.endOf('day'))
}

/**
 * If the interval is at the end of the day, round it up to midnight the next day.
 */
export function intervalRoundEndOfDay(interval: Interval): Interval {
  if (interval.end.equals(interval.end.endOf('day'))) {
    return interval.set({ end: interval.end.plus(1) })
  } else {
    return interval
  }
}

export function intervalLastPeriod(
  period: CalendarPeriod,
  now = DateTime.local()
): Interval {
  switch (period) {
    case CalendarPeriod.DAY:
      return intervalLast24Hours(now)
    case CalendarPeriod.WEEK:
      return intervalLast7Days(now)
    case CalendarPeriod.MONTH:
      return intervalLast30Days(now)
    case CalendarPeriod.QUARTER:
      return intervalLast90Days(now)
    case CalendarPeriod.YEAR:
      return intervalLast12Months(now)
    default:
      return Interval.fromDateTimes(now, now) // Empty interval
  }
}

export function periodFromLastInterval(
  interval: Interval,
  now = DateTime.local()
): CalendarPeriod {
  if (interval.equals(intervalLast24Hours(now))) return CalendarPeriod.DAY
  if (interval.equals(intervalLast7Days(now))) return CalendarPeriod.WEEK
  if (interval.equals(intervalLast30Days(now))) return CalendarPeriod.MONTH
  if (interval.equals(intervalLast90Days(now))) return CalendarPeriod.QUARTER
  if (interval.equals(intervalLast12Months(now))) return CalendarPeriod.YEAR

  return CalendarPeriod.CALENDAR_PERIOD_UNSPECIFIED
}

/**
 * Returns UTC offset from `now` in string format (e.g. UTC-03:00)
 */
export function utcOffsetString(zone = Settings.defaultZone as Zone): string {
  return `UTC${zone.formatOffset(Date.now(), 'short')}`
}

/**
 * Returns abbreviated time zone.
 * Example: 'America/Denver' returns 'MST' or 'MDT'
 * ("Mountain Standard Time" / "Mountain Daylight Time")
 * @param zone (Optional) IANA-specified zone name or Luxon Zone
 */
export function offsetNameShort(zone: Zone | string = Settings.defaultZone) {
  if (typeof zone === 'string') {
    zone = IANAZone.create(zone)
  }
  return zone.offsetName(Date.now(), { format: 'short' })
}

export function durationFmt(duration: Duration): string {
  return duration.toHuman({ unitDisplay: 'short' })
}

type DateTimeLike = number | Date | Timestamp | DateTime
type DateTimeMaybe = DateTimeLike | null | undefined

export function toDateTime(value: DateTimeLike): DateTime {
  if (value instanceof DateTime) {
    return value
  } else if (value instanceof Date) {
    return DateTime.fromJSDate(value)
  } else if (value instanceof Timestamp) {
    return DateTime.fromMillis(value.toMillis())
  } else {
    return DateTime.fromMillis(value)
  }
}

export function formatDt(value: DateTimeMaybe): string {
  if (value == null) return TEXT_NO_VALUE

  const dt = toDateTime(value)
  // Treat dates at Unix epoch as no value
  if (dt.valueOf() === 0) return TEXT_NO_VALUE

  return dt.toFormat('yyyy-MM-dd HH:mm')
}

export function formatDtWithZoneShort(value: DateTimeMaybe): string {
  if (value == null) return TEXT_NO_VALUE

  const dt = toDateTime(value)
  // Treat dates at Unix epoch as no value
  if (dt.valueOf() === 0) return TEXT_NO_VALUE

  return dt.toFormat('yyyy-MM-dd HH:mm:ss (ZZZZ)')
}

export function createDateTimeFromISOTime(
  time: string,
  base: DateTime
): null | DateTime {
  const [h, m] = time.split(':')

  const hour = Number(h)
  const minute = Number(m)

  if ([hour, minute].some(Number.isNaN)) return null

  const dt = base.set({ hour, minute })

  return dt.isValid ? dt : null
}

export function formatToISOTime(ts: Timestamp): string {
  const dt = DateTime.fromMillis(ts.toMillis())
  const [hour, minutes] = dt.toISOTime().split(':')
  return `${hour}:${minutes}`
}

export function formatToISODate(ts: Timestamp): string {
  const dt = DateTime.fromMillis(ts.toMillis())
  return dt.toISODate()
}

/**
 * Merges overlapping or contiguous intervals, returning a list of merged intervals.
 */
export function mergeIntervals(intervals: Interval[]): Interval[] {
  if (!intervals.length) return []

  const orderedIntervals = intervals.sort(
    (a, b) => a.start.toMillis() - b.start.toMillis()
  )

  return orderedIntervals.reduce<Interval[]>((merged, current) => {
    const last = merged[merged.length - 1]

    if (last && (last.overlaps(current) || last.abutsStart(current))) {
      merged[merged.length - 1] = last.union(current)
    } else {
      merged.push(current)
    }

    return merged
  }, [])
}

/**
 * Restricts the time interval to avoid:
 * 1) Retrieving data from the "future";
 * 2) Displaying "future" data in summary boxes for historical time series.
 */
export function limitInterval(reqInterval: Interval, now: DateTime): Interval {
  if (reqInterval.start > now) {
    // The entire interval is in the future, return an empty interval.
    return Interval.fromDateTimes(now, now)
  } else if (reqInterval.end > now) {
    // Adjust the interval to end at 'now' to exclude future data.
    return Interval.fromDateTimes(reqInterval.start, now)
  } else {
    // The interval is fully in the past or present, return it unchanged.
    return reqInterval
  }
}

export function toCalendarPeriod(
  interval: Interval,
  now: DateTime = DateTime.now()
): undefined | CalendarPeriod {
  const toIso = interval.toISO()

  if (toIso === intervalLast24Hours(now).toISO()) {
    return CalendarPeriod.DAY
  } else if (toIso === intervalLast7Days(now).toISO()) {
    return CalendarPeriod.WEEK
  } else if (toIso === intervalLast30Days(now).toISO()) {
    return CalendarPeriod.MONTH
  } else if (toIso === intervalLast90Days(now).toISO()) {
    return CalendarPeriod.QUARTER
  } else if (toIso === intervalLast12Months(now).toISO()) {
    return CalendarPeriod.YEAR
  }

  return undefined
}
