<template>
  <section aria-label="Downline meters">
    <!-- Content -->
    <ce-data-table
      :headers
      :table
      :server-items-length
      :filters
      :options
      :is-loading
      :download-href
      :bg-color
      :selected
      :show-pagination="!isTransformer"
      :show-select="isTransformer"
      sticky
      dense
      :class="isTransformer ? 'remove-paddings' : null"
      @new-selected="updateSelected"
      @new-options="updateOptions"
      @new-filters="updateFilters"
    >
      <template v-if="!isTransformer" v-slot:left-of-actions>
        <ce-title>Downline meters</ce-title>
      </template>

      <!-- Compare Chart -->
      <template v-if="isTransformer" v-slot:above-actions>
        <time-series-chart-group
          :charts
          :data-source
          :interval
          hide-date-range-picker
          @new-interval="handleNewInterval"
        />
      </template>
    </ce-data-table>
  </section>
</template>

<script lang="ts">
import { defineComponent, shallowReactive, type PropType } from 'vue'
import { type ConnectError, Code } from '@connectrpc/connect'
import type { Interval } from 'luxon'
import { GREY6 } from '@/constants/colors'
import { ResourceType } from '@/constants/resourceType'
import { Timestamp } from '@/services/timestamp_pb'
import { AggregateDataSource, type ITimeSeriesDataSource } from '@/model/charts'
import {
  createHeaders,
  createPristineOptions,
  isStatsHeader,
  type DownlineMetersDataTable,
} from '@/model/grid/ResourceDownlineMetersDataTable'
import {
  downlineMetersChart,
  newMeterDataSource,
} from '@/model/grid/ResourceImpactChartData'
import type {
  Filters,
  Options,
  NewOptionsContext,
  RowIDSet,
} from '@/model/tables/DataTable'
import { TimeSeriesChartGroup } from '@/components/common'
import CeTitle from '@/components/CeTitle.vue'
import CeDataTable from '@/components/common/CeDataTable.vue'
import { ResourceImpactResponse } from 'rfs/frontend/proto/analysis_pb'
import { DownlineResponse } from 'rfs/frontend/proto/grid_pb'
import type { Resource } from 'rfs/pb/resource_pb'
import {
  convertFilters,
  convertOrderBy,
  newFiltersMap,
} from '@/model/tables/column'

const METER_SELECT_TOP = 5

export default defineComponent({
  name: 'ResourceImpactsMetersTable',
  props: {
    resource: {
      type: Object as PropType<Resource>,
      required: true,
    },
    interval: {
      type: Object as PropType<Interval>,
      required: true,
    },
    /**
     * The impact prop is watched so that we download meter data only when we
     * receive new impact data.
     */
    impact: {
      type: Object as PropType<ResourceImpactResponse>,
      required: true,
    },
  },
  emits: ['new-interval'],
  components: { CeTitle, CeDataTable, TimeSeriesChartGroup },
  setup() {
    return {
      bgColor: GREY6.hex,
      charts: [downlineMetersChart],
      abortController: undefined as undefined | AbortController,
    }
  },
  data() {
    const headers = createHeaders(this.resource, this.$dictionary)

    return shallowReactive({
      headers,
      isLoading: false,
      filters: newFiltersMap(headers), // TODO(rafael): the filters seem to not be working.
      options: createPristineOptions(this.resource.type as ResourceType),
      meterDataSources: new Map<string, ITimeSeriesDataSource>(),
      response: new DownlineResponse(),
      // Selected meters. Initially, the top 5 meters by average power.
      selected: new Set() as RowIDSet,
    })
  },
  watch: {
    impact: {
      immediate: true,
      handler: function () {
        if (this.impact.id) {
          this.fetchData() // New impact data so fetch the table
        } else {
          this.resetState() // Loading new impact data so clear the table
        }
      },
    },
  },
  computed: {
    isTransformer(): boolean {
      return this.resource.type === ResourceType.TRANSFORMER
    },
    serverItemsLength(): number {
      return this.response.totalRows
    },
    downloadHref(): string {
      return this.response.exportUrl
    },
    dataSource(): ITimeSeriesDataSource {
      const datasources: ITimeSeriesDataSource[] = []

      for (const meterId of Array.from(this.selected)) {
        const ds = this.meterDataSources.get(meterId)
        if (ds) datasources.push(ds)
      }

      return new AggregateDataSource(...datasources)
    },
    table(): DownlineMetersDataTable {
      return { rows: this.response.rows, selectable: this.isTransformer }
    },
  },
  beforeUnmount(): void {
    this.abortController?.abort()
  },
  methods: {
    updateFilters(newFilters?: Filters): void {
      this.filters = newFilters ?? newFiltersMap(this.headers)
      this.fetchData()
    },
    updateOptions(newOptions: Options, ctx?: NewOptionsContext): void {
      this.options = newOptions
      if (ctx?.triggeredByFilter) return
      this.fetchData()
    },
    updateSelected(selectedItems: RowIDSet): void {
      this.selected = selectedItems
    },
    handleNewInterval(newInterval: Interval): void {
      this.$emit('new-interval', newInterval)
    },
    resetState(): void {
      this.response = new DownlineResponse()
      this.meterDataSources = new Map()
      this.selected = new Set()
      this.options = createPristineOptions(this.resource.type as ResourceType)
      this.filters = newFiltersMap(this.headers)
      // Show the loading UI because the reset is happening so we can load new data
      this.isLoading = true
    },
    async fetchData(): Promise<void> {
      this.isLoading = true

      this.abortController?.abort()
      this.abortController = new AbortController()

      try {
        const request = {
          resource: this.resource.id,
          filterBy: convertFilters(this.filters),
          orderBy: convertOrderBy(this.options.orderBy),
          start: Timestamp.fromDateTime(this.interval.start),
          end: Timestamp.fromDateTime(this.interval.end),
          limit: this.options.itemsPerPage,
          offset: (this.options.page - 1) * this.options.itemsPerPage,
        }
        // If we're not showing meter stats, use the RPC that doesn't query telemetry
        if (!this.headers.some(isStatsHeader)) {
          this.response =
            await this.$services.analysisService.downlineMetersTable(request)
          this.isLoading = false
          return
        }

        if (this.isTransformer) {
          request.limit = -1 // ask for everything because we show top 5 meters by power
          request.offset = 0
        }
        // For midline devices, request the complete table data with telemetry-based stats
        const iter = this.$services.analysisService.downlineMetersTable2(
          request,
          { signal: this.abortController.signal }
        )

        for await (const response of iter) {
          this.response = response

          // Make sure all rows have data sources for the chart.
          if (this.isTransformer && !this.meterDataSources.size) {
            this.meterDataSources = this.response.rows.reduce((acc, row) => {
              acc.set(
                row.id,
                newMeterDataSource(this.$services.chartsService, row.id)
              )
              return acc
            }, new Map<string, ITimeSeriesDataSource>())
          }
        }

        // Auto select meters to be displayed on the chart. This has to be done after
        // all the responses are received, since it depends on power values.
        if (this.isTransformer && !this.selected.size) {
          const newSet = new Set(
            this.response.rows.slice(0, METER_SELECT_TOP).map((r) => r.id)
          )
          this.selected = newSet
        }

        // End.
        this.isLoading = false
      } catch (err) {
        const code = (err as ConnectError).code
        const cause = (err as ConnectError).cause

        const abortedBeforeFirstResponse =
          code === Code.Unknown && cause === 'missing request message'
        const abortedAfterFirstResponse = code === Code.Canceled

        // Normal error.
        if (!abortedBeforeFirstResponse && !abortedAfterFirstResponse) {
          this.isLoading = false
          console.error('ResourceImpactsMetersTable.fetchData: %o', err)
        } else {
          // Aborted by new request.
          console.warn('ResourceImpactsMetersTable.fetchData: %o', err)
        }
      }
    },
  },
})
</script>

<style lang="scss">
section[aria-label='Downline meters'] {
  .remove-paddings {
    .c-AbstractChart {
      padding-bottom: 0;
    }
    div[aria-label='Search & Actions'] {
      padding-top: 0 !important;
    }
  }
}
</style>
