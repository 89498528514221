<template>
  <div
    class="chartjs-window-resizing"
    :style="{ height }"
    role="region"
    aria-label="Chart"
  >
    <bar-chart :data :options :aria-label="name" />
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import type { ChartData, ChartOptions } from 'chart.js'
import { Bar as BarChart } from 'vue-chartjs'
import type { Hex } from '@/constants/colors'
import {
  calculateOptimalBinSize,
  generateBins,
  mergeSmallBins,
} from '@/utils/charts/histogram'

export default defineComponent({
  name: 'HistogramChart',
  props: {
    values: {
      type: Array as PropType<number[]>,
      required: true,
    },
    xAxisTitle: {
      type: String,
      required: true,
    },
    yAxisTitle: {
      type: String,
      required: true,
    },
    barColor: {
      type: String as PropType<Hex>,
      required: true,
    },
    mergeThreshold: {
      type: Number,
      required: false,
      default: 0.05, // 5%
    },
    /**
     * Specifies the height of the chart.
     * Accepts any valid CSS unit (e.g., 'px', '%', 'em').
     */
    height: {
      type: String,
      required: false,
      default: '15rem',
    },
    /** * Mostly for E2E tests */
    name: {
      type: String,
      required: false,
    },
  },
  components: { BarChart },
  computed: {
    computedValues(): number[] {
      return this.values.filter(v => !Number.isNaN(v))
    },
    binSize(): number {
      return calculateOptimalBinSize(this.computedValues)
    },
    bins() {
      const bins = generateBins(this.computedValues, this.binSize)
      const merged = mergeSmallBins(bins, this.mergeThreshold)
      return merged
    },
    data(): ChartData<'bar'> {
      return {
        labels: this.bins.map((item, index) => {
          const size = Math.abs(item.range[0] - item.range[1])

          // First item.
          if (size > this.binSize && index === 0) {
            return `< ${item.range[1]}`
          } else if (size > this.binSize && this.bins.length === index + 1) {
            // Last item.
            return `≥ ${item.range[0]}`
          } else {
            // Others.
            return `${item.range[0]} - ${item.range[1]}`
          }
        }),
        datasets: [
          {
            label: this.yAxisTitle,
            data: this.bins.map((item) => item.count),
            backgroundColor: this.barColor,
            barPercentage: 0.99,
            categoryPercentage: 1,
          },
        ],
      }
    },
    options(): ChartOptions<'bar'> {
      return {
        plugins: {
          legend: { display: false },
          tooltip: { callbacks: { title: () => this.xAxisTitle } },
        },
        scales: {
          x: {
            title: { display: true, text: this.xAxisTitle },
            grid: { display: false },
          },
          y: {
            beginAtZero: true,
            title: { display: true, text: this.yAxisTitle },
          },
        },
      }
    },
  },
})
</script>
