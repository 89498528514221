import { ColumnType } from '@/model/tables/column'
import type { DictionaryUtil } from '@/plugins/vue'
import { ResourceType } from '@/constants/resourceType'
import type { Header, DataTable, Options } from '@/model/tables/DataTable'
import { createDefaultOptions } from '@/model/tables/helper'
import { createSiteRoute } from '@/utils/router/create'
import type { DownlineResponse_TableRow as ResponseTableRow } from 'rfs/frontend/proto/grid_pb'
import { Resource } from 'rfs/pb/resource_pb'
import { isMidlineDevice } from '../resource'

export interface DownlineMetersDataTable extends DataTable<ResponseTableRow> {}

const siteIdKey = 'resource.site.id'
const averageKey = 'stats.meanPower'

export function createHeaders(
  resource: Resource,
  dictionary: DictionaryUtil
): Header<ResponseTableRow>[] {
  const headers: Header<ResponseTableRow>[] = [
    {
      title: dictionary('Site ID'),
      key: siteIdKey,
      routeFactory(_config, row) {
        const r = row.resource
        return r ? createSiteRoute(r) : undefined
      },
    },
    {
      title: dictionary('Service meter'),
      key: 'resource.meter.electrical',
    },
    {
      title: 'Peak Power (kW)',
      key: 'stats.maxPower',
      valueType: ColumnType.KILO_W,
      align: 'end',
    },
    {
      title: 'Average (kW)',
      key: averageKey,
      valueType: ColumnType.KILO_W,
      align: 'end',
    },
    {
      title: 'BESS (kW)',
      key: 'ratingsBattery',
      valueType: ColumnType.KILO_W,
      align: 'end',
    },
    {
      title: 'EVSE (kW)',
      key: 'ratingsCharger',
      valueType: ColumnType.KILO_W,
      align: 'end',
    },
    {
      title: 'PV (kW)',
      key: 'ratingsSolar',
      valueType: ColumnType.KILO_W,
      align: 'end',
    },
    {
      title: 'Over voltage (%)',
      key: 'stats.overVoltagePct',
      valueType: ColumnType.PERCENT,
      align: 'end',
      width: '13%',
    },
    {
      title: 'Under voltage (%)',
      key: 'stats.underVoltagePct',
      valueType: ColumnType.PERCENT,
      align: 'end',
      width: '13%',
    },
  ]
  // Substations and Feeders don't have the "stats" columns
  if (!isMidlineDevice(resource)) {
    return headers.filter((h) => !isStatsHeader(h))
  }

  return headers
}

export function isStatsHeader(h: Header<ResponseTableRow>): boolean {
  return h.key.startsWith('stats.')
}

export function createPristineOptions(rt: ResourceType): Options {
  return rt === ResourceType.TRANSFORMER
    ? createDefaultOptions(averageKey, 'descending')
    : createDefaultOptions(siteIdKey, 'descending')
}
