<template>
  <time-series-chart-group
    :charts
    :data-source
    :interval="selectedInterval"
    :max-end-time
    @new-interval="handleNewInterval"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { mapActions } from 'pinia'
import { DateTime, Interval } from 'luxon'
import { siteChartDefinitions } from '@/constants/siteCharts'
import { usePreferencesStore } from '@/stores/preferences'
import { TimeSeriesDataSource } from '@/model/charts/index'
import {
  computeIntervalOptionFromInterval,
  createIntervalFromIntervalOption,
  IntervalOption,
  isIntervalOption,
} from '@/model/charts/intervalOption'
import { addForecastInterval } from '@/model/forecast/index'
import { isStorageResource } from '@/model/resource/index'
import { Resource } from 'rfs/pb/resource_pb'
import { ChartDefinition } from '@/types/charts'
import { intervalLast7Days } from '@/utils/time'
import { TimeSeriesChartGroup } from '../common'
import { newSiteChartDataSource } from './SiteChartData'

export default defineComponent({
  name: 'SiteCharts',
  props: {
    siteResource: {
      type: Object as PropType<Resource>,
      required: true,
    },
    otherResources: {
      type: Array as PropType<Resource[]>,
      required: true,
    },
  },
  components: { TimeSeriesChartGroup },
  setup() {
    return { dataSource: TimeSeriesDataSource.emptyDataSource() }
  },
  data() {
    const selectedInterval: Interval = (() => {
      const persistedInterval = usePreferencesStore().siteChartsInterval

      const defaultInterval = addForecastInterval(
        intervalLast7Days(this.$observationTime()),
        this.$rittaConfig
      )

      if (!persistedInterval) return defaultInterval

      if (isIntervalOption(persistedInterval)) {
        return (
          createIntervalFromIntervalOption(
            this.$observationTime(),
            persistedInterval
          ) ?? defaultInterval
        )
      }

      return Interval.fromISO(persistedInterval)
    })()

    return { selectedInterval }
  },
  computed: {
    charts(): ChartDefinition[] {
      return siteChartDefinitions(this.$rittaConfig.monitor).filter((chart) => {
        // Only include storage if there's a battery
        if (chart.id === 'site-storage-chart') {
          return this.otherResources.some(isStorageResource)
        }
        return true
      })
    },
    maxEndTime(): DateTime {
      const baseInterval = intervalLast7Days(this.$observationTime())
      // Use the end of the initial interval as the max end time for the chart
      return addForecastInterval(baseInterval, this.$rittaConfig).end
    },
  },
  created() {
    this.initDataSource()
  },
  methods: {
    ...mapActions(usePreferencesStore, ['updateSiteChartsInterval']),
    initDataSource(): void {
      this.dataSource = newSiteChartDataSource(
        this.charts,
        this.siteResource,
        this.otherResources,
        this.$services,
        this.$rittaConfig
      )
    },
    handleNewInterval(newInterval: Interval): void {
      const intervalOption = computeIntervalOptionFromInterval(
        this.$observationTime(),
        newInterval
      )

      // Persist the selected interval.
      this.updateSiteChartsInterval(
        intervalOption !== IntervalOption.CUSTOM
          ? intervalOption
          : newInterval.toISO()
      )

      this.selectedInterval = newInterval
    },
  },
})
</script>
