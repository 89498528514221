import { shallowReactive } from 'vue'
import { PlainMessage, toPlainMessage } from '@bufbuild/protobuf'
import { isEqual } from 'lodash-es'
import { DateTime, Settings } from 'luxon'
import { PiniaPluginContext } from 'pinia'

import { ResourceType, ResourceTypeLabels } from '@/constants/resourceType'
import {
  CustomFilters,
  CustomOptions,
  createHeaders,
  updateFilterMapWithNewHeaders,
} from '@/model/grid/ImpactDataTable'
import { newFiltersMap, convertFilters } from '@/model/tables/column'
import {
  createInitialVisibleHeaders,
  upgradeFilters,
  upgradeVisibleHeaders,
} from '@/model/tables/header'
import { createDefaultOptions } from '@/model/tables/helper'
import {
  DERImpactsRequest,
  DERImpactsRow,
  DERImpactsTableResponse,
  ModelMaker,
} from 'rfs/frontend/proto/analysis_pb'
import { defineImmutableStore } from './defineStore'
import { useInternalStore } from './internal'
import {
  Header,
  NewOptionsContext,
  VisibleHeaders,
} from '@/model/tables/DataTable'
import { assoc } from '@/utils/immutable'
import { CalendarPeriod } from 'rfs/pb/calendar_pb'
import { useGlobalSnackbar } from './globalSnackbar'

export type FiltersByType = Map<ResourceType, CustomFilters>

interface GridImpactsState {
  isLoading: boolean
  headers: Header[]
  visibleHeaders: VisibleHeaders
  componentType: ResourceType
  filtersByType: FiltersByType
  options: CustomOptions
  modelParamsByType: Map<ResourceType, null | PlainMessage<ModelMaker>>
  period: CalendarPeriod

  response: null | DERImpactsTableResponse
  responseHasModelParams: boolean

  searchText: string
}

type GridImpactsRow = PlainMessage<DERImpactsRow>

export const useGridImpactsStore = defineImmutableStore('gridImpacts', {
  persist: {
    paths: [
      'componentType',
      'filtersByType',
      'options',
      'visibleHeaders',
      'modelParamsByType',
      'period',
      'searchText',
    ],
    afterRestore: onStateRestore,
  },
  state: () => {
    const headers = createHeaders()

    return shallowReactive<GridImpactsState>({
      headers,
      visibleHeaders: createInitialVisibleHeaders(headers),
      isLoading: false,
      componentType: ResourceType.FEEDER,
      filtersByType:
        useInternalStore().allowedResourceTypes.reduce<FiltersByType>(
          (acc, rt) => acc.set(rt, newFiltersMap(headers)),
          new Map()
        ),
      options: createDefaultOptions('id'),
      modelParamsByType: new Map(),
      period: CalendarPeriod.MONTH,
      response: null,
      responseHasModelParams: false,
      searchText: '',
    })
  },
  getters: {
    filters(): CustomFilters {
      return (
        this.filtersByType.get(this.componentType) ??
        newFiltersMap(this.headers)
      )
    },
    modelParams(): null | PlainMessage<ModelMaker> {
      return this.modelParamsByType.get(this.componentType) ?? null
    },
    componentSelectItems(): { value: ResourceType; title: string }[] {
      return useInternalStore().allowedResourceTypes.map((rtype) => ({
        value: rtype,
        title: ResourceTypeLabels[rtype],
      }))
    },
    rows(): GridImpactsRow[] {
      return this.response?.rows ?? []
    },
    serverItemsLength(): number {
      return this.response?.totalRows ?? 0
    },
    isInitialState(): boolean {
      return this.response === null
    },
    isBeingFetched(): boolean {
      // If the response has no start/end time, it's a placeholder for the background fetch
      return this.response?.requestStart == null
    },
    /** If there is a response with an export URL, return the full URL */
    csvDownloadUrl(): URL | null {
      if (!this.response?.exportUrl) return null

      const url = new URL(this.response.exportUrl, this.config.rfsEndpoint)
      // The data includes timestamps so we need to pass up the local TZ
      url.searchParams.set('TZ', Settings.defaultZone.name)

      return url
    },
    validAsOf(): string {
      if (!this.response) return ''

      const { requestStart, requestEnd } = this.response
      if (!requestStart || !requestEnd) {
        return ''
      }
      const start = DateTime.fromJSDate(requestStart.toDate()).toLocaleString(
        DateTime.DATE_MED
      )
      const end = DateTime.fromJSDate(requestEnd.toDate()).toLocaleString(
        DateTime.DATE_MED
      )
      return `Data compiled from ${start} until ${end}`
    },
  },
  actions: {
    updateSearchText(newSearch: string): void {
      if (this.searchText === newSearch) return

      this.searchText = newSearch
      // When a new search text, reset back to page 1.
      this.updateOptions(
        { ...this.options, page: 1 },
        { triggeredByFilter: true }
      )

      this.fetchTable()
    },
    async fetchTable() {
      const { itemsPerPage, orderBy, page } = this.options

      const newHeaders = createHeaders({
        addModelHeaders: !!this.modelParams,
        resourceType: this.componentType,
      })

      const updatedFilters = updateFilterMapWithNewHeaders(
        this.filters,
        this.headers,
        newHeaders
      )

      try {
        this.isLoading = true

        this.response =
          await this.services.analysisService.fetchDERImpactsTable(
            new DERImpactsRequest({
              componentType: this.componentType,
              fixedInterval: this.period,
              limit: itemsPerPage,
              offset: (page - 1) * itemsPerPage,
              orderBy: {
                property: orderBy.column,
                descending: orderBy.descending,
              },
              filterBy: convertFilters(updatedFilters),
              modelParams: this.modelParams ?? undefined,
              search: this.searchText,
            })
          )

        this.responseHasModelParams = !!this.modelParams
        this.headers = newHeaders
        this.filtersByType = new Map(this.filtersByType).set(
          this.componentType,
          updatedFilters
        )
      } catch (err) {
        console.error('gridImpact.fetchTable: %o', err)
        // When the table fails to load, clear the contents
        this.response = new DERImpactsTableResponse()
        this.responseHasModelParams = false
        showErrorSnackbar(this.componentType, err as Error)
      } finally {
        this.isLoading = false
      }
    },
    async updateComponentType(newType: ResourceType) {
      this.componentType = newType
      // Return to page one before fetching new data
      this.options = assoc(this.options, 'page', 1)

      await this.fetchTable()
    },
    /** Action called when the user changes one or more filters */
    async updateFilters(newFilters?: CustomFilters) {
      this.filtersByType = new Map(this.filtersByType).set(
        this.componentType,
        newFilters ?? newFiltersMap(this.headers)
      )
      await this.fetchTable()
    },
    /** Action called when the user changes a page or sort order */
    async updateOptions(newOptions: CustomOptions, ctx?: NewOptionsContext) {
      this.options = newOptions
      if (ctx?.triggeredByFilter) return
      await this.fetchTable()
    },
    /** Action called when the user changes the model params */
    async updateModelParams(newMaker?: ModelMaker) {
      const newModelParams = newMaker ? toPlainMessage(newMaker) : null
      // Don't make a request if the model params are effectively the same
      if (isEqual(this.modelParams, newModelParams)) {
        return
      }
      this.modelParamsByType = new Map(this.modelParamsByType).set(
        this.componentType,
        newModelParams
      )
      await this.fetchTable()
    },
    async updatePeriod(newPeriod: CalendarPeriod) {
      this.period = newPeriod
      await this.fetchTable()
    },
    updateVisibleHeaders(newValue: VisibleHeaders) {
      this.visibleHeaders = newValue
    },
  },
  upgradeState(currentState, initialState) {
    // Filters
    upgradeFiltersByType(currentState.filtersByType, initialState.filtersByType)

    // Options
    // TODO(rafael): upgrade options: when header doesn't exist anymore, it could still be in use in 'options.orderBy'.

    // Headers
    upgradeVisibleHeaders(
      currentState.visibleHeaders,
      initialState.visibleHeaders
    )
  },
})

function onStateRestore(context: PiniaPluginContext) {
  // There was a bug which caused some properties to get saved.
  // Delete them.
  if (context.store.$state.response) {
    context.store.$reset()
    console.warn('Discarded broken Grid Impacts saved state')
  }
}

export function upgradeFiltersByType(
  currentFilters: FiltersByType,
  initialFilters: FiltersByType
): void {
  // Add missing filters to current filters
  for (const [componentType, filters] of initialFilters) {
    const currentFilter = currentFilters.get(componentType)
    if (!currentFilter) {
      currentFilters.set(componentType, filters)
    } else {
      upgradeFilters(currentFilter, filters)
    }
  }
  // Remove filters that are no longer in use
  for (const [componentType] of currentFilters) {
    if (!initialFilters.has(componentType)) {
      currentFilters.delete(componentType)
    }
  }
}

function showErrorSnackbar(rsrcType: ResourceType, err: Error) {
  const message = `Unable to load ${ResourceTypeLabels[rsrcType]}: ${err.message}`

  useGlobalSnackbar().openSnackbar(message, 'error')
}
