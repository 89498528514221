<template>
  <insight-template
    :charts
    :data-source
    :summary-boxes
    :analysis-sections
    :interval
    :selected-period
    @new-period="handleNewPeriod"
  />
</template>

<script lang="ts">
import { defineComponent, shallowReactive } from 'vue'
import { Interval } from 'luxon'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { ResourceType } from '@/constants/resourceType'
import { MEGA_OPTIONS, Format } from '@/utils/format'
import { numberNoDecimals } from '@/utils/formatText'
import { formatDtWithZoneShort, intervalLastPeriod } from '@/utils/time'
import {
  chartDistributedGeneration,
  getMaxPvGenerationByFeeder,
  getNumberOfPvsByFeeder,
  getTopFeedersByMaxPvGeneration,
  getTopFeedersByNumberOfPvs,
  newPvInsightDataSource2,
} from '@/model/insight/InsightChartData'
import {
  getNumberOfPvs,
  getPeakPvLoad,
  getPvSystemPeak,
} from '@/model/insight/summaries'
import InsightTemplate, {
  type SummaryBox,
  type AnalysisSection,
} from '@/components/analyze/insights/InsightTemplate.vue'
import { TimeSeriesDataSource } from '@/model/charts'
import type { HorizontalBarChartData } from '@/types/charts'
import { CalendarPeriod } from 'rfs/pb/calendar_pb'

const EMPTY_HORIZONTAL: HorizontalBarChartData = { bars: [] }

export default defineComponent({
  name: 'InsightPv',
  components: { InsightTemplate },
  setup() {
    return { charts: [chartDistributedGeneration] }
  },
  data() {
    return shallowReactive({
      isLoadingResources: false,
      isLoadingStats: false,
      isLoadingImpactTable: false,
      selectedPeriod: CalendarPeriod.MONTH,
      dataSource: TimeSeriesDataSource.emptyDataSource(),
      numberOfPvs: undefined as undefined | ReturnType<typeof getNumberOfPvs>,
      peakPvLoad: undefined as
        | undefined
        | Awaited<ReturnType<typeof getPeakPvLoad>>,
      systemPeak: undefined as undefined | ReturnType<typeof getPvSystemPeak>,
      numberOfPvsByFeeders: [] as number[],
      topFeedersByNumberOfPvs: EMPTY_HORIZONTAL,
      maxPvGenerationByFeeder: [] as number[],
      topFeedersByMaxPvGeneration: EMPTY_HORIZONTAL,
    })
  },
  computed: {
    interval(): Interval {
      return intervalLastPeriod(this.selectedPeriod, this.$observationTime())
    },
    summaryBoxes(): SummaryBox[] {
      return [
        {
          isLoading: this.isLoadingResources,
          title: 'Number of PV systems',
          mainValue:
            this.numberOfPvs?.total !== undefined
              ? numberNoDecimals.format(this.numberOfPvs.total)
              : TEXT_NO_VALUE,
          infoColumnItems: [
            {
              label: 'Metered',
              text:
                this.numberOfPvs?.metered !== undefined
                  ? numberNoDecimals.format(this.numberOfPvs.metered)
                  : TEXT_NO_VALUE,
            },
            {
              label: 'Estimated',
              text:
                this.numberOfPvs?.estimated !== undefined
                  ? numberNoDecimals.format(this.numberOfPvs.estimated)
                  : TEXT_NO_VALUE,
            },
            {
              label: 'Installed capacity',
              text:
                this.numberOfPvs?.installedCapacity !== undefined
                  ? Format.fmtWatts(
                      this.numberOfPvs.installedCapacity,
                      MEGA_OPTIONS
                    )
                  : TEXT_NO_VALUE,
            },
          ],
        },
        {
          isLoading: this.isLoadingResources,
          title: 'Peak PV generation',
          mainValue:
            this.peakPvLoad?.peak !== undefined
              ? Format.fmtWatts(this.peakPvLoad.peak, MEGA_OPTIONS)
              : TEXT_NO_VALUE,
          infoColumnItems: [
            {
              label: 'Time of peak',
              text: this.peakPvLoad?.timeOfPeak
                ? formatDtWithZoneShort(this.peakPvLoad.timeOfPeak)
                : TEXT_NO_VALUE,
            },
            {
              label: 'Metered',
              text:
                this.peakPvLoad?.peakOfMetered !== undefined
                  ? Format.fmtWatts(this.peakPvLoad.peakOfMetered, MEGA_OPTIONS)
                  : TEXT_NO_VALUE,
            },
            {
              label: 'Estimated',
              text:
                this.peakPvLoad?.peakOfEstimated !== undefined
                  ? Format.fmtWatts(
                      this.peakPvLoad.peakOfEstimated,
                      MEGA_OPTIONS
                    )
                  : TEXT_NO_VALUE,
            },
          ],
        },
        {
          isLoading: this.isLoadingStats,
          title: 'System peak',
          mainValue:
            this.systemPeak?.total !== undefined
              ? Format.fmtWatts(this.systemPeak?.total, MEGA_OPTIONS)
              : TEXT_NO_VALUE,
          infoColumnItems: [
            {
              label: 'Time of peak',
              text: this.systemPeak?.timeOfPeak
                ? formatDtWithZoneShort(this.systemPeak.timeOfPeak)
                : TEXT_NO_VALUE,
            },
            {
              label: 'PV generation at peak',
              text:
                this.systemPeak?.pvProductionAtPeak !== undefined
                  ? Format.fmtWatts(
                      this.systemPeak.pvProductionAtPeak,
                      MEGA_OPTIONS
                    )
                  : TEXT_NO_VALUE,
            },
            {
              label: 'PV generation of peak',
              text:
                this.systemPeak?.pvProductionAtPeakPercent !== undefined
                  ? Format.fmtPercent(this.systemPeak.pvProductionAtPeakPercent)
                  : TEXT_NO_VALUE,
            },
          ],
        },
      ]
    },
    analysisSections(): AnalysisSection[] {
      return [
        {
          isLoading: this.isLoadingResources,
          title: 'PV adoption',
          histogram: {
            title: 'Number of PV systems by feeder',
            xAxisTitle: 'Number of PV systems',
            yAxisTitle: 'Number of feeders',
            data: this.numberOfPvsByFeeders,
          },
          horizontalBar: {
            title: 'Number of PV systems (top 10 feeders)',
            xAxisTitle: 'Number of PV systems',
            data: this.topFeedersByNumberOfPvs,
          },
        },
        {
          isLoading: this.isLoadingImpactTable,
          title: 'PV generation',
          histogram: {
            title: 'Max PV generation by feeder',
            xAxisTitle: 'Max generation (kW)',
            yAxisTitle: 'Number of feeders',
            data: this.maxPvGenerationByFeeder,
          },
          horizontalBar: {
            title: 'Max PV generation (top 10 feeders)',
            xAxisTitle: 'Max generation (kW)',
            data: this.topFeedersByMaxPvGeneration,
          },
        },
      ]
    },
  },
  watch: {
    selectedPeriod: {
      immediate: true,
      handler: function () {
        this.fetchData()
      },
    },
  },
  methods: {
    handleNewPeriod(newPeriod: CalendarPeriod): void {
      this.selectedPeriod = newPeriod
    },
    resetState(): void {
      this.numberOfPvs = undefined
      this.systemPeak = undefined
      this.numberOfPvsByFeeders = []
      this.topFeedersByNumberOfPvs = EMPTY_HORIZONTAL
      this.maxPvGenerationByFeeder = []
      this.topFeedersByMaxPvGeneration = EMPTY_HORIZONTAL
      this.dataSource = newPvInsightDataSource2(
        this.$observationTime(),
        this.$services,
        this.interval
      )
    },
    async fetchData() {
      this.resetState()

      await Promise.all([
        this.fetchResources(),
        this.fetchStats(),
        this.fetchImpactTable(),
      ])
    },
    async fetchResources(): Promise<void> {
      this.isLoadingResources = true

      try {
        const [feeders, distributedSolars] = await Promise.all([
          this.$services.queryService.getResourcesByType(ResourceType.FEEDER),
          this.$services.queryService.getResourcesByType(
            ResourceType.SOLAR_DISTRIBUTED
          ),
          ,
        ])

        this.peakPvLoad = await getPeakPvLoad(
          this.$services,
          this.interval,
          distributedSolars.map((ds) => ds.id)
        )

        this.numberOfPvs = getNumberOfPvs(distributedSolars)

        this.numberOfPvsByFeeders = getNumberOfPvsByFeeder(
          feeders,
          distributedSolars
        )

        this.topFeedersByNumberOfPvs = getTopFeedersByNumberOfPvs(
          feeders,
          distributedSolars
        )
      } catch (err) {
        console.error('InsightPv.fetchResources: %o', err)
      } finally {
        this.isLoadingResources = false
      }
    },
    async fetchStats(): Promise<void> {
      this.isLoadingStats = true

      try {
        const gridImpactStats =
          await this.$services.analysisService.fetchDERImpactsStats({
            fixedInterval: this.selectedPeriod,
            componentType: 'NONE',
          })

        this.systemPeak = getPvSystemPeak(gridImpactStats)
      } catch (err) {
        console.error('InsightPv.fetchStats: %o', err)
      } finally {
        this.isLoadingStats = false
      }
    },
    async fetchImpactTable(): Promise<void> {
      this.isLoadingImpactTable = true

      try {
        const gridImpactTable =
          await this.$services.analysisService.fetchDERImpactsTable({
            fixedInterval: this.selectedPeriod,
            componentType: ResourceType.FEEDER,
            limit: -1,
          })
        this.maxPvGenerationByFeeder =
          getMaxPvGenerationByFeeder(gridImpactTable)

        this.topFeedersByMaxPvGeneration =
          getTopFeedersByMaxPvGeneration(gridImpactTable)
      } catch (err) {
        console.error('InsightPv.fetchImpactTable: %o', err)
      } finally {
        this.isLoadingImpactTable = false
      }
    },
  },
})
</script>
