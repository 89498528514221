import {
  Chart,
  type LegendItem,
  type ChartOptions,
  type FontSpec,
} from 'chart.js'
import type { AnnotationOptions } from 'chartjs-plugin-annotation'
import { BLACK } from '@/constants/colors'
import { usePreferencesStore } from '@/stores/preferences'
import type { ThresholdConfig } from '@/types/charts'

interface State {
  initialPreferencesApplied: boolean
}

// Maintain private state in a weak map to avoid storing data directly on the Chart instance.
const stateMap = new WeakMap() as WeakMap<Chart, State>

const DOTS = [1, 1]

const FONT = { ...Chart.defaults.font, size: 10, weight: null } as FontSpec

function createThresholdKey(chartId: string, label: string): string {
  return [chartId, label].join('|')
}

function isThresholdActive(chartId: string, label: string): boolean {
  const key = createThresholdKey(chartId, label)
  return !usePreferencesStore().hiddenThresholdAnnotations.has(key)
}

function updateThresholdState(
  chartId: string,
  label: string,
  isActive: boolean
): void {
  const globalStore = usePreferencesStore()

  const key = createThresholdKey(chartId, label)

  const newSet = new Set(globalStore.hiddenThresholdAnnotations)

  if (isActive) {
    newSet.delete(key)
  } else {
    newSet.add(key)
  }

  globalStore.updateHiddenThresholdAnnotations(newSet)
}

export function getCurrentThresholdAnnotation(
  chart: Chart
): undefined | AnnotationOptions<'line'> {
  // @ts-ignore
  return chart.options.plugins?.annotation?.annotations?.threshold
}

/**
 * A threshold annotation is a line annotation that runs horizontally at a specific Y value.
 */
export function createThresholdAnnotation(opts: {
  chartId: string
  config: ThresholdConfig
  persistVisibility?: boolean
}): ChartOptions {
  const threshold: AnnotationOptions<'line'> = {
    type: 'line',
    scaleID: 'y',
    display: isThresholdActive(opts.chartId, opts.config.label),
    value: opts.config.yValue,
    borderColor: BLACK.hex,
    borderWidth: 1,
    borderDash: DOTS,
    label: {
      content: opts.config.label,
      display: true,
      position: 'end',
      yAdjust: 10,
      backgroundColor: 'transparent',
      color: BLACK.hex,
      font: FONT,
    },
  }

  // Skip all the rest.
  if (!opts.config.showLegend) {
    return { plugins: { annotation: { annotations: { threshold } } } }
  }

  // Add a custom legend to the chart and intercept clicks on all legends.
  const options: ChartOptions = {
    plugins: {
      annotation: { annotations: { threshold } },
      legend: {
        // Always show the legends.
        display: true,
        labels: {
          generateLabels: (chart) => {
            let state = stateMap.get(chart)

            if (!state) {
              const newState = { initialPreferencesApplied: false }
              stateMap.set(chart, newState)
              state = newState
            }

            if (!state.initialPreferencesApplied) {
              if (!opts.persistVisibility) {
                // Reset back to "active" since persistence is not required.
                updateThresholdState(opts.chartId, opts.config.label, true)
              }

              state.initialPreferencesApplied = true
            }

            // Check the current state of the annotation so we can build
            // the legend with the correct visuals.
            const isActive = isThresholdActive(opts.chartId, opts.config.label)

            // Legend for the Threshold annotation.
            const customLabel: LegendItem = {
              text: opts.config.label,
              fillStyle: 'transparent',
              fontColor: isActive ? '#666' : '#00000066',
              strokeStyle: isActive ? 'black' : '#00000066',
              hidden: false,
              lineCap: 'butt',
              lineDash: [1, 1],
              lineDashOffset: 0,
              lineJoin: 'miter',
              datasetIndex: -1, // Not linked to any dataset.
            }

            // Generate the original legends.
            const original =
              Chart.defaults.plugins.legend.labels.generateLabels(chart)

            return [...original, customLabel]
          },
        },
        onClick: (event, legendItem, legend) => {
          // When it's a click on a original legend, use default beavior.
          if (legendItem.datasetIndex !== -1) {
            // @ts-ignore
            return Chart.defaults.plugins.legend.onClick(
              event,
              legendItem,
              legend
            )
          }

          const currentThreshold = getCurrentThresholdAnnotation(legend.chart)

          // Toggle state.
          if (currentThreshold) {
            const currentState = isThresholdActive(
              opts.chartId,
              opts.config.label
            )
            const newState = !currentState

            // Save preference.
            updateThresholdState(opts.chartId, opts.config.label, newState)

            // Update annotation.
            currentThreshold.display = newState
          }

          legend.chart.update()
        },
      },
    },
  }

  return options
}
