import {
  Code,
  ConnectError,
  createPromiseClient,
  PromiseClient,
  Transport,
} from '@connectrpc/connect'

import { Tile as ITileService } from 'rfs/frontend/proto/tile_connect'
import {
  TileRequest_DetailLevel as DetailLevel,
  TileResponse,
  TileResponse_Feature,
  TileResponse_GeometryType,
} from 'rfs/frontend/proto/tile_pb'
import { Resource } from 'rfs/pb/resource_pb'
import { ResourceType } from '@/constants/resourceType'
import { TileLoadProps } from '@/model/map/types'
import { optimizeResource } from './immutable'

export const TileDetailLevel = DetailLevel

/** Features from the TileService always have resources */
export type Feature = TileResponse_Feature & { resource: Resource }

export const GeometryType = TileResponse_GeometryType

export class TileService {
  private readonly client: PromiseClient<typeof ITileService>

  constructor(transport: Transport) {
    this.client = createPromiseClient(ITileService, transport)
  }

  /**
   * Return the grid resources whose location is within the given bounding box.
   * The resource type can be specifc or generic.
   */
  public async getTileResources(
    resourceType: ResourceType,
    { id, bbox, signal }: TileLoadProps,
    detailLevel?: DetailLevel
  ): Promise<TileResponse | null> {
    const request = { resourceType, bbox, detailLevel }

    try {
      await checkForAbort(signal)

      const response = await this.client.getTile(request, { signal })

      return optimizeResources(response)
    } catch (err: any) {
      // The TileLayer may abort a request if there are too many queued requests.
      // Note: only tiles that aren't visible will be aborted.
      if (!isAbortError(err)) {
        console.error(`[TileService] Failed to load '${id}' tile:`, err)
      }
      return null // Tell TileLayer that the data was not loaded
    }
  }
}

function isAbortError(err: any): boolean {
  // Connect-Web has a special error code for aborted requests
  if (err instanceof ConnectError && err.code === Code.Canceled) {
    return true
  }
  return err.name === 'AbortError'
}

/**
 * Wait a short time to see if a user action caused deck.gl to abort the tile request.
 * We want to prevent excessive requests to RFS.
 */
async function checkForAbort(signal: AbortSignal | undefined) {
  // Wait 250ms
  await new Promise((resolve) => setTimeout(resolve, 250))

  if (signal?.aborted) {
    throw new DOMException('Request cancelled', 'AbortError')
  }
}

function optimizeResources(response: TileResponse): TileResponse {
  response.layers.forEach((layer) => {
    layer.features.forEach((feat) => {
      if (feat.resource) optimizeResource(feat.resource)
    })
  })
  return response
}
