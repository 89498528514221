<template>
  <div class="d-flex position-relative">
    <!-- Loading -->
    <centered-spinner v-if="isLoading" />

    <!-- Conteent -->
    <template v-else>
      <!-- Details -->
      <div :style="{ width: detailsWidth }">
        <!-- Info Panel -->
        <site-info-panel v-model="selectedTab" :siteResource :otherResources />
      </div>

      <!-- Charts -->
      <device-telemetry-charts
        :group
        :resource
        :transformer
        :showStateOfCharge
        :isOperatingEnvelopeEnabled
        :currentOEStatus
        :intervalBroadcaster
        class="px-6"
        style="flex: 1"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, shallowReactive } from 'vue'
import { ResourceType } from '@/constants/resourceType'
import { DRAWER_LEFT_WIDTH_NARROW } from '@/stores/navigationControls'
import type { IntervalBroadcaster } from '@/utils/time/IntervalBroadcaster'
import type { OperatingEnvelopeStatus } from '@/model/control/operatingEnvelope'
import { SiteTab } from '@/model/site/tabs'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import SiteInfoPanel from '@/components/SiteInfoPanel.vue'
import DeviceTelemetryCharts from '@/components/control/device/DeviceTelemetryCharts.vue'
import { Device, Group } from 'rfs/control/proto/model_pb'
import { Resource } from 'rfs/pb/resource_pb'
import { DeviceHelper } from '@/model/control/device/helper'

export default defineComponent({
  name: 'DeviceTelemetry',
  props: {
    group: {
      type: Object as PropType<Group>,
      required: true,
    },
    resource: {
      type: Object as PropType<Resource>,
      required: true,
    },
    device: {
      type: Object as PropType<Device>,
      required: false,
    },
    isOperatingEnvelopeEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentOEStatus: {
      type: String as PropType<OperatingEnvelopeStatus>,
      required: false,
    },
    /**
     * Instructs component to refresh chart data, signaled by parent component.
     */
    intervalBroadcaster: {
      type: Object as PropType<IntervalBroadcaster>,
      required: false,
    },
  },
  components: { CenteredSpinner, SiteInfoPanel, DeviceTelemetryCharts },
  setup() {
    return { detailsWidth: `${DRAWER_LEFT_WIDTH_NARROW}px` }
  },
  data() {
    return shallowReactive({
      isLoading: false,
      selectedTab: SiteTab.DER,
      siteResource: new Resource(),
      otherResources: [] as Resource[],
      transformer: undefined as undefined | Resource,
    })
  },
  computed: {
    showStateOfCharge(): boolean {
      if (this.device) {
        return (
          DeviceHelper.isBattery(this.device) || DeviceHelper.isV2g(this.device)
        )
      } else {
        return this.resource.type === ResourceType.BATTERY_DISTRIBUTED
      }
    },
  },
  created(): void {
    this.fetchData()
  },
  methods: {
    async fetchData(): Promise<void> {
      this.isLoading = true

      // TODO(andrewg): Add an RPC to ControlsData that fetches all the resources
      // for a device. This will be more efficient than fetching them one by one.
      try {
        const { queryService } = this.$services

        const siteId = this.resource.site?.id
        const transformerId = this.resource.upline?.transformer

        if (!siteId) throw new Error('resource has no site ID')

        const [site, other] = await queryService.getResourcesBySiteID(siteId)

        this.siteResource = site
        this.otherResources = other

        if (!transformerId) {
          throw new Error('resource has no upline transformer')
        }

        this.transformer = await queryService.getResource(
          transformerId,
          ResourceType.TRANSFORMER
        )
      } catch (err) {
        console.error('DeviceTelemetry.fetchData: %o', err)
      } finally {
        this.isLoading = false
      }
    },
  },
})
</script>
