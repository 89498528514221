<template>
  <insight-template
    :charts
    :data-source
    :summary-boxes
    :analysis-sections
    :interval
    :selected-period
    @new-period="handleNewPeriod"
  />
</template>

<script lang="ts">
import { defineComponent, shallowReactive } from 'vue'
import { Interval } from 'luxon'
import { ResourceType } from '@/constants/resourceType'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { MEGA_OPTIONS, Format } from '@/utils/format'
import { formatDtWithZoneShort, intervalLastPeriod } from '@/utils/time'
import { numberNoDecimals } from '@/utils/formatText'
import { TimeSeriesDataSource } from '@/model/charts'
import {
  chartChargingLoad,
  getMaxEvLoadByFeeder,
  getNumberOfEvsByFeeder,
  getTopFeedersByMaxEvLoad,
  getTopFeedersByNumberOfEvs,
  newEvInsightDataSource2,
} from '@/model/insight/InsightChartData'
import {
  getChargerIds,
  getNumberOfEvs,
  getPeakEvLoad,
  getEvSystemPeak,
} from '@/model/insight/summaries'
import InsightTemplate, {
  type SummaryBox,
  type AnalysisSection,
} from '@/components/analyze/insights/InsightTemplate.vue'
import type { HorizontalBarChartData } from '@/types/charts'
import { CalendarPeriod } from 'rfs/pb/calendar_pb'

const EMPTY_HORIZONTAL: HorizontalBarChartData = { bars: [] }

export default defineComponent({
  name: 'InsightEv',
  components: { InsightTemplate },
  setup() {
    return { charts: [chartChargingLoad] }
  },
  data() {
    return shallowReactive({
      isLoadingResources: false,
      isLoadingStats: false,
      isLoadingImpactTable: false,
      selectedPeriod: CalendarPeriod.MONTH,
      dataSource: TimeSeriesDataSource.emptyDataSource(),
      numberOfEvs: undefined as undefined | ReturnType<typeof getNumberOfEvs>,
      peakEvLoad: undefined as
        | undefined
        | Awaited<ReturnType<typeof getPeakEvLoad>>,
      systemPeak: undefined as undefined | ReturnType<typeof getEvSystemPeak>,
      numberOfEvsByFeeders: [] as number[],
      topFeedersByNumberOfEvs: EMPTY_HORIZONTAL,
      maxEvLoadByFeeder: [] as number[],
      topFeedersByMaxEvLoad: EMPTY_HORIZONTAL,
    })
  },
  computed: {
    interval(): Interval {
      return intervalLastPeriod(this.selectedPeriod, this.$observationTime())
    },
    summaryBoxes(): SummaryBox[] {
      return [
        {
          isLoading: this.isLoadingResources,
          title: 'Number of EVs',
          mainValue:
            this.numberOfEvs?.total !== undefined
              ? numberNoDecimals.format(this.numberOfEvs.total)
              : TEXT_NO_VALUE,
          infoColumnItems: [
            {
              label: 'Detected',
              text:
                this.numberOfEvs?.detected !== undefined
                  ? numberNoDecimals.format(this.numberOfEvs.detected)
                  : TEXT_NO_VALUE,
            },
            {
              label: 'Known',
              text:
                this.numberOfEvs?.known !== undefined
                  ? numberNoDecimals.format(this.numberOfEvs.known)
                  : TEXT_NO_VALUE,
            },
            {
              label: 'Installed capacity',
              text:
                Format.fmtWatts(
                  this.numberOfEvs?.installedCapacity,
                  MEGA_OPTIONS
                ) || TEXT_NO_VALUE,
            },
          ],
        },
        {
          isLoading: this.isLoadingResources,
          title: 'Peak EV load',
          mainValue:
            Format.fmtWatts(this.peakEvLoad?.peak, MEGA_OPTIONS) ||
            TEXT_NO_VALUE,
          infoColumnItems: [
            {
              label: 'Time of peak',
              text: this.peakEvLoad?.timeOfPeak
                ? formatDtWithZoneShort(this.peakEvLoad.timeOfPeak)
                : TEXT_NO_VALUE,
            },
            {
              label: 'Detected',
              text:
                Format.fmtWatts(
                  this.peakEvLoad?.peakOfDetected,
                  MEGA_OPTIONS
                ) || TEXT_NO_VALUE,
            },
            {
              label: 'Known',
              text:
                Format.fmtWatts(this.peakEvLoad?.peakOfKnown, MEGA_OPTIONS) ||
                TEXT_NO_VALUE,
            },
          ],
        },
        {
          isLoading: this.isLoadingStats,
          title: 'System peak',
          mainValue:
            Format.fmtWatts(this.systemPeak?.total, MEGA_OPTIONS) ||
            TEXT_NO_VALUE,
          infoColumnItems: [
            {
              label: 'Time of peak',
              text: this.systemPeak?.timeOfPeak
                ? formatDtWithZoneShort(this.systemPeak.timeOfPeak)
                : TEXT_NO_VALUE,
            },
            {
              label: 'EV load at peak',
              text:
                Format.fmtWatts(this.systemPeak?.evLoadAtPeak, MEGA_OPTIONS) ||
                TEXT_NO_VALUE,
            },
            {
              label: 'EV load of peak',
              text:
                Format.fmtPercent(this.systemPeak?.evLoadAtPeakPercent) ||
                TEXT_NO_VALUE,
            },
          ],
        },
      ]
    },
    analysisSections(): AnalysisSection[] {
      return [
        {
          isLoading: this.isLoadingResources,
          title: 'EV adoption',
          histogram: {
            title: 'Number of EVs by feeder',
            xAxisTitle: 'Number of EVs',
            yAxisTitle: 'Number of feeders',
            data: this.numberOfEvsByFeeders,
          },
          horizontalBar: {
            title: 'Number of EVs (top 10 feeders)',
            xAxisTitle: 'Number of EVs',
            data: this.topFeedersByNumberOfEvs,
          },
        },
        {
          isLoading: this.isLoadingImpactTable,
          title: 'EV load',
          histogram: {
            title: 'Max EV load by feeder',
            xAxisTitle: 'Max load (kW)',
            yAxisTitle: 'Number of feeders',
            data: this.maxEvLoadByFeeder,
          },
          horizontalBar: {
            title: 'Max EV load (top 10 feeders)',
            xAxisTitle: 'Load (kW)',
            data: this.topFeedersByMaxEvLoad,
          },
        },
      ]
    },
  },
  watch: {
    selectedPeriod: {
      immediate: true,
      handler: function () {
        this.fetchData()
      },
    },
  },
  methods: {
    handleNewPeriod(newPeriod: CalendarPeriod): void {
      this.selectedPeriod = newPeriod
    },
    resetState(): void {
      this.numberOfEvs = undefined
      this.peakEvLoad = undefined
      this.systemPeak = undefined
      this.numberOfEvsByFeeders = []
      this.topFeedersByNumberOfEvs = EMPTY_HORIZONTAL
      this.maxEvLoadByFeeder = []
      this.topFeedersByMaxEvLoad = EMPTY_HORIZONTAL
      this.dataSource = newEvInsightDataSource2(
        this.$observationTime(),
        this.$services,
        this.interval
      )
    },
    async fetchData() {
      this.resetState()

      await Promise.all([
        this.fetchResources(),
        this.fetchStats(),
        this.fetchImpactTable(),
      ])
    },
    async fetchResources(): Promise<void> {
      this.isLoadingResources = true

      try {
        const [feeders, chargers] = await Promise.all([
          this.$services.queryService.getResourcesByType(ResourceType.FEEDER),
          this.$services.queryService.getResourcesByType(ResourceType.CHARGER),
        ])

        this.numberOfEvs = getNumberOfEvs(chargers)

        const { detected, known } = getChargerIds(chargers)

        this.peakEvLoad = await getPeakEvLoad(
          this.$services,
          this.interval,
          detected,
          known
        )

        this.numberOfEvsByFeeders = getNumberOfEvsByFeeder(feeders, chargers)

        this.topFeedersByNumberOfEvs = getTopFeedersByNumberOfEvs(
          feeders,
          chargers
        )
      } catch (err) {
        console.error('InsightEv.fetchResources: %o', err)
      } finally {
        this.isLoadingResources = false
      }
    },
    async fetchStats(): Promise<void> {
      this.isLoadingStats = true

      try {
        const gridImpactStats =
          await this.$services.analysisService.fetchDERImpactsStats({
            fixedInterval: this.selectedPeriod,
            componentType: 'NONE',
          })

        this.systemPeak = getEvSystemPeak(gridImpactStats)
      } catch (err) {
        console.error('InsightEv.fetchStats: %o', err)
      } finally {
        this.isLoadingStats = false
      }
    },
    async fetchImpactTable(): Promise<void> {
      this.isLoadingImpactTable = true

      try {
        const gridImpactTable =
          await this.$services.analysisService.fetchDERImpactsTable({
            fixedInterval: this.selectedPeriod,
            componentType: ResourceType.FEEDER,
            limit: -1,
          })

        this.maxEvLoadByFeeder = getMaxEvLoadByFeeder(gridImpactTable)

        this.topFeedersByMaxEvLoad = getTopFeedersByMaxEvLoad(gridImpactTable)
      } catch (err) {
        console.error('InsightEv.fetchImpactTable: %o', err)
      } finally {
        this.isLoadingImpactTable = false
      }
    },
  },
})
</script>
