import { TabId } from '@/config/types'
import * as RouteNames from '@/router/routeNames'
import { useGridMapStore } from '@/stores/gridMap'
import { ZOOM_METERS } from '@/model/map/constants'
import {
  createAutocompleteService,
  createGeocoder,
  loadGmaps,
} from '@/model/map/gmaps'
import type { LoadBlockFunction, Block, Result } from './types'

const TITLE = 'Address'

export const searchAddresses: LoadBlockFunction = async (
  searchText,
  _maxResults,
  { config, currentRoute, router }
) => {
  const block: Block = { title: TITLE, results: [] }

  try {
    // NOTE: When the user haven't visited the "Monitor" tab yet.
    await loadGmaps()

    const autocomplete = await createAutocompleteService()

    const [sw, ne] = config.map.boundingArea

    const response = await autocomplete.getPlacePredictions({
      input: searchText,
      locationRestriction: new google.maps.LatLngBounds(sw, ne),
    })

    block.results = response.predictions.map((pred): Result => {
      const onClick = async () => {
        const geocoder = await createGeocoder()

        const response = await geocoder.geocode({ placeId: pred.place_id })

        // Push the user to one of the "Monitor" routes.
        if (currentRoute.meta.tab !== TabId.MONITOR) {
          router.push({ name: RouteNames.OVERVIEW })
        }

        // Add red pin.
        useGridMapStore().focusLocations({
          locations: [response.results[0].geometry.location.toJSON()],
          showRedPins: true,
          zoom: ZOOM_METERS,
        })
      }

      return {
        title: { text: pred.structured_formatting.main_text, onClick },
        subTitle: pred.structured_formatting.secondary_text,
        pairs: [],
      }
    })
  } catch (err) {
    console.error('searchAddresses: %o', err)
  }

  return block
}
