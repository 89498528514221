import { PlainMessage } from '@bufbuild/protobuf'
import { mergeWith } from 'lodash-es'
import { ColumnType, NO_WRAP_CELL, newFilterField } from '@/model/tables/column'
import { Header, DataTable, Filters, Options } from '@/model/tables/DataTable'
import { DERImpactsRow } from 'rfs/frontend/proto/analysis_pb'
import { offsetNameShort } from '@/utils/time'
import { createGridImpactResourceRoute } from '@/utils/router/create'
import { ResourceType } from '@/constants/resourceType'

// The table can use the RPC response rows directly
export type GridImpactRow = PlainMessage<DERImpactsRow>

export interface GridImpactDataTable extends DataTable<GridImpactRow> {}

export type CustomFilters = Filters<string>

export type CustomOptions = Options<string>

// See https://docs.google.com/document/d/1hZyggbJrmoQTFb58tCWm_UWaXsXtftPzr9IrcATJvdw/edit#heading=h.ag3g8m50ppho

const HEADER_GROUP_01_NAME = 'Component constants'
const HEADER_GROUP_02_NAME = 'Component loading'
const HEADER_GROUP_03_NAME = 'EV charging'
const HEADER_GROUP_04_NAME = 'EV charging during peak'
const HEADER_GROUP_05_NAME = 'PV generation'
const HEADER_GROUP_06_NAME = 'PV generation during min'
const HEADER_GROUP_07_NAME = 'BESS'
const HEADER_GROUP_08_NAME = 'Voltage'

const MODELED_PROPS = { class: ['bg-color--mango-50'] }

type CreateHeaderOptions = {
  addModelHeaders: boolean
  resourceType: ResourceType
}

function createHeaderGroup1(opt?: CreateHeaderOptions): Header[] {
  const isSubstation = opt?.resourceType === ResourceType.SUBSTATION
  const isFeeder = opt?.resourceType === ResourceType.FEEDER

  const headers: Header[] = []

  // Always include the 'Name' column
  headers.push({
    title: 'Name',
    key: 'id',
    filterable: false,
    routeFactory: (config, r) =>
      createGridImpactResourceRoute(config, r.resource),
    columnSelector: { group: HEADER_GROUP_01_NAME, required: true },
  })

  // Include the 'Substation' if the resource is not a substation
  if (!isSubstation) {
    headers.push({
      title: 'Substation',
      key: 'resource.upline.substation',
      valueType: ColumnType.SUBSTATION,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_01_NAME },
    })
  }

  // Include the 'Feeder' if the resource is not a substation or feeder
  if (!isSubstation && !isFeeder) {
    headers.push({
      title: 'Feeder',
      key: 'resource.upline.feeder',
      valueType: ColumnType.FEEDER,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_01_NAME },
    })
  }

  // Always include the other columns regardless of resource type
  headers.push(
    {
      title: 'Number of meters',
      key: 'meters',
      valueType: ColumnType.INTEGER,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_01_NAME },
    },
    {
      title: 'Time steps evaluated (%)',
      key: 'timeStepsEvaluated',
      valueType: ColumnType.PERCENT,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_01_NAME },
    },
    {
      title: 'Residential Meters (%)',
      key: 'residentialFracMeters',
      valueType: ColumnType.PERCENT,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_01_NAME },
    },
    {
      title: 'Capacity rating (kVA)',
      key: 'rating',
      valueType: ColumnType.KILO_VA,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_01_NAME },
    }
  )

  return headers
}

function createHeaderGroup2(opt?: CreateHeaderOptions): Header[] {
  const headers: Header[] = [
    {
      title: `Peak Time (${offsetNameShort()})`,
      key: 'data.peakTime',
      valueType: ColumnType.DATETIME,
      filterable: false, // TODO(andrewg): implement this
      align: 'end',
      cellProps: NO_WRAP_CELL,
      columnSelector: { group: HEADER_GROUP_02_NAME },
    },
    {
      title: 'Peak load (kW)',
      key: 'data.peakLoad',
      valueType: ColumnType.KILO_W,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_02_NAME },
    },
    {
      title: 'Utilization rate at peak (%)',
      key: 'data.utilizationRate',
      valueType: ColumnType.PERCENT,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_02_NAME },
    },
    {
      title: 'Percent of time overloaded',
      key: 'data.timeOverloadedFrac',
      valueType: ColumnType.PERCENT,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_02_NAME },
    },
    {
      title: `Min load time (${offsetNameShort()})`,
      key: 'data.minTime',
      valueType: ColumnType.DATETIME,
      filterable: false, // TODO(andrewg): implement this
      align: 'end',
      cellProps: NO_WRAP_CELL,
      columnSelector: { group: HEADER_GROUP_02_NAME },
    },
    {
      title: 'Min load (kW)',
      key: 'data.minLoad',
      valueType: ColumnType.KILO_W,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_02_NAME },
    },
    {
      title: 'Utilization rate at min (%)',
      key: 'data.minUtilizationRate',
      valueType: ColumnType.PERCENT,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_02_NAME },
    },
    {
      title: 'Average load (kW)',
      key: 'data.avgLoad',
      valueType: ColumnType.KILO_W,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_02_NAME },
    },
    {
      title: 'Load factor (%)',
      key: 'data.loadFactor',
      valueType: ColumnType.PERCENT,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_02_NAME },
    },
  ]

  if (opt?.addModelHeaders) {
    return headers.flatMap((h) => [h, toModelHeader(h)])
  }

  return headers
}

function createHeaderGroup3(opt?: CreateHeaderOptions): Header[] {
  const headers: Header[] = [
    {
      title: 'Number of EVs',
      key: 'data.numEvs',
      valueType: ColumnType.INTEGER,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_03_NAME },
    },
    {
      title: 'EV adoption rate (%)',
      key: 'data.evAdoptionFrac',
      valueType: ColumnType.PERCENT,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_03_NAME },
    },
    {
      title: 'EV capacity (kW)',
      key: 'data.evCapacity',
      valueType: ColumnType.KILO_W,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_03_NAME },
    },
    {
      title: 'Max number of EVs charging',
      key: 'data.maxNumEvsCharging',
      valueType: ColumnType.INTEGER,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_03_NAME },
    },
    {
      title: 'Max EV load (kW)',
      key: 'data.maxEvLoad',
      valueType: ColumnType.KILO_W,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_03_NAME },
    },
    {
      title: 'Avg number of EVs charging',
      key: 'data.avgNumEvsCharging',
      valueType: ColumnType.DECIMAL,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_03_NAME },
    },
    {
      title: 'Avg EV load (kW)',
      key: 'data.avgEvLoad',
      valueType: ColumnType.KILO_W,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_03_NAME },
    },
    {
      title: 'EV load factor (%)',
      key: 'data.evLoadFactor',
      valueType: ColumnType.PERCENT,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_03_NAME },
    },
  ]

  if (opt?.addModelHeaders) {
    return headers.flatMap((h) => [h, toModelHeader(h)])
  }

  return headers
}

function createHeaderGroup4(opt?: CreateHeaderOptions): Header[] {
  const headers: Header[] = [
    {
      title: 'Number of EVs charging at peak',
      key: 'data.numEvsChargingAtPeak',
      valueType: ColumnType.INTEGER,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_04_NAME },
    },
    {
      title: 'Percent of EVs charging at peak (%)',
      key: 'data.fracEvsChargingAtPeak',
      valueType: ColumnType.PERCENT,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_04_NAME },
    },
    {
      title: 'EV load at peak (kW)',
      key: 'data.evLoadAtPeak',
      valueType: ColumnType.KILO_W,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_04_NAME },
    },
    {
      title: 'EV load at peak (%)',
      key: 'data.evLoadFracAtPeak',
      valueType: ColumnType.PERCENT,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_04_NAME },
    },
  ]

  if (opt?.addModelHeaders) {
    return headers.flatMap((h) => [h, toModelHeader(h)])
  }

  return headers
}

function createHeaderGroup5(opt?: CreateHeaderOptions): Header[] {
  const headers: Header[] = [
    {
      title: 'Number of PV Systems',
      key: 'data.numPvs',
      valueType: ColumnType.INTEGER,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_05_NAME },
    },
    {
      title: 'PV adoption rate (%)',
      key: 'data.pvAdoptionFrac',
      valueType: ColumnType.PERCENT,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_05_NAME },
    },
    {
      title: 'PV capacity (kW)',
      key: 'data.pvCapacity',
      valueType: ColumnType.KILO_W,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_05_NAME },
    },
    {
      title: 'Max PV generation (kW)',
      key: 'data.maxPvProduction',
      valueType: ColumnType.KILO_W,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_05_NAME },
    },
  ]

  if (opt?.addModelHeaders) {
    return headers.flatMap((h) => [h, toModelHeader(h)])
  }

  return headers
}

function createHeaderGroup6(opt?: CreateHeaderOptions): Header[] {
  const headers: Header[] = [
    {
      title: 'PV generation at min load (kW)',
      key: 'data.pvProductionAtMin',
      valueType: ColumnType.KILO_W,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_06_NAME },
    },
    {
      title: 'PV load fraction at min load (%)',
      key: 'data.pvProductionGrossAtMin',
      valueType: ColumnType.PERCENT,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_06_NAME },
    },
  ]

  if (opt?.addModelHeaders) {
    return headers.map((h) => [h, toModelHeader(h)]).flat()
  }

  return headers
}

function createHeaderGroup7(_opt?: CreateHeaderOptions): Header[] {
  return [
    {
      title: 'Number of BESS',
      key: 'data.numBess',
      valueType: ColumnType.INTEGER,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_07_NAME },
    },
    {
      title: 'BESS adoption rate (%)',
      key: 'data.bessAdoptionFrac',
      valueType: ColumnType.PERCENT,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_07_NAME },
    },
    {
      title: 'BESS capacity (kW)',
      key: 'data.bessCapacity',
      valueType: ColumnType.KILO_W,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_07_NAME },
    },
  ]
}

function createHeaderGroup8(_opt?: CreateHeaderOptions): Header[] {
  return [
    {
      title: 'Max meters under voltage (%)',
      key: 'data.maxPctMetersUnderVoltage',
      valueType: ColumnType.PERCENT,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_08_NAME },
    },
    {
      title: 'Max meters over voltage (%)',
      key: 'data.maxPctMetersOverVoltage',
      valueType: ColumnType.PERCENT,
      align: 'end',
      columnSelector: { group: HEADER_GROUP_08_NAME },
    },
  ]
}

export function createHeaders(opt?: CreateHeaderOptions): Header[] {
  const createHeaderGroupFns = [
    createHeaderGroup1,
    createHeaderGroup2,
    createHeaderGroup3,
    createHeaderGroup4,
    createHeaderGroup5,
    createHeaderGroup6,
    createHeaderGroup7,
    createHeaderGroup8,
  ]
  const headers = createHeaderGroupFns.flatMap((group) => group(opt))

  for (const h of headers) {
    // Add "min-width" so the column's title occupy 2 lines at max.
    h.minWidth = h.title.length < 26 ? '112px' : '122px'

    // Add background-color when modeled column.
    if (h.key.startsWith('modeled.')) {
      h.headerProps = combineProps(MODELED_PROPS, h.headerProps)
      h.cellProps = combineProps(MODELED_PROPS, h.cellProps)
    }
  }

  return headers
}

export function updateFilterMapWithNewHeaders(
  currentFilterMap: CustomFilters,
  currentHeaders: Header[],
  newHeaders: Header[]
): CustomFilters {
  const newFilterMap = new Map(currentFilterMap)

  // Add
  if (newHeaders.length > currentHeaders.length) {
    for (const newHeader of newHeaders) {
      if (!currentFilterMap.has(newHeader.key) && newHeader.valueType) {
        const ff = newFilterField(newHeader.valueType)
        if (ff) {
          newFilterMap.set(newHeader.key, ff)
        }
      }
    }
  } else {
    // Remove
    for (const [headerValue, _filterFields] of currentFilterMap.entries()) {
      if (!newHeaders.find((h) => h.key === headerValue)) {
        newFilterMap.delete(headerValue)
      }
    }
  }

  return newFilterMap
}

function toModelHeader(h: Header): Header {
  return {
    ...h,
    key: h.key.replace('data.', 'modeled.'),
    columnSelector: { dependentOn: h.key },
  }
}

function combineProps(
  props1: Record<string, any>,
  props2: Record<string, any> | undefined
) {
  if (!props2) return props1
  return mergeWith({}, props1, props2, (objValue, srcValue) => {
    if (Array.isArray(objValue)) {
      return objValue.concat(srcValue)
    }
  })
}
