<template>
  <div class="c-TemporaryWaypointForm">
    <!-- Table -->
    <ce-data-table
      v-if="table.rows.length"
      :headers
      :table
      :bg-color="bgColor"
      :show-pagination="false"
      :sticky-offset="TOP_BAR_HEIGHT"
      sticky
      dense
      border-bottom
      class="pb-4"
    >
      <!-- Custom rows -->
      <template v-slot:item="{ item, index }">
        <automatic-control-row-edit
          v-if="indexOfWaypointToEdit === index && formFields"
          :index="index"
          :metric="metric"
          v-model:date="formFields.date"
          v-model:start-time="formFields.startTime"
          v-model:end-time="formFields.endTime"
          v-model:target-value="formFields.targetValue"
          :validation="formValidation"
          :min-date="now"
          :max-date="maxDate"
          @cancel="cancelEdit"
          @save="saveEditted"
        />
        <automatic-control-row
          v-else
          :row="item"
          :is-disabled="isSubmitting || isRowBeingEditted"
          @edit="() => handleClickEdit(index)"
          @delete="() => handleClickDelete(index)"
        />
      </template>
    </ce-data-table>

    <!-- Overlap errors -->
    <alert-error
      data-test="overlap"
      v-for="(err, index) of overlapErrors"
      :key="index"
      class="mb-2"
    >
      {{ err }}
    </alert-error>

    <!-- Server Failed -->
    <alert-error v-if="failedMsg" data-test="failedMsg" class="mb-2">
      {{ failedMsg }}
    </alert-error>

    <!-- Action buttons -->
    <form-actions
      ref="formActions"
      :metric
      :device-type
      :is-submitting
      :is-editting="isRowBeingEditted"
      :is-touched="
        hasTouchedWaypoints || hasTouchedEnvelope || hasTouchedExpiration
      "
      :has-overlaps="Boolean(overlapErrors.length)"
      @add="addNewWaypoint"
      @cancel="$emit('cancel')"
      @submit="openSubmitConfirmDialog"
    >
      <div
        v-if="showEnvelopeRadioGroup || showExpirationRadioGroup"
        class="d-flex"
        style="gap: 1rem"
      >
        <!-- Operating Envelope -->
        <envelope-status-radio-group
          v-if="showEnvelopeRadioGroup && currentOEStatus !== undefined"
          :model-value="radioOptionEnvelope"
          :currentOEStatus
          :isSubmitting
          class="min-height-100"
          style="flex: 1"
          @update:model-value="handleNewEnvelopeOption"
        />

        <expiration-radio-group
          v-if="showExpirationRadioGroup"
          ref="expirationRadioGroup"
          :model-value="radioOptionExpiration"
          :group
          :has-o-e-enabled="currentOEStatus !== undefined"
          :has-no-waypoints="!waypoints.length"
          :isSubmitting
          class="min-height-100"
          style="flex: 1"
          @update:model-value="handleNewExpirationOption"
        />
      </div>
    </form-actions>

    <!-- Modal Confirmation -->
    <dialog-confirm
      v-if="dialogState"
      :title="dialogState.title"
      :body="dialogState.body"
      @cancel="() => closeDialog('cancel')"
      @confirm="() => closeDialog('confirm')"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, shallowReactive } from 'vue'
import { DateTime, Interval } from 'luxon'
import { Timestamp } from '@/services/timestamp_pb'
import { useGlobalSnackbar } from '@/stores/globalSnackbar'
import { NEUTRAL_50 } from '@/constants/colors'
import { TOP_BAR_HEIGHT } from '@/constants/topBar'
import {
  isEnvelopesOnly,
  isRecurringSchedule,
} from '@/model/control/group/helper'
import type { OperatingEnvelopeStatus } from '@/model/control/operatingEnvelope'
import {
  Metric,
  areWaypointsEqual,
  computeAllowedIntervalAndFilter,
  validateTemporaryOverlapping,
  sortByStartTime,
  getSubmitScheduleConfirmationMessage,
  confirmationTitle,
  changeRouteMessage,
  getScheduleSuccessMessage,
  submitTemporaryWaypointsInChunks,
} from '@/model/control/waypoint'
import {
  controlsOffActivated,
  envelopesOnlyActivated,
} from '@/model/control/controlMode'
import {
  createAutomaticControlDataTable,
  createAutomaticControlHeaders,
} from '@/model/control/WaypointDataTable'
import { WaypointFormFields } from '@/model/control/waypointFormFields'
import AlertError from '@/components/common/AlertError.vue'
import CeDataTable from '@/components/common/CeDataTable.vue'
import AutomaticControlRow from '@/components/control/AutomaticControlRow.vue'
import AutomaticControlRowEdit from '@/components/control/AutomaticControlRowEdit.vue'
import FormActions from '@/components/control/FormActions.vue'
import DialogConfirm from '@/components/common/DialogConfirm.vue'
import EnvelopeStatusRadioGroup, {
  RADIO_OPTION_ACTIVATE,
  RADIO_OPTION_RETAIN,
  type RadioOption as RadioOptionEnvelope,
} from '@/components/control/EnvelopeStatusRadioGroup.vue'
import ExpirationRadioGroup, {
  RADIO_OPTION_CONTROLS_OFF,
  RADIO_OPTION_ENVELOPES_ONLY,
  RADIO_OPTION_REPLACE_WITH_RECURR,
  type RadioOption as RadioOptionExpiration,
} from '@/components/control/ExpirationRadioGroup.vue'
import { Waypoint } from 'rfs/control/proto/waypoints_pb'
import {
  Policy,
  Params as PolicyParams,
  Params_AutomaticControlEvent as AutomaticPolicyParams,
} from 'rfs/control/proto/policy_pb'
import type { Device, DeviceType, Group } from 'rfs/control/proto/model_pb'
import type { NewTemporaryWaypoints } from '@/components/control/ControlMode.vue'

type DialogProps = {
  title: string
  body: string
  confirmCb: Function
  cancelCb?: Function
}

const DEFAULT_ERR_MSG = 'Something went wrong.'

// NOTE(rafael): These are the metrics that this component supports for now.
export const validMetrics = [
  Metric.METRIC_MAX_CHARGE_POWER_WATTS,
  Metric.METRIC_SOC_PERCENT,
  Metric.METRIC_SOC_WATT_HOURS,
  Metric.METRIC_ACTIVE_POWER_WATTS,
]

export default defineComponent({
  name: 'TemporaryWaypointForm',
  props: {
    group: {
      type: Object as PropType<Group>,
      required: true,
    },
    futureInterval: {
      type: Object as PropType<Interval>,
      required: true,
    },
    metric: {
      type: Number as PropType<Metric>,
      required: true,
      validator(value, _props) {
        return validMetrics.includes(value as Metric)
      },
    },
    deviceType: {
      type: Number as PropType<DeviceType>,
      required: true,
    },
    initialWaypoints: {
      type: Array as PropType<Waypoint[]>,
      required: false,
      default: () => [],
    },
    powerRating: {
      type: Number,
      required: false,
    },
    devices: {
      type: Array as PropType<Device[]>,
      required: false,
      default: () => [],
    },
    currentOEStatus: {
      type: String as PropType<OperatingEnvelopeStatus>,
      required: false,
    },
  },
  components: {
    AlertError,
    CeDataTable,
    AutomaticControlRow,
    AutomaticControlRowEdit,
    FormActions,
    DialogConfirm,
    EnvelopeStatusRadioGroup,
    ExpirationRadioGroup,
  },
  setup() {
    return {
      TOP_BAR_HEIGHT,
      globalSnackbarStore: useGlobalSnackbar(),
      bgColor: NEUTRAL_50.hex,
    }
  },
  data() {
    const { allowedInterval, filtered } = computeAllowedIntervalAndFilter(
      this.futureInterval,
      this.initialWaypoints
    )

    // Auto-select since the group already has a configured "nextPolicy".
    const radioOptionExpiration: undefined | RadioOptionExpiration = (() => {
      // "Controls off" option.
      if (this.group.nextPolicy === Policy.DEFAULT) {
        return RADIO_OPTION_CONTROLS_OFF
      } else if (
        // "Envelopes only" option.
        isEnvelopesOnly(this.group.nextPolicy, this.group.nextPolicyParameters)
      ) {
        return RADIO_OPTION_ENVELOPES_ONLY
      } else if (
        isRecurringSchedule(
          this.group.nextPolicy,
          this.group.nextPolicyParameters
        )
      ) {
        // "Recurring Schedule" option.
        return RADIO_OPTION_REPLACE_WITH_RECURR
      } else {
        return undefined
      }
    })()

    return shallowReactive({
      isSubmitting: false,
      hasSubmittingFailed: false,
      failedMsg: null as null | string,
      radioOptionEnvelope: RADIO_OPTION_RETAIN as RadioOptionEnvelope,
      radioOptionExpiration,
      isAddingNewWaypoint: false,
      hasTouchedWaypoints: false,
      hasTouchedEnvelope: false,
      hasTouchedExpiration: false,
      indexOfWaypointToEdit: undefined as undefined | number,
      dialogState: null as null | DialogProps,
      allowedIntervalForWaypoints: allowedInterval,
      filteredWaypoints: filtered,
      waypoints: [...filtered],
      //
      formFields: undefined as
        | undefined
        | ReturnType<typeof shallowReactive<WaypointFormFields>>,
      formValidation: undefined as
        | undefined
        | ReturnType<typeof WaypointFormFields.prototype.validate>,
      // The success message varies based on the policy being submitted. For
      // instance, when the user deletes all existing temporary waypoints
      // and selects "Controls off", a specific message is required.
      snackbarSuccessMsg: '',
    })
  },
  computed: {
    groupId(): string {
      return this.group.id
    },
    currentPolicy() {
      return this.group.currentPolicy
    },
    currentPolicyParams() {
      return this.group.currentPolicyParams
    },
    nextPolicy() {
      return this.group.nextPolicy
    },
    nextPolicyParams() {
      return this.group.nextPolicyParameters
    },
    now(): DateTime {
      return this.futureInterval.start
    },
    minStart(): DateTime {
      return this.allowedIntervalForWaypoints.start
    },
    maxDate(): DateTime {
      return this.futureInterval.end
    },
    table() {
      return createAutomaticControlDataTable({
        metric: this.metric,
        waypoints: this.waypoints,
      })
    },
    headers() {
      return createAutomaticControlHeaders({
        metric: this.metric,
        powerRating: this.powerRating,
        deviceType: this.deviceType,
      })
    },
    overlapErrors(): string[] {
      return validateTemporaryOverlapping(this.waypoints)
    },
    isRowBeingEditted(): boolean {
      return this.indexOfWaypointToEdit !== undefined
    },
    showEnvelopeRadioGroup(): boolean {
      // There is at least 1 waypoint and the user is not editting, which
      // means the waypoint is saved.
      const cond1 = !this.isAddingNewWaypoint && this.waypoints.length

      // The user is adding a new waypoint and has already one saved waypoint.
      const cond2 = this.isAddingNewWaypoint && this.waypoints.length > 1

      // All waypoints were deleted, and the user selected the "Recurring Schedule"
      // option in the "expiration" radio group. This requires the "envelope"
      // radio group to be enabled, allowing the user to choose the
      // desired "OE" status.
      const cond3 =
        this.filteredWaypoints.length &&
        !this.waypoints.length &&
        this.radioOptionExpiration === RADIO_OPTION_REPLACE_WITH_RECURR

      return Boolean(cond1 || cond2 || cond3)
    },
    showExpirationRadioGroup(): boolean {
      // There is at least 1 waypoint and the user is not editting, which
      // means the waypoint is saved.
      const cond1 = !this.isAddingNewWaypoint && this.waypoints.length

      // The user is adding a new waypoint and has already one saved waypoint.
      const cond2 = this.isAddingNewWaypoint && this.waypoints.length > 1

      // There was at least 1 initial waypoint and the user deleted all of them.
      const cond3 = this.filteredWaypoints.length && !this.waypoints.length

      return Boolean(cond1 || cond2 || cond3)
    },
  },
  watch: {
    initialWaypoints(): void {
      if (this.hasTouchedWaypoints || this.isRowBeingEditted) return

      this.resetState()
    },
    radioOptionExpiration(): void {
      this.handleTouchedWaypoints()
    },
  },
  mounted(): void {
    this.focusBtnAddWaypoint()
  },
  methods: {
    shouldChangeRoute(next: Function): void {
      if (this.hasTouchedWaypoints) {
        this.dialogState = {
          title: confirmationTitle,
          body: changeRouteMessage,
          cancelCb: () => next(false),
          confirmCb: () => next(),
        }
      } else {
        next()
      }
    },
    handleNewEnvelopeOption(newOption: RadioOptionEnvelope): void {
      this.hasTouchedEnvelope = true
      this.radioOptionEnvelope = newOption
    },
    handleNewExpirationOption(newOption: RadioOptionExpiration): void {
      this.hasTouchedExpiration = true
      this.radioOptionExpiration = newOption
    },
    handleClickEdit(index: number): void {
      const waypoint = this.waypoints?.[index]

      if (!waypoint) throw new Error('waypoint expected')

      this.indexOfWaypointToEdit = index

      this.formFields = shallowReactive(
        new WaypointFormFields({
          waypoint,
          metric: this.metric,
          deviceType: this.deviceType,
          powerRating: this.powerRating,
          config: {
            getNow: () => this.now,
            getMinStart: () => this.minStart,
            getMaxEnd: () => this.maxDate,
          },
        })
      )

      this.formValidation = undefined
    },
    cancelEdit(): void {
      // When the user chooses to cancel the addition of a new Waypoint,
      // delete the new Waypoint from the list of Waypoints since
      // it's an empty Waypoint.
      if (this.isAddingNewWaypoint) {
        const indexOfLastWaypoint = this.waypoints.length - 1
        const newWaypoints = [...this.waypoints]
        newWaypoints.splice(indexOfLastWaypoint, 1)
        this.waypoints = newWaypoints
        this.isAddingNewWaypoint = false
      }

      this.indexOfWaypointToEdit = undefined
      this.formFields = undefined
      this.formValidation = undefined

      this.focusBtnAddWaypoint()
    },
    saveEditted(): void {
      if (!this.formFields) {
        throw new Error(
          'TemporaryWaypointForm.saveEditted: unavailable formFields'
        )
      }

      const validation = this.formFields.validate()

      if (!validation.isValid) {
        this.formValidation = validation
        return
      }

      const edittedWaypoint = this.formFields.generateWaypoint()

      const newWaypoints = [...this.waypoints]

      if (this.indexOfWaypointToEdit === undefined) {
        throw new Error(
          'TemporaryWaypointForm.saveEditted: unexpected "undefined" index'
        )
      }

      newWaypoints[this.indexOfWaypointToEdit] = edittedWaypoint

      this.waypoints = newWaypoints
      this.indexOfWaypointToEdit = undefined
      this.formFields = undefined
      this.formValidation = undefined
      this.isAddingNewWaypoint = false

      this.handleTouchedWaypoints()

      this.focusBtnAddWaypoint()
    },
    focusBtnAddWaypoint(): void {
      ;(this.$refs.formActions as any)?.focusBtnAdd?.()
    },
    addNewWaypoint(): void {
      this.isAddingNewWaypoint = true

      const pristineWaypoint = new Waypoint({ targetMetric: this.metric })

      const newWaypoints = [...this.waypoints, pristineWaypoint]

      this.waypoints = newWaypoints

      const lastIndex = newWaypoints.length - 1

      this.$nextTick(() => {
        this.handleClickEdit(lastIndex)
      })
    },
    handleClickDelete(index: number): void {
      const newWaypoints = [...this.waypoints]
      newWaypoints.splice(index, 1)
      this.waypoints = newWaypoints
      this.handleTouchedWaypoints()
    },
    async handleTouchedWaypoints(): Promise<void> {
      this.hasTouchedWaypoints = !areWaypointsEqual(
        this.filteredWaypoints,
        this.waypoints
      )

      if (this.overlapErrors.length) return

      const payload: NewTemporaryWaypoints = {
        waypoints: sortByStartTime(this.waypoints),
        unifyWithRecurringWaypoints:
          this.radioOptionExpiration === RADIO_OPTION_REPLACE_WITH_RECURR,
      }

      this.$emit('new-waypoints', payload)
    },
    resetState(): void {
      const { allowedInterval, filtered } = computeAllowedIntervalAndFilter(
        this.futureInterval,
        this.initialWaypoints
      )

      this.allowedIntervalForWaypoints = allowedInterval
      this.filteredWaypoints = filtered
      this.waypoints = [...filtered]
      this.hasTouchedWaypoints = false
      this.indexOfWaypointToEdit = undefined
      this.formFields = undefined
      this.formValidation = undefined
    },
    closeDialog(opt: 'cancel' | 'confirm'): void {
      if (opt === 'confirm') {
        this.dialogState?.confirmCb()
      } else if (opt === 'cancel') {
        this.dialogState?.cancelCb?.()
      } else {
        throw new Error('TemporaryWaypointForm.closeDialog: unexpected option')
      }

      this.dialogState = null
    },
    openSubmitConfirmDialog(): void {
      // The user should always pick one "expiration" option before being
      // allowed to submit.
      if (this.radioOptionExpiration === undefined) {
        ;(this.$refs.expirationRadioGroup as any).showValidationMsg()
        return
      }

      this.dialogState = {
        title: confirmationTitle,
        body: getSubmitScheduleConfirmationMessage({
          metric: this.metric,
          deviceType: this.deviceType,
          hasWaypoints: !!this.waypoints.length,
        }),
        confirmCb: this.submitNewTemporarySchedule,
      }
    },
    async submitNewTemporarySchedule(): Promise<void> {
      this.$emit('submitting', true)
      this.isSubmitting = true
      this.hasSubmittingFailed = false
      this.failedMsg = null

      try {
        if (this.overlapErrors.length) {
          throw new Error('there are overlapped waypoints')
        }

        if (!this.radioOptionExpiration) {
          throw new Error('no "expiration" option selected')
        }

        // Step 1: Operating Envelope.
        await this.submitOperatingEnvelope()

        // Step 2: Waypoints.
        // Chargers. The strategy of submitting the waypoints is different.
        // The untouched waypoints are kept and only the new ones are
        // submitted.
        if (this.metric === Metric.METRIC_MAX_CHARGE_POWER_WATTS) {
          await submitTemporaryWaypointsInChunks(
            this.$services,
            this.groupId,
            this.filteredWaypoints,
            this.waypoints
          )
        } else {
          const { start, end } = this.allowedIntervalForWaypoints

          // All other metrics. Wipe out everything and overwrite with
          // new waypoints all at once.
          await this.$services.control.submitWaypoints({
            groupId: this.groupId,
            startTime: Timestamp.fromDateTime(start),
            endTime: Timestamp.fromDateTime(end),
            waypointsList: { waypoints: sortByStartTime(this.waypoints) },
          })
        }

        // Step 3: set policy.

        const lastWaypoint = sortByStartTime(this.waypoints).at(-1)

        if (lastWaypoint) {
          await this.submitWithNextPolicy(lastWaypoint)
        } else {
          // At this point, the group's current policy is "Temporary Scheule"
          // and the user deleted all existing temp. waypoints, apply the
          // "expiration" option right now.
          await this.revertPolicyNow()
        }

        this.handleSubmitSuccess()
      } catch (err) {
        this.hasSubmittingFailed = true
        const failedMsg = (err as any).message || DEFAULT_ERR_MSG
        this.failedMsg = failedMsg
        this.globalSnackbarStore.openSnackbar(failedMsg, 'error')
        console.error(
          'TemporaryWaypointForm.submitNewTemporarySchedule: %o',
          err
        )
      } finally {
        this.$emit('submitting', false)
        this.isSubmitting = false
      }
    },
    async submitOperatingEnvelope(): Promise<void> {
      // Do nothing, the "Operating Envelope" feature is disabled for the group.
      if (this.currentOEStatus === undefined) return

      if (!this.devices.length) throw new Error('no devices available')

      // It means 1) the user deleted all existing waypoints and 2) the user doesn't
      // want (ou doesn't have the option) to use "Recurring Schedule" option.
      // Do what the user choosed in the "Expiration" radio group, either
      // "Controls off" or "Envelopes only".
      if (!this.showEnvelopeRadioGroup) {
        await this.$services.control.updateDevices({
          updates: this.devices.map((d) => {
            return {
              deviceId: d.id,
              oeEnabled:
                this.radioOptionExpiration === RADIO_OPTION_ENVELOPES_ONLY,
            }
          }),
        })
      } else if (this.radioOptionEnvelope === RADIO_OPTION_RETAIN) {
        // Do nothing. Retain the same status.
      } else {
        // Activate or deactivate the OE status for the entire group of devices.
        await this.$services.control.updateDevices({
          updates: this.devices.map((d) => {
            return {
              deviceId: d.id,
              oeEnabled: this.radioOptionEnvelope === RADIO_OPTION_ACTIVATE,
            }
          }),
        })
      }
    },
    async revertPolicyNow(): Promise<void> {
      // "Controls off"
      if (this.radioOptionExpiration === RADIO_OPTION_CONTROLS_OFF) {
        await this.$services.control.setGroupPolicy({
          ids: [this.groupId],
          policy: Policy.DEFAULT,
        })
        this.snackbarSuccessMsg = controlsOffActivated
      } else if (this.radioOptionExpiration === RADIO_OPTION_ENVELOPES_ONLY) {
        // "Envelopes only"
        await this.$services.control.setGroupPolicy({
          ids: [this.groupId],
          policy: Policy.AUTOMATIC_CONTROL_EVENT,
          params: {
            paramsOneof: {
              case: 'automaticControlEvent',
              value: new AutomaticPolicyParams({
                constraintsOnly: true,
              }),
            },
          },
        })
        this.snackbarSuccessMsg = envelopesOnlyActivated
      } else if (
        this.radioOptionExpiration === RADIO_OPTION_REPLACE_WITH_RECURR
      ) {
        // Revert to "Recurring Schedule".
        if (!isRecurringSchedule(this.nextPolicy, this.nextPolicyParams)) {
          throw new Error('"nextPolicy" needs to be "Recurring Schedule"')
        }

        await this.$services.control.setGroupPolicy({
          ids: [this.groupId],
          policy: this.nextPolicy,
          params: this.nextPolicyParams,
        })

        this.snackbarSuccessMsg = getScheduleSuccessMessage({
          metric: this.metric,
          deviceType: this.deviceType,
          isRecurring: true,
        })
      } else {
        throw new Error('revertPolicyNow: "expiration" field was unselected')
      }
    },
    async submitWithNextPolicy(lastWaypoint: Waypoint): Promise<void> {
      // "Temporary Schedule" policy and policy params.
      const policy = Policy.AUTOMATIC_CONTROL_EVENT
      const params = new PolicyParams({
        // NOTE: even blank, the server requires the params object to be submitted
        // when Temp. Schedule.
        paramsOneof: { case: 'automaticControlEvent', value: {} },
      })
      const endTime = lastWaypoint.endTime

      if (!endTime) throw new Error('the last waypoint nas no "endTime"')

      // "Controls off"
      if (this.radioOptionExpiration === RADIO_OPTION_CONTROLS_OFF) {
        await this.$services.control.setGroupPolicy({
          policy,
          params,
          endTime,
          ids: [this.groupId],
          nextPolicy: { policy: Policy.DEFAULT },
        })
      } else if (this.radioOptionExpiration === RADIO_OPTION_ENVELOPES_ONLY) {
        // "Envelopes only"
        await this.$services.control.setGroupPolicy({
          policy,
          params,
          endTime,
          ids: [this.groupId],
          nextPolicy: {
            policy: Policy.AUTOMATIC_CONTROL_EVENT,
            params: {
              paramsOneof: {
                case: 'automaticControlEvent',
                value: new AutomaticPolicyParams({
                  constraintsOnly: true,
                }),
              },
            },
          },
        })
      } else if (
        this.radioOptionExpiration === RADIO_OPTION_REPLACE_WITH_RECURR
      ) {
        // Revert to "Recurring Schedule".
        if (
          !(
            isRecurringSchedule(this.nextPolicy, this.nextPolicyParams) ||
            isRecurringSchedule(this.currentPolicy, this.currentPolicyParams)
          )
        ) {
          throw new Error(
            'no "Recurring Schedule" available to be used as next policy'
          )
        }

        await this.$services.control.setGroupPolicy({
          policy,
          params,
          endTime,
          ids: [this.groupId],
          nextPolicy: {
            policy: this.nextPolicy || this.currentPolicy,
            params: this.nextPolicyParams || this.currentPolicyParams,
          },
        })
      } else {
        throw new Error(
          'submitWithNextPolicy: "expiration" field was unselected'
        )
      }
    },
    handleSubmitSuccess(): void {
      const msg =
        this.snackbarSuccessMsg ||
        getScheduleSuccessMessage({
          metric: this.metric,
          deviceType: this.deviceType,
        })

      this.globalSnackbarStore.openSnackbar(msg, 'info')
      this.$emit('success')
    },
  },
})
</script>
