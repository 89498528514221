<template>
  <insight-template
    :analysis-sections
    :interval
    :selected-period
    @new-period="handleNewPeriod"
  />
</template>

<script lang="ts">
import { defineComponent, shallowReactive } from 'vue'
import { Interval } from 'luxon'
import { intervalLastPeriod } from '@/utils/time'
import {
  getPeakUtilizationHistogram,
  getTopPeakUtilization,
} from '@/model/insight/InsightChartData'
import InsightTemplate, {
  type AnalysisSection,
} from '@/components/analyze/insights/InsightTemplate.vue'
import { ResourceType } from '@/constants/resourceType'
import { CalendarPeriod } from 'rfs/pb/calendar_pb'

type Utilization = {
  isLoading: boolean
  peakUtilization: number[]
  topPeakUtilization: ReturnType<typeof getTopPeakUtilization>
}

const EMPTY_TOP: ReturnType<typeof getTopPeakUtilization> = {
  chart: { bars: [] },
  sideData: { items: [] },
}

export default defineComponent({
  name: 'InsightPv',
  components: { InsightTemplate },
  data() {
    return {
      selectedPeriod: CalendarPeriod.MONTH,
      utilization: {
        [ResourceType.SUBSTATION]: shallowReactive({
          isLoading: false,
          peakUtilization: [],
          topPeakUtilization: EMPTY_TOP,
        }),
        [ResourceType.FEEDER]: shallowReactive({
          isLoading: false,
          peakUtilization: [],
          topPeakUtilization: EMPTY_TOP,
        }),
        [ResourceType.TRANSFORMER]: shallowReactive({
          isLoading: false,
          peakUtilization: [],
          topPeakUtilization: EMPTY_TOP,
        }),
      } as Record<string, Utilization>,
    }
  },
  computed: {
    interval(): Interval {
      return intervalLastPeriod(this.selectedPeriod, this.$observationTime())
    },
    analysisSections(): AnalysisSection[] {
      const substation = this.utilization[ResourceType.SUBSTATION]
      const feeder = this.utilization[ResourceType.FEEDER]
      const transformer = this.utilization[ResourceType.TRANSFORMER]

      const sections = [] as AnalysisSection[]

      if (this.isVisble(substation)) {
        sections.push({
          isLoading: substation.isLoading,
          title: 'Substation utilization',
          histogram: {
            title: 'Peak utilization by substation',
            xAxisTitle: 'Utilization rate at peak (%)',
            yAxisTitle: 'Number of substations',
            data: substation.peakUtilization,
          },
          horizontalBar: {
            title: 'Peak utilization (top 10 substations)',
            xAxisTitle: 'Utilization (%)',
            data: substation.topPeakUtilization.chart,
            sideData: {
              title: 'EV load at peak (%)',
              items: substation.topPeakUtilization.sideData.items,
            },
          },
        })
      }
      if (this.isVisble(feeder)) {
        sections.push({
          isLoading: feeder.isLoading,
          title: 'Feeder utilization',
          histogram: {
            title: 'Peak utilization by feeder',
            xAxisTitle: 'Utilization rate at peak (%)',
            yAxisTitle: 'Number of feeders',
            data: feeder.peakUtilization,
          },
          horizontalBar: {
            title: 'Peak utilization (top 10 feeders)',
            xAxisTitle: 'Utilization (%)',
            data: feeder.topPeakUtilization.chart,
            sideData: {
              title: 'EV load at peak (%)',
              items: feeder.topPeakUtilization.sideData.items,
            },
          },
        })
      }
      if (this.isVisble(transformer)) {
        sections.push({
          isLoading: transformer.isLoading,
          title: 'Transformer utilization',
          histogram: {
            title: 'Peak utilization by transformer',
            xAxisTitle: 'Utilization rate at peak (%)',
            yAxisTitle: 'Number of transformers',
            data: transformer.peakUtilization,
          },
          horizontalBar: {
            title: 'Peak utilization (top 10 transformers)',
            xAxisTitle: 'Utilization (%)',
            data: transformer.topPeakUtilization.chart,
            sideData: {
              title: 'EV load at peak (%)',
              items: transformer.topPeakUtilization.sideData.items,
            },
          },
        })
      }

      return sections
    },
  },
  watch: {
    selectedPeriod: {
      immediate: true,
      handler: function () {
        this.fetchData()
      },
    },
  },
  methods: {
    handleNewPeriod(newPeriod: CalendarPeriod): void {
      this.selectedPeriod = newPeriod
    },
    isVisble(section: Utilization): boolean {
      return section.isLoading || section.peakUtilization.some((n) => n !== 0)
    },
    async fetchData() {
      await Promise.all([
        this.fetchDataFor(ResourceType.SUBSTATION),
        this.fetchDataFor(ResourceType.FEEDER),
        this.fetchDataFor(ResourceType.TRANSFORMER),
      ])
    },
    async fetchDataFor(rt: ResourceType): Promise<void> {
      const util = this.utilization[rt]
      util.isLoading = true

      try {
        const impacts =
          await this.$services.analysisService.fetchDERImpactsTable({
            fixedInterval: this.selectedPeriod,
            componentType: rt,
            limit: -1,
            orderBy: { property: 'data.utilizationRate', descending: true },
            include: ['id', 'data.utilizationRate', 'data.evLoadFracAtPeak'],
          })

        util.peakUtilization = getPeakUtilizationHistogram(impacts)
        util.topPeakUtilization = getTopPeakUtilization(impacts)
      } catch (err) {
        console.error('InsightLoading.fetchDataFor: %o', err)
      } finally {
        util.isLoading = false
      }
    },
  },
})
</script>
