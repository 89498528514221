import { shallowReactive } from 'vue'
import { convertFilters } from '@/model/tables/column'
import {
  updateHeaderLabels,
  createInitialVisibleHeaders,
  upgradeVisibleHeaders,
} from '@/model/tables/header'
import {
  FilterMultiSelect,
  Header,
  NewOptionsContext,
  type VisibleHeaders,
} from '@/model/tables/DataTable'
import {
  SearchFilters,
  SearchOptions,
  SearchTableColumn,
  searchTableHeaders,
  createNewFilters,
} from '@/model/meter/MeterSearchDataTable'
import { valueToSelectItem } from '@/model/tables/filter'
import { createDefaultOptions } from '@/model/tables/helper'
import { FilterOptions } from 'rfs/frontend/proto/pagination_pb'
import { SearchResponse } from 'rfs/frontend/proto/query_pb'
import { NO_MORE_RESULTS } from '@/services/query.service'
import { assoc } from '@/utils/immutable'
import { defineImmutableStore } from './defineStore'
import { useInternalStore } from './internal'

export const useMeterSearchStore = defineImmutableStore('meterSearch', {
  persist: {
    paths: ['searchText', 'filters', 'options', 'visibleHeaders'],
  },
  state: () => {
    const store = useInternalStore()
    const headers = updateHeaderLabels(store.dictionary, searchTableHeaders)

    return shallowReactive({
      isLoading: false,
      hasLoadingFailed: false,
      searchText: '',
      filters: createNewFilters(),
      options: createDefaultOptions(SearchTableColumn.METER) as SearchOptions,
      allHeaders: headers,
      visibleHeaders: createInitialVisibleHeaders(headers),
      lastResponse: null as null | SearchResponse,
      /**
       * Stores the number of results for each page. This allows us to use the
       * actual total number of results when we reach the end of the results
       * when the order by, filter by and search query are the same.
       */
      pageResultCounts: {} as Record<string, number>,
    })
  },
  getters: {
    headers(): Header[] {
      // Remove the customer column if the site is configured to hide customer info
      return this.allHeaders.filter((header) => {
        if (this.config.monitor?.site?.hideCustomerInfo) {
          return header.key !== SearchTableColumn.CUSTOMER
        } else {
          return true
        }
      })
    },
    serverItemsLength(): undefined | number {
      if (this.lastResponse === null) return undefined

      if (this.lastResponse.nextOffset === NO_MORE_RESULTS) {
        return Object.values(this.pageResultCounts).reduce(
          (acc, count) => acc + count,
          0
        )
      } else {
        return Number.MAX_SAFE_INTEGER
      }
    },
    isInitialState(): boolean {
      return this.lastResponse === null
    },
    /** If there is a response with an export URL, return the full URL */
    csvDownloadUrl(): URL | null {
      if (!this.lastResponse?.exportUrl) return null

      return new URL(this.lastResponse.exportUrl, this.config.rfsEndpoint)
    },
  },
  actions: {
    async fetchData() {
      this.isLoading = true
      this.hasLoadingFailed = false

      try {
        const response = await this.services.queryService.searchForMeters(
          this.searchText,
          this.options,
          convertFilters(this.filters)
        )

        this.pageResultCounts = {
          ...this.pageResultCounts,
          [this.options.page]: response.results.length,
        }

        this.lastResponse = response
        this.filters = updateSelectFilters(
          this.filters,
          this.lastResponse.filterOptions
        )
      } catch (err) {
        this.hasLoadingFailed = true
        console.error('meterSearch.fetchData: %o', err)
      } finally {
        this.isLoading = false
      }
    },
    /** Action called when the user changes one or more filters */
    updateFilters(newFilters?: SearchFilters): void {
      this.filters = newFilters ?? createNewFilters()
      this.fetchData()
    },
    /** Action called when the user changes a page or sort order */
    updateOptions(newOptions: SearchOptions, ctx?: NewOptionsContext): void {
      this.options = newOptions

      // When first page, flush the count of results.
      if (newOptions.page === 1) {
        this.pageResultCounts = {}
      }

      if (ctx?.triggeredByFilter) return

      this.fetchData()
    },
    updateSearchText(newSearch: string): void {
      if (this.searchText === newSearch) return

      this.searchText = newSearch
      // When a new search text, reset back to page 1.
      this.updateOptions(
        { ...this.options, page: 1 },
        { triggeredByFilter: true }
      )
      this.fetchData()
    },
    updateVisibleHeaders(newValue: VisibleHeaders): void {
      this.visibleHeaders = newValue
    },
  },
  upgradeState(currentState, initialState) {
    const { filters: currentFilters } = currentState
    // The current filter map may be missing some newly added filters
    for (const [key, filter] of initialState.filters) {
      if (!currentFilters.has(key)) currentFilters.set(key, filter)
    }

    // TODO(rafael): upgrade options: when header doesn't exist anymore, it could still be in use in 'options.orderBy'.

    // Headers
    upgradeVisibleHeaders(
      currentState.visibleHeaders,
      initialState.visibleHeaders
    )
  },
})

function updateSelectFilters(
  filters: SearchFilters,
  options: FilterOptions[]
): SearchFilters {
  if (options.length === 0) return filters

  const newFilters = new Map(filters)
  for (const option of options) {
    const choices = option.options?.values ?? []
    const filter = filters.get(option.property as SearchTableColumn)

    if (choices.length === 0) {
      continue
    } else if (option.property === SearchTableColumn.RATE_TYPE) {
      const multiSelect = filter as FilterMultiSelect
      newFilters.set(
        SearchTableColumn.RATE_TYPE,
        assoc(multiSelect, 'items', choices.map(valueToSelectItem))
      )
    }
  }
  return newFilters
}
