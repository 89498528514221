import { defineImmutableStore } from './defineStore'
import { ResourceType } from '@/constants/resourceType'

// The internal store exists just so that we can access plugins within the
// "state" block of other Pinia stores.
export const useInternalStore = defineImmutableStore('internal', {
  getters: {
    allowedResourceTypes(): ResourceType[] {
      return (this.config.gridImpact?.resourceTypes as ResourceType[]) ?? []
    },
  },
})
