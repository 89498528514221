import { PlainMessage } from '@bufbuild/protobuf'
import { difference } from 'lodash-es'
import { Services } from '@/services'
import { ITimeSeriesDataSource, TimeSeriesDataSource } from '@/model/charts'
import { ChartType, ChartDefinition } from '@/types/charts'
import { ResourceGroup } from 'rfs/frontend/proto/capacity_pb'
import {
  BUTTERCUP,
  CARDINAL,
  CILANTRO,
  GREY9,
  JAFFA,
  MALIBU,
  WALLABY,
} from '@/constants/colors'
import {
  TimeSeries,
  TimeSeriesResponse,
  TimeSeries_DataPoint as TimeSeriesDataPoint,
} from 'rfs/frontend/proto/tsdb_pb'
import { Metric } from '@/constants/metrics'
import { Resolution } from 'rfs/frontend/proto/resolution_pb'
import { ResourceType } from '@/constants/resourceType'

// DEMAND
const DEMAND_NET_NAME = 'Net Demand'
export const DEMAND_NET_CACHE_KEY = 'demand/net'
const DEMAND_NET_COLOR = MALIBU.hex

const DEMAND_GROSS_NAME = 'Gross Demand'
const DEMAND_GROSS_CACHE_KEY = 'demand/gross'
const DEMAND_GROSS_COLOR = CARDINAL.hex

// SUPPLY
const HYDRO_NAME = 'Hydro'
const HYDRO_COLOR = MALIBU.hex

const SOLAR_DISTRIBUTED_NAME = 'Distributed Solar'
const SOLAR_DISTRIBUTED_COLOR = JAFFA.hex

const SOLAR_FARM_NAME = 'Utility Solar'
const SOLAR_FARM_COLOR = BUTTERCUP.hex

const WIND_NAME = 'Wind'
const WIND_COLOR = CILANTRO.hex

const WHOLESALE_POWER_NAME = 'Wholesale Power'
const WHOLESALE_POWER_CACHE_KEY = 'wholesale/power'
const WHOLESALE_POWER_COLOR = WALLABY.hex

// CARBON FREE SUPPLY
const CARBON_POSITIVE_NAME = 'Carbon Emitting'
const CARBON_POSITIVE_CACHE_KEY = 'carbon/positive'
const CARBON_POSITIVE_COLOR = GREY9.hex

const CARBON_NEUTRAL_NAME = 'Carbon Free'
export const CARBON_NEUTRAL_CACHE_KEY = 'carbon/neutral'
const CARBON_NEUTRAL_COLOR = CILANTRO.hex

// Cache Key to Display Name Dictionary by legend Order
export const cacheKeyToDisplayName: { [key: string]: string } = {
  // DEMAND
  [DEMAND_NET_CACHE_KEY]: DEMAND_NET_NAME,
  [DEMAND_GROSS_CACHE_KEY]: DEMAND_GROSS_NAME,
  // SUPPLY
  [WHOLESALE_POWER_CACHE_KEY]: WHOLESALE_POWER_NAME,
  [ResourceType.SOLAR_DISTRIBUTED]: SOLAR_DISTRIBUTED_NAME,
  [ResourceType.SOLAR_FARM]: SOLAR_FARM_NAME,
  [ResourceType.HYDRO]: HYDRO_NAME,
  [ResourceType.WIND]: WIND_NAME,
  // CARBON FREE SUPPLY
  [CARBON_NEUTRAL_CACHE_KEY]: CARBON_NEUTRAL_NAME,
  [CARBON_POSITIVE_CACHE_KEY]: CARBON_POSITIVE_NAME,
}

export const demandChartDefinition: ChartDefinition = {
  id: 'demand-chart',
  title: 'Demand',
  type: ChartType.PowerMW,
  height: 288,
}

export const supplyChartDefinition: ChartDefinition = {
  id: 'supply-chart',
  title: 'Supply',
  type: ChartType.PowerMW,
  isStackedBar: true,
  height: 288,
}

export const carbonFreeChartDefinition: ChartDefinition = {
  id: 'carbon-free-chart',
  title: 'Carbon Free Electricity',
  type: ChartType.CarbonFree,
  isStackedBar: true,
  yAxis: { max: 1 },
  height: 288,
}

/**
 * Returns a data source that includes time series for the following charts:
 * - Supply
 * - Demand
 * - Carbon Free
 */
export function newSupplyDemandDataSource(
  services: Services,
  availableResources: Set<ResourceType>
): ITimeSeriesDataSource {
  const ds = new TimeSeriesDataSource((request) => {
    const response = services.capacityService.fetchSupplyDemandTimeSeries(
      ResourceGroup.ALL,
      request.interval,
      Resolution.ONE_HOUR
    )
    // The carbon chart shows percentages, so we need to update the data
    return response.then(updateCarbonSeries)
  })

  // Supply
  // WHOLESALE/POWER
  // TODO (Isaac): Have RFA return `wholesale/power` as `substation`
  ds.addChartSeries(supplyChartDefinition, {
    metric: Metric.COND_POWER_REAL,
    resource: WHOLESALE_POWER_CACHE_KEY,
    config: {
      seriesName: WHOLESALE_POWER_NAME,
      seriesColor: WHOLESALE_POWER_COLOR,
    },
  })

  // SOLAR/DISTRIBUTED
  if (availableResources.has(ResourceType.SOLAR_DISTRIBUTED)) {
    ds.addChartSeries(supplyChartDefinition, {
      metric: Metric.POWER_PRODUCED,
      resource: ResourceType.SOLAR_DISTRIBUTED,
      config: {
        seriesName: SOLAR_DISTRIBUTED_NAME,
        seriesColor: SOLAR_DISTRIBUTED_COLOR,
      },
    })
  }

  // SOLAR/FARM
  if (availableResources.has(ResourceType.SOLAR_FARM)) {
    ds.addChartSeries(supplyChartDefinition, {
      metric: Metric.POWER_PRODUCED,
      resource: ResourceType.SOLAR_FARM,
      config: {
        seriesName: SOLAR_FARM_NAME,
        seriesColor: SOLAR_FARM_COLOR,
      },
    })
  }

  // HYDRO
  if (availableResources.has(ResourceType.HYDRO)) {
    ds.addChartSeries(supplyChartDefinition, {
      metric: Metric.POWER_PRODUCED,
      resource: ResourceType.HYDRO,
      config: {
        seriesName: HYDRO_NAME,
        seriesColor: HYDRO_COLOR,
      },
    })
  }

  // WIND
  if (availableResources.has(ResourceType.WIND)) {
    ds.addChartSeries(supplyChartDefinition, {
      metric: Metric.POWER_PRODUCED,
      resource: ResourceType.WIND,
      config: {
        seriesName: WIND_NAME,
        seriesColor: WIND_COLOR,
      },
    })
  }

  // DEMAND/NET
  ds.addChartSeries(demandChartDefinition, {
    metric: Metric.COND_POWER_REAL,
    resource: DEMAND_NET_CACHE_KEY,
    config: {
      seriesLineWidth: 2,
      seriesName: DEMAND_NET_NAME,
      seriesColor: DEMAND_NET_COLOR,
    },
  })

  // DEMAND/GROSS
  ds.addChartSeries(demandChartDefinition, {
    metric: Metric.POWER_PRODUCED,
    resource: DEMAND_GROSS_CACHE_KEY,
    config: {
      seriesLineWidth: 1.5,
      seriesName: DEMAND_GROSS_NAME,
      seriesColor: DEMAND_GROSS_COLOR,
    },
  })

  // CARBON/NEUTRAL
  ds.addChartSeries(carbonFreeChartDefinition, {
    metric: Metric.POWER_PRODUCED,
    resource: CARBON_NEUTRAL_CACHE_KEY,
    config: {
      seriesName: CARBON_NEUTRAL_NAME,
      seriesColor: CARBON_NEUTRAL_COLOR,
    },
  })

  // CARBON/POSITIVE
  ds.addChartSeries(carbonFreeChartDefinition, {
    metric: Metric.POWER_PRODUCED,
    resource: CARBON_POSITIVE_CACHE_KEY,
    config: {
      seriesName: CARBON_POSITIVE_NAME,
      seriesColor: CARBON_POSITIVE_COLOR,
    },
  })

  return ds
}

function isCarbonSeries(series: TimeSeries) {
  return series.resource.startsWith('carbon/')
}

export function updateCarbonSeries(
  response: TimeSeriesResponse
): TimeSeriesResponse {
  const carbonSeries = response.series.filter(isCarbonSeries)
  const percents = timeSeriesToPercentages(carbonSeries)
  // Remove the old carbon series and add the percentage carbon series
  response.series = difference(response.series, carbonSeries).concat(percents)
  return response
}

// This converts a TimeSeriesResponse with raw power data
// into values as a percentage of the total.
// It only works when totaling all values ignoring the metrics
// TODO (Isaac): Decided how this should work with multiple metrics
// Currently the SupplyDemandTimeSeries returns a single metric
// for each TimeSeriesResponse ("power.produced" or "conditions.power.real")
// which can be compared because they are both in Watts.
function timeSeriesToPercentages(series: TimeSeries[]): TimeSeries[] {
  // Create an an array of all data points
  const allData = series.flatMap((series) => series.data)
  const totalTimeSeries = allData.reduce(
    timeSeriesTotalByTimeStamp,
    new Map<number, number>()
  )

  // Create a new series for each timeSeries
  // divide each data point by the total at that timestamp
  // and find the new minY, maxY, meanY
  const percentageSeries: TimeSeries[] = series.map((s) => {
    // Normalize data to percentages of total at timestamp
    const data = s.data.map((d) => {
      const value = (d.y ?? 0) / (totalTimeSeries.get(d.x) || 1)
      return {
        x: d.x,
        // Clamp to 0-100
        y: value < 0 ? 0 : value > 100 ? 100 : value,
      }
    })
    // Find new minY, maxY, meanY
    const meanY = data.reduce((acc, d) => acc + (d.y || 0), 0) / data.length

    const maxY = data.reduce((acc, d) => Math.max(acc, d.y || 0), 0)
    const maxTimestamp = data.find((d) => d.y === maxY)?.x
    const max = new TimeSeriesDataPoint({ x: maxTimestamp, y: maxY })

    const minY = data.reduce((acc, d) => Math.min(acc, d.y || 0), 0)
    const minTimestamp = data.find((d) => d.y === minY)?.x
    const min = new TimeSeriesDataPoint({ x: minTimestamp, y: minY })
    // return s
    return new TimeSeries({
      ...s,
      data,
      meanY,
      maxY,
      minY,
      min,
      max,
      resource: s.resource,
    })
  })

  return percentageSeries
}

export function timeSeriesTotalByTimeStamp(
  ts: Map<number, number>,
  dp: PlainMessage<TimeSeriesDataPoint>
): Map<number, number> {
  const value = ts.get(dp.x) ?? 0
  ts.set(dp.x, value + (dp.y ?? 0))
  return ts
}
