<template>
  <div>
    <!-- Charts -->
    <capacity-charts-fixed
      :interval
      :max-end-time
      :data-source
      :interval-broadcaster
      hide-summary-box
      class="pb-12"
      @new-interval="handleNewInterval"
    />

    <!-- Data Table -->
    <capacity-data-table
      :is-loading
      :group
      :is-operating-envelope-enabled
      :is-communication-status-enabled
      :dataTable
      @new-options="handleNewOptions"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, shallowReactive } from 'vue'
import { DateTime, Interval } from 'luxon'
import { OPTION_ITEMS_PER_PAGE } from '@/constants/table'
import type { TimeSeries } from '@/services/charts.service'
import { IntervalBroadcaster } from '@/utils/time/IntervalBroadcaster'
import { TimeSeriesDataSource } from '@/model/charts'
import { PADDING } from '@/model/control'
import type { CommunicationStatusMap } from '@/model/control/communicationStatus'
import {
  newGroupCapacityDataSource,
  type CustomOptions,
  type CapacityDataTable as DataTable,
} from '@/model/control/capacity'
import {
  createGroupCapacityDataTable,
  getDevicesPerformancePowerData,
} from '@/model/control/group/GroupCapacityDataTable'
import { isOEEnabled } from '@/model/control/operatingEnvelope'
import CapacityChartsFixed from '@/components/control/CapacityChartsFixed.vue'
import CapacityDataTable from '@/components/control/CapacityDataTable.vue'
import type { Device, Group } from 'rfs/control/proto/model_pb'
import type { Resource } from 'rfs/pb/resource_pb'

export default defineComponent({
  name: 'GroupPerformance',
  props: {
    group: {
      type: Object as PropType<Group>,
      required: true,
    },
    devices: {
      type: Array as PropType<Device[]>,
      required: true,
    },
    constitutingResources: {
      type: Array as PropType<Resource[]>,
      required: false,
      default: () => [],
    },
    communicationStatusMap: {
      type: Map as PropType<CommunicationStatusMap>,
      required: false,
    },
    /**
     * Instructs component to refresh chart data, signaled by parent component.
     */
    intervalBroadcaster: {
      type: Object as PropType<undefined | IntervalBroadcaster>,
      required: false,
    },
  },
  components: { CapacityChartsFixed, CapacityDataTable },
  data() {
    const now = this.$observationTime()
    const interval = Interval.fromDateTimes(
      now.minus({ hours: 24 }),
      now.plus(PADDING)
    )
    return shallowReactive({
      interval,
      maxEndTime: interval.end,
      // NOTE(rafael): The group object may change because the parent component
      // reloads every X seconds, but the group may still be the same. So
      // keep the datasource here and not as a computed property.
      dataSource: TimeSeriesDataSource.emptyDataSource(),
      isLoading: false,
      tableSeries: [] as TimeSeries[],
    })
  },
  watch: {
    group: {
      immediate: true,
      handler: function (newValue: Group, oldValue?: Group): void {
        // NOTE(rafael): only update the data source when the group really
        // changes.
        if (newValue.id === oldValue?.id) return

        this.fetchData()

        this.dataSource = newGroupCapacityDataSource(this.$services, this.group)
      },
    },
  },
  computed: {
    isOperatingEnvelopeEnabled(): boolean {
      return isOEEnabled(this.$rittaConfig) && this.group.supportsOes
    },
    isCommunicationStatusEnabled(): boolean {
      return !!this.communicationStatusMap
    },
    dataTable(): DataTable {
      return createGroupCapacityDataTable(
        this.group,
        this.devices,
        this.constitutingResources,
        this.tableSeries,
        this.communicationStatusMap
      )
    },
  },
  methods: {
    handleNewInterval(newInterval: Interval): void {
      this.interval = newInterval
    },
    getElementsInCurrentPage<T>(
      elements: T[],
      page: number,
      itemsPerPage: number
    ): T[] {
      return elements.slice((page - 1) * itemsPerPage, page * itemsPerPage)
    },
    async getTableSeries(page: number, itemsPerPage: number): Promise<void> {
      // TODO(Isaac) Uncomment when there is commanded power
      // for groups. Currently, groups bigtable fetches timeout.
      const lastMonthInterval = Interval.fromDateTimes(
        DateTime.local().startOf('month').minus({ months: 1 }),
        DateTime.local().startOf('month').minus({ months: 1 }).endOf('month')
      )
      try {
        this.isLoading = true
        this.tableSeries = await getDevicesPerformancePowerData(
          this.$services,
          this.getElementsInCurrentPage(this.devices, page, itemsPerPage),
          this.getElementsInCurrentPage(
            this.constitutingResources,
            page,
            itemsPerPage
          ),
          lastMonthInterval
        )
      } catch (error) {
        console.error('GroupPerformance.getTableSeries: %o', error)
      } finally {
        this.isLoading = false
      }
    },
    handleNewOptions({ page, itemsPerPage }: CustomOptions): void {
      this.getTableSeries(page, itemsPerPage)
    },
    async fetchData(): Promise<void> {
      // Reset state.
      this.tableSeries = []

      // Fetch data to hydrate the data table. No need to hold the rendering
      // of the data table now. Fetch data for the first page.
      this.getTableSeries(1, OPTION_ITEMS_PER_PAGE[0])
    },
  },
})
</script>
