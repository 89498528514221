import {
  Customer,
  FeatureFlags,
  Map,
  RittaConfig as RittaConfigBase,
} from 'rfs/frontend/proto/config_pb'
export { MapLayerGroup, MonitorConfig } from 'rfs/frontend/proto/config_pb'

export enum TabId {
  MONITOR = 'Monitor',
  ANALYZE = 'Analyze',
  CONTROL = 'Control',
  CONNECT = 'Connect',
}

export enum MapLayerId {
  ALERT = 'alert',
  BREAKER = 'breaker',
  CAPACITOR = 'capacitor',
  DISTRIBUTED_BATTERY = 'distributedBattery',
  DISTRIBUTED_SOLAR = 'distributedSolar',
  DISTRIBUTION = 'distribution',
  EV_CHARGER = 'evCharger',
  FLEET = 'fleet',
  FUSE = 'fuse',
  HYDRO = 'hydro',
  METER_ENDPOINT = 'meterEndpoint',
  METHANE_CAPTURE = 'methaneCapture',
  RECLOSER = 'recloser',
  REGULATOR = 'regulator',
  SECTIONALIZER = 'sectionalizer',
  SENSOR_ELECTRIC = 'sensorElectric',
  SUBSTATION = 'substation',
  SWITCH = 'switch',
  TRANSFORMER = 'transformer',
  TRANSMISSION = 'transmission',
  UTILITY_SOLAR = 'utilitySolar',
  V2G_BUS = 'v2gBus',
  VOLTAGE_MAPPING = 'voltageMapping',
  WIND = 'wind',
  UTILITY_BATTERY = 'utilityBattery',
}

export enum MapLayerGroupId {
  FLEET = 'GROUP_FLEET',
  GRID = 'GROUP_GRID',
}

export type Dictionary = { [key: string]: string }

/**
 * Override the proto-generated declaration so that we can mark some properties non-optional.
 * This provides a better developer experience since the runtime will make sure the JSON config
 * always has values for these objects.
 */
export class RittaConfig extends RittaConfigBase {
  declare customer: Customer
  declare map: Map
  declare featureFlags: FeatureFlags

  /**
   * Customize `fromJson` to reject unknown config values in non-production builds.
   * That will help catch typos and accidental changes more quickly.
   */
  static fromJson(json: any): RittaConfig {
    const readOpts = { ignoreUnknownFields: import.meta.env.PROD }

    return RittaConfigBase.fromJson(json, readOpts) as RittaConfig
  }
}
