<template>
  <abstract-chart
    :chart-def="chartDef"
    :chart-data="data"
    :chart-options="options"
    :custom-download-file-name="getFilename"
    :custom-download-csv="createCsvRows"
    :is-loading="isLoading"
  >
    <!-- Custom slots -->
    <template v-for="key of slotNames" v-slot:[`${key}`]>
      <slot :name="key"></slot>
    </template>
  </abstract-chart>
</template>

<script lang="ts">
import type {
  BarControllerDatasetOptions,
  Chart,
  ChartData,
  ChartDataset,
  ChartOptions,
  LinearScaleOptions,
  LineOptions,
  Point,
  Scale,
  TooltipItem,
} from 'chart.js'
import { debounce, merge } from 'lodash-es'
import { DateTime, Interval } from 'luxon'
import { defineComponent, PropType, useSlots } from 'vue'
import {
  CursorType,
  Direction,
  ZOOM_DEBOUNCE,
} from '@/constants/infiniteScrollChart'
import { ChartDefinition, ChartFormatters, ChartType } from '@/types/charts'
import {
  MIN_X_DATE,
  additionalYAxisOptions,
  getScrollDirection,
  isPointInRect,
  yAxisTitle,
  getSeriesColor,
  standardYAxisTicks,
  getSeriesFillColor,
  getSeriesFillOption,
  getSeriesBackgroundColor,
  isBarChart,
} from '@/utils/charts'
import {
  XAXIS_GRID,
  Y2_AXIS,
  timeScaleTickFormat,
  timeScaleTickFont,
  edgePointsConfig,
} from '@/utils/chartjs'
import { colorWithOpacity } from '@/utils/colors'
import {
  type CsvColumn,
  type CsvRow,
  createCsvRowsFromTimeSeriesData,
} from '@/utils/csv'
import { toExcelTimeString, utcOffsetString } from '@/utils/time'
import { chartFormattersByChartType } from '@/model/charts/formatters'
import { NamedTimeSeries } from '@/model/charts/TimeSeriesDataSource'
import AbstractChart from '@/components/common/AbstractChart.vue'
import {
  createEveryOtherDayAnnotation,
  createTimeMarkerAnnotations,
  createHighlightRangeAnnotations,
} from '@/utils/chartjs/annotations'

// The chartjs-zoom-plugin does not provide types for callback arguments
type ZoomStartEvent = { event: Event; chart: Chart; point: Point }

/**
 * Represents a mapping from series names to their corresponding Y-axis
 * formatters in ChartJs. Each property key is a series name that
 * aligns with the 'label' in ChartJs configurations.
 */
type SeriesFormatters = { [seriesName: string]: ChartFormatters['yaxis'] }

/** Dashed lines are 2px wide and dashes are 4px long with 4px spacing. */
const DASHED_LINE_OPTS: Partial<LineOptions> = {
  borderDash: [4, 4],
  borderWidth: 2,
  backgroundColor: 'transparent',
  fill: false,
}

const THIN_DASHED_LINE_OPTS: Partial<LineOptions> = {
  ...DASHED_LINE_OPTS,
  borderDash: [1, 1],
}

/** Make the bars darker and fill more of the space. */
const BAR_CHART_OPTS: Partial<BarControllerDatasetOptions> = {
  borderSkipped: true,
  barPercentage: 0.95,
  categoryPercentage: 1.0,
}

/**
 * Determines if Y-axis offset should be added to the chart.
 * 1) For charts with 0-100% range to prevent edge clipping.
 * 2) Ensures "now" annotation label doesn't overlap with dataset.
 */
function shouldAddYAxisOffset(chartDef: ChartDefinition): boolean {
  return (
    chartDef.type === ChartType.EnergyLinePercentage ||
    chartDef.type === ChartType.Clouds ||
    Boolean(chartDef.annotations?.timeMarkers)
  )
}

function hasAnyHideFromTooltip(series: Readonly<NamedTimeSeries[]>): boolean {
  return series.some((s) => !!s.config.hideFromTooltip)
}

function hasAtLeastOneStackGroup(series: Readonly<NamedTimeSeries[]>): boolean {
  return series.some((s) => !!s.config.stackGroup)
}

export default defineComponent({
  name: 'TimeSeriesChart',
  components: { AbstractChart },
  props: {
    interval: {
      type: Object as PropType<Interval>,
      required: true,
    },
    chartDef: {
      type: Object as PropType<ChartDefinition>,
      required: true,
    },
    chartSeries: {
      type: Array as PropType<Readonly<NamedTimeSeries[]>>,
      required: true,
    },
    chartOptions: {
      type: Array as PropType<ChartOptions[]>,
      required: true,
    },
    chartFormatters: {
      type: Object as PropType<ChartFormatters>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableScroll: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxEndTime: {
      type: Object as PropType<DateTime>,
      required: false,
    },
    now: {
      type: Object as PropType<DateTime>,
      required: false,
    },
  },
  setup() {
    return { slotNames: Object.keys(useSlots()) }
  },
  computed: {
    computedMaxEndTime(): DateTime {
      return this.maxEndTime ?? this.$observationTime()
    },
    data(): ChartData {
      const { additionalYAxis, seriesColors } = this.chartDef
      return {
        datasets: this.chartSeries.map(({ config, data, metric }, index) => {
          const seriesColor = getSeriesColor(config, seriesColors, index)

          const baseOptions = {
            label: config.seriesName,
            data: data as Point[], // because `y` is optional on TimeSeries_DataPoint
            normalized: true,
            parsing: false as ChartDataset['parsing'],
            backgroundColor: getSeriesFillColor(this.chartDef, seriesColor),
            borderColor: seriesColor,
            borderWidth: config.seriesLineWidth ?? 1,
            hoverBackgroundColor: colorWithOpacity(seriesColor, 0.3),
            yAxisID: additionalYAxis?.metric === metric ? Y2_AXIS : 'y',
            ...(config.seriesLine === 'dashed' && DASHED_LINE_OPTS),
            ...(config.seriesLine === 'thin-dashed' && THIN_DASHED_LINE_OPTS),
            ...(config.seriesFill && getSeriesFillOption(config)),
          }

          const lineOptions: ChartDataset<'line'> = {
            ...baseOptions,
            type: 'line',
            stepped: config.steppedLine,
            ...getSeriesBackgroundColor(config, seriesColor),
            ...(config.seriesLineEdgePoints && edgePointsConfig()),
            ...(config.isGhostSeries && {
              backgroundColor: 'transparent',
              showLine: false,
              pointRadius: 0,
              pointHoverRadius: 0,
            }),
            stack: config.stackGroup,
          }

          const barOptions: ChartDataset<'bar'> = {
            ...baseOptions,
            ...BAR_CHART_OPTS,
            type: 'bar',
            data: baseOptions.data as unknown as ChartDataset<'bar'>['data'],
            ...(config.minBarLength && { minBarLength: config.minBarLength }),
          }

          const scatterOptions: ChartDataset<'scatter'> = {
            ...baseOptions,
            type: 'scatter',
            pointRadius: 6,
            pointHoverRadius: 6,
          }

          return isBarChart(this.chartDef)
            ? barOptions
            : config.seriesType === 'scatter'
              ? scatterOptions
              : lineOptions
        }),
      }
    },
    options(): ChartOptions {
      let options: ChartOptions = {
        layout: {
          padding: {
            // space for the legends.
            bottom: 12,
          },
        },
        elements: {
          line: { fill: this.chartDef.isAreaChart ?? false },
          point: { radius: 0 }, // default to disabled in all datasets
        },
        plugins: {
          legend: {
            display: this.chartSeries.length > 1,
            position: 'bottom',
          },
          tooltip: {
            enabled: true,
            filter: hasAnyHideFromTooltip(this.chartSeries)
              ? (tooltipItem) => {
                  const series = this.chartSeries.find(
                    (s) => s.config.seriesName === tooltipItem.dataset.label
                  )
                  return !series?.config.hideFromTooltip
                }
              : undefined,
            callbacks: { label: this.formatTooltip },
            position: 'fixedPosition',
          },
          zoom: {
            limits: {
              x: { min: MIN_X_DATE, max: this.computedMaxEndTime.toMillis() },
            },
            pan: {
              enabled: true,
              mode: 'x',
              modifierKey: undefined,
              onPanStart: this.onPanStart,
              onPanComplete: this.onPanComplete,
            },
            zoom: {
              mode: 'x',
              wheel: { enabled: true, speed: 0.05, modifierKey: 'ctrl' },
              onZoomStart: this.onZoomStart,
              onZoomComplete: this.onZoomComplete,
            },
          },
        },
        scales: {
          x: {
            stacked: !!this.chartDef.isStackedBar,
            type: 'time',
            grid: XAXIS_GRID,
            min: this.interval.start.toMillis(),
            max: this.interval.end.toMillis(),
            ticks: {
              callback: (v) => timeScaleTickFormat(v as number),
              font: timeScaleTickFont,
            },
          },
          y: {
            ...(shouldAddYAxisOffset(this.chartDef) && { offset: true }),
            max: this.chartDef.yAxis?.max,
            min: this.chartDef.yAxis?.min,
            suggestedMax: this.chartDef.yAxis?.suggested?.max,
            suggestedMin: this.chartDef.yAxis?.suggested?.min,
            stacked: hasAtLeastOneStackGroup(this.chartSeries)
              ? 'single'
              : this.chartDef.isStackedBar,
            ticks: standardYAxisTicks(this.chartFormatters),
            title: {
              display: true,
              text:
                this.chartDef.yAxis?.title ?? yAxisTitle(this.chartDef.type),
            },
            afterBuildTicks: this.onAfterBuildTicks,
          },
        },
      }

      if (this.disableScroll) {
        // The zoom plugin implements all the date scrolling
        delete options.plugins?.zoom
      }

      if (this.chartDef.annotations?.everyOtherDay) {
        options = merge(options, createEveryOtherDayAnnotation(this.interval))
      }

      if (this.chartDef.additionalYAxis) {
        const scales = {
          [Y2_AXIS]: additionalYAxisOptions(this.chartDef.additionalYAxis),
        }
        const plugins: ChartOptions['plugins'] = {
          legend: {
            // show the legend but block the user from deactivating the series
            // because clicking on a series to hide it messes up the Y-Axes.
            onClick: () => {
              // Do nothing
            },
          },
        }
        options = merge(options, { plugins, scales })
      }

      if (this.chartDef.annotations?.timeMarkers) {
        const { timeMarkers } = this.chartDef.annotations
        const now = this.now ?? this.$observationTime()
        options = merge(options, createTimeMarkerAnnotations(timeMarkers, now))
      }

      if (this.chartDef.annotations?.highlightRanges) {
        options = merge(
          options,
          createHighlightRangeAnnotations(
            this.chartDef.annotations.highlightRanges
          )
        )
      }

      if (this.chartSeries.some((series) => series.config.isGhostSeries)) {
        const plugins: ChartOptions['plugins'] = {
          legend: {
            // Remove the "ghost" time series before checking the number of time series.
            display:
              this.chartSeries.filter((series) => !series.config.isGhostSeries)
                .length > 1,
            labels: {
              // Remove the legend for the "ghost" time series.
              filter: (item, _data) => {
                const seriesConfig = this.chartSeries.find(
                  (series) => series.config.seriesName === item.text
                )
                return !seriesConfig?.config.isGhostSeries
              },
            },
          },
        }
        options = merge(options, { plugins })
      }

      // Using merge() so that nested objects are merged
      return merge(options, ...this.chartOptions)
    },
    /**
     * Custom Y Axis formatters.
     */
    seriesFormatters(): SeriesFormatters {
      return this.chartSeries.reduce<{
        [seriesName: string]: ChartFormatters['yaxis']
      }>((acc, series) => {
        if (series.config.formatter) {
          acc[series.config.seriesName] = series.config.formatter
        }
        return acc
      }, {})
    },
  },
  created() {
    // The chartjs zoom plugin invokes the callback after every wheel movement,
    // but we want to wait until the user has finished scrolling before we tell the data source.
    this.onZoomComplete = debounce(this.onZoomComplete, ZOOM_DEBOUNCE)
  },
  methods: {
    /** * Returns a file name for the download features. */
    getFilename(): string {
      const formattedTitle =
        this.chartDef.fileName || this.chartDef.title || this.chartDef.id

      const wordsInFileName = [formattedTitle]

      if (this.data.datasets.length) {
        // use the first timestamp in the dataset with an underscore
        // in the filename. so `ProductionAndConsumption.csv` becomes
        // `ProductionAndConsumption_20231230.csv`
        const data = this.data.datasets[0].data[0] as Point
        const firstTimestamp = data.x
        const formattedFirstTimestamp =
          DateTime.fromMillis(firstTimestamp).toFormat('yyyyMMdd')
        wordsInFileName.push(formattedFirstTimestamp)
      }
      return wordsInFileName.join(' ')
    },
    createCsvRows(visibleData: ChartData): CsvRow[] {
      return createCsvRowsFromTimeSeriesData({
        timestampHeader: `timestamp ${utcOffsetString()}`,
        timestampFormatter: toExcelTimeString,
        columns: visibleData.datasets.map(
          (ds): CsvColumn<Point> => ({
            datapoints: ds.data as Point[],
            columnName: ds.label ?? '',
            getDpTimestamp: (dp) => dp.x,
            getDpValue: (dp) => dp.y,
          })
        ),
      })
    },
    formatTooltip(item: TooltipItem<'line'>): string {
      const { additionalYAxis } = this.chartDef
      let formatter =
        this.seriesFormatters[item.dataset.label ?? ''] ??
        this.chartFormatters.yaxis
      if (item.dataset.yAxisID === Y2_AXIS && additionalYAxis != null) {
        formatter = chartFormattersByChartType[additionalYAxis.type].yaxis
      }
      return `${item.dataset.label}: ${formatter(item.parsed.y)}`
    },
    onAfterBuildTicks(yAxis: Scale) {
      if (this.chartDef.additionalYAxis) {
        // Keep the number of ticks in sync between the two y-axes
        const y2Axis = yAxis.chart.scales[Y2_AXIS] as Scale<LinearScaleOptions>
        y2Axis.options.ticks.count = yAxis.ticks.length
      }
    },
    onPanStart({ chart, point }: { chart: Chart; point: Point }) {
      this.setCursor(chart, CursorType.DRAG)
      // Ignore events starting in title, legend, etc.
      return isPointInRect(point.x, point.y, chart.chartArea)
    },
    onPanComplete({ chart }: { chart: Chart }) {
      console.debug('[TSChart] onPanComplete', this.chartDef.id)
      // Called when mouse up after drag; update the visible interval
      this.fireIntervalEvent(chart)
      this.setCursor(chart, CursorType.DEFAULT)
    },
    onZoomStart({ event, chart, point }: ZoomStartEvent) {
      this.setCursor(chart, getScrollDirection(event))
      // Ignore scroll events that only scroll horizontally
      if (event instanceof WheelEvent && event.deltaY === 0) {
        return false
      }
      // Ignore events starting in title, legend, etc.
      return isPointInRect(point.x, point.y, chart.chartArea)
    },
    onZoomComplete({ chart }: { chart: Chart }) {
      console.debug('[TSChart] onZoomComplete', this.chartDef.id)
      // Called when the last scroll event is received (via debounce)
      // Because of the debounce, the call might happen after the chart has been destroyed.
      if (chart.canvas == null) return

      this.fireIntervalEvent(chart)
      this.setCursor(chart, CursorType.DEFAULT)
    },
    fireIntervalEvent(chart: Chart) {
      const { min, max } = chart.scales['x']
      const xAxisInterval = Interval.fromDateTimes(new Date(min), new Date(max))

      this.$emit('interval', xAxisInterval)
    },
    setCursor(chart: Chart, cursorOrDirection: CursorType | Direction) {
      switch (cursorOrDirection) {
        case CursorType.DRAG:
          chart.canvas.style.cursor = 'grabbing'
          break
        case Direction.UP:
        case CursorType.ZOOM_IN:
          chart.canvas.style.cursor = 'zoom-in'
          break
        case Direction.DOWN:
        case CursorType.ZOOM_OUT:
          chart.canvas.style.cursor = 'zoom-out'
          break
        case Direction.NONE:
          // Trackpad scrolling sometimes causes 'None' to be returned - ignore it
          break
        default:
          chart.canvas.style.cursor = 'auto'
          break
      }
    },
  },
})
</script>
