import { shallowReactive } from 'vue'
import { MapLayerGroup, MapLayerGroupId, MapLayerId } from '@/config/types'
import type { FocusLocations, MapLayerFeature } from '@/model/map'
import { defineImmutableStore } from './defineStore'
import { useInternalStore } from './internal'
import { getAvailableResourceTypes } from '@/model/grid/availableResources'
import { MapLayer } from 'rfs/frontend/proto/config_pb'
import {
  mapLayerCatalog,
  resourceTypeToMapLayerId,
  type ApplicationMapLayer,
} from '@/components/maps/layers/catalog'
import { isMapLayerEnabled } from '@/utils/map'
import { mapLayerMenuGroups } from '@/components/maps/layerGroups/catalog'

export interface GridMapState {
  /**
   * IMPORTANT: This is not kept in-sync with Google Maps state. Instead it
   * acts as a means to implement the action to center the map.
   */
  focus: null | FocusLocations
  /** Objects to highlight in a layer separate from their main layer */
  highlighted: null | {
    /** The map layer which knows how to render the features */
    layerId: MapLayerId
    features: MapLayerFeature[]
  }
  switches: Switches
}

export type Switches = Set<string>

export type UpdateSwitches = Array<{
  id: string
  newValue: true | false | 'toggle'
}>

// In unit tests, Pinia does not have the config plugin unless the test installs it.
const TEST_LAYERS = [] as MapLayerGroup[]

/**
 * The Grid Map Store contains application-level state and actions for components to interact
 * with the primary map. Google Maps and Deck.gl maintain their own internal state, and this
 * store does not attempt to mirror that. However, it will provide state that will be useful
 * for components to see the state of the map from the user's perspective.
 */
export const useGridMapStore = defineImmutableStore('gridMap', {
  persist: { paths: ['switches'] },
  state(): GridMapState {
    const store = useInternalStore()

    return shallowReactive<GridMapState>({
      focus: null,
      highlighted: null,
      switches: createSwitches(store.config?.map?.layerGroups ?? TEST_LAYERS),
    })
  },
  getters: {
    applicationMapLayers(): ApplicationMapLayer[] {
      return Array.from(mapLayerCatalog.values()).filter((l) =>
        isMapLayerEnabled(this.mapLayersGroups, l.id)
      )
    },
    mapLayersGroups(): MapLayerGroup[] {
      const configMapLayersGroups = this.config?.map.layerGroups ?? []
      const layers = Array.from(getAvailableResourceTypes(this.config)).reduce(
        (acc, rt) => {
          const id = resourceTypeToMapLayerId[rt]
          if (id) {
            acc.push(new MapLayer({ id }))
          }
          return acc
        },
        [] as MapLayer[]
      )

      // All resource types are added to the GRID group
      const mapLayerGroups = [
        new MapLayerGroup({
          id: MapLayerGroupId.GRID,
          activeOnStart: true,
          layers,
        }),
      ]

      // merge the configMapLayersGroups layers with the mapLayerGroups layers
      // use the configMapLayersGroups to override the mapLayerGroups
      for (const configGroup of configMapLayersGroups) {
        const mapLayerGroup = mapLayerGroups.find(
          (g) => g.id === configGroup.id
        )
        if (mapLayerGroup) {
          mapLayerGroup.activeOnStart = configGroup.activeOnStart
          for (const configLayer of configGroup.layers) {
            const mapLayer = mapLayerGroup.layers.find(
              (l) => l.id === configLayer.id
            )
            if (mapLayer) {
              mapLayer.activeOnStart = configLayer.activeOnStart
              mapLayer.disabled = configLayer.disabled
              mapLayer.hideSwitch = configLayer.hideSwitch
            } else {
              mapLayerGroup.layers.push(configLayer)
            }
          }
        } else {
          mapLayerGroups.push(configGroup)
        }
      }

      // filter out MapLayerGroups that have no layers
      return mapLayerGroups.filter((g) => g.layers.length > 0)
    },
  },
  actions: {
    /** * Moves the focus of the map. */
    focusLocations(focus: FocusLocations) {
      this.focus = focus
    },
    /** * Clears the focus */
    clearFocus() {
      this.focus = null
    },
    /** * Sets a list of features to be highlighted on the map. */
    highlight(layerId: MapLayerId, features: MapLayerFeature[]) {
      this.highlighted = Object.freeze({ layerId, features })
    },
    /** * Clear the highlight */
    clearHighlight() {
      this.highlighted = null
    },
    updateSwitches(updateSwitches: UpdateSwitches = []) {
      this.switches = updateSwitches.reduce((acc, p) => {
        if (
          p.newValue === false ||
          (p.newValue === 'toggle' && this.switches?.has(p.id))
        ) {
          acc.delete(p.id)
        } else {
          acc.add(p.id)
        }

        return acc
      }, new Set(this.switches))
    },
  },
  upgradeState(currentState, _initialState) {
    // Voltage Mapping is not a switchable layer, so remove it from the switches
    // TODO(andrewg): come up with a new way for a component to show a layer
    currentState.switches.delete('voltageMapping')
  },
})

function createSwitches(
  mapLayerGroups: MapLayerGroup[] | undefined
): GridMapState['switches'] {
  const set: GridMapState['switches'] = new Set()

  for (const group of mapLayerGroups ?? []) {
    // Add the group id to the switches too. The user should be able to turn
    // on/off the entire group of layers at the same time.
    if (group.activeOnStart) set.add(group.id)

    for (const l of group.layers) {
      if (!l.disabled && l.activeOnStart) set.add(l.id)
    }
  }

  return set
}
