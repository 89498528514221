import type { RittaConfig } from '@/config'
import type { ResourceType } from '@/constants/resourceType'

export function getAvailableResourceTypes(config: Readonly<RittaConfig>) {
  return Object.entries(config.typeStats).reduce(
    (acc, [resourceType, groupStats]) => {
      if (groupStats.resources > 0) {
        acc.add(resourceType as ResourceType)
      }
      return acc
    },
    new Set<ResourceType>()
  )
}
