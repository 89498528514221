import type { DateTime, Interval } from 'luxon'
import {
  intervalLast7Days,
  intervalLast30Days,
  intervalLast90Days,
  intervalLast12Months,
  intervalMonthToDate,
  intervalYearToDate,
  intervalLast24Hours,
} from '@/utils/time'

export enum IntervalOption {
  LAST_24_HOURS = 'LAST_24_HOURS',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_90_DAYS = 'LAST_90_DAYS',
  LAST_12_MONTHS = 'LAST_12_MONTHS',
  MONTH_TO_DATE = 'MONTH_TO_DATE',
  YEAR_TO_DATE = 'YEAR_TO_DATE',
  CUSTOM = 'CUSTOM',
}

export const OPTION_LABEL: Record<IntervalOption, string> = {
  [IntervalOption.LAST_24_HOURS]: 'Last 24 hours',
  [IntervalOption.LAST_7_DAYS]: 'Last 7 days',
  [IntervalOption.LAST_30_DAYS]: 'Last 30 days',
  [IntervalOption.LAST_90_DAYS]: 'Last 90 days',
  [IntervalOption.LAST_12_MONTHS]: 'Last 12 months',
  [IntervalOption.MONTH_TO_DATE]: 'Month to date',
  [IntervalOption.YEAR_TO_DATE]: 'Year to date',
  [IntervalOption.CUSTOM]: 'Custom',
}

const intervalFactories: Record<
  IntervalOption,
  (now: DateTime) => undefined | Interval
> = {
  [IntervalOption.LAST_24_HOURS]: intervalLast24Hours,
  [IntervalOption.LAST_7_DAYS]: intervalLast7Days,
  [IntervalOption.LAST_30_DAYS]: intervalLast30Days,
  [IntervalOption.LAST_90_DAYS]: intervalLast90Days,
  [IntervalOption.LAST_12_MONTHS]: intervalLast12Months,
  [IntervalOption.MONTH_TO_DATE]: intervalMonthToDate,
  [IntervalOption.YEAR_TO_DATE]: intervalYearToDate,
  [IntervalOption.CUSTOM]: () => undefined,
}

export function createIntervalFromIntervalOption(
  now: DateTime,
  option: IntervalOption
): undefined | Interval {
  const factory =
    option !== IntervalOption.CUSTOM ? intervalFactories[option] : undefined
  return factory?.(now)
}

export function computeIntervalOptionFromInterval(
  now: DateTime,
  interval: Interval
): IntervalOption {
  // Last 24 hours.
  if (
    interval.toDuration(['hours']).toObject().hours === 24 &&
    interval.end.hasSame(now, 'day')
  ) {
    return IntervalOption.LAST_24_HOURS
  }

  // Other options.
  for (const [option, factory] of Object.entries(intervalFactories)) {
    if (interval.toISO() === factory?.(now)?.toISO()) {
      return option as IntervalOption
    }
  }

  return IntervalOption.CUSTOM
}

export function isIntervalOption(value: string): value is IntervalOption {
  return Object.values(IntervalOption).includes(value as IntervalOption)
}
