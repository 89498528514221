<template>
  <div aria-label="Interval Picker">
    <v-select
      :model-value="selectedOption"
      :items="options"
      :menu-props="{ maxHeight: 'none' }"
      label="Interval"
      max-width="180"
      @update:model-value="emitNewOption"
    >
      <template v-slot:item="all">
        <template v-if="shouldShowItem(all.item)">
          <!-- Divider -->
          <v-divider
            v-if="shouldShowDivider(all.item)"
            class="my-4"
            :color="NEUTRAL_100.hex"
            style="opacity: 1"
          />

          <v-list-item v-bind:="all.props" />
        </template>
      </template>
    </v-select>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import type { Interval } from 'luxon'
import { NEUTRAL_100 } from '@/constants/colors'
import {
  OPTION_LABEL,
  createIntervalFromIntervalOption,
  computeIntervalOptionFromInterval,
  IntervalOption,
} from '@/model/charts/intervalOption'

type SelectItem = { title: string; value: IntervalOption }

export default defineComponent({
  name: 'IntervalPicker',
  props: {
    interval: {
      type: Object as PropType<Interval>,
      required: true,
    },
  },
  emits: ['interval'],
  setup() {
    return {
      NEUTRAL_100,
      options: Object.values(IntervalOption).map((option): SelectItem => {
        return { title: OPTION_LABEL[option], value: option }
      }),
    }
  },
  data() {
    return {
      selectedOption: computeIntervalOptionFromInterval(
        this.$observationTime(),
        this.interval
      ),
    }
  },
  watch: {
    interval(newValue: Interval) {
      const newOption = computeIntervalOptionFromInterval(
        this.$observationTime(),
        newValue
      )

      if (newOption !== this.selectedOption) {
        this.selectedOption = newOption
      }
    },
  },
  methods: {
    shouldShowItem(item: SelectItem): boolean {
      return item.value !== IntervalOption.CUSTOM
    },
    shouldShowDivider(item: SelectItem): boolean {
      return item.value === IntervalOption.MONTH_TO_DATE
    },
    emitNewOption(newOption: IntervalOption) {
      const newInterval = createIntervalFromIntervalOption(
        this.$observationTime(),
        newOption
      )

      if (newInterval) {
        this.$emit('interval', newInterval)
      }
    },
  },
})
</script>
