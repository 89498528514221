// See //frontend/tile/tile.go for more details about this RPC

// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file frontend/proto/tile.proto (package ritta.frontend.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Resource } from "../../pb/resource_pb.js";

/**
 * BBox defines a geodesic bounding box of WGS84 coordinates.
 *
 * @generated from message ritta.frontend.proto.BBox
 */
export const BBox = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.BBox",
  () => [
    { no: 1, name: "south", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "west", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "north", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "east", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * A request includes a bounding box and the resource type.
 * The resource type can be specific (e.g. "meter/electrical") or generic (e.g. "meter")
 *
 * @generated from message ritta.frontend.proto.TileRequest
 */
export const TileRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.TileRequest",
  () => [
    { no: 1, name: "bbox", kind: "message", T: BBox },
    { no: 2, name: "resource_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "detail_level", kind: "enum", T: proto3.getEnumType(TileRequest_DetailLevel) },
  ],
);

/**
 * Some resource types have filters for different levels of detail. If a level is not set,
 * all resources in the tile are returned.
 * For "conductor" resources:
 * - LOW = PRIMARY, 3-PHASE
 * - MEDIUM = PRIMARY, 1 & 2-PHASE
 * - HIGH = SECONDARY (ALL)
 *
 * @generated from enum ritta.frontend.proto.TileRequest.DetailLevel
 */
export const TileRequest_DetailLevel = /*@__PURE__*/ proto3.makeEnum(
  "ritta.frontend.proto.TileRequest.DetailLevel",
  [
    {no: 0, name: "DEFAULT"},
    {no: 1, name: "LOW"},
    {no: 2, name: "MEDIUM"},
    {no: 4, name: "HIGH"},
  ],
);

/**
 * @generated from message ritta.frontend.proto.TileAtTimeRequest
 */
export const TileAtTimeRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.TileAtTimeRequest",
  () => [
    { no: 1, name: "bbox", kind: "message", T: BBox },
    { no: 2, name: "resource_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "start", kind: "message", T: Timestamp },
    { no: 4, name: "end", kind: "message", T: Timestamp },
    { no: 5, name: "metrics", kind: "message", T: TileAtTimeRequest_MetricFrame, repeated: true },
    { no: 6, name: "aggregation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Not all metrics are supported.
 * - aggregation.count.violating.voltage.[min,max]
 *
 * @generated from message ritta.frontend.proto.TileAtTimeRequest.MetricFrame
 */
export const TileAtTimeRequest_MetricFrame = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.TileAtTimeRequest.MetricFrame",
  () => [
    { no: 1, name: "metric", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "min", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
  ],
  {localName: "TileAtTimeRequest_MetricFrame"},
);

/**
 * @generated from message ritta.frontend.proto.TileResponse
 */
export const TileResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.TileResponse",
  () => [
    { no: 1, name: "layers", kind: "message", T: TileResponse_Layer, repeated: true },
  ],
);

/**
 * These geometries are reflected in the types of Location data stored in each Resource.
 *
 * @generated from enum ritta.frontend.proto.TileResponse.GeometryType
 */
export const TileResponse_GeometryType = /*@__PURE__*/ proto3.makeEnum(
  "ritta.frontend.proto.TileResponse.GeometryType",
  [
    {no: 0, name: "POINT"},
    {no: 1, name: "LINE_STRING"},
    {no: 2, name: "POLYGON"},
  ],
);

/**
 * The Feature object includes the Resource and any custom properties for the map.
 * Custom properties are typically used for values computed based on external data
 * such as analytics and forecasting.
 *
 * @generated from message ritta.frontend.proto.TileResponse.Feature
 */
export const TileResponse_Feature = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.TileResponse.Feature",
  () => [
    { no: 1, name: "resource", kind: "message", T: Resource },
    { no: 2, name: "properties", kind: "message", T: TileResponse_Properties },
  ],
  {localName: "TileResponse_Feature"},
);

/**
 * @generated from message ritta.frontend.proto.TileResponse.Layer
 */
export const TileResponse_Layer = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.TileResponse.Layer",
  () => [
    { no: 1, name: "geometry_type", kind: "enum", T: proto3.getEnumType(TileResponse_GeometryType) },
    { no: 2, name: "features", kind: "message", T: TileResponse_Feature, repeated: true },
  ],
  {localName: "TileResponse_Layer"},
);

/**
 * Contains additional metadata for each resource
 *
 * @generated from message ritta.frontend.proto.TileResponse.Properties
 */
export const TileResponse_Properties = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.TileResponse.Properties",
  () => [
    { no: 1, name: "load_factor_percent", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 2, name: "max_load_percent", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 3, name: "downline_meters", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "solar_capacity", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 5, name: "values_at_time", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 1 /* ScalarType.DOUBLE */} },
  ],
  {localName: "TileResponse_Properties"},
);

