import { ResourceType } from '@/constants/resourceType'
import { Format } from '@/utils/format'
import { Config_Phasing as Phasing, type Resource } from 'rfs/pb/resource_pb'

export const SiteHelper = {
  /** Return the meter resource that is the net meter for the site. */
  findNetMeter(
    site: Resource,
    siteResources: Resource[]
  ): undefined | Resource {
    return siteResources.find(
      (r) =>
        r.type === ResourceType.METER_ELECTRICAL &&
        r.meter?.electrical === site.meter?.electrical
    )
  },

  formatNominalVoltage(meter: Resource | undefined): string {
    if (meter?.config == null) return ''

    if (meter.config.phasing < Phasing.AB) {
      // The " || null" is so we don't show 0 as "0.00 V"
      return Format.fmtVolts(meter.config.voltage || null)
    }

    // For mult-phase voltage, look for per-phase voltage.
    const results = []
    if (meter.config.a?.voltage) {
      results.push(Format.fmtCount(meter.config.a.voltage))
    }
    if (meter.config.b?.voltage) {
      results.push(Format.fmtCount(meter.config.b.voltage))
    }
    if (meter.config.c?.voltage) {
      results.push(Format.fmtCount(meter.config.c.voltage))
    }
    // If we have no per-phase voltage, use the single phase one.
    if (results.length === 0 && meter.config.voltage !== 0) {
      results.push(Format.fmtVolts(meter.config.voltage))
    }
    return results.join('/')
  },
}
