<template>
  <teleport to="body">
    <section
      :ref="REF_SIDE_SHEET"
      class="panel-transition"
      :class="{ 'elevation-12': modelValue }"
      :style="computedStyle"
      role="region"
      :aria-label="`${title} side panel`"
    >
      <!-- Header -->
      <div
        class="d-flex align-center px-6 justify-space-between"
        style="flex-shrink: 0"
        :style="{
          height: headerHeight,
          'background-color': headerBgColor,
        }"
      >
        <!-- Title -->
        <h6 class="text-h6 text-white">{{ title }}</h6>

        <!-- Close btn -->
        <v-btn
          aria-label="Close"
          data-test="close-btn"
          color="transparent"
          class="mr-n4"
          icon
          variant="flat"
          @click="emitClose"
        >
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </div>

      <!-- Body content: renders only when side panel opened -->
      <div v-if="modelValue" class="py-4" style="flex: 1; overflow-y: auto">
        <slot />
      </div>
    </section>
  </teleport>
</template>

<script lang="ts">
import { defineComponent, PropType, StyleValue } from 'vue'
import { Hex, GRAY_COOL_700, GREY6 } from '@/constants/colors'
import { TOP_BAR_HEIGHT } from '@/constants/topBar'
import { emitsBoolean } from '@/utils/emits'

const REF_SIDE_SHEET = 'REF_SIDE_SHEET'

export default defineComponent({
  name: 'SideSheet',
  props: {
    title: {
      type: String,
      required: true,
    },
    /** * v-model */
    modelValue: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * `left` or `right`.
     */
    side: {
      type: String,
      required: false,
      default: 'right',
      validator: (v: string) => v === 'right' || v === 'left',
    },
    /**
     * Accepts any valid CSS unit (e.g., 'px', '%', 'em').
     */
    width: {
      type: String,
      required: false,
      default: '21rem',
    },
    bgColor: {
      type: String as PropType<Hex>,
      required: false,
      default: GREY6.hex,
    },
  },
  emits: { 'update:model-value': emitsBoolean },
  setup() {
    return {
      headerHeight: TOP_BAR_HEIGHT,
      headerBgColor: GRAY_COOL_700.hex,
    }
  },
  data() {
    return { REF_SIDE_SHEET }
  },
  computed: {
    computedStyle(): StyleValue {
      return {
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        top: '0',
        bottom: '0',
        right: this.side === 'right' ? '0' : 'none',
        left: this.side === 'left' ? '0' : 'none',
        zIndex: '10',
        width: this.width,
        backgroundColor: this.bgColor,
        transitionProperty: 'transform, width',
        transform: (() => {
          if (this.modelValue) {
            return 'translateX(0%)'
          } else if (this.side === 'right') {
            return 'translateX(100%)'
          } else if (this.side === 'left') {
            return 'translateX(-100%)'
          } else {
            return 'none'
          }
        })(),
      }
    },
  },
  methods: {
    emitClose(): void {
      this.$emit('update:model-value', false)
    },
  },
})
</script>
