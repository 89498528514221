export type Bin = {
  range: [number, number]
  count: number
}

export function adjustToMultiple(binSize: number, range: number) {
  // Define dynamic multiples based on the dataset range.
  const multiples =
    range <= 100
      ? [2, 5]
      : range <= 1000
        ? [10, 20, 50]
        : [25, 50, 100, 200, 500]

  // Finds the smallest multiple that is greater than or equal to "binSize".
  const closest = multiples.map((m) => Math.ceil(binSize / m) * m)

  // Returns the smallest acceptable multiple.
  return Math.min(...closest)
}

export function calculateOptimalBinSize(values: number[]): number {
  const n = values.length

  if (n === 0) return 0

  const sortedValues = [...values].sort((a, b) => a - b)

  const min = sortedValues.at(0) || 0
  const max = sortedValues.at(-1) || 0

  if (min === max) return min

  // Freedman-Diaconis.
  const q1 = sortedValues[Math.floor(n * 0.25)] // First quartile.
  const q3 = sortedValues[Math.floor(n * 0.75)] // Third quartile.
  const iqr = q3 - q1 // Interquartile range.

  const rawBinSize = (2 * iqr) / Math.cbrt(n)

  const range = max - min
  const adjustedBinSize = adjustToMultiple(rawBinSize, range)

  return adjustedBinSize
}

// The bins are inclusive at the lower bound and exclusive at the upper bound.
export function generateBins(values: number[], binSize: number): Bin[] {
  let min = Infinity
  let max = -Infinity

  // Single loop to capture both.
  for (const value of values) {
    if (value < min) min = value
    if (value > max) max = value
  }

  const bins = []

  // Create bins
  for (
    let start = Math.floor(min / binSize) * binSize;
    start <= max;
    start += binSize
  ) {
    bins.push([start, start + binSize])
  }

  // Map values to bins
  const binCounts = bins.map(
    ([lower, upper]): Bin => ({
      range: [lower, upper],
      count: values.filter((x) => x >= lower && x < upper).length,
    })
  )

  return binCounts
}

function calculateMinItems(bins: Bin[], fraction: number): number {
  // Find max value.
  const maxCount = Math.max(...bins.map((bin) => bin.count))

  // Calculate the minimum as a fraction of the maximum.
  return Math.ceil(maxCount * fraction)
}

/**
 * Merges bins with a count below the minimum threshold into larger ranges for
 * better distribution analysis.
 */
export function mergeSmallBins(bins: Bin[], fraction: number) {
  const minItems = calculateMinItems(bins, fraction)
  const result = []
  let overflowStart = null
  let overflowCount = 0

  for (let i = 0; i < bins.length; i++) {
    if (bins[i].count < minItems) {
      // If the bin has fewer items than the minimum, initiate or continue overflow merging.
      if (overflowStart === null) {
        overflowStart = bins[i].range[0] // Mark the starting point of the overflow range.
      }
      overflowCount += bins[i].count // Accumulate the count of items in the overflow.
    } else {
      // If the current bin meets the minimum item threshold, handle any previous overflow.
      if (overflowStart !== null) {
        result.push({
          range: [overflowStart, bins[i].range[0]], // End the overflow range at the start of the current bin.
          count: overflowCount,
        })

        // Reset overflow tracking variables.
        overflowStart = null
        overflowCount = 0
      }

      result.push(bins[i]) // Add the current bin directly as it satisfies the minimum item condition.
    }
  }

  // Handle any remaining overflow at the end of the bins array.
  if (overflowStart !== null) {
    result.push({
      range: [overflowStart, bins[bins.length - 1].range[1]], // Extend the range to the end of the last bin.
      count: overflowCount,
    })
  }

  return result
}
