<template>
  <abstract-chart
    :chart-def="chartDef"
    :chart-data="data"
    :chart-options="options"
    :is-loading="isLoading"
    :custom-download-csv="createCsvRows"
  >
    <!-- Custom slots -->
    <template v-for="key of slotNames" v-slot:[`${key}`]>
      <slot :name="key"></slot>
    </template>
  </abstract-chart>
</template>

<script lang="ts">
import {
  ChartData,
  ChartDataset,
  ChartOptions,
  LineOptions,
  TooltipItem,
} from 'chart.js'
import { merge } from 'lodash-es'
import { defineComponent, PropType, useSlots } from 'vue'
import { ChartDefinition, ChartFormatters } from '@/types/charts'
import { yAxisTitle, getSeriesColor, standardYAxisTicks } from '@/utils/charts'
import { XAXIS_GRID } from '@/utils/chartjs'
import { colorWithOpacity } from '@/utils/colors'
import { type CsvRow, createCsvRowsFromSimpleChartData } from '@/utils/csv'
import { NamedTimeSeries } from '@/model/charts/TimeSeriesDataSource'
import AbstractChart from './AbstractChart.vue'

/** Dashed lines are 2px wide and dashes are 4px long with 4px spacing. */
const DASHED_LINE_OPTS: Partial<LineOptions> = {
  borderDash: [4, 4],
  borderWidth: 2,
  fill: false,
}

export default defineComponent({
  name: 'LinearChart',
  components: { AbstractChart },
  props: {
    chartDef: {
      type: Object as PropType<ChartDefinition>,
      required: true,
    },
    chartFormatters: {
      type: Object as PropType<ChartFormatters>,
      required: true,
    },
    chartSeries: {
      type: Array as PropType<Readonly<NamedTimeSeries[]>>,
      required: true,
    },
    chartOptions: {
      type: Array as PropType<ChartOptions[]>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return { slotNames: Object.keys(useSlots()) }
  },
  computed: {
    data(): ChartData {
      const { seriesColors } = this.chartDef
      return {
        datasets: this.chartSeries.map(
          ({ config, data }, index): ChartDataset<'line'> => {
            const seriesColor = getSeriesColor(config, seriesColors, index)

            return {
              label: config.seriesName,
              data: data as any[],
              backgroundColor: colorWithOpacity(seriesColor, 0.3),
              borderColor: seriesColor,
              borderWidth: 2,
              normalized: true,
              parsing: this.chartDef.xAxis?.parsing,
              ...(config.seriesLine === 'dashed' && DASHED_LINE_OPTS),
            }
          }
        ),
      }
    },
    options(): ChartOptions {
      const options: ChartOptions = {
        elements: {
          line: { fill: this.chartDef.isAreaChart ?? false },
          point: { radius: 0 }, // default to disabled in all datasets
        },
        plugins: {
          legend: {
            display: this.chartSeries.length > 1,
            position: 'bottom',
          },
          tooltip: {
            enabled: true,
            animation: false,
            callbacks: {
              title: this.formatTooltipTitle,
              label: this.formatTooltip,
            },
            position: 'fixedPosition',
          },
        },
        scales: {
          x: {
            type: 'linear',
            grid: XAXIS_GRID,
            ticks: {
              callback: (v) => this.chartFormatters?.xaxis?.(v as number) ?? v,
            },
          },
          y: {
            ticks: standardYAxisTicks(this.chartFormatters),
            title: {
              display: true,
              text:
                this.chartDef.yAxis?.title ?? yAxisTitle(this.chartDef.type),
            },
          },
        },
      }

      // Using merge() so that nested objects are merged
      return merge(options, ...this.chartOptions)
    },
  },
  methods: {
    formatTooltipTitle(tooltipItems: TooltipItem<'line'>[]): string {
      const firstItem = tooltipItems[0]
      const formatter = this.chartFormatters.xaxis
      return formatter
        ? `${this.chartDef.xAxis?.title}: ${formatter(firstItem.parsed.x)}`
        : firstItem.parsed.x.toString()
    },
    formatTooltip(item: TooltipItem<'line'>): string {
      const formatter = this.chartFormatters.yaxis
      return `${item.dataset.label}: ${formatter(item.parsed.y)}`
    },
    createCsvRows(): CsvRow[] {
      return createCsvRowsFromSimpleChartData(this.data, {
        xAxisColumnHeader: this.chartDef.xAxis?.title ?? '',
      })
    },
  },
})
</script>
