import { DateTime } from 'luxon'
import { Header, Row, Options, DataTable } from '@/model/tables/DataTable'
import { OPTION_ITEMS_PER_PAGE } from '@/constants/table'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { getWaypointLabel, Metric } from '@/model/control/waypoint'
import {
  GroupHelper,
  isRecurringSchedule,
  isTemporarySchedule,
} from '@/model/control/group/helper'
import { Format } from '@/utils/format'
import { titleCase } from '@/utils/formatText'
import { formatDtWithZoneShort } from '@/utils/time'
import { NumberOrNull } from '@/types/charts'
import { InfoColumnGroup, InfoColumnItem } from '@/model/types'
import { SetPolicyEvent, SubmitWaypointsEvent } from 'rfs/control/proto/log_pb'
import { Policy, Params } from 'rfs/control/proto/policy_pb'
import { Waypoint } from 'rfs/control/proto/waypoints_pb'
import { ScheduleWaypoint } from 'rfs/control/proto/control_service_pb'
import { Timestamp } from '@/services/timestamp_pb'

export enum Columns {
  CREATED_AT = 'CREATED_AT',
  GROUP_NAME = 'GROUP_NAME',
  USER_EMAIL = 'USER_EMAIL',
  TYPE = 'TYPE',
  POLICY = 'POLICY',
  POLICY_PARAMS = 'POLICY_PARAMS',
}

// NOTE(rafael): values in milliseconds so we don't need to sort the rows
// ourselves. `CeDataTable.vue` sorts for us.
export interface LogRow extends Row {
  [Columns.CREATED_AT]: number // millis
  [Columns.GROUP_NAME]: string
  [Columns.USER_EMAIL]: string
  [Columns.TYPE]: string
  [Columns.POLICY]?: Policy | string
  [Columns.POLICY_PARAMS]?: Params | string
  infoColumnGroup?: InfoColumnGroup
  scheduleId?: bigint
}

export type HistoryDataTable = DataTable<LogRow>

export type CustomOptions = Options<Columns>

const headerCreatedAt: Header<LogRow> = {
  title: 'Created At',
  key: Columns.CREATED_AT,
  sortable: true,
  value: (r) => formatDateTime(r[Columns.CREATED_AT]),
}

const headerName: Header<LogRow> = {
  title: 'Name',
  key: Columns.GROUP_NAME,
  sortable: true,
}

const headerUserEmail: Header<LogRow> = {
  title: 'User email',
  key: Columns.USER_EMAIL,
  width: '20%',
  sortable: true,
}

const headerType: Header<LogRow> = {
  title: 'Log type',
  key: Columns.TYPE,
  sortable: true,
}

export const headerPolicy: Header<LogRow> = {
  title: 'Policy',
  key: Columns.POLICY,
  value: (row) => {
    const policy = row[Columns.POLICY]
    const params = row[Columns.POLICY_PARAMS]

    if (policy && typeof policy !== 'string' && typeof params !== 'string') {
      return GroupHelper.getPolicyLabel(policy, params)
    } else if (typeof policy === 'string') {
      return policy
    } else {
      return TEXT_NO_VALUE
    }
  },
  sortable: true,
}

const headerPolicyParams: Header<LogRow> = {
  title: 'Params',
  key: Columns.POLICY_PARAMS,
  value: (row) => {
    const params = row[Columns.POLICY_PARAMS]

    if (typeof params === 'string') {
      return params
    } else {
      return GroupHelper.getPolicyParams(params) || TEXT_NO_VALUE
    }
  },
  maxWidth: '10rem',
  sortable: false,
}

export const headers: Header<LogRow>[] = [
  headerCreatedAt,
  headerName,
  headerUserEmail,
  headerType,
  headerPolicy,
  headerPolicyParams,
]

export function createInitialOptions(): CustomOptions {
  return {
    page: 1,
    itemsPerPage: OPTION_ITEMS_PER_PAGE[0],
    orderBy: { column: Columns.CREATED_AT, descending: true },
  }
}

export function formatDateTime(v: undefined | NumberOrNull) {
  return v === null || v === undefined
    ? TEXT_NO_VALUE
    : formatDtWithZoneShort(DateTime.fromMillis(v))
}

export function createSetPolicyRow(
  createdAt: Timestamp,
  displayName: string,
  binary: Uint8Array,
  id?: string
): undefined | LogRow {
  const {
    eventId,
    principal,
    policy,
    params,
    policyStartTime,
    policyEndTime,
    nextPolicy,
    nextPolicyParams,
  } = SetPolicyEvent.fromBinary(binary)

  const scheduleId = GroupHelper.getCurrentScheduleId(params)

  const nextScheduleId = GroupHelper.getCurrentScheduleId(nextPolicyParams)

  const isRecurringNext = isRecurringSchedule(nextPolicy, nextPolicyParams)

  let scheduleProp = {}

  if (scheduleId) {
    scheduleProp = { scheduleId: scheduleId }
  } else if (nextScheduleId && isRecurringNext) {
    scheduleProp = { scheduleId: nextScheduleId }
  }

  return {
    ...scheduleProp,
    id: id !== undefined ? id : eventId,
    [Columns.CREATED_AT]: createdAt.toMillis(), // millis
    [Columns.GROUP_NAME]: displayName,
    [Columns.USER_EMAIL]: principal !== '' ? principal : TEXT_NO_VALUE,
    [Columns.TYPE]: 'SetPolicyEvent',
    [Columns.POLICY]: policy,
    [Columns.POLICY_PARAMS]: params,
    infoColumnGroup: (() => {
      if (policy === Policy.PEAK_TIME_PAYBACK_EVENT) {
        return createPeakTimePaybackDetails(
          params,
          policyStartTime,
          policyEndTime
        )
      } else if (isTemporarySchedule(policy, params) && nextPolicy) {
        return createTempScheduleDetails(
          nextPolicy,
          nextPolicyParams,
          policyEndTime
        )
      } else {
        return undefined
      }
    })(),
  }
}

export function createSubmitWaypointRow(
  createdAt: Timestamp,
  displayName: string,
  binary: Uint8Array
): LogRow {
  const { eventId, principal, waypoints, scheduleStartTime, scheduleEndTime } =
    SubmitWaypointsEvent.fromBinary(binary)

  return {
    id: eventId,
    infoColumnGroup: waypoints.length
      ? createWaypointsDetails(waypoints)
      : createErasedWaypoints(scheduleStartTime, scheduleEndTime),
    [Columns.CREATED_AT]: createdAt.toMillis(), // millis
    [Columns.GROUP_NAME]: displayName,
    [Columns.USER_EMAIL]: principal !== '' ? principal : TEXT_NO_VALUE,
    [Columns.TYPE]: 'SubmitWaypointsEvent',
  }
}

function createErasedWaypoints(
  start?: Timestamp,
  end?: Timestamp
): InfoColumnGroup {
  return [
    {
      id: '01',
      infoColumn: {
        name: 'All waypoints removed',
        subtitle: 'All waypoints in this interval were removed',
        subtitleBold: true,
        items: [
          { label: 'Start', text: formatDateTime(start?.toMillis()) },
          { label: 'End', text: formatDateTime(end?.toMillis()) },
        ],
      },
    },
  ]
}

function createWaypointsDetails(waypoints: Waypoint[]): InfoColumnGroup {
  return waypoints.map((w, idx) => {
    const items: InfoColumnItem[] = (() => {
      switch (w.targetMetric) {
        case Metric.METRIC_MAX_CHARGE_POWER_WATTS:
        case Metric.METRIC_ACTIVE_POWER_WATTS:
          return createTemporaryPowerWaypointItems(w, idx)
        case Metric.METRIC_SOC_PERCENT:
        case Metric.METRIC_SOC_WATT_HOURS:
          return createTemporarySoCWaypointItems(w, idx)
        default:
          return []
      }
    })()

    return {
      id: idx.toString(),
      infoColumn: {
        name:
          w.targetMetric === Metric.METRIC_MAX_CHARGE_POWER_WATTS
            ? `Event ${idx}`
            : `Waypoint ${idx}`,
        items: [...items, { label: 'Priority', text: w.priority.toString() }],
      },
    }
  })
}

function createTemporarySoCWaypointItems(
  w: Waypoint,
  index: number
): InfoColumnItem[] {
  const setpointText = (() => {
    if (w.targetMetric === Metric.METRIC_SOC_PERCENT) {
      return Format.fmtPercentNoFraction(w.targetValue)
    } else if (w.targetMetric === Metric.METRIC_SOC_WATT_HOURS) {
      return Format.fmtEnergy(w.targetValue)
    } else {
      return TEXT_NO_VALUE
    }
  })()

  // NOTE: When "time" field exists, it's a legacy temporary waypoint.
  return w.time
    ? [
        { label: index.toString(), text: formatDateTime(w.time?.toMillis()) },
        { label: 'Setpoint', text: setpointText },
      ]
    : // "startTime" and "endTime".
      [
        { label: `Waypoint ${index}`, text: setpointText },
        { label: 'Start time', text: formatDateTime(w.startTime?.toMillis()) },
        { label: 'End time', text: formatDateTime(w.endTime?.toMillis()) },
      ]
}

function createTemporaryPowerWaypointItems(
  w: Waypoint,
  index: number
): InfoColumnItem[] {
  return [
    {
      label: `${titleCase(getWaypointLabel({ metric: w.targetMetric }))} ${index}`,
      text: Format.fmtWatts(w.targetValue) || TEXT_NO_VALUE,
    },
    { label: 'Start time', text: formatDateTime(w.startTime?.toMillis()) },
    { label: 'End time', text: formatDateTime(w.endTime?.toMillis()) },
  ]
}

export function createScheduleInfoColumnGroup(
  scheduleId: bigint,
  metric: Metric,
  waypoints: ScheduleWaypoint[]
): InfoColumnGroup {
  return waypoints.map((w, idx) => {
    const formattedValue = (() => {
      switch (metric) {
        case Metric.METRIC_SOC_PERCENT:
          return Format.fmtPercentNoFraction(w.value)
        case Metric.METRIC_SOC_WATT_HOURS:
          return Format.fmtEnergy(w.value)
        case Metric.METRIC_MAX_CHARGE_POWER_WATTS:
        case Metric.METRIC_ACTIVE_POWER_WATTS:
          return Format.fmtWatts(w.value)
        default:
          return TEXT_NO_VALUE
      }
    })()

    const label =
      metric === Metric.METRIC_MAX_CHARGE_POWER_WATTS
        ? `Event ${idx}`
        : `Waypoint ${idx}`

    const items: InfoColumnItem[] =
      // When no "startTime", legacy recurring waypoint.
      !w.startTime
        ? [
            { label: idx.toString(), text: w.scheduleTime },
            { label: 'Setpoint', text: formattedValue },
          ]
        : [
            { label, text: formattedValue },
            { label: 'Start time', text: w.startTime || TEXT_NO_VALUE },
            { label: 'End time', text: w.endTime || TEXT_NO_VALUE },
          ]

    return {
      id: idx.toString(),
      infoColumn: {
        items,
        title: idx === 0 ? `Schedule ID: ${scheduleId.toString()}` : undefined,
        name: label,
      },
    }
  })
}

function createPeakTimePaybackDetails(
  params?: Params,
  start?: Timestamp,
  end?: Timestamp
): InfoColumnGroup {
  return [
    {
      id: 'peak-time-payback',
      infoColumn: {
        items: [
          {
            label: 'Setpoint',
            text: GroupHelper.getPolicyParams(params) || TEXT_NO_VALUE,
          },
          {
            label: 'Start time',
            text: formatDateTime(start?.toMillis()) || TEXT_NO_VALUE,
          },
          {
            label: 'End time',
            text: formatDateTime(end?.toMillis()) || TEXT_NO_VALUE,
          },
        ],
      },
    },
  ]
}

function createTempScheduleDetails(
  nextPolicy: Policy,
  nextPolicyParams?: Params,
  end?: Timestamp
): undefined | InfoColumnGroup {
  const endTime = end ? formatDateTime(end.toMillis()) : undefined
  const scheduleId = GroupHelper.getCurrentScheduleId(nextPolicyParams)

  return [
    {
      id: 'End time',
      infoColumn: {
        name: 'End time',
        subtitle: ' ',
        items: [
          {
            label: 'End time',
            text: endTime ?? TEXT_NO_VALUE,
          },
        ],
      },
    },
    {
      id: 'nextPolicy',
      infoColumn: {
        name: 'Next policy',
        title: 'Next policy:',
        items: [
          {
            label: 'Policy',
            text: GroupHelper.getPolicyLabel(nextPolicy, nextPolicyParams),
          },
          ...(scheduleId !== undefined
            ? [{ label: 'Schedule ID', text: scheduleId.toString() }]
            : []),
        ],
      },
    },
  ]
}
