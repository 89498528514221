<template>
  <v-app-bar class="c-CeTopBar" :height="TOP_BAR_HEIGHT" flat>
    <div class="d-flex w-100 min-height-100 px-6 ce-border-bottom">
      <!-- 1/3 part: Hamb. Menu & Logo -->
      <div class="d-flex align-center" style="flex: 1">
        <!-- Hamb. Menu -->
        <div
          style="margin-left: -23px"
          :style="{ visibility: menuOptions.length ? 'unset' : 'hidden' }"
        >
          <ce-menu :menuOptions />
        </div>

        <!-- Logo -->
        <img
          v-if="logoUrl"
          :src="logoUrl"
          :alt="customerName"
          height="40px"
          class="ml-4"
        />
      </div>

      <!-- 1/3 part: Tabs -->
      <div class="d-flex" style="width: 26rem">
        <ce-tabs :height="TOP_BAR_HEIGHT" />
      </div>

      <!-- 1/3 part: User menu -->
      <div class="d-flex align-center justify-end" style="flex: 1">
        <!-- Search -->
        <search-box class="mr-6" />

        <!-- Alerts Menu -->
        <alerts-menu v-if="isAlertsEnabled" />

        <!-- Notifications -->
        <notifications-menu v-if="showNotifications" />

        <!-- User Menu -->
        <div class="ml-4" style="margin-right: -15px">
          <user-dropdown-menu />
        </div>
      </div>
    </div>
  </v-app-bar>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { TOP_BAR_HEIGHT } from '@/constants/topBar'
import { TITLE_OVERVIEW } from '@/router'
import * as RouteNames from '@/router/routeNames'
import {
  TITLE_ALERTS,
  TITLE_FORECAST,
  TITLE_FORECAST_QUALITY,
  TITLE_GRID_COMPONENTS,
  TITLE_SUPPLY_AND_DEMAND,
  TITLE_INSIGHTS,
} from '@/router/analyzeRoutes'
import { TITLE_DERS } from '@/router/dersRoutes'
import { TITLE_FLEET } from '@/router/fleetRoutes'
import { TITLE_METERS } from '@/router/meterRoutes'
import { TITLE_SUBSTATIONS } from '@/router/substationRoutes'
import { TITLE_TRANSFORMERS } from '@/router/transformerRoutes'
import { TITLE_VOLTAGE_MAPPING } from '@/router/voltageMappingRoutes'
import { TITLE_VOLTAGE_DEVIATION } from '@/router/voltageDeviationRoutes'
import { useCustomFiles } from '@/stores/customFiles'
import { TabId } from '@/config/types'
import SearchBox from '@/components/navigation/SearchBox.vue'
import CeTabs from '@/components/others/CeTabs.vue'
import AlertsMenu from '@/components/alerts/AlertsMenu.vue'
import NotificationsMenu from '@/components/monitor/NotificationsMenu.vue'
import UserDropdownMenu from '@/components/others/User/UserDropdownMenu.vue'
import CeMenu, { type NavMenuListItem } from '@/components/others/CeMenu.vue'

const monitorItems: NavMenuListItem[] = [
  { to: { name: RouteNames.OVERVIEW }, text: TITLE_OVERVIEW },
  { to: { name: RouteNames.METERS }, text: TITLE_METERS },
  { to: { name: RouteNames.SUBSTATIONS }, text: TITLE_SUBSTATIONS },
  { to: { name: RouteNames.DERS }, text: TITLE_DERS },
  { to: { name: RouteNames.TRANSFORMERS }, text: TITLE_TRANSFORMERS },
  { to: { name: RouteNames.FLEET }, text: TITLE_FLEET },
  { to: { name: RouteNames.VOLTAGE_MAPPING }, text: TITLE_VOLTAGE_MAPPING },
  { to: { name: RouteNames.VOLTAGE_DEVIATION }, text: TITLE_VOLTAGE_DEVIATION },
]

const analyzeItems: NavMenuListItem[] = [
  { to: { name: RouteNames.GRID_IMPACT }, text: TITLE_GRID_COMPONENTS },
  { to: { name: RouteNames.ALERTS }, text: TITLE_ALERTS },
  { to: { name: RouteNames.FORECAST }, text: TITLE_FORECAST },
  { to: { name: RouteNames.FORECAST_QUALITY }, text: TITLE_FORECAST_QUALITY },
  { to: { name: RouteNames.INSIGHTS }, text: TITLE_INSIGHTS },
  { to: { name: RouteNames.SUPPLY_AND_DEMAND }, text: TITLE_SUPPLY_AND_DEMAND },
]

const controlItems: NavMenuListItem[] = []

export default defineComponent({
  name: 'CeTopBar',
  components: {
    CeMenu,
    SearchBox,
    CeTabs,
    AlertsMenu,
    NotificationsMenu,
    UserDropdownMenu,
  },
  setup() {
    return { TOP_BAR_HEIGHT }
  },
  data() {
    const filterRoutes = (items: NavMenuListItem[]) =>
      items.filter(
        (item) => item.to.name && this.$router.hasRoute(item.to.name)
      )

    return {
      monitorMenuItems: filterRoutes(monitorItems),
      analyzeMenuItems: filterRoutes(analyzeItems),
      controlMenuItems: filterRoutes(controlItems),
      customerName: this.$rittaConfig.customer.shortName,
      isAlertsEnabled: this.$router.hasRoute(RouteNames.ALERTS),
    }
  },
  computed: {
    ...mapState(useCustomFiles, ['logoUrl']),
    menuOptions(): NavMenuListItem[] {
      switch (this.$route.meta?.tab) {
        case TabId.MONITOR:
          return this.monitorMenuItems
        case TabId.ANALYZE:
          return this.analyzeMenuItems
        case TabId.CONTROL:
          return this.controlMenuItems
        default:
          return []
      }
    },
    showNotifications(): boolean {
      return !!this.$rittaConfig.monitor?.notifications?.enabled
    },
  },
})
</script>

<style lang="scss">
.c-CeTopBar {
  z-index: 10 !important;

  // removes the padding from the vuetify content wrapper.
  .v-toolbar__content {
    padding: 0;
    // Required so the search results can appear to the user.
    overflow: visible;
  }
}
</style>
