import { TEXT_NO_VALUE } from '@/constants/formatText'

type AnyValue = null | undefined | number
export type FmtOptions = {
  sigFigs: number
  scaleReference?: number
  maxScale?: number
  noUnit?: boolean
  noThousandsSeparator?: boolean
}

// The options bellow are the defaults used in the entire app.
export const KILO_OPTIONS: FmtOptions = {
  sigFigs: 3,
  scaleReference: 1_000,
}
export const MEGA_OPTIONS: FmtOptions = {
  sigFigs: 3,
  scaleReference: 1_000_000,
}
export const GIGA_OPTIONS: FmtOptions = {
  sigFigs: 3,
  scaleReference: 1_000_000_000,
}
const AMPS_OPTIONS: FmtOptions = {
  sigFigs: 3,
  scaleReference: 1,
}

// Linter complains if you use the globals
const { isFinite, isNaN } = Number

// Utilities for pretty-printing numbers with various units.

const Format = {
  // Renders electrical potential/power/current/etc., encoded as a decimal
  // string.
  //
  // The input n may be a string or a number.
  //
  // sigFigs is a non-optional number; the output will scaled to exactly that
  // many significant figures (modulo scaleReference, see below).
  //
  // scaleReference is an optional number.  It's useful when you're displaying
  // a list of numbers and you want them all to have the same units.  For
  // example, if you want to display voltages [0, 500, 20000] on a chart's Y
  // axis, perhaps you want them displayed as ["0 kV", "0.5 kV", "20.0 kV"]
  // rather than ["0 V", "500 V", "20.0 kV"].
  //
  // If you provide a scaleReference, then we'll figure out how we'd format
  // that number (e.g. "XX.X kV") and then format the given `n` with the same
  // unit prefix (e.g. "k") and the same number of decimal places.
  fmtVolts(n: AnyValue, opts?: FmtOptions) {
    if (isNaN(n)) return ''

    return formatMetric(n, 'V', {
      sigFigs: (opts?.sigFigs !== 0 && opts?.sigFigs) || 3,
      scaleReference: opts?.scaleReference,
      noThousandsSeparator: opts?.noThousandsSeparator,
      noUnit: opts?.noUnit,
    })
  },
  /** Volts per unit is a number usually between 0.95 and 1.05 */
  fmtVoltsPerUnit(n: AnyValue): string {
    return n != null ? n.toFixed(2) : TEXT_NO_VALUE
  },

  fmtWatts(n: AnyValue, opts: FmtOptions = KILO_OPTIONS) {
    return formatMetric(n, 'W', {
      sigFigs: (opts.sigFigs !== 0 && opts.sigFigs) || 3,
      scaleReference: opts.scaleReference,
      noThousandsSeparator: opts.noThousandsSeparator,
      noUnit: opts.noUnit,
    })
  },
  /**
   * The input and output types must be watt hours.
   */
  fmtEnergy(n: AnyValue, opts: FmtOptions = KILO_OPTIONS) {
    return formatMetric(n, 'Wh', {
      sigFigs: (opts.sigFigs !== 0 && opts.sigFigs) || 3,
      scaleReference: opts.scaleReference,
      noThousandsSeparator: opts.noThousandsSeparator,
      noUnit: opts.noUnit,
    })
  },
  fmtAmps(n: AnyValue, opts: FmtOptions = AMPS_OPTIONS) {
    return formatMetric(n, 'A', {
      sigFigs: (opts?.sigFigs !== 0 && opts?.sigFigs) || 3,
      scaleReference: opts?.scaleReference,
      noThousandsSeparator: opts.noThousandsSeparator,
      noUnit: opts.noUnit,
    })
  },
  fmtApparentPower(n: AnyValue, opts: FmtOptions = KILO_OPTIONS) {
    return formatMetric(n, 'VA', {
      sigFigs: (opts.sigFigs !== 0 && opts.sigFigs) || 3,
      scaleReference: opts.scaleReference,
      noThousandsSeparator: opts.noThousandsSeparator,
      noUnit: opts.noUnit,
    })
  },
  fmtReactivePower(n: AnyValue, opts: FmtOptions = KILO_OPTIONS) {
    return formatMetric(n, 'VAR', {
      sigFigs: (opts.sigFigs !== 0 && opts.sigFigs) || 3,
      scaleReference: opts.scaleReference,
      noThousandsSeparator: opts.noThousandsSeparator,
      noUnit: opts.noUnit,
    })
  },
  fmtMeters(n: AnyValue, opts: FmtOptions = KILO_OPTIONS) {
    // maxScale = 1e3 because "kilo" is the largest prefix conventionally used
    // with meters.
    return formatMetric(n, 'm', {
      sigFigs: (opts.sigFigs !== 0 && opts.sigFigs) || 3,
      scaleReference: opts.scaleReference,
      maxScale: 1e3,
      noThousandsSeparator: opts.noThousandsSeparator,
      noUnit: opts.noUnit,
    })
  },

  // Render a distance in feet.
  //
  // TODO(jlebar): If necessary add support for sigFigs and scaleReference params,
  // like the metric measures above.
  fmtFeet(ft: AnyValue) {
    if (ft === null || ft === undefined) {
      // I guess this is a reasonable behavior?  It makes some things easier in
      // Vue, because it lets you write e.g. `fmtFeet(x) || 'foo'`.
      return ''
    }
    return Format.fmtCount(ft) + ' ft'
  },

  // Render a large distance in imperial units, e.g. 15.1 miles, 2,830 miles.
  //
  // TODO(jlebar): If necessary add support for scaleReference
  // param, like the metric measures above.
  fmtImperialDistance(
    ft: AnyValue,
    { sigFigs }: FmtOptions = KILO_OPTIONS
  ): string {
    if (ft === null || ft === undefined) {
      // I guess this is a reasonable behavior?  It makes some things easier in
      // Vue, because it lets you write e.g. `fmtImperialDistance(x) || 'foo'`.
      return ''
    }

    if (ft < 0) {
      return '-' + Format.fmtImperialDistance(-ft)
    }
    // Less than 1/8 mile, display as feet.  Don't call formatSigFigs because we
    // don't want to display fractional feet.
    if (ft < 5280 / 8) {
      return `${formatSigFigs(ft)} ft`
    }
    const miles = ft / 5280
    return `${formatSigFigs(miles, sigFigs)} miles`
  },

  // Render an integer number of things, e.g. 152 or 2,921.
  fmtCount(n: AnyValue) {
    if (n === null || n === undefined) {
      // I guess this is a reasonable behavior?  It makes some things easier in
      // Vue, because it lets you write e.g. `fmtCount(x) || 'foo'`.
      return ''
    }
    return number0Decimals.format(n)
  },

  /**
   * Format a percentage value in the range 0-1 for 0-100%.
   * If the value is null-ish or NaN, a dash "-" is returned.
   * If the value is +/-Infinity, "N/A" is returned.
   */
  fmtPercent(n: AnyValue): string {
    if (n == null || isNaN(n)) {
      return TEXT_NO_VALUE
    } else if (!isFinite(n)) {
      return 'N/A' // The server likely did division by zero
    }
    return percent2Decimals.format(n)
  },

  fmtPercentNoFraction(n: AnyValue): string {
    if (n == null || isNaN(n)) {
      return TEXT_NO_VALUE
    } else if (!isFinite(n)) {
      return 'N/A' // The server likely did division by zero
    }
    return percent0Decimals.format(n)
  },

  fmtTemperature(n: AnyValue): string {
    if (n == null || !isFinite(n)) {
      return TEXT_NO_VALUE
    }
    return tempFahrenheit.format(n)
  },
}

// Hard-coding US English will make unit tests consistent
const number0Decimals = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  maximumFractionDigits: 0,
  useGrouping: true,
})

const percent2Decimals = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  useGrouping: true,
})
const percent0Decimals = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 0,
  useGrouping: true,
})

const tempFahrenheit = new Intl.NumberFormat('en-US', {
  style: 'unit',
  unit: 'fahrenheit',
  unitDisplay: 'short',
  maximumFractionDigits: 0,
  useGrouping: true,
})

// Formats `n` with exactly `sigFigs` significant figures.  Unless n == 0, in
// which case we just return '0'.
function formatSigFigs(n: number, sigFigs?: number) {
  if (n === 0) {
    return '0'
  }
  return n.toLocaleString(undefined, {
    maximumSignificantDigits: sigFigs,
  })
}

function getScale(v: number, sigFigs: number, maxScale?: number) {
  if (maxScale === undefined) {
    maxScale = Infinity
  }
  const prefixes: Array<[string, number]> = [
    ['T', 1e12],
    ['G', 1e9],
    ['M', 1e6],
    ['k', 1e3],
  ]
  for (const [prefix, scale] of prefixes) {
    // Fun edge case: Check the scale *after* rounding to the correct number of
    // significant digits!  Otherwise we might format e.g. 999 as "1,000 W" and
    // 1000 as "1.0 kW".
    const rounded = Number.parseFloat(v.toPrecision(sigFigs))

    // With 4 or more sigfigs, we prefer e.g. "12,300 kV" to "12.30 MV".
    if (
      maxScale >= scale &&
      ((sigFigs < 4 && rounded >= scale) ||
        (sigFigs >= 4 && rounded >= 10 * scale))
    ) {
      return { prefix, scale }
    }
  }
  return { prefix: '', scale: 1 }
}

function getDecimalPlaces(v: number, sigFigs: number): number {
  if (v < 0) {
    return getDecimalPlaces(-v, sigFigs)
  }
  const digits = v === 0 ? 1 : Math.floor(Math.log10(v)) + 1
  return Math.max(0, sigFigs - digits)
}

function formatMetric(
  n: AnyValue,
  unit: string,
  {
    sigFigs,
    scaleReference,
    maxScale,
    noThousandsSeparator,
    noUnit,
  }: FmtOptions
): string {
  if (n === null || n === undefined || Number.isNaN(Number(n))) {
    // I guess this is a reasonable behavior?  It makes some things easier in
    // Vue, because it lets you write e.g. `fmtWatts(x) || 'foo'`.
    return ''
  }

  let v = n
  if (!Number.isFinite(v)) {
    return `${v.toLocaleString()} ${unit}`
  }
  if (v < 0) {
    return `-${formatMetric(-n, unit, {
      sigFigs,
      scaleReference,
      maxScale,
      noThousandsSeparator,
      noUnit,
    })}`
  }
  if (scaleReference === undefined) {
    scaleReference = v
  }
  scaleReference = Math.abs(scaleReference)
  const { scale, prefix } = getScale(scaleReference, sigFigs, maxScale)
  v /= scale
  scaleReference /= scale

  // Round `v` to the appropriate number of significant figures.  Stringify'ing
  // and then un-stringifying is an ugly way to do this, but I'm not convinced
  // the more "obvious" things like round(v / 100)) * 100 are correct.
  //
  // When v == scaleReference, this precision == sigFigs.  But when
  // scaleReference > v, it may lose some significant digits.
  const precision =
    sigFigs -
    (Math.floor(Math.log10(scaleReference)) - Math.floor(Math.log10(v)))
  // JS requires we clamp precision to [1, 100].  Clamping to 100 is no
  // problem.  But clamping to 1 is subtly wrong.  Suppose v = 0.47,
  // scaleReference = 100, and sigFigs = 3.  The correct output is "0".  But if
  // we were to use precision == 1 here, we'd end up rounding 0.47 to 0.5 and
  // then rounding it again up to 1 on output (because decimalPlaces == 0)!
  // Thus we need the special case for precision <= 0.
  //
  // The special cases for v == 0 and scaleReference == 0 are because those
  // result in precision = NaN.
  if (v === 0 || scaleReference === 0 || precision <= 0) {
    v = 0
  } else {
    v = Number.parseFloat(v.toPrecision(Math.min(100, precision)))
    // Round the scale reference as well, so that we can calculate
    // `decimalPlaces` correctly.
    scaleReference = Number.parseFloat(
      scaleReference.toPrecision(Math.min(100, sigFigs))
    )
  }

  // Finally, print v with the appropriate number of decimal places and commas.
  const decimalPlaces = getDecimalPlaces(scaleReference, sigFigs)
  const s = v.toLocaleString(undefined, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
    useGrouping: !noThousandsSeparator,
  })
  return noUnit ? s : `${s} ${prefix}${unit}`
}

export { Format }
