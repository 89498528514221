import { PartialMessage } from '@bufbuild/protobuf'
import {
  CallOptions,
  Client,
  createClient,
  Transport,
} from '@connectrpc/connect'

import { GridAnalysis } from 'rfs/frontend/proto/analysis_connect'
import {
  DERImpactsRequest,
  DownlineMetersTableRequest,
  GetUplineRequest,
  ResourceImpactRequest,
  ResourceImpactResponse,
} from 'rfs/frontend/proto/analysis_pb'
import { debounceAsync } from '@/utils/async'

/**
 * This is the amount of time we debounce requests to fetch the resource impact.
 * When the user is changing the date using several pan or zoom gestures, we want
 * to wait until they're finished before fetching the impact data.
 */
const IMPACT_DELAY = 2_000 // 2 seconds

export class AnalysisService {
  private client: Client<typeof GridAnalysis>

  constructor(transport: Transport) {
    this.client = createClient(GridAnalysis, transport)
    // Hook up the debounced version of the resourceDERImpact method
    const resourceDERImpact = this.resourceDERImpact.bind(this)
    this.resourceDERImpact_DB = debounceAsync(resourceDERImpact, IMPACT_DELAY)
  }

  fetchDERImpactsStats(request: PartialMessage<DERImpactsRequest>) {
    return this.client.fetchDERImpactsStats(request)
  }

  fetchDERImpactsTable(request: PartialMessage<DERImpactsRequest>) {
    return this.client.fetchDERImpactsTable(request)
  }

  resourceDERImpact(request: PartialMessage<ResourceImpactRequest>) {
    return this.client.resourceDERImpact(request)
  }

  /**
   * Same as `resourceDERImpact` but debounced to avoid overloading the server.
   * It returns `undefined` until the actual call is made.
   */
  resourceDERImpact_DB(
    request: PartialMessage<ResourceImpactRequest>
  ): Promise<ResourceImpactResponse | undefined> {
    return this.client.resourceDERImpact(request)
  }

  downlineMetersTable(request: PartialMessage<DownlineMetersTableRequest>) {
    return this.client.downlineMetersTable(request)
  }

  downlineMetersTable2(
    request: PartialMessage<DownlineMetersTableRequest>,
    options?: CallOptions
  ) {
    return this.client.downlineMetersTable2(request, options)
  }

  getUpline(request: PartialMessage<GetUplineRequest>) {
    return this.client.getUpline(request)
  }
}
