import { createClient, Client } from '@connectrpc/connect'
import { createGrpcWebTransport } from '@connectrpc/connect-web'

import { AlertService } from 'rfs/frontend/proto/alert_connect'
import { RittaConfig } from '@/config'
import { AnalysisService } from './analysis.service'
import { NotificationService } from './notification.service'
import { ChartsService } from '@/services/charts.service'
import { GridService } from '@/services/grid.service'
import { Control as IControlService } from 'rfs/control/proto/control_service_connect'
import { Proxy as IProxyService } from 'rfs/device/proto/proxy_connect'
import { ForecastService } from './forecast.service'
import { GCSService } from './gcs.service'
import { getInterceptors } from './interceptors'
import { FileStorage } from 'rfs/frontend/proto/gcs_connect'
import { Hosting } from 'rfs/frontend/proto/hosting_connect'
import { CapacityService } from './capacity.service'
import { ControlsDataService } from './controlsData.service'
import { FleetService } from './fleet.service'
import { QueryService } from './query.service'
import { TileService } from './tile.service'

export type Services = {
  alertService: Client<typeof AlertService>
  notificationService: NotificationService
  analysisService: AnalysisService
  capacityService: CapacityService
  chartsService: ChartsService
  control: Client<typeof IControlService>
  controlsData: ControlsDataService
  proxy: Client<typeof IProxyService>
  fileStorage: Client<typeof FileStorage>
  fleetService: FleetService
  forecastService: ForecastService
  gcsService: GCSService
  gridService: GridService
  queryService: QueryService
  tileService: TileService
  hostingService: Client<typeof Hosting>
}

export function newServices(
  isDevEnv: boolean,
  config: Readonly<RittaConfig>
): Services {
  // We use the grpc-web binary protocol for all services
  const transport = createGrpcWebTransport({
    // Requests will be made to <baseUrl>/<package>.<service>/method
    baseUrl: config.rfsEndpoint!,
    // Controls what the fetch client will do with credentials, such as Cookies.
    // The default value is "same-origin", which will not transmit Cookies in cross-origin requests.
    credentials: 'include',
    // Interceptors apply to all calls running through this transport.
    interceptors: getInterceptors(isDevEnv, config),
  })

  return {
    alertService: createClient(AlertService, transport),
    notificationService: new NotificationService(transport),
    analysisService: new AnalysisService(transport),
    capacityService: new CapacityService(transport),
    chartsService: new ChartsService(transport),
    control: createClient(IControlService, transport),
    controlsData: new ControlsDataService(transport),
    proxy: createClient(IProxyService, transport),
    fileStorage: createClient(FileStorage, transport),
    fleetService: new FleetService(transport),
    forecastService: new ForecastService(transport),
    gcsService: new GCSService(transport),
    gridService: new GridService(transport),
    queryService: new QueryService(transport),
    tileService: new TileService(transport),
    hostingService: createClient(Hosting, transport),
  }
}
