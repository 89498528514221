<template>
  <full-height-layout class="px-6">
    <template v-slot:top>
      <ce-title big>Welcome to Interconnect</ce-title>
    </template>

    <p>
      If you want to play around, edit
      `src/components/connect/InterconnectPannel.vue`
    </p>
  </full-height-layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import CeTitle from '@/components/CeTitle.vue'
import FullHeightLayout from '@/components/common/FullHeightLayout.vue'
// import CeDataTable from '@/components/common/CeDataTable.vue'
import { GREY6 } from '@/constants/colors'

export default defineComponent({
  name: 'DersLeftPanel',
  components: { CeTitle, FullHeightLayout },
  setup() {
    return {
      title: 'Interconnect',
      bgColor: GREY6.rgb,
    }
  },
})
</script>
