<template>
  <info-column v-bind="consumption" class="pb-6" />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import type { LocationAsRelativeRaw } from 'vue-router'
import { formatText } from '@/utils/formatText'
import { getUnqualifiedId } from '@/model/resource'
import { formatPhase } from '@/model/resource/conductor/phase'
import { SiteHelper } from '@/model/site/helper'
import { createSiteRoute, createTransformerRoute } from '@/utils/router/create'
import { Resource } from 'rfs/pb/resource_pb'
import InfoColumn from '@/components/InfoColumn.vue'
import { InfoColumnAllProps } from '@/components/InfoColumn'

export default defineComponent({
  name: 'SiteConsumptionTab',
  props: {
    siteResource: {
      type: Object as PropType<Resource>,
      required: true,
    },
    otherResources: {
      type: Array as PropType<Resource[]>,
      required: true,
    },
  },
  components: {
    InfoColumn,
  },
  computed: {
    netMeter(): Resource | undefined {
      return SiteHelper.findNetMeter(this.siteResource, this.otherResources)
    },
    meterNumberValue(): string {
      return formatText(this.netMeter?.meter?.electrical)
    },
    meterNumberLink(): undefined | LocationAsRelativeRaw {
      const route = createSiteRoute(this.siteResource)

      // NOTE: don't show the link when it's already the Site details page.
      return this.$route.name !== route?.name ? route : undefined
    },
    consumption(): InfoColumnAllProps {
      const transformer = this.netMeter?.upline?.transformer

      const infoColumn: InfoColumnAllProps = {
        title: 'Consumption',
        items: [
          {
            label: 'Meter number',
            text: this.meterNumberValue,
            textLink: this.meterNumberLink,
          },
          {
            label: 'Manufacturer',
            text: formatText(this.netMeter?.manufacture?.brand),
          },
          {
            label: 'Phase',
            text: formatPhase(this.netMeter),
          },
          {
            label: 'Voltage (nominal)',
            text: SiteHelper.formatNominalVoltage(this.netMeter),
          },
          {
            label: 'Upline',
            text: getUnqualifiedId(this.netMeter?.upline?.parent ?? ''),
          },
          {
            label: 'Transformer',
            text: formatText(transformer),
            textLink: transformer
              ? createTransformerRoute(transformer)
              : undefined,
          },
        ],
      }

      return infoColumn
    },
  },
})
</script>
