import { RouteRecordRaw } from 'vue-router'
import { RittaConfig } from '@/config'
import { TabId } from '@/config/types'
import * as RouteNames from './routeNames'
import VoltageDeviationLeftPanel from '@/components/monitor/VoltageDeviationLeftPanel.vue'
import Home from '@/views/Home.vue'

export const TAB = TabId.MONITOR

export const TITLE_VOLTAGE_DEVIATION = 'Voltage Deviation'

export function voltageDeviationRoutes(
  config: Readonly<RittaConfig>
): RouteRecordRaw[] {
  if (config.monitor?.voltageDeviation?.enabled) {
    return [
      {
        path: '/voltage-deviation',
        component: Home,
        children: [
          {
            path: '',
            name: RouteNames.VOLTAGE_DEVIATION,
            meta: {
              tab: TAB,
              resetMapCenterAndZoom: true,
              drawerLeftWide: true,
              pageTitle: TITLE_VOLTAGE_DEVIATION,
            },
            components: { leftPanel: VoltageDeviationLeftPanel },
          },
        ],
      },
    ]
  } else {
    return []
  }
}
