<template>
  <v-app>
    <!-- Top Bar -->
    <ce-top-bar />

    <!-- Content Wrapper -->
    <div
      :style="{
        width: '100%',
        height: '100%',
        paddingTop: TOP_BAR_HEIGHT,
      }"
    >
      <!-- Router View -->
      <router-view />
    </div>

    <!-- Snackbar -->
    <global-snackbar />
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions } from 'pinia'
import { TOP_BAR_HEIGHT } from '@/constants/topBar'
import CeTopBar from '@/components/navigation/CeTopBar.vue'
import GlobalSnackbar from '@/components/GlobalSnackbar.vue'
import { useCustomFiles } from '@/stores/customFiles'
import { useGridMapStore } from './stores/gridMap'

export default defineComponent({
  name: 'App',
  components: { CeTopBar, GlobalSnackbar },
  setup() {
    return { TOP_BAR_HEIGHT }
  },
  methods: {
    ...mapActions(useCustomFiles, ['loadCustomFiles']),
  },
  mounted() {
    this.loadCustomFiles()
  },
})
</script>

<style lang="scss">
@use './styles/utilities.scss';

:root {
  --ce-primary-color: rgba(9, 68, 189, 1);
  --ce-primary-color-very-low-opacity: rgba(9, 68, 189, 0.04);
  --ce-border-color: #d4d4d4;
}

/**
* Removes letter-spacing attribute from all typography classes except button and overline.
*/
body {
  .text-h1,
  .text-h2,
  .text-h3,
  .text-h4,
  .text-h5,
  .text-h6,
  .text-subtitle-1,
  .text-subtitle-2,
  .text-body-1,
  .text-body-2,
  .text-caption {
    letter-spacing: 0em !important;
  }

  /* 
  Vuetify has a hard-coded 16px padding between table cells.
  Reduce it to 8px to give more space for values in the table. 
  */
  .v-data-table {
    > .v-table__wrapper {
      > table {
        > tbody,
        > thead,
        > tfoot {
          > tr {
            > td,
            > th {
              padding: 0 8px;
            }
          }
        }
      }
    }
  }

  a {
    color: var(--ce-primary-color);
    text-decoration: none;
  }

  // Custom color for Radio input labels.
  .v-radio-group {
    .v-selection-control {
      .v-label {
        color: black;
      }
    }
    .v-selection-control:not(.v-selection-control--disabled) {
      .v-label {
        opacity: 1 !important;
      }
    }
  }

  // GMaps > Info window customization: decreases the size of the "X" button.
  div[role='dialog'].gm-style-iw {
    // The "row" where the button exists.
    .gm-style-iw-chr {
      max-height: 24px;

      button {
        width: 24px !important;
        height: 24px !important;

        // The "X" image.
        span {
          width: 16px !important;
          height: 16px !important;
          margin: 4px !important;
        }
      }
    }
  }
}
</style>
