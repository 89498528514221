<template>
  <side-sheet
    :title
    :model-value
    @update:model-value="(newValue) => $emit('update:model-value', newValue)"
  >
    <!-- Body -->
    <form class="c-GridImpactModelMaker px-6" @submit.prevent="submit">
      <!-- Adoption -->
      <fieldset
        aria-label="Adoption parameters"
        class="mb-4"
        :class="wrapperClasses"
        :style="wrapperStyle"
      >
        <!-- EV Adoption -->
        <div role="group" aria-label="EV adoption rate">
          <!-- Title & Tooltip -->
          <div class="d-flex align-center pb-4">
            <!-- Title -->
            <span role="heading" class="d-box text-subtitle-2 mr-2">
              EV adoption rate
            </span>

            <!-- Tooltip -->
            <ce-tooltip :text="evAdoptionTooltip" type="info" />
          </div>

          <!-- Input -->
          <v-text-field
            v-model="formFields.evCagr"
            type="number"
            step="0.1"
            :error="!!evCagrErrors.length"
            :error-messages="evCagrErrors"
            :hide-details="!evCagrErrors.length"
            class="hide-arrows pb-4"
            color="secondary"
            label="CAGR (%)"
            clearable
            @keydown="restrictToFloatInput"
          />
        </div>

        <!-- PV Adoption -->
        <div role="group" aria-label="PV adoption rate">
          <!-- Title & Tooltip -->
          <div class="d-flex align-center pb-4">
            <!-- Title -->
            <span role="heading" class="d-box text-subtitle-2 mr-2">
              PV adoption rate
            </span>

            <!-- Tooltip -->
            <ce-tooltip :text="pvAdoptionTooltip" type="info" />
          </div>

          <!-- Input -->
          <v-text-field
            v-model="formFields.pvCagr"
            type="number"
            step="0.1"
            :error="!!pvCagrErrors.length"
            :error-messages="pvCagrErrors"
            :hide-details="!pvCagrErrors.length"
            class="hide-arrows pb-4"
            color="secondary"
            label="CAGR (%)"
            clearable
            @keydown="restrictToFloatInput"
          />
        </div>

        <!-- Model horizon -->
        <div role="group" aria-label="Model horizon">
          <!-- Title & Tooltip -->
          <div class="d-flex align-center pb-4">
            <!-- Title -->
            <span role="heading" class="d-box text-subtitle-2 mr-2">
              Model horizon
            </span>

            <!-- Tooltip -->
            <ce-tooltip :text="modelHorizonTooltip" type="info" />
          </div>

          <!-- Input -->
          <v-text-field
            aria-label="Model horizon"
            v-model="formFields.until"
            type="number"
            :error="!!untilErrors.length"
            :error-messages="untilErrors"
            :hide-details="!untilErrors.length"
            class="hide-arrows"
            color="secondary"
            label="Until"
            clearable
            @keydown="restrictToFloatInput"
          />
        </div>
      </fieldset>

      <!-- Ev charging session coincidence -->
      <fieldset
        aria-label="Coincidence parameters"
        :class="wrapperClasses"
        :style="wrapperStyle"
      >
        <!-- Title & Tooltip -->
        <div class="d-flex align-center pb-4">
          <!-- Title -->
          <span role="heading" class="d-box text-subtitle-2 mr-2">
            EV charging session coincidence
          </span>

          <!-- Tooltip -->
          <ce-tooltip :text="coincidenceTooltip" type="info" />
        </div>

        <!-- Input -->
        <v-text-field
          v-model="formFields.coincidence"
          type="number"
          step="0.1"
          :error="!!coincidenceFactorErrors.length"
          :error-messages="coincidenceFactorErrors"
          :hide-details="!coincidenceFactorErrors.length"
          class="hide-arrows"
          color="secondary"
          label="Factor (%)"
          clearable
          @keydown="restrictToFloatInput"
        />
      </fieldset>

      <!-- Pre-validation -->
      <span
        v-if="preValidation"
        class="d-block pt-4 text-caption text-error"
        role="alert"
        aria-live="polite"
      >
        {{ preValidation }}
      </span>

      <!-- Action buttons -->
      <div class="d-flex justify-end pt-4">
        <!-- Reset -->
        <v-tooltip :open-delay="OPEN_DELAY" location="bottom">
          <template v-slot:activator="{ props }">
            <button-segmented
              v-bind="props"
              aria-label="Reset"
              icon="$reset"
              class="mr-2"
              :disabled="!isBtnResetEnabled || isLoading"
              :loading="isLoading"
              @click="reset"
            />
          </template>

          <span>Reset</span>
        </v-tooltip>

        <v-btn
          type="submit"
          height="40"
          :disabled="isLoading"
          :loading="isLoading"
          color="primary"
          flat
        >
          Compare
        </v-btn>
      </div>
    </form>
  </side-sheet>
</template>

<script lang="ts">
import {
  defineComponent,
  shallowReactive,
  type PropType,
  type StyleValue,
} from 'vue'
import { GRAY_COOL_50, GRAY_COOL_100 } from '@/constants/colors'
import { OPEN_DELAY } from '@/constants/tooltip'
import { emitsBoolean } from '@/utils/emits'
import { restrictToFloatInput } from '@/utils/input'
import { ModelMakerFormFields } from '@/model/grid/modelMakerFormFields'
import SideSheet from '@/components/navigation/SideSheet.vue'
import CeTooltip from '@/components/CeTooltip.vue'
import ButtonSegmented from '@/components/common/ButtonSegmented.vue'
import { ModelMaker } from 'rfs/frontend/proto/analysis_pb'

export default defineComponent({
  name: 'GridImpactModelMaker',
  props: {
    /** * v-model */
    modelValue: {
      type: Boolean,
      required: false,
      default: false,
    },
    initialModelParams: {
      type: Object as PropType<ModelMaker>,
      required: false,
      default: () => new ModelMaker(),
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: {
    'update:model-value': emitsBoolean,
    'new-model-params': (newValue?: ModelMaker) => {
      return newValue instanceof ModelMaker
    },
  },
  components: { SideSheet, CeTooltip, ButtonSegmented },
  setup() {
    const wrapperClasses = ['pa-4', 'rounded']
    const wrapperStyle: StyleValue = {
      'background-color': GRAY_COOL_50.hex,
      border: `1px solid ${GRAY_COOL_100.hex}`,
    }

    return {
      OPEN_DELAY,
      restrictToFloatInput,
      wrapperClasses,
      wrapperStyle,
      title: 'Model Maker',
      evAdoptionTooltip:
        'Model EV growth over a specified timeframe to see the hypothetical impacts relative to the current time window.',
      pvAdoptionTooltip:
        'Model PV growth over a specified timeframe to see the hypothetical impacts relative to the current time window.',
      modelHorizonTooltip: '',
      coincidenceTooltip:
        'Charging session coincidence reflects observed coincident EV charging patterns per feeder peak. Explore alternative scenarios with or without changes to EV adoption.',
    }
  },
  data() {
    const formFields = new ModelMakerFormFields(this.initialModelParams)

    return shallowReactive({
      isBtnResetEnabled: !formFields.isNoneFilled(),
      formFields: shallowReactive(formFields),
      formValidation: undefined as
        | undefined
        | ReturnType<typeof ModelMakerFormFields.prototype.validate>,
    })
  },
  computed: {
    preValidation(): undefined | string {
      return this.formValidation?.preValidation
    },
    evCagrErrors(): string[] {
      return this.formValidation?.evCagr ?? []
    },
    pvCagrErrors(): string[] {
      return this.formValidation?.pvCagr ?? []
    },
    untilErrors(): string[] {
      return this.formValidation?.until ?? []
    },
    coincidenceFactorErrors(): string[] {
      return this.formValidation?.coincidence ?? []
    },
  },
  methods: {
    reset(): void {
      this.isBtnResetEnabled = false

      // Reset the fields.
      this.formFields.evCagr = ''
      this.formFields.pvCagr = ''
      this.formFields.until = ''
      this.formFields.coincidence = ''

      // Emit.
      this.$emit('new-model-params')
    },
    submit(): void {
      this.formValidation = this.formFields.validate()
      if (this.formValidation.isValid) {
        this.isBtnResetEnabled = true
        this.$emit('new-model-params', this.formFields.generateModelMaker())
      }
    },
  },
})
</script>

<style lang="scss">
.c-GridImpactModelMaker {
  .v-input__control {
    // Applies the expected size only to the input field. This way the validation
    // messages can be 100%.
    width: 50%;
  }

  .v-input__details {
    padding-right: 0;
    padding-left: 0;
  }
}
</style>
