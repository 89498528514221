import { PlainMessage } from '@bufbuild/protobuf'
import {
  createPromiseClient,
  PromiseClient,
  Transport,
} from '@connectrpc/connect'

import { ResourceType } from '@/constants/resourceType'
import { makeId } from '@/model/resource'
import { Grid } from 'rfs/frontend/proto/grid_connect'
import {
  SummaryStats,
  SummaryStatsAll,
  TableRequest,
  TableRequest_Type as TableRequestType,
} from 'rfs/frontend/proto/grid_pb'
import { FilterBy, OrderBy } from 'rfs/frontend/proto/pagination_pb'

export type DersTableRequest = Omit<PlainMessage<TableRequest>, 'tableType'>

interface PageOptions {
  page: number
  itemsPerPage: number
}

export class GridService {
  private readonly client: PromiseClient<typeof Grid>

  constructor(transport: Transport) {
    this.client = createPromiseClient(Grid, transport)
  }

  get summarize() {
    return this.client.summarize.bind(this.client)
  }

  public async dersTable(req: DersTableRequest) {
    return this.client.resourcesTable({
      ...req,
      tableType: TableRequestType.DERS,
    })
  }

  public async allSubstationSummary(): Promise<SummaryStatsAll> {
    return this.client
      .summarizeAll({ downline: ResourceType.SUBSTATION })
      .then((s) => makeIds(ResourceType.SUBSTATION, s))
  }

  public async allFeederSummary(): Promise<SummaryStatsAll> {
    return this.client
      .summarizeAll({ downline: ResourceType.FEEDER })
      .then((s) => makeIds(ResourceType.FEEDER, s))
  }

  public async substationSummary(sub: string): Promise<SummaryStats> {
    return this.client.summarize({
      downline: makeId(ResourceType.SUBSTATION, sub),
    })
  }

  public async feederSummary(feeder: string): Promise<SummaryStats> {
    return this.client.summarize({
      downline: makeId(ResourceType.FEEDER, feeder),
    })
  }

  public async downlineMetersTable(
    resource: string,
    pageOptions: PageOptions,
    filters: PlainMessage<FilterBy>[],
    sortOrder: PlainMessage<OrderBy>
  ) {
    return this.client.downlineResourcesTable({
      uplineResource: resource,
      resourceType: ResourceType.METER_ELECTRICAL,
      limit: pageOptions.itemsPerPage,
      offset: (pageOptions.page - 1) * pageOptions.itemsPerPage,
      filterBy: filters,
      orderBy: sortOrder,
    })
  }
}

/**
 * RFS returns the IDs referenced in the `upline` property.
 * Replace them with complete resource IDs so it's easier to pair the stats
 * with the substation & feeder resource.
 */
function makeIds(r: ResourceType, summary: SummaryStatsAll): SummaryStatsAll {
  const entries = Object.entries(summary.statistics)
  summary.statistics = Object.fromEntries(
    entries.map(([id, stats]) => [makeId(r, id), stats])
  )
  return Object.freeze(summary) as SummaryStatsAll
}
