import { type DateTime } from 'luxon'
import { PACIFIC, PURPLE1 } from '@/constants/colors'
import { Metric } from '@/constants/metrics'
import { ResourceType } from '@/constants/resourceType'
import type { Services } from '@/services'
import { limitInterval } from '@/utils/time'
import {
  AggregateDataSource,
  type DataProvider,
  type ITimeSeriesDataSource,
} from '@/model/charts'
import { ONE_TILE_SIZE } from '@/model/charts/TimeSeriesDataSource'
import { createCutOffDate } from '@/model/control'
import {
  activePowerChartDefinition,
  reactivePowerChartDefinition,
  createActivePowerTimeSeriesConfig,
  createReactivePowerTimeSeriesConfig,
  socPercentageChartDefinition,
  socTimeSeriesConfig,
  transformerLoadingChartDefinition,
} from '@/model/control/telemetry'
import { loadPercentConfig } from '@/model/transformer'
import { createOperatingEnvelpeDataSource } from '@/model/control/operatingEnvelope'
import { TimeSeriesWithRefreshDataSource } from '@/model/charts/TimeSeriesWithRefreshDataSource'
import type { Group } from 'rfs/control/proto/model_pb'
import type { Resource } from 'rfs/pb/resource_pb'
import { Resolution } from 'rfs/frontend/proto/resolution_pb'

// NOTE(rafael): The resolution for device telemetry is raw.
export const DATA_POINT_AGE_LIMIT_MINUTES = 65

export function newDeviceTelemetryDataSource(
  services: Services,
  group: Group,
  resource: Resource,
  showSoc: boolean,
  showReactivePower: boolean,
  showOperatingEnvelope: boolean,
  getNow: () => DateTime
): ITimeSeriesDataSource {
  const dataProvider: DataProvider = async (request) => {
    request.interval = limitInterval(request.interval, getNow())
    // Truncates the timestamps, ensuring 1-minute intervals.
    request.resolution = Resolution.ONE_MINUTE
    return services.chartsService.fetchTimeSeries(resource.id, request)
  }

  const ds = new TimeSeriesWithRefreshDataSource(
    dataProvider,
    createCutOffDate(getNow())
  )

  // Active Power.
  ds.addChartSeries(
    activePowerChartDefinition,
    createActivePowerTimeSeriesConfig(group)
  )

  // Reactive Power.
  if (showReactivePower) {
    ds.addChartSeries(
      reactivePowerChartDefinition,
      createReactivePowerTimeSeriesConfig()
    )
  }

  // SoC.
  if (showSoc) {
    ds.addChartSeries(socPercentageChartDefinition, socTimeSeriesConfig)
  }

  // Operating Envelope.
  if (showOperatingEnvelope) {
    return new AggregateDataSource(
      ds,
      createOperatingEnvelpeDataSource(
        services,
        [resource.id],
        activePowerChartDefinition,
        getNow,
        resource.type as ResourceType
      )
    )
  }

  return ds
}

export function newTransformerDataSource(
  services: Services,
  transformerId: string,
  getNow: () => DateTime
): ITimeSeriesDataSource {
  const cutOff = createCutOffDate(getNow())

  const ds = new TimeSeriesWithRefreshDataSource((request) => {
    request.aggregation = ResourceType.METER_ELECTRICAL
    request.resolution = Resolution.ONE_HOUR
    return services.chartsService.fetchTimeSeries(transformerId, request)
  }, cutOff)

  // Load %.
  ds.addChartSeries(transformerLoadingChartDefinition, loadPercentConfig)

  // Uncontrollable load.
  ds.addChartSeries(transformerLoadingChartDefinition, {
    metric: Metric.FORECAST_ACTUAL_COND_POWER_APPARENT,
    config: {
      seriesName: 'Uncontrollable load',
      seriesColor: PACIFIC.hex,
      stackGroup: 'group1',
      seriesFill: 'origin',
    },
  })

  // Controllable load.
  ds.addChartSeries(transformerLoadingChartDefinition, {
    metric: Metric.CONTROLLABLE_COND_POWER_APPARENT,
    config: {
      seriesName: 'Controllable load',
      seriesColor: PURPLE1.hex,
      stackGroup: 'group1',
      seriesFill: 'prev',
    },
  })

  // Forecast.
  ds.addChartSeries(transformerLoadingChartDefinition, {
    metric: Metric.FORECAST_LATEST_COND_POWER_APPARENT,
    config: {
      seriesName: 'Forecast',
      seriesColor: PACIFIC.hex,
      seriesLine: 'dashed',
    },
  })

  return ds
}

export function createCurtailmentDatasource(
  services: Services,
  resource: Resource,
  showOperatingEnvelope: boolean,
  getNow: () => DateTime
): ITimeSeriesDataSource {
  const dataProvider: DataProvider = async (request) => {
    // Truncates the timestamps, ensuring 1-minute intervals.
    request.resolution = Resolution.ONE_MINUTE
    return services.chartsService.fetchTimeSeries(resource.id, request)
  }

  const ds = new TimeSeriesWithRefreshDataSource(
    dataProvider,
    getNow(),
    ONE_TILE_SIZE
  )

  // Curtailment (observed).
  ds.addChartSeries(transformerLoadingChartDefinition, {
    metric: Metric.COMPUTED_CURTAILMENT_OBSERVED,
    config: {
      seriesName: 'Curtailment (observed)',
      seriesColor: '#18440b',
      hideFromTooltip: true,
    },
  })

  // Curtailment (forecasted).
  if (showOperatingEnvelope) {
    ds.addChartSeries(transformerLoadingChartDefinition, {
      metric: Metric.COMPUTED_CURTAILMENT_FORECASTED,
      config: {
        seriesName: 'Curtailment (forecasted)',
        seriesColor: '#349318',
        hideFromTooltip: true,
      },
    })
  }

  return ds
}
