import { ResourceType } from '@/constants/resourceType'

export const DEFAULT_GMAPS_INITIAL_ZOOM_LEVEL = 10
/** This minimum allows seeing the whole United States in a full-screen window. */
export const DEFAULT_GMAPS_MINIMUM_ZOOM_LEVEL = 5

export const DEFAULT_GMAPS_TILT = 47.5

/**
 * This constant is used to size icons/circles for layers that use `sizeUnits: 'common'`.
 * Having a common divisor makes icons grow or shrink uniformly across all layers.
 */
export const COMMON_SIZE_DIVISOR = 2 ** 16
export const COMMON_SIZE_DIVISOR_14 = 2 ** 14
export const COMMON_SIZE_DIVISOR_13 = 2 ** 13

export const ICON_SIZE = 24
export const ICON_MAX_PIXELS = 24

export const ICON_OFFSET_NONE: [number, number] = [0, 0]
export const ICON_OFFSET_TOP: [number, number] = [0, -(ICON_MAX_PIXELS / 2)]
export const ICON_OFFSET_LEFT: [number, number] = [-(ICON_MAX_PIXELS / 2), 0]
export const ICON_OFFSET_RIGHT: [number, number] = [+(ICON_MAX_PIXELS / 2), 0]

export const GMAPS_BOTTOM_CONTROL_HEIGHT = 12

/** Use a 512x512 tile by default */
export const TILE_SIZE = 2 ** 9

/* Zoom Levels for various resources */

export const ZOOM_DEFAULT = DEFAULT_GMAPS_INITIAL_ZOOM_LEVEL
/** Shows 3-phase primary conductors */
export const ZOOM_CONDUCTORS_LOW = DEFAULT_GMAPS_INITIAL_ZOOM_LEVEL
/** Shows all primary conductors */
export const ZOOM_CONDUCTORS_MED = 13
/** Shows all conductors (primary & secondary) */
export const ZOOM_CONDUCTORS_HIGH = 16
export const ZOOM_TRANSFORMERS = 15
export const ZOOM_METERS = 16

/** These can be used for resources that should be visible on Overview */
export const ZOOM_OVERVIEW = ZOOM_DEFAULT - 2
/** Use with `ZOOM_OVERVIEW` to minimize tile RPCs */
export const TILE_SIZE_2X = TILE_SIZE * 2
