<template>
  <v-hover>
    <template v-slot:default="{ isHovering, props }">
      <div
        v-bind="props"
        class="d-flex align-center"
        :class="column.align === 'end' ? 'justify-end' : null"
      >
        <!-- Column Title -->
        <div
          class="d-flex align-center"
          :class="column.align === 'end' ? 'order-2' : null"
        >
          <slot name="before-title"></slot>
          <span>{{ column.title }}</span>
          <!-- Tooltip -->
          <div v-if="column.tooltip" class="d-flex align-center pl-1">
            <ce-tooltip :text="column.tooltip" type="info" />
          </div>
          <slot name="after-title"></slot>
        </div>

        <!-- Sort icon -->
        <v-icon
          v-if="column.sortable"
          v-show="isSorted(column) || isHovering"
          :class="column.align === 'end' ? 'order-1' : null"
          :style="{
            opacity: isHovering && !isSorted(column) ? '0.5' : '1',
          }"
          :icon="getSortIcon(column)"
        />
      </div>
    </template>
  </v-hover>
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'
import type { Header } from '@/model/tables/DataTable'
import CeTooltip from '@/components/CeTooltip.vue'

export default defineComponent({
  name: 'DataTableCustomHeader',
  props: {
    column: {
      type: Object as PropType<Header>,
      required: true,
    },
    getSortIcon: {
      type: Function as PropType<(column: Header) => any>,
      required: true,
    },
    isSorted: {
      type: Function as PropType<(column: Header) => boolean>,
      required: true,
    },
  },
  components: { CeTooltip },
})
</script>
