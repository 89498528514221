import type { DateTime } from 'luxon'
import type { RittaConfig } from '@/config'
import type { Services } from '@/services'
import { MetricCalculation } from '@/services/charts.service'
import { WALLABY } from '@/constants/colors'
import { ResourceType } from '@/constants/resourceType'
import { Metric } from '@/constants/metrics'
import { titleCase } from '@/utils/formatText'
import type { DataProvider, ITimeSeriesDataSource } from '@/model/charts'
import { ONE_TILE_SIZE } from '@/model/charts/TimeSeriesDataSource'
import { TimeSeriesWithRefreshDataSource } from '@/model/charts/TimeSeriesWithRefreshDataSource'
import { createCutOffDate } from '@/model/control'
import type { Device } from 'rfs/control/proto/model_pb'
import type { ChartDefinition } from '@/types/charts'

export enum OperatingEnvelopeStatus {
  UNSPECIFIED = 'UNSPECIFIED',
  ACTIVE = 'ACTIVE',
  MIXED = 'MIXED',
  INACTIVE = 'INACTIVE',
  OVERRIDEN = 'OVERRIDEN',
}

export function getOELabel(status: OperatingEnvelopeStatus): string {
  return titleCase(OperatingEnvelopeStatus[status])
}

export function getGroupCurrentEnvelopeStatus(
  groupId: string,
  devices: Device[]
): OperatingEnvelopeStatus {
  const filteredDevices = devices.filter((d) => d.groupId === groupId)

  if (!filteredDevices.length) {
    return OperatingEnvelopeStatus.UNSPECIFIED
  } else if (filteredDevices.every((d) => d.oeEnabled)) {
    return OperatingEnvelopeStatus.ACTIVE
  } else if (filteredDevices.every((d) => !d.oeEnabled)) {
    return OperatingEnvelopeStatus.INACTIVE
  } else {
    return OperatingEnvelopeStatus.MIXED
  }
}

export function getDeviceCurrentEnvelopeStatus(
  device?: null | Device
): OperatingEnvelopeStatus {
  if (device == null) {
    return OperatingEnvelopeStatus.UNSPECIFIED
  } else if (device.oeEnabled) {
    return OperatingEnvelopeStatus.ACTIVE
  } else {
    return OperatingEnvelopeStatus.INACTIVE
  }
}

/**
 * Returns true if the Operating Envelope feature is enabled in RFA.
 */
export function isOEEnabled(config: Readonly<RittaConfig>): boolean {
  return !!config.control?.operatingEnvelopes?.enabled
}

export function createOperatingEnvelpeDataSource(
  services: Services,
  resourceIds: string[],
  chart: ChartDefinition,
  getNow: () => DateTime,
  rt?: ResourceType
): ITimeSeriesDataSource {
  const dataProvider: DataProvider = async (request) =>
    services.chartsService.fetchMultiTimeSeries(resourceIds, request)

  const ds = new TimeSeriesWithRefreshDataSource(
    dataProvider,
    createCutOffDate(getNow()),
    ONE_TILE_SIZE
  )

  const metric =
    rt === ResourceType.SOLAR_DISTRIBUTED
      ? Metric.ENVELOPE_POWER_PRODUCING
      : Metric.COMPUTED_ENVELOPE_POWER_CONSUMING

  ds.addChartSeries(chart, {
    metric,
    calculation: MetricCalculation.SUM,
    config: {
      seriesName: 'Operating Envelope',
      seriesColor: WALLABY.hex,
      backgroundGradient: true,
      seriesLineWidth: 0,
      steppedLine: 'after',
    },
  })

  return ds
}
