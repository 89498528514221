// Attributes of generic resources in Ritta.
//
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// ACHTUNG! If you're new to resource modeling at Camus, there's a lot to learn,
// which can be slow and painful. It's WAY better to ask for expert help on the
// #eng-modeling channel in Slack. Operators are standing by to help figure out
// your data modeling challenges.
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//
// Generic container for document-oriented data in Camus data pipeline and
// serving system.
//
// Resources describe the electrical grid, broadband network, service
// customers, provider employees, and supporting model elements (e.g. street
// addresses). In the Ritta model, a 'resource' is any type of element,
// e.g. transformer, router, employee, meter reading, outage, or vehicle
// registration.
//
// Resources are identified by strings, which resemble relative paths as in
// URLs, e.g. "transformer/distribution/T4191", "account/customer/80a46d".
//
// See conventions for units of measure: https://go.grid.dev/units
//
// In electrical engineering, angular distance is almost always expressed in
// degrees, rather than the SI unit (radians), so a suffix-free field would lead
// to frequent errors. So all angles are suffixed with _deg and measured in
// degrees.
//
// Otherwise field names should avoid using underscores in their names, since
// protobuf code generation in different languages handles them differently,
// leading to confusion when working across languages. Use nested messages
// instead, e.g. no `real_power`, yes `power.real`.
//
// Points in time use google.protobuf.Timestamp, and have verb form names, or
// proximate adjective if the verb form is too awkward (e.g. effective vs
// effected). Past tense (e.g. snapped) refers to observations and present tense
// (e.g. expires) refers to expectations or plans.
//
// For fields that need to distinguish the default value (0 or "") from unset,
// use the "optional" tag. Older fields can continue to use standard scalar
// wrappers, e.g. google.protobuf.DoubleValue or google.protobuf.StringValue.
// This is necessary because Proto3 does not record presence of scalar fields
// by default, although it does for message fields.
//
// Enums should always use an "{TYPE}_UNSPECIFIED" code as their zero value,
// and can use the "optional" tag when it's necessary to distinguish that value
// from unset. This code should represent the situation where the field is
// definitely not applicable, not defined, not provided, or not measured.
//
// This particular proto represents both version 2 and version 4 of the common
// model. See bando/resources/v4.md for more detailed documentation, including
// variations between customers, and bando/doc/resources.md for v2. This model
// will continue to evolve over time, and users should be prepared to keep up.
//
// This proto does not use oneof, in part because they are not supported in
// corresponding data structures. Moreover, this specification exists to define
// the meaning of individual fields, and not prohibited combinations or other
// higher level constraints. So the sole purpose of a oneof is out of scope.
//
// Footnotes are denoted with integers in square brackets, e.g. [3], and
// expanded at the end of this file.
//
// Author: Cody Smith <cody@camus.energy>

// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file pb/resource.proto (package ritta.pb, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { BoolValue, DoubleValue, Duration, FloatValue, Int32Value, Int64Value, proto3, StringValue, Timestamp } from "@bufbuild/protobuf";
import { Date } from "./calendar_pb.js";

/**
 * Part of the electrical system to which a resource belongs
 *
 * @generated from enum ritta.pb.GridLevel
 */
export const GridLevel = /*@__PURE__*/ proto3.makeEnum(
  "ritta.pb.GridLevel",
  [
    {no: 0, name: "GRIDLEVEL_UNSPECIFIED"},
    {no: 1, name: "DISTRIBUTION_PRIMARY"},
    {no: 2, name: "DISTRIBUTION_SECONDARY"},
    {no: 3, name: "TRANSMISSION"},
  ],
);

/**
 * Whether a resource does its job. Note that configuration and conditions are
 * often different; in other words, the intended state of a device might be
 * active, but the actual state inactive.
 *
 * @generated from enum ritta.pb.Operation
 */
export const Operation = /*@__PURE__*/ proto3.makeEnum(
  "ritta.pb.Operation",
  [
    {no: 0, name: "OPERATION_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "OPERATION_ACTIVE", localName: "ACTIVE"},
    {no: 2, name: "OPERATION_INACTIVE", localName: "INACTIVE"},
  ],
);

/**
 * Whether a resource is theoretically capable of operating, without repairing
 * the device itself. For instance, a service meter in an area experiencing a
 * power outage has capability intact, configured operation active, and
 * condition of operation inactive.
 *
 * @generated from enum ritta.pb.Capability
 */
export const Capability = /*@__PURE__*/ proto3.makeEnum(
  "ritta.pb.Capability",
  [
    {no: 0, name: "CAPABILITY_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "CAPABILITY_INTACT", localName: "INTACT"},
    {no: 2, name: "CAPABILITY_BROKEN", localName: "BROKEN"},
  ],
);

/**
 * State of electrical connection in a toggleable device, e.g. switch or fuse.
 *
 * @generated from enum ritta.pb.Contacts
 */
export const Contacts = /*@__PURE__*/ proto3.makeEnum(
  "ritta.pb.Contacts",
  [
    {no: 0, name: "CONTACTS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "CONTACTS_CONNECTED", localName: "CONNECTED"},
    {no: 2, name: "CONTACTS_DISCONNECTED", localName: "DISCONNECTED"},
  ],
);

/**
 * How a device is connected. Typically used for specifying three phase transformer
 * winding configurations.
 *
 * @generated from enum ritta.pb.Connection
 */
export const Connection = /*@__PURE__*/ proto3.makeEnum(
  "ritta.pb.Connection",
  [
    {no: 0, name: "CONNECTION_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "CONNECTION_WYE", localName: "WYE"},
    {no: 2, name: "CONNECTION_DELTA", localName: "DELTA"},
  ],
);

/**
 * What holds a device in place.
 *
 * @generated from enum ritta.pb.Mounting
 */
export const Mounting = /*@__PURE__*/ proto3.makeEnum(
  "ritta.pb.Mounting",
  [
    {no: 0, name: "MOUNTING_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "MOUNTING_POLE", localName: "POLE"},
    {no: 2, name: "MOUNTING_PAD", localName: "PAD"},
    {no: 3, name: "MOUNTING_RACK", localName: "RACK"},
    {no: 4, name: "MOUNTING_TRACKER", localName: "TRACKER"},
    {no: 5, name: "MOUNTING_ROOF", localName: "ROOF"},
  ],
);

/**
 * @generated from enum ritta.pb.Tracking
 */
export const Tracking = /*@__PURE__*/ proto3.makeEnum(
  "ritta.pb.Tracking",
  [
    {no: 0, name: "TRACKING_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "TRACKING_FIXED", localName: "FIXED"},
    {no: 2, name: "TRACKING_SINGLE_AXIS", localName: "SINGLE_AXIS"},
    {no: 3, name: "TRACKING_DUAL_AXIS", localName: "DUAL_AXIS"},
  ],
);

/**
 * Presence or absence of more than one instance, e.g. used for multi-unit
 * residential buildings.
 *
 * @generated from enum ritta.pb.Composition
 */
export const Composition = /*@__PURE__*/ proto3.makeEnum(
  "ritta.pb.Composition",
  [
    {no: 0, name: "COMPOSITION_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "COMPOSITION_SINGLE", localName: "SINGLE"},
    {no: 2, name: "COMPOSITION_MULTIPLE", localName: "MULTIPLE"},
  ],
);

/**
 * Utility describes the conveyance of energy.
 *
 * @generated from enum ritta.pb.Utility
 */
export const Utility = /*@__PURE__*/ proto3.makeEnum(
  "ritta.pb.Utility",
  [
    {no: 0, name: "UTILITY_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "UTILITY_ELECTRICAL", localName: "ELECTRICAL"},
    {no: 2, name: "UTILITY_NATURAL_GAS", localName: "NATURAL_GAS"},
  ],
);

/**
 * Propulsion describes how a device creates movement.
 *
 * @generated from enum ritta.pb.Propulsion
 */
export const Propulsion = /*@__PURE__*/ proto3.makeEnum(
  "ritta.pb.Propulsion",
  [
    {no: 0, name: "PROPULSION_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "PROPULSION_COMBUSTION_ENGINE", localName: "COMBUSTION_ENGINE"},
    {no: 2, name: "PROPULSION_ELECTRIC_MOTOR", localName: "ELECTRIC_MOTOR"},
    {no: 10, name: "PROPULSION_HYBRID", localName: "HYBRID"},
  ],
);

/**
 * Fuel refers to material fed into a propulsion device. Note that "electricity"
 * is not considered a fuel. See PROPULSION_ELECTRIC_MOTOR above.
 *
 * @generated from enum ritta.pb.Fuel
 */
export const Fuel = /*@__PURE__*/ proto3.makeEnum(
  "ritta.pb.Fuel",
  [
    {no: 0, name: "FUEL_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "FUEL_GASOLINE", localName: "GASOLINE"},
    {no: 2, name: "FUEL_DIESEL", localName: "DIESEL"},
    {no: 3, name: "FUEL_HYDROGEN", localName: "HYDROGEN"},
    {no: 4, name: "FUEL_COMPRESSED_NATURAL_GAS", localName: "COMPRESSED_NATURAL_GAS"},
    {no: 5, name: "FUEL_LIQUEFIED_NATURAL_GAS", localName: "LIQUEFIED_NATURAL_GAS"},
    {no: 6, name: "FUEL_LIQUID_PROPANE", localName: "LIQUID_PROPANE"},
  ],
);

/**
 * How data was first created. This can be used for whole records, as in
 * record.genesis, individual static fields, as in field.genesis, or individual
 * timeseries values, for which field.genesis is also used.
 *
 * These codes are intentionally vague, because it's meant to simply flag
 * values in the Ritta UI, and not to aid detailed investigation of these
 * processes. For example, this could be used to flag figures in Ritta
 * generated by nowcasting.
 *
 * @generated from enum ritta.pb.Genesis
 */
export const Genesis = /*@__PURE__*/ proto3.makeEnum(
  "ritta.pb.Genesis",
  [
    {no: 0, name: "GENESIS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "GENESIS_INSTALLATION", localName: "INSTALLATION"},
    {no: 2, name: "GENESIS_REGISTRATION", localName: "REGISTRATION"},
    {no: 6, name: "GENESIS_OBSERVATION", localName: "OBSERVATION"},
    {no: 3, name: "GENESIS_DETECTION", localName: "DETECTION"},
    {no: 7, name: "GENESIS_INFERENCE", localName: "INFERENCE"},
    {no: 8, name: "GENESIS_ASSUMPTION", localName: "ASSUMPTION"},
    {no: 4, name: "GENESIS_PREDICTION", localName: "PREDICTION"},
    {no: 5, name: "GENESIS_PLANNING", localName: "PLANNING"},
  ],
);

/**
 * @generated from message ritta.pb.Resource
 */
export const Resource = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Resource",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "level", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "location", kind: "message", T: Location },
    { no: 6, name: "grid", kind: "message", T: Grid },
    { no: 7, name: "transmission", kind: "message", T: Transmission },
    { no: 8, name: "upline", kind: "message", T: Upline },
    { no: 9, name: "downline", kind: "message", T: Downline },
    { no: 54, name: "circuit", kind: "message", T: Circuit },
    { no: 61, name: "constituting", kind: "message", T: Constituting },
    { no: 62, name: "components", kind: "message", T: Components },
    { no: 58, name: "zone", kind: "message", T: NamedString, repeated: true },
    { no: 10, name: "device", kind: "message", T: Device },
    { no: 15, name: "ratings", kind: "message", T: Ratings },
    { no: 63, name: "config", kind: "message", T: Config },
    { no: 59, name: "efficiency", kind: "message", T: Efficiency },
    { no: 60, name: "limits", kind: "message", T: Limits },
    { no: 16, name: "manufacture", kind: "message", T: Manufacture },
    { no: 17, name: "readings", kind: "message", T: Readings },
    { no: 70, name: "tap", kind: "message", T: Tap },
    { no: 11, name: "conductor", kind: "message", T: Conductor },
    { no: 12, name: "generator", kind: "message", T: Generator },
    { no: 13, name: "meter", kind: "message", T: Meter },
    { no: 14, name: "vehicle", kind: "message", T: Vehicle },
    { no: 42, name: "interconnection", kind: "message", T: Interconnection },
    { no: 55, name: "registration", kind: "message", T: Registration },
    { no: 72, name: "transformer", kind: "message", T: Transformer },
    { no: 18, name: "site", kind: "message", T: Site },
    { no: 45, name: "parcel", kind: "message", T: Parcel },
    { no: 19, name: "building", kind: "message", T: Building },
    { no: 20, name: "industry", kind: "message", T: Industry },
    { no: 21, name: "jurisdiction", kind: "message", T: Jurisdiction },
    { no: 22, name: "tract", kind: "message", T: Tract },
    { no: 47, name: "gis", kind: "message", T: GIS },
    { no: 49, name: "appliance", kind: "message", T: Appliance },
    { no: 37, name: "subscription", kind: "message", T: Subscription },
    { no: 23, name: "provider", kind: "message", T: Provider },
    { no: 24, name: "programs", kind: "message", T: Program, repeated: true },
    { no: 25, name: "billing", kind: "message", T: Billing },
    { no: 26, name: "payments", kind: "message", T: Payments },
    { no: 38, name: "usage", kind: "message", T: Usage },
    { no: 43, name: "energy", kind: "message", T: Energy },
    { no: 48, name: "aggregators", kind: "message", T: Aggregator, repeated: true },
    { no: 57, name: "dependency", kind: "message", T: Dependency },
    { no: 27, name: "tax", kind: "message", T: Tax },
    { no: 28, name: "owner", kind: "message", T: Owner },
    { no: 29, name: "phone", kind: "message", T: Phone },
    { no: 30, name: "email", kind: "message", T: Email },
    { no: 31, name: "language", kind: "message", T: Language },
    { no: 32, name: "work", kind: "message", T: Work },
    { no: 44, name: "project", kind: "message", T: Project },
    { no: 51, name: "events", kind: "message", T: Events },
    { no: 56, name: "conditions", kind: "message", T: ConditionsV4 },
    { no: 66, name: "sensor", kind: "message", T: Sensor },
    { no: 67, name: "intentions", kind: "message", T: Intentions },
    { no: 68, name: "scheduling", kind: "message", T: Scheduling },
    { no: 71, name: "controls", kind: "message", T: Controls },
    { no: 36, name: "caltrack", kind: "message", T: Caltrack },
    { no: 65, name: "prediction", kind: "message", T: Prediction },
    { no: 33, name: "start", kind: "message", T: Timestamp },
    { no: 34, name: "end", kind: "message", T: Timestamp },
    { no: 40, name: "incident", kind: "message", T: Incident },
    { no: 41, name: "fire", kind: "message", T: Fire },
    { no: 64, name: "alert", kind: "message", T: Alert },
    { no: 46, name: "record", kind: "message", T: Record },
    { no: 52, name: "field", kind: "message", T: Field, repeated: true },
    { no: 50, name: "reference", kind: "message", T: NamedString, repeated: true },
    { no: 53, name: "input", kind: "message", T: Input },
    { no: 35, name: "unknown", kind: "message", T: Unknown, repeated: true },
    { no: 69, name: "x", kind: "message", T: NamedString, repeated: true },
  ],
);

/**
 * ConditionsV4 contains instantaneous readings from instrumentation under the
 * v4 Resource model, used mostly by SCADA. See Grid.conditions for older v2
 * fields with similar meanings, and see Energy for meter readings.
 *
 * @generated from message ritta.pb.ConditionsV4
 */
export const ConditionsV4 = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.ConditionsV4",
  () => [
    { no: 1, name: "observed", kind: "message", T: Timestamp },
    { no: 2, name: "a", kind: "message", T: PhaseConditions },
    { no: 3, name: "b", kind: "message", T: PhaseConditions },
    { no: 4, name: "c", kind: "message", T: PhaseConditions },
    { no: 5, name: "neutral", kind: "message", T: NeutralConditions },
    { no: 7, name: "phases", kind: "message", T: PhaseSummaryConditions },
    { no: 8, name: "voltage", kind: "message", T: DoubleValue },
    { no: 9, name: "current", kind: "message", T: CurrentConditions },
    { no: 10, name: "power", kind: "message", T: PowerConditions },
    { no: 15, name: "terminal", kind: "message", T: ConditionsV4_Terminals },
    { no: 14, name: "charge", kind: "message", T: ChargeConditions },
    { no: 11, name: "frequency", kind: "message", T: DoubleValue },
    { no: 12, name: "temperature", kind: "message", T: ThermalConditions },
    { no: 13, name: "tap", kind: "message", T: TapConditions },
    { no: 16, name: "contacts", kind: "message", T: ContactConditions },
  ],
);

/**
 * Conditions for common types of electrical connection points
 *
 * @generated from message ritta.pb.ConditionsV4.Terminals
 */
export const ConditionsV4_Terminals = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.ConditionsV4.Terminals",
  () => [
    { no: 1, name: "pv", kind: "message", T: TerminalConditions },
  ],
  {localName: "ConditionsV4_Terminals"},
);

/**
 * Conditions for a single electrical connection point of a device
 *
 * @generated from message ritta.pb.TerminalConditions
 */
export const TerminalConditions = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.TerminalConditions",
  () => [
    { no: 1, name: "voltage", kind: "message", T: DoubleValue },
  ],
);

/**
 * Conditions for internal disconnectable points, e.g. for switches or breakers
 *
 * @generated from message ritta.pb.ContactConditions
 */
export const ContactConditions = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.ContactConditions",
  () => [
    { no: 1, name: "closed", kind: "message", T: BoolValue },
  ],
);

/**
 * State of energy stored, e.g. in a battery
 *
 * @generated from message ritta.pb.ChargeConditions
 */
export const ChargeConditions = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.ChargeConditions",
  () => [
    { no: 1, name: "state", kind: "message", T: DoubleValue },
    { no: 2, name: "level", kind: "message", T: DoubleValue },
  ],
);

/**
 * Temperature readings from thermometers
 *
 * @generated from message ritta.pb.ThermalConditions
 */
export const ThermalConditions = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.ThermalConditions",
  () => [
    { no: 1, name: "device", kind: "message", T: DoubleValue },
    { no: 2, name: "ambient", kind: "message", T: DoubleValue },
  ],
);

/**
 * State information for variable transformers
 *
 * @generated from message ritta.pb.TapConditions
 */
export const TapConditions = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.TapConditions",
  () => [
    { no: 1, name: "position", kind: "message", T: DoubleValue },
  ],
);

/**
 * Transformer tap configurations
 *
 * @generated from message ritta.pb.Tap
 */
export const Tap = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Tap",
  () => [
    { no: 1, name: "nominal", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "min", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "max", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "step", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

/**
 * Electrical configuration of one phase in a multi-phase system.
 *
 * @generated from message ritta.pb.PhaseConfig
 */
export const PhaseConfig = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.PhaseConfig",
  () => [
    { no: 1, name: "voltage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * Electrical conditions of one phase in a multi-phase system.
 *
 * @generated from message ritta.pb.PhaseConditions
 */
export const PhaseConditions = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.PhaseConditions",
  () => [
    { no: 1, name: "voltage", kind: "message", T: PhaseVoltageConditions },
    { no: 2, name: "current", kind: "message", T: CurrentConditions },
    { no: 3, name: "power", kind: "message", T: PowerConditions },
  ],
);

/**
 * @generated from message ritta.pb.PhaseSummaryConditions
 */
export const PhaseSummaryConditions = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.PhaseSummaryConditions",
  () => [
    { no: 1, name: "power", kind: "message", T: PowerConditions },
  ],
);

/**
 * Electrical conditions of the neutral wire in a single or multi-phase system.
 *
 * @generated from message ritta.pb.NeutralConditions
 */
export const NeutralConditions = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.NeutralConditions",
  () => [
    { no: 1, name: "current", kind: "message", T: CurrentConditions },
  ],
);

/**
 * Desired state of a device based on recent control commands. For example,
 * this can be used to describe the active power input/output from a battery.
 * Unlike ConditionsV4, this only contains fields that can be controlled.
 *
 * Actual state of the device might differ, either because a command was not
 * received by the device, or it chose to not follow the command. See
 * ConditionsV4 for the actual state, albeit with some lag.
 *
 * Note the "intender" here is Camus' controls system, not the user. Intentions
 * of the user (e.g. via waypoints) are not yet included in the Resource model.
 *
 * @generated from message ritta.pb.Intentions
 */
export const Intentions = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Intentions",
  () => [
    { no: 1, name: "desired", kind: "message", T: Timestamp },
    { no: 2, name: "power", kind: "message", T: PowerIntentions },
  ],
);

/**
 * Desired energy flow into or out of device. Keep this structure in sync with
 * the equivalent measurement fields in PowerConditions.
 *
 * @generated from message ritta.pb.PowerIntentions
 */
export const PowerIntentions = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.PowerIntentions",
  () => [
    { no: 1, name: "real", kind: "message", T: DoubleValue },
    { no: 2, name: "limits", kind: "message", T: PowerLimitIntentions },
  ],
);

/**
 * Power limit intention sets a maximum amount of power draw or production
 * for a device.  This limit is set by Camus' controls system.
 *
 * @generated from message ritta.pb.PowerLimitIntentions
 */
export const PowerLimitIntentions = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.PowerLimitIntentions",
  () => [
    { no: 1, name: "consuming", kind: "message", T: DoubleValue },
    { no: 2, name: "producing", kind: "message", T: DoubleValue },
  ],
);

/**
 * @generated from message ritta.pb.Scheduling
 */
export const Scheduling = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Scheduling",
  () => [
    { no: 1, name: "interval", kind: "message", T: Duration },
    { no: 2, name: "horizon", kind: "message", T: Duration },
  ],
);

/**
 * External sensors of adjacent equipment
 *
 * This is used, for instance, for the external current transformers (CTs)
 * installed with Tesla Powerwalls, which monitor solar output and site
 * consumption.
 *
 * For devices with internal sensors, or devices that only ever have a single
 * external sensor, use ConditionsV4 above.
 *
 * See for background: https://go.grid.dev/site-controllers
 *
 * @generated from message ritta.pb.Sensor
 */
export const Sensor = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Sensor",
  () => [
    { no: 1, name: "site", kind: "message", T: SensorReadings },
    { no: 2, name: "solar", kind: "message", T: SensorReadings },
    { no: 3, name: "phases", kind: "enum", T: proto3.getEnumType(Config_Phasing) },
    { no: 4, name: "bus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "divisor", kind: "message", T: Sensor_Divisor },
  ],
);

/**
 * @generated from message ritta.pb.Sensor.Divisor
 */
export const Sensor_Divisor = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Sensor.Divisor",
  () => [
    { no: 1, name: "current", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 2, name: "power", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
  ],
  {localName: "Sensor_Divisor"},
);

/**
 * @generated from message ritta.pb.SensorReadings
 */
export const SensorReadings = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.SensorReadings",
  () => [
    { no: 2, name: "power", kind: "message", T: PowerConditions },
  ],
);

/**
 * Electrical potential of one phase in a multi-phase system.
 *
 * Note: documentation erroneously flipped the definition of the voltage fields
 * here prior to 2022-10-19. Any existing data or code using these fields should
 * be treated as suspect until explicitly validated as post-correction.
 *
 * @generated from message ritta.pb.PhaseVoltageConditions
 */
export const PhaseVoltageConditions = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.PhaseVoltageConditions",
  () => [
    { no: 1, name: "phase", kind: "message", T: DoubleValue },
    { no: 2, name: "line", kind: "message", T: DoubleValue },
  ],
);

/**
 * Rate of charge flow through a single AC circuit. All fields are effective
 * current, i.e. root mean square [1].
 * TODO(cody): vet these definitions with Milt.
 *
 * @generated from message ritta.pb.CurrentConditions
 */
export const CurrentConditions = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.CurrentConditions",
  () => [
    { no: 1, name: "magnitude", kind: "message", T: DoubleValue },
    { no: 2, name: "fault", kind: "message", T: DoubleValue },
  ],
);

/**
 * PowerConditions describes the rate of energy transfer in a single AC circuit.
 *
 * @generated from message ritta.pb.PowerConditions
 */
export const PowerConditions = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.PowerConditions",
  () => [
    { no: 1, name: "apparent", kind: "message", T: DoubleValue },
    { no: 2, name: "reactive", kind: "message", T: DoubleValue },
    { no: 3, name: "real", kind: "message", T: DoubleValue },
    { no: 4, name: "factor", kind: "message", T: DoubleValue },
  ],
);

/**
 * Attributes related to electrical service, mostly for grid devices.
 *
 * @generated from message ritta.pb.Grid
 */
export const Grid = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Grid",
  () => [
    { no: 1, name: "conditions", kind: "message", T: Conditions },
    { no: 2, name: "config", kind: "message", T: Config },
    { no: 3, name: "ratings", kind: "message", T: Ratings },
    { no: 4, name: "constituting", kind: "message", T: Constituting },
    { no: 5, name: "level", kind: "enum", T: proto3.getEnumType(GridLevel), opt: true },
  ],
);

/**
 * Aggregate statistics about a subset of a grid.
 *
 * @generated from message ritta.pb.Grid.Summary
 */
export const Grid_Summary = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Grid.Summary",
  () => [
    { no: 1, name: "meters", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "length", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
  {localName: "Grid_Summary"},
);

/**
 * @generated from message ritta.pb.Meter
 */
export const Meter = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Meter",
  () => [
    { no: 1, name: "electrical", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "gas", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message ritta.pb.Transformer
 */
export const Transformer = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Transformer",
  () => [
    { no: 1, name: "distribution", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * A bank is a collection of resources, likely in physical proximity.
 * For example, a transformer bank is a collection of transformers.
 *
 * @generated from message ritta.pb.Bank
 */
export const Bank = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Bank",
  () => [
    { no: 1, name: "transformer", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Downline is the scope most specifically served by a resource, used only in
 * hierarchical context. One way to think of this is: if this resource dies,
 * downline are the resources that will lose power.
 *
 * @generated from message ritta.pb.Downline
 */
export const Downline = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Downline",
  () => [
    { no: 1, name: "area", kind: "message", T: Location },
    { no: 4, name: "display", kind: "message", T: Location, repeated: true },
    { no: 2, name: "children", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "summary", kind: "message", T: Grid_Summary },
    { no: 5, name: "bus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Upline refers to the chain of resources between this an the root of a
 * hierarchy, usually the nearest substation.
 *
 * @generated from message ritta.pb.Upline
 */
export const Upline = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Upline",
  () => [
    { no: 1, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "substation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "feeder", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "summary", kind: "message", T: Grid_Summary },
    { no: 5, name: "transformer", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "bank", kind: "message", T: Bank },
    { no: 7, name: "bus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Attributes of the process of querying a device for information. For the
 * values produced by readings, see grid.conditions.
 *
 * @generated from message ritta.pb.Readings
 */
export const Readings = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Readings",
  () => [
    { no: 1, name: "cycle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "automated", kind: "message", T: BoolValue },
    { no: 3, name: "granular", kind: "message", T: BoolValue },
    { no: 4, name: "multiplier", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * When one resource is one piece of another logical resource, these Fields
 * reference the whole from the part.
 *
 * @generated from message ritta.pb.Constituting
 */
export const Constituting = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Constituting",
  () => [
    { no: 1, name: "feeder", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "generator", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "bank", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "pool", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message ritta.pb.Components
 */
export const Components = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Components",
  () => [
    { no: 1, name: "summary", kind: "message", T: Components_Summary },
  ],
);

/**
 * Common summary stats for sub-resources of a particular type, referenced
 * from Components.Summary below.
 *
 * @generated from message ritta.pb.Components.TypeSummary
 */
export const Components_TypeSummary = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Components.TypeSummary",
  () => [
    { no: 1, name: "count", kind: "message", T: Int64Value },
    { no: 2, name: "power", kind: "message", T: DoubleValue },
    { no: 3, name: "watthours", kind: "message", T: DoubleValue },
  ],
  {localName: "Components_TypeSummary"},
);

/**
 * @generated from message ritta.pb.Components.Summary
 */
export const Components_Summary = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Components.Summary",
  () => [
    { no: 1, name: "inverters", kind: "message", T: Components_TypeSummary },
    { no: 2, name: "modules", kind: "message", T: Components_TypeSummary },
    { no: 3, name: "cells", kind: "message", T: Components_TypeSummary },
  ],
  {localName: "Components_Summary"},
);

/**
 * Electrical energy creation or storage, including plants, farms, batteries.
 *
 * @generated from message ritta.pb.Generator
 */
export const Generator = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Generator",
  () => [
    { no: 1, name: "exports", kind: "message", T: BoolValue },
    { no: 3, name: "arrangement", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Present electrical service situation. Some of these fields are collected via
 * instrumentation automatically, and others are entered by hand based on field
 * crew observations. In the former case, the values are read from InfluxDB,
 * rather than being stored in PostgreSQL, because they change too quickly.
 *
 * This is v2 of the Resource model; new fields should be added to ConditionsV4
 * instead.
 *
 * @generated from message ritta.pb.Conditions
 */
export const Conditions = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Conditions",
  () => [
    { no: 1, name: "operation", kind: "enum", T: proto3.getEnumType(Operation) },
    { no: 2, name: "capability", kind: "enum", T: proto3.getEnumType(Capability) },
    { no: 3, name: "power", kind: "message", T: Power },
    { no: 4, name: "voltage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "current", kind: "message", T: DoubleValue },
    { no: 6, name: "temperature", kind: "message", T: DoubleValue },
  ],
);

/**
 * Summaries of past occurances
 *
 * @generated from message ritta.pb.Events
 */
export const Events = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Events",
  () => [
    { no: 1, name: "reversals", kind: "message", T: Int64Value },
  ],
);

/**
 * Designed or manually-observed arrangement of the components of an electrical
 * device, which often does not match reality. Use Conditions instead for
 * automated measurements.
 *
 * @generated from message ritta.pb.Config
 */
export const Config = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Config",
  () => [
    { no: 1, name: "operation", kind: "enum", T: proto3.getEnumType(Operation) },
    { no: 2, name: "voltages", kind: "message", T: Config_Voltages },
    { no: 3, name: "voltage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "phasing", kind: "enum", T: proto3.getEnumType(Config_Phasing) },
    { no: 5, name: "contacts", kind: "enum", T: proto3.getEnumType(Contacts) },
    { no: 6, name: "closed", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 10, name: "status", kind: "enum", T: proto3.getEnumType(Config_Status) },
    { no: 7, name: "delay", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 8, name: "connection", kind: "enum", T: proto3.getEnumType(Connection) },
    { no: 9, name: "grounded", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 11, name: "power", kind: "message", T: PowerConditions },
    { no: 12, name: "a", kind: "message", T: PhaseConfig },
    { no: 13, name: "b", kind: "message", T: PhaseConfig },
    { no: 14, name: "c", kind: "message", T: PhaseConfig },
  ],
);

/**
 * For devices in or downline from three-phase circuits, the assignment to
 * one or more phases.
 *
 * @generated from enum ritta.pb.Config.Phasing
 */
export const Config_Phasing = /*@__PURE__*/ proto3.makeEnum(
  "ritta.pb.Config.Phasing",
  [
    {no: 0, name: "PHASING_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "PHASING_A", localName: "A"},
    {no: 2, name: "PHASING_B", localName: "B"},
    {no: 3, name: "PHASING_C", localName: "C"},
    {no: 4, name: "PHASING_AN", localName: "AN"},
    {no: 5, name: "PHASING_BN", localName: "BN"},
    {no: 6, name: "PHASING_CN", localName: "CN"},
    {no: 7, name: "PHASING_AB", localName: "AB"},
    {no: 8, name: "PHASING_AC", localName: "AC"},
    {no: 9, name: "PHASING_BC", localName: "BC"},
    {no: 10, name: "PHASING_ABC", localName: "ABC"},
  ],
);

/**
 * @generated from enum ritta.pb.Config.Status
 */
export const Config_Status = /*@__PURE__*/ proto3.makeEnum(
  "ritta.pb.Config.Status",
  [
    {no: 0, name: "STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "STATUS_CLOSED", localName: "CLOSED"},
    {no: 2, name: "STATUS_OPEN", localName: "OPEN"},
  ],
);

/**
 * @generated from message ritta.pb.Config.Voltages
 */
export const Config_Voltages = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Config.Voltages",
  () => [
    { no: 1, name: "high", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "low", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "bandwidth", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
  {localName: "Config_Voltages"},
);

/**
 * Vendor recommended operating limits for an electrical device.
 *
 * @generated from message ritta.pb.Ratings
 */
export const Ratings = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Ratings",
  () => [
    { no: 1, name: "current", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "wattage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "power", kind: "message", T: Power },
    { no: 8, name: "voltage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 7, name: "watthours", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "energy", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "producing", kind: "message", T: DirectionalRatings },
    { no: 6, name: "consuming", kind: "message", T: DirectionalRatings },
    { no: 9, name: "emergency", kind: "message", T: EmergencyRatings },
  ],
);

/**
 * Ratings for just generation/reverse or just consumption/forward.
 *
 * @generated from message ritta.pb.DirectionalRatings
 */
export const DirectionalRatings = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.DirectionalRatings",
  () => [
    { no: 1, name: "power", kind: "message", T: Power },
  ],
);

/**
 * @generated from message ritta.pb.EmergencyRatings
 */
export const EmergencyRatings = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.EmergencyRatings",
  () => [
    { no: 1, name: "current", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * Relative amount of energy preserved in/through a system.
 *
 * @generated from message ritta.pb.Efficiency
 */
export const Efficiency = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Efficiency",
  () => [
    { no: 1, name: "consumption", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "production", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "roundtrip", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "effective", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * Operating bounds for a device, not including nameplate (see Ratings). For
 * example, this might describe minimum and maximum state of charge for a
 * battery during regular cycling.
 *
 * @generated from message ritta.pb.Limits
 */
export const Limits = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Limits",
  () => [
    { no: 1, name: "charge", kind: "message", T: ChargeLimits },
    { no: 2, name: "ramp", kind: "message", T: RampLimits },
    { no: 3, name: "power", kind: "message", T: PowerLimits },
  ],
);

/**
 * Charge limits for an energy storage device
 *
 * @generated from message ritta.pb.ChargeLimits
 */
export const ChargeLimits = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.ChargeLimits",
  () => [
    { no: 1, name: "state", kind: "message", T: FloatBounds },
    { no: 2, name: "level", kind: "message", T: FloatBounds },
  ],
);

/**
 * Upper and lower limits of a real quantity, e.g. state of charge for a battery.
 *
 * @generated from message ritta.pb.FloatBounds
 */
export const FloatBounds = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.FloatBounds",
  () => [
    { no: 1, name: "min", kind: "message", T: FloatValue },
    { no: 2, name: "max", kind: "message", T: FloatValue },
  ],
);

/**
 * Power limits
 *
 * All values in positive Watts.
 *
 * It is up to the caller to figure out how to map them into the signage
 * desired. This is because we expect that this message may apply to different
 * devices which live at different positions in the grid for which conventions
 * may differ.
 *
 * @generated from message ritta.pb.PowerLimits
 */
export const PowerLimits = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.PowerLimits",
  () => [
    { no: 1, name: "producing", kind: "message", T: DoubleValue },
    { no: 2, name: "consuming", kind: "message", T: DoubleValue },
  ],
);

/**
 * Ramp limits smooth the transition from one state to another, to avoid
 * problems caused by jerky behavior. Note these are operating bounds and not
 * nameplate ratings--`ratings.ramp` is not currently defined in this model.
 *
 * @generated from message ritta.pb.RampLimits
 */
export const RampLimits = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.RampLimits",
  () => [
    { no: 1, name: "consuming", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 2, name: "producing", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 3, name: "min", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 4, name: "max", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
  ],
);

/**
 * Rate of energy transfer. Currently apparent and reactive are unused, and real
 * is only populated for a fraction of grid resources. New applications should
 * prefer PowerConditions from the v4 Resource model, since this will eventually
 * be deprecated/retired.
 *
 * @generated from message ritta.pb.Power
 */
export const Power = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Power",
  () => [
    { no: 1, name: "apparent", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "real", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "reactive", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * Amount of electricity or gas transferred.
 *
 * @generated from message ritta.pb.Energy
 */
export const Energy = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Energy",
  () => [
    { no: 1, name: "consumed", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "produced", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * Usually an electronic apparatus, and sometimes mechanical implements, e.g.
 * electrical switches. This does not include conductors, poles, or other
 * essentially static objects.
 *
 * @generated from message ritta.pb.Device
 */
export const Device = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Device",
  () => [
    { no: 1, name: "equipment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "mounting", kind: "enum", T: proto3.getEnumType(Mounting) },
    { no: 3, name: "access", kind: "enum", T: proto3.getEnumType(Device_Access) },
    { no: 4, name: "tracking", kind: "enum", T: proto3.getEnumType(Tracking) },
    { no: 5, name: "backtracking", kind: "message", T: BoolValue },
    { no: 7, name: "ports", kind: "message", T: Int32Value },
    { no: 8, name: "sensor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Who is allowed to use the device?
 *
 * @generated from enum ritta.pb.Device.Access
 */
export const Device_Access = /*@__PURE__*/ proto3.makeEnum(
  "ritta.pb.Device.Access",
  [
    {no: 0, name: "UNSPECIFIED"},
    {no: 1, name: "PRIVATE"},
    {no: 2, name: "PUBLIC"},
  ],
);

/**
 * For electrical devices, information about the complete electrical network on
 * which they reside.
 *
 * @generated from message ritta.pb.Circuit
 */
export const Circuit = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Circuit",
  () => [
    { no: 1, name: "purpose", kind: "enum", T: proto3.getEnumType(Circuit_Purpose) },
    { no: 9, name: "classifier", kind: "message", T: NamedString, repeated: true },
  ],
);

/**
 * @generated from enum ritta.pb.Circuit.Purpose
 */
export const Circuit_Purpose = /*@__PURE__*/ proto3.makeEnum(
  "ritta.pb.Circuit.Purpose",
  [
    {no: 0, name: "UNSPECIFIED"},
    {no: 1, name: "DISTRIBUTION"},
    {no: 2, name: "TRANSMISSION"},
  ],
);

/**
 * Physical location of a resource on the surface of Earth. See RFC 7946 for
 * precise definitions of LatLng fields:
 *   https://tools.ietf.org/html/rfc7946#section-3.1
 *
 * @generated from message ritta.pb.Location
 */
export const Location = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Location",
  () => [
    { no: 1, name: "point", kind: "message", T: LatLng },
    { no: 2, name: "line_string", kind: "message", T: LatLng, repeated: true },
    { no: 3, name: "polygon", kind: "message", T: LatLng, repeated: true },
    { no: 4, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "parcel", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "census", kind: "message", T: NamedString, repeated: true },
  ],
);

/**
 * Area for governmental purposes, unrelated to jurisdiction, e.g. census.
 *
 * @generated from message ritta.pb.Tract
 */
export const Tract = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Tract",
  () => [
    { no: 1, name: "census", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "metric", kind: "message", T: NamedFloat, repeated: true },
    { no: 2, name: "designation", kind: "message", T: NamedBool, repeated: true },
  ],
);

/**
 * Information related to storage in geographic information systems.
 *
 * @generated from message ritta.pb.GIS
 */
export const GIS = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.GIS",
  () => [
    { no: 1, name: "element", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "parent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Attributes of an enclosed space for human occupancy.
 *
 * @generated from message ritta.pb.Building
 */
export const Building = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Building",
  () => [
    { no: 1, name: "floorspace", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "constructed", kind: "message", T: Date },
  ],
);

/**
 * Premises as defined by utility service provider, usually all of the things
 * behind a single customer meter.
 *
 * @generated from message ritta.pb.Site
 */
export const Site = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Site",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "composition", kind: "enum", T: proto3.getEnumType(Composition) },
    { no: 3, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "units", kind: "message", T: Site_Units },
    { no: 5, name: "function", kind: "enum", T: proto3.getEnumType(Site_Function) },
    { no: 6, name: "size", kind: "enum", T: proto3.getEnumType(Site_Size) },
    { no: 7, name: "services", kind: "message", T: Site_Services },
  ],
);

/**
 * Purpose of a site. Because these codes are not mutually exclusive,
 * collections should specify which codes they use.
 *
 * TODO(cody): move this to top level, to share with other physical
 * delineations, e.g. parcel.
 *
 * @generated from enum ritta.pb.Site.Function
 */
export const Site_Function = /*@__PURE__*/ proto3.makeEnum(
  "ritta.pb.Site.Function",
  [
    {no: 0, name: "FUNCTION_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "FUNCTION_RESIDENTIAL", localName: "RESIDENTIAL"},
    {no: 2, name: "FUNCTION_COMMERCIAL", localName: "COMMERCIAL"},
    {no: 3, name: "FUNCTION_INDUSTRIAL", localName: "INDUSTRIAL"},
    {no: 4, name: "FUNCTION_AGRICULTURAL", localName: "AGRICULTURAL"},
    {no: 5, name: "FUNCTION_STREETLIGHT", localName: "STREETLIGHT"},
    {no: 6, name: "FUNCTION_PUBLIC_BUILDINGS", localName: "PUBLIC_BUILDINGS"},
    {no: 11, name: "FUNCTION_NONRESIDENTIAL", localName: "NONRESIDENTIAL"},
    {no: 10, name: "FUNCTION_COMMERCIAL_INDUSTRIAL", localName: "COMMERCIAL_INDUSTRIAL"},
  ],
);

/**
 * Local bucketing of sites according to their usage profile, typically used
 * for commercial and industrial sites.
 *
 * @generated from enum ritta.pb.Site.Size
 */
export const Site_Size = /*@__PURE__*/ proto3.makeEnum(
  "ritta.pb.Site.Size",
  [
    {no: 0, name: "SIZE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "SIZE_SMALL", localName: "SMALL"},
    {no: 2, name: "SIZE_MEDIUM", localName: "MEDIUM"},
    {no: 3, name: "SIZE_LARGE", localName: "LARGE"},
  ],
);

/**
 * Distinct areas of occupancy within a subdivided building, most often
 * apartments in a multi-tenant building. Collections should specify
 * whether hotel rooms are treated as separate units.
 *
 * @generated from message ritta.pb.Site.Units
 */
export const Site_Units = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Site.Units",
  () => [
    { no: 1, name: "total", kind: "message", T: Int32Value },
    { no: 2, name: "gas", kind: "message", T: Int32Value },
  ],
  {localName: "Site_Units"},
);

/**
 * Which services are enabled at a site.
 *
 * @generated from message ritta.pb.Site.Services
 */
export const Site_Services = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Site.Services",
  () => [
    { no: 1, name: "gas", kind: "message", T: BoolValue },
  ],
  {localName: "Site_Services"},
);

/**
 * Parcel is a local code for a location, usually assigned by tax assessor or
 * other local government entity for legal purposes. Parcels are not always
 * buildings or portions thereof; they can describe parking lots, farm land,
 * and utility poles.
 *
 * @generated from message ritta.pb.Parcel
 */
export const Parcel = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Parcel",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "function", kind: "enum", T: proto3.getEnumType(Site_Function) },
    { no: 3, name: "composition", kind: "enum", T: proto3.getEnumType(Composition) },
    { no: 4, name: "units", kind: "message", T: Int32Value },
    { no: 5, name: "use", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "zoning", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "space", kind: "enum", T: proto3.getEnumType(Parcel_Space) },
    { no: 8, name: "location", kind: "message", T: LatLng },
  ],
);

/**
 * Different areas use different rules for dividing space, but they follow
 * some very rough common patterns.
 *
 * @generated from enum ritta.pb.Parcel.Space
 */
export const Parcel_Space = /*@__PURE__*/ proto3.makeEnum(
  "ritta.pb.Parcel.Space",
  [
    {no: 0, name: "SPACE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "SPACE_LAND", localName: "LAND"},
    {no: 2, name: "SPACE_AIR", localName: "AIR"},
  ],
);

/**
 * Information about how this resource was created.
 *
 * @generated from message ritta.pb.Manufacture
 */
export const Manufacture = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Manufacture",
  () => [
    { no: 1, name: "brand", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "model", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "submodel", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "generation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "serial", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Appliance describes utility-connected devices, which consume a significiant
 * amount of energy.
 *
 * @generated from message ritta.pb.Appliance
 */
export const Appliance = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Appliance",
  () => [
    { no: 1, name: "heater", kind: "message", T: Appliance_Heater },
    { no: 2, name: "medical", kind: "message", T: Appliance_Generic },
  ],
);

/**
 * Parameters common to various different types of devices.
 *
 * @generated from message ritta.pb.Appliance.Generic
 */
export const Appliance_Generic = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Appliance.Generic",
  () => [
    { no: 1, name: "energy", kind: "enum", T: proto3.getEnumType(Utility) },
  ],
  {localName: "Appliance_Generic"},
);

/**
 * Devices that increase temperature, usually for human comfort.
 *
 * @generated from message ritta.pb.Appliance.Heater
 */
export const Appliance_Heater = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Appliance.Heater",
  () => [
    { no: 1, name: "water", kind: "message", T: Appliance_Generic },
    { no: 2, name: "air", kind: "message", T: Appliance_Generic },
  ],
  {localName: "Appliance_Heater"},
);

/**
 * Information about self-propelling devices.
 *
 * @generated from message ritta.pb.Vehicle
 */
export const Vehicle = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Vehicle",
  () => [
    { no: 5, name: "vin", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 1, name: "propulsion", kind: "enum", T: proto3.getEnumType(Propulsion) },
    { no: 2, name: "fuel", kind: "enum", T: proto3.getEnumType(Fuel) },
    { no: 3, name: "pluggable", kind: "message", T: BoolValue },
    { no: 4, name: "technology", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "body", kind: "message", T: Vehicle_Body },
    { no: 7, name: "odometer_reading", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Physical shape and purpose of a vehicle, usually independent of how it's
 * propelled or fueled. These vary by jurisdiction; here for example is CA:
 * https://www.dmv.ca.gov/portal/handbook/vehicle-industry-registration-procedures-manual-2/inquiries/chart-1-body-type-model-and-body-type-codes/
 *
 * @generated from message ritta.pb.Vehicle.Body
 */
export const Vehicle_Body = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Vehicle.Body",
  () => [
    { no: 1, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "classifier", kind: "message", T: NamedString, repeated: true },
  ],
  {localName: "Vehicle_Body"},
);

/**
 * Aspects of the connection of a distributed device or system to the grid.
 *
 * @generated from message ritta.pb.Interconnection
 */
export const Interconnection = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Interconnection",
  () => [
    { no: 1, name: "composition", kind: "enum", T: proto3.getEnumType(Composition) },
    { no: 2, name: "agreement", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "direct", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ],
);

/**
 * Filing a device with some controlling organization, e.g. vehicle with state
 * Department of Motor Vehicles (DMV).
 *
 * @generated from message ritta.pb.Registration
 */
export const Registration = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Registration",
  () => [
    { no: 1, name: "expires", kind: "message", T: Timestamp },
    { no: 2, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Telephone numbers associated with this resource, e.g. how to phone an
 * account holder.
 * TODO(cody): replace the various fields with a "place" enum, and make it
 * repeated in the parent message (Resource).
 * TODO(cody): define canonical formats for these.
 *
 * @generated from message ritta.pb.Phone
 */
export const Phone = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Phone",
  () => [
    { no: 1, name: "number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "business", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "home", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "cell", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Electronic mail address associated with this resource, e.g. how to email the
 * account holder.
 *
 * @generated from message ritta.pb.Email
 */
export const Email = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Email",
  () => [
    { no: 1, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message ritta.pb.Language
 */
export const Language = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Language",
  () => [
    { no: 1, name: "preference", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message ritta.pb.Industry
 */
export const Industry = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Industry",
  () => [
    { no: 1, name: "naics", kind: "message", T: Int32Value },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * An observation whose meaning could not be deciphered at ingestion time. These
 * are exclusively for debugging, and should not be processed or shown to users.
 *
 * @generated from message ritta.pb.Unknown
 */
export const Unknown = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Unknown",
  () => [
    { no: 1, name: "source", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Pass-thru containers for additional data, which Ritta does not need to
 * comprehend directly. For instance, Census equity metrics (e.g. linguistic
 * isolation percentile) for SVCE use NamedFloats fields.
 *
 * These contain essentially dynamic fields. So if you have a message like:
 *
 *   zone {  # <- repeated NamedString
 *     name: "usps.zip"
 *     value: "94103"
 *   }
 *
 * This will map in BigQuery (and other columnular representations) to:
 *
 *   zone.usps.zip: "94103"
 *
 * Keys in Named* messages need to correspond to valid variable identifiers
 * across languages. This means among other things:
 *  - Only letters, numbers, underscores, and dots
 *  - Cannot start or end with dot
 *  - No consecutive dots
 *  - Dot-delimited components cannot start with a number
 *  - One key cannot be a prefix of another key
 *
 * Sticking with lowercase is a best practice, since we don't generally have
 * consistent case sensitivity. But this is not strictly enforced.
 *
 * @generated from message ritta.pb.NamedFloat
 */
export const NamedFloat = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.NamedFloat",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message ritta.pb.NamedBool
 */
export const NamedBool = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.NamedBool",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message ritta.pb.NamedString
 */
export const NamedString = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.NamedString",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * TODO(cody): reference this version in ritta_frontend.proto.
 *
 * @generated from message ritta.pb.LatLng
 */
export const LatLng = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.LatLng",
  () => [
    { no: 1, name: "lat", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "lng", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * Attributes of electrical wires.
 *
 * @generated from message ritta.pb.Conductor
 */
export const Conductor = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Conductor",
  () => [
    { no: 1, name: "length", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "composition", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "neutral", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "config", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Situation within a legal system, particularly relevant to tax policy.
 *
 * @generated from message ritta.pb.Jurisdiction
 */
export const Jurisdiction = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Jurisdiction",
  () => [
    { no: 1, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "county", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "municipality", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Period when service is provided at a particular customer site. Some utilities
 * call this "service agreement ID" or "SA ID". Meaning differs based on type:
 *  - subscription/electrical: the resource itself
 *  - site/customer: the immediately active subscription at the site
 *
 * @generated from message ritta.pb.Subscription
 */
export const Subscription = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Subscription",
  () => [
    { no: 1, name: "electrical", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "gas", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Identifiers of organizations that dispense services.
 *
 * @generated from message ritta.pb.Provider
 */
export const Provider = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Provider",
  () => [
    { no: 1, name: "electrical", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "pending", kind: "message", T: Provider_Pending },
  ],
);

/**
 * Pending electrical provider describes an imminent switch to a new
 * provider.electrical, using local codes to name different providers, e.g.
 * "SVCE", "PG&E".
 *
 * @generated from message ritta.pb.Provider.Pending
 */
export const Provider_Pending = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Provider.Pending",
  () => [
    { no: 1, name: "electrical", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "Provider_Pending"},
);

/**
 * Work describes tasks performed in relation to a resource, e.g. connecting
 * wiring to a new customer site.
 *
 * @generated from message ritta.pb.Work
 */
export const Work = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Work",
  () => [
    { no: 1, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Discrete collections of work, e.g. DER installation.
 *
 * @generated from message ritta.pb.Project
 */
export const Project = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Project",
  () => [
    { no: 1, name: "incentive", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "applicant", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Owner describes a single individual or organization holding responsibility
 * over and authority for a resource.
 *
 * @generated from message ritta.pb.Owner
 */
export const Owner = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Owner",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "government", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message ritta.pb.Transmission
 */
export const Transmission = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Transmission",
  () => [
    { no: 1, name: "node", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message ritta.pb.Program
 */
export const Program = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Program",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "enrolled", kind: "message", T: BoolValue },
    { no: 3, name: "eligible", kind: "message", T: BoolValue },
    { no: 4, name: "refused", kind: "message", T: BoolValue },
  ],
);

/**
 * @generated from message ritta.pb.Billing
 */
export const Billing = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Billing",
  () => [
    { no: 1, name: "account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "party", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "rate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "nem", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "classifier", kind: "message", T: NamedString, repeated: true },
    { no: 3, name: "climate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "cycle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "protection", kind: "message", T: NamedBool, repeated: true },
    { no: 5, name: "medium", kind: "enum", T: proto3.getEnumType(Billing_Medium) },
  ],
);

/**
 * How bills are transmitted to the account owner.
 *
 * @generated from enum ritta.pb.Billing.Medium
 */
export const Billing_Medium = /*@__PURE__*/ proto3.makeEnum(
  "ritta.pb.Billing.Medium",
  [
    {no: 0, name: "MEDIUM_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "MEDIUM_POSTAL", localName: "POSTAL"},
    {no: 2, name: "MEDIUM_INTERNET", localName: "INTERNET"},
  ],
);

/**
 * @generated from message ritta.pb.Usage
 */
export const Usage = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Usage",
  () => [
    { no: 1, name: "determination", kind: "enum", T: proto3.getEnumType(Usage_Determination) },
    { no: 2, name: "constant", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "allowances", kind: "message", T: NamedFloat, repeated: true },
    { no: 4, name: "netted", kind: "message", T: BoolValue },
  ],
);

/**
 * @generated from enum ritta.pb.Usage.Determination
 */
export const Usage_Determination = /*@__PURE__*/ proto3.makeEnum(
  "ritta.pb.Usage.Determination",
  [
    {no: 0, name: "DETERMINATION_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "DETERMINATION_METERED", localName: "METERED"},
    {no: 2, name: "DETERMINATION_STATIC", localName: "STATIC"},
  ],
);

/**
 * Results of Caltrack analysis of seasonal variability in electrical and gas
 * usage, primarily caused by air heating and cooling. See amazingly detailed
 * documentation from Caltrack:
 *   http://docs.caltrack.org/en/latest/methods.html
 *
 * @generated from message ritta.pb.Caltrack
 */
export const Caltrack = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Caltrack",
  () => [
    { no: 1, name: "electrical", kind: "message", T: Caltrack_Regression },
    { no: 2, name: "gas", kind: "message", T: Caltrack_Regression },
  ],
);

/**
 * @generated from message ritta.pb.Caltrack.Regression
 */
export const Caltrack_Regression = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Caltrack.Regression",
  () => [
    { no: 1, name: "cooling", kind: "message", T: Caltrack_Regression_Elbow },
    { no: 2, name: "heating", kind: "message", T: Caltrack_Regression_Elbow },
    { no: 3, name: "mean", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "determination", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
  {localName: "Caltrack_Regression"},
);

/**
 * The Caltrack model expects load to follow a \_ (heating) or _/ (cooling)
 * shape, which is why we call this "Elbow" \_o_/
 *
 * @generated from message ritta.pb.Caltrack.Regression.Elbow
 */
export const Caltrack_Regression_Elbow = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Caltrack.Regression.Elbow",
  () => [
    { no: 3, name: "coefficient", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 1, name: "coefficient_f", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "balance_f", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "balance_c", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
  {localName: "Caltrack_Regression_Elbow"},
);

/**
 * @generated from message ritta.pb.Payments
 */
export const Payments = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Payments",
  () => [
    { no: 1, name: "balanced", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "structured", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "auto", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * Utility usage tax parameters
 *
 * @generated from message ritta.pb.Tax
 */
export const Tax = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Tax",
  () => [
    { no: 1, name: "rate", kind: "message", T: FloatValue },
    { no: 2, name: "exempt", kind: "message", T: BoolValue },
  ],
);

/**
 * Generic attributes of regrettable occurrences
 *
 * @generated from message ritta.pb.Incident
 */
export const Incident = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Incident",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Conflagration planning and response
 *
 * @generated from message ritta.pb.Fire
 */
export const Fire = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Fire",
  () => [
    { no: 1, name: "area", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Participation by a customer with a demand response provider (DRP), which
 * typically reduces electric load across a large number of devices when
 * dispatched. DRPs are similar to a simple form of virtual power plant (VPP).
 *
 * @generated from message ritta.pb.Aggregator
 */
export const Aggregator = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Aggregator",
  () => [
    { no: 1, name: "provider", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "enrolled", kind: "message", T: BoolValue },
  ],
);

/**
 * Important services that depend on a resource, e.g. a dialysis machine that
 * depends on an electrical subscription, or a public library used as a cooling
 * center.
 *
 * @generated from message ritta.pb.Dependency
 */
export const Dependency = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Dependency",
  () => [
    { no: 1, name: "medical", kind: "message", T: BoolValue },
    { no: 2, name: "community", kind: "message", T: BoolValue },
  ],
);

/**
 * Metadata about this unit of data, eventually including when it was observed
 * or ingested, and the source from which it was derived.
 *
 * @generated from message ritta.pb.Record
 */
export const Record = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Record",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "snapped", kind: "message", T: Timestamp },
    { no: 3, name: "source", kind: "message", T: NamedString, repeated: true },
    { no: 4, name: "genesis", kind: "enum", T: proto3.getEnumType(Genesis) },
  ],
);

/**
 * Metadata about a particular field.
 *
 * @generated from message ritta.pb.Field
 */
export const Field = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Field",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "effective", kind: "message", T: Timestamp },
    { no: 3, name: "observed", kind: "message", T: Timestamp },
    { no: 4, name: "grade", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "genesis", kind: "enum", T: proto3.getEnumType(Genesis) },
  ],
);

/**
 * Input describes source data sets.
 *
 * @generated from message ritta.pb.Input
 */
export const Input = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Input",
  () => [
    { no: 1, name: "stream", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "file", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "line", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "row", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "column", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "value", kind: "message", T: StringValue },
  ],
);

/**
 * Instance of an alert that has fired
 *
 * In addition to the fields embedded in this message, alert instances have the following
 * set in the top-level Resource
 *
 *    .type       alert/instance
 *    .id         alert/instance/{id}
 *    .start      time when the alert instance started firing
 *    .end        time when the alert cleared; if un-set alert is active
 *    .title      human-readable description of this alert instance
 *
 * @generated from message ritta.pb.Alert
 */
export const Alert = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Alert",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "labels", kind: "message", T: NamedString, repeated: true },
  ],
);

/**
 * @generated from message ritta.pb.Prediction
 */
export const Prediction = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Prediction",
  () => [
    { no: 1, name: "technique", kind: "enum", T: proto3.getEnumType(Prediction_Technique) },
  ],
);

/**
 * Technique describes the method of determining future behavior. This is
 * intentionally imprecise attribute, used for individual resources to
 * inform:
 *  1. Whether model-based forecasting should train on observed timeseries
 *     or predict their future behavior.
 *  2. Whether source data can be considered complete, e.g. generating
 *     MissingSchedule alerts.
 *  3. Which data store to query for appropriate predictions, e.g. schedules
 *     might be stored in GCS in CSV format.
 *  4. How to explain the predictions to customers in the Ritta UI, although
 *     additional prediction metadata might be necessary to render a helpful
 *     explanation, e.g. "biomass plant uses a scheduled provided by HCE,
 *     last updated 2023-07-24."
 *
 * It also influences handling of predictions in aggregations, e.g. rolling
 * up load under a feeder or transformer. Such aggregations might be flagged
 * in the Ritta UI as "low confidence", if a large fraction of their content
 * is based on low confidence prediction techniques.
 *
 * @generated from enum ritta.pb.Prediction.Technique
 */
export const Prediction_Technique = /*@__PURE__*/ proto3.makeEnum(
  "ritta.pb.Prediction.Technique",
  [
    {no: 0, name: "TECHNIQUE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 10, name: "TECHNIQUE_MODELING", localName: "MODELING"},
    {no: 20, name: "TECHNIQUE_SCHEDULING", localName: "SCHEDULING"},
    {no: 99, name: "TECHNIQUE_OTHER", localName: "OTHER"},
  ],
);

/**
 * Thresholds for determining control actions. For example, a voltage based
 * capacitor controller will turn on a capacitor when the voltage exceeds a
 * certain value (after a time delay) and turn it off when the voltage falls
 * below another value (after a time delay).
 *
 * @generated from message ritta.pb.MeasurementThresholds
 */
export const MeasurementThresholds = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.MeasurementThresholds",
  () => [
    { no: 1, name: "voltage", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 2, name: "current", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 3, name: "reactive_power", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 4, name: "power_factor", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
    { no: 5, name: "temperature_c", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
  ],
);

/**
 * Control settings for a device such as a capacitor or voltage regulator.
 *
 * @generated from message ritta.pb.Controls
 */
export const Controls = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Controls",
  () => [
    { no: 1, name: "thresholds", kind: "message", T: Controls_Thresholds },
    { no: 2, name: "basis", kind: "enum", T: proto3.getEnumType(Controls_Basis) },
    { no: 3, name: "winding", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "target", kind: "message", T: Controls_Target },
    { no: 5, name: "clock", kind: "message", T: Controls_Clock },
    { no: 6, name: "commandable", kind: "enum", T: proto3.getEnumType(Controls_Commandable) },
  ],
);

/**
 * Control basis for a device such as a capacitor.
 *
 * @generated from enum ritta.pb.Controls.Basis
 */
export const Controls_Basis = /*@__PURE__*/ proto3.makeEnum(
  "ritta.pb.Controls.Basis",
  [
    {no: 0, name: "BASIS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "BASIS_FIXED", localName: "FIXED"},
    {no: 2, name: "BASIS_VOLTAGE", localName: "VOLTAGE"},
    {no: 3, name: "BASIS_CURRENT", localName: "CURRENT"},
    {no: 4, name: "BASIS_REACTIVE_POWER", localName: "REACTIVE_POWER"},
    {no: 5, name: "BASIS_POWER_FACTOR", localName: "POWER_FACTOR"},
    {no: 7, name: "BASIS_TEMPERATURE", localName: "TEMPERATURE"},
  ],
);

/**
 * Indicates whether a resource is in an appropriate state to have control commands issued to it.
 *
 * @generated from enum ritta.pb.Controls.Commandable
 */
export const Controls_Commandable = /*@__PURE__*/ proto3.makeEnum(
  "ritta.pb.Controls.Commandable",
  [
    {no: 0, name: "COMMANDABLE_UNKNOWN", localName: "UNKNOWN"},
    {no: 1, name: "COMMANDABLE_ACTIVE", localName: "ACTIVE"},
    {no: 2, name: "COMMANDABLE_INACTIVE", localName: "INACTIVE"},
  ],
);

/**
 * On and off measurment thresholds for control actions.
 *
 * @generated from message ritta.pb.Controls.Thresholds
 */
export const Controls_Thresholds = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Controls.Thresholds",
  () => [
    { no: 1, name: "on", kind: "message", T: MeasurementThresholds },
    { no: 2, name: "off", kind: "message", T: MeasurementThresholds },
  ],
  {localName: "Controls_Thresholds"},
);

/**
 * @generated from message ritta.pb.Controls.Target
 */
export const Controls_Target = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Controls.Target",
  () => [
    { no: 1, name: "voltage", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
  ],
  {localName: "Controls_Target"},
);

/**
 * @generated from message ritta.pb.Controls.Clock
 */
export const Controls_Clock = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Controls.Clock",
  () => [
    { no: 1, name: "on", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "off", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
  {localName: "Controls_Clock"},
);

