import { DateTime } from 'luxon'

import { MEGA_OPTIONS, Format } from '@/utils/format'
import { ChartFormatters, ChartType } from '@/types/charts'

export const chartFormattersByChartType: Record<ChartType, ChartFormatters> = {
  [ChartType.Clouds]: { yaxis: Format.fmtPercentNoFraction },
  [ChartType.CarbonFree]: { yaxis: Format.fmtPercent },
  [ChartType.Current]: { yaxis: Format.fmtAmps },
  [ChartType.Energy]: { yaxis: Format.fmtEnergy },
  [ChartType.EnergyLine]: { yaxis: Format.fmtEnergy },
  [ChartType.EnergyLinePercentage]: { yaxis: Format.fmtPercentNoFraction },
  [ChartType.Percentage]: { yaxis: Format.fmtPercentNoFraction },
  [ChartType.Errors]: { yaxis: Format.fmtWatts },
  [ChartType.Load]: { yaxis: Format.fmtPercent },
  [ChartType.LoadDuration]: {
    yaxis: Format.fmtApparentPower,
    xaxis: Format.fmtPercentNoFraction,
  },
  [ChartType.Power]: { yaxis: Format.fmtWatts },
  [ChartType.PowerMW]: { yaxis: (v) => Format.fmtWatts(v, MEGA_OPTIONS) },
  [ChartType.ReactivePower]: { yaxis: Format.fmtReactivePower },
  [ChartType.ApparentPower]: { yaxis: Format.fmtApparentPower },
  [ChartType.Voltage]: { yaxis: Format.fmtVolts },
  [ChartType.VoltagePerUnit]: { yaxis: Format.fmtVoltsPerUnit },
  [ChartType.Temperature]: { yaxis: Format.fmtTemperature },
  [ChartType.Violations]: { yaxis: Format.fmtCount },
}

export function createLastUpdateString(dt = DateTime.now()): string {
  return `Last update: ${dt.toLocaleString(DateTime.TIME_24_WITH_SHORT_OFFSET)}`
}
