// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=js+dts"
// @generated from file frontend/proto/notification.proto (package ritta.frontend.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetNotificationRequest, ListNotificationsRequest, ListNotificationsResponse, MarkAsReadRequest, MarkAsReadResponse, Notification, RefreshRequest } from "./notification_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service ritta.frontend.proto.NotificationService
 */
export const NotificationService = {
  typeName: "ritta.frontend.proto.NotificationService",
  methods: {
    /**
     * Query for notifications for use in tables
     *
     * @generated from rpc ritta.frontend.proto.NotificationService.ListNotifications
     */
    listNotifications: {
      name: "ListNotifications",
      I: ListNotificationsRequest,
      O: ListNotificationsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Retrieve the latest notifications
     *
     * @generated from rpc ritta.frontend.proto.NotificationService.NotificationsRefresh
     */
    notificationsRefresh: {
      name: "NotificationsRefresh",
      I: RefreshRequest,
      O: ListNotificationsResponse,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * Returns the full detail for a single notification
     *
     * @generated from rpc ritta.frontend.proto.NotificationService.GetNotification
     */
    getNotification: {
      name: "GetNotification",
      I: GetNotificationRequest,
      O: Notification,
      kind: MethodKind.Unary,
    },
    /**
     * Update unread state for the request user
     *
     * @generated from rpc ritta.frontend.proto.NotificationService.MarkAsRead
     */
    markAsRead: {
      name: "MarkAsRead",
      I: MarkAsReadRequest,
      O: MarkAsReadResponse,
      kind: MethodKind.Unary,
    },
  }
};

