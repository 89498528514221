<template>
  <v-row>
    <!-- Main chart + Summary boxes -->
    <v-col :sm="12" :xl="6" class="py-0">
      <!-- Interval -->
      <v-select
        :model-value="selectedPeriod"
        label="Interval"
        aria-label="Interval"
        :items="periodOptions"
        class="ml-6"
        :class="hasCharts ? 'mb-8' : null"
        style="max-width: 12rem"
        @update:model-value="handleNewPeriod"
      />

      <!-- Main chart -->
      <time-series-chart-group
        :charts
        :data-source
        :interval
        hide-date-range-picker
        disable-scroll
        class="pb-8"
        :class="isLarge ? 'pl-6' : 'px-6'"
      >
        <!-- Default slot: Summary boxes -->
        <v-row
          v-if="summaryBoxes.length"
          tag="section"
          aria-label="Summary boxes"
          class="pt-3 pb-12"
          style="min-height: 18rem"
        >
          <v-col
            v-for="summary of summaryBoxes"
            :key="summary.title"
            sm="4"
            role="region"
            aria-label="Summary box"
          >
            <div
              class="position-relative h-100 pa-4"
              :style="{ backgroundColor: summaryBgColor }"
            >
              <!-- Loading -->
              <centered-spinner v-if="summary.isLoading" />

              <!-- Content -->
              <template v-else>
                <!-- Title -->
                <h3 class="d-block text-subtitle-2 pb-2">
                  {{ summary.title }}
                </h3>

                <!-- Main value -->
                <span
                  class="d-block text-h4"
                  :aria-label="`${summary.title} value`"
                >
                  {{ summary.mainValue }}
                </span>

                <!-- Info Column -->
                <info-column
                  subtitle=" "
                  :items="summary.infoColumnItems"
                  :name="`${summary.title} items`"
                />
              </template>
            </div>
          </v-col>
        </v-row>
      </time-series-chart-group>
    </v-col>

    <!-- Analysis sections -->
    <v-col
      :sm="12"
      :xl="hasCharts ? 6 : 12"
      class="py-0"
      :style="{ marginTop: hasCharts && isLarge ? '75.5px' : undefined }"
    >
      <section
        aria-label="Analysis sections"
        :class="!hasCharts && isLarge ? 'analysis-grid-container' : null"
        style="min-height: 23rem"
      >
        <section
          v-for="analysisSection of analysisSections"
          :key="analysisSection.title"
          :aria-label="analysisSection.title"
          class="position-relative"
          :class="!hasCharts && isLarge ? 'pa-5' : 'mx-6 mb-6 pa-6'"
          :style="{ backgroundColor: chartsBgColor, minHeight: '25rem' }"
        >
          <!-- Section loading -->
          <centered-spinner v-if="analysisSection.isLoading" />

          <!-- Content -->
          <template v-else>
            <!-- Title -->
            <ce-title big class="pb-12">
              {{ analysisSection.title }}
            </ce-title>

            <!-- Charts -->
            <div class="d-flex">
              <!-- Histogram chart -->
              <div class="d-flex flex-column" style="flex: 1; padding: 0 2rem">
                <!-- Title -->
                <h4 class="d-block text-subtitle-1 font-weight-medium">
                  {{ analysisSection.histogram.title }}
                </h4>
                <!-- Chart -->
                <div style="flex: 1" class="align-content-end">
                  <histogram-chart
                    :name="analysisSection.histogram.title"
                    :values="analysisSection.histogram.data"
                    :x-axis-title="analysisSection.histogram.xAxisTitle"
                    :y-axis-title="analysisSection.histogram.yAxisTitle"
                    :barColor
                  />
                </div>
              </div>

              <!-- Horizontal bar chart -->
              <div style="flex: 1; padding: 0 2rem">
                <!-- Titles -->
                <div class="d-flex">
                  <!-- Chart title -->
                  <h4
                    class="d-block text-subtitle-1 font-weight-medium pr-4"
                    style="flex: 1"
                  >
                    {{ analysisSection.horizontalBar.title }}
                  </h4>
                  <!-- Side Data title -->
                  <h4
                    v-if="analysisSection.horizontalBar.sideData"
                    class="d-block text-subtitle-1 font-weight-medium"
                  >
                    {{ analysisSection.horizontalBar.sideData?.title }}
                  </h4>
                </div>
                <!-- Chart + Side Data -->
                <div class="d-flex">
                  <!-- Chart -->
                  <div style="flex: 1">
                    <horizontal-bar-chart
                      :name="analysisSection.horizontalBar.title"
                      :horizontal-bar-data="analysisSection.horizontalBar.data"
                      :x-axis-title="analysisSection.horizontalBar.xAxisTitle"
                      :barColor
                    />
                  </div>

                  <!-- Site Data -->
                  <section
                    v-if="analysisSection.horizontalBar.sideData"
                    aria-label="Site Data items"
                  >
                    <!-- Items -->
                    <div style="padding-top: 11px">
                      <span
                        v-for="item of analysisSection.horizontalBar.sideData
                          ?.items"
                        :key="item"
                        class="d-block text-caption text-end pr-12"
                        style="line-height: 1.5"
                      >
                        {{ item }}
                      </span>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </template>
        </section>
      </section>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { useDisplay } from 'vuetify'
import type { Interval } from 'luxon'
import { GRAY_COOL_50, NEUTRAL_50, VELVET } from '@/constants/colors'
import {
  TimeSeriesDataSource,
  type ITimeSeriesDataSource,
} from '@/model/charts'
import CeTitle from '@/components/CeTitle.vue'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import type { InfoColumnItem } from '@/components/InfoColumn'
import InfoColumn from '@/components/InfoColumn.vue'
import { TimeSeriesChartGroup } from '@/components/common'
import HistogramChart from '@/components/common/HistogramChart.vue'
import HorizontalBarChart from '@/components/common/HorizontalBarChart.vue'
import type { ChartDefinition, HorizontalBarChartData } from '@/types/charts'
import { CalendarPeriod } from 'rfs/pb/calendar_pb'

export type SummaryBox = {
  isLoading: boolean
  title: string
  mainValue: string
  infoColumnItems: InfoColumnItem[]
}

export type AnalysisSection = {
  title: string
  isLoading: boolean
  histogram: {
    title: string
    xAxisTitle: string
    yAxisTitle: string
    data: number[]
  }
  horizontalBar: {
    title: string
    xAxisTitle: string
    data: HorizontalBarChartData
    sideData?: {
      title: string
      items: string[]
    }
  }
}

type PeriodOption = { title: string; value: CalendarPeriod }

export default defineComponent({
  name: 'InsightTemplate',
  props: {
    interval: {
      type: Object as PropType<Interval>,
      required: true,
    },
    selectedPeriod: {
      type: Number as PropType<CalendarPeriod>,
      required: true,
    },
    charts: {
      type: Array as PropType<ChartDefinition[]>,
      required: false,
      default: () => [],
    },
    dataSource: {
      type: Object as PropType<ITimeSeriesDataSource>,
      required: false,
      default: () => TimeSeriesDataSource.emptyDataSource(),
    },
    summaryBoxes: {
      type: Array as PropType<SummaryBox[]>,
      required: false,
      default: () => [],
    },
    analysisSections: {
      type: Array as PropType<AnalysisSection[]>,
      required: false,
      default: () => [],
    },
  },
  emits: ['new-period'],
  components: {
    CeTitle,
    CenteredSpinner,
    InfoColumn,
    TimeSeriesChartGroup,
    HistogramChart,
    HorizontalBarChart,
  },
  setup(props) {
    const periodOptions: PeriodOption[] = [
      { title: 'Last 30 days', value: CalendarPeriod.MONTH },
      { title: 'Last 12 months', value: CalendarPeriod.YEAR },
    ]

    return {
      periodOptions,
      summaryBgColor: NEUTRAL_50.hex,
      chartsBgColor: GRAY_COOL_50.hex,
      barColor: VELVET.hex,
      isLarge: useDisplay().xlAndUp,
      hasCharts: !!props.charts.length,
    }
  },
  methods: {
    handleNewPeriod(newPeriod: CalendarPeriod): void {
      this.$emit('new-period', newPeriod)
    },
  },
})
</script>

<style lang="scss">
.analysis-grid-container {
  display: grid;
  padding: 0 1.5rem 1.5rem;
  grid-template-columns: repeat(2, 1fr); /* 2 equal columns */
  gap: 1.5rem;
}
</style>
