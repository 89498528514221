<template>
  <div>
    <p class="text-caption pl-2 mb-3">
      Averages calculated for the month of {{ tableMonth }}
    </p>
    <ce-data-table
      :is-loading
      :headers
      :table="dataTable"
      :options
      :bg-color
      :sticky-offset="TOP_BAR_HEIGHT"
      sticky
      @new-options="handleNewOptions"
    >
      <!-- Status / Operating Envelope -->
      <template v-slot:[`item.${Columns.STATUS}`]="{ item }">
        <operating-envelope-badge
          v-if="isOperatingEnvelopeEnabled"
          :status="item[Columns.STATUS]"
        />
        <template v-else>{{ TEXT_NO_VALUE }}</template>
      </template>

      <!-- Communications -->
      <template
        v-if="isCommunicationStatusEnabled"
        v-slot:[`item.${Columns.COMMUNICATIONS}`]="{ item }"
      >
        <communication-status-badge
          :communication-status="item[Columns.COMMUNICATIONS]"
        />
      </template>
    </ce-data-table>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { DateTime } from 'luxon'
import { WHITE } from '@/constants/colors'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { TOP_BAR_HEIGHT } from '@/constants/topBar'
import { OperatingEnvelopeStatus } from '@/model/control/operatingEnvelope'
import CeDataTable from '@/components/common/CeDataTable.vue'
import OperatingEnvelopeBadge from '@/components/control/OperatingEnvelopeBadge.vue'
import CommunicationStatusBadge from '@/components/control/CommunicationStatusBadge.vue'
import {
  Columns,
  createHeaders,
  createInitialOptions,
  type CapacityDataTable,
  type CustomOptions,
} from '@/model/control/capacity'
import type { Group } from 'rfs/control/proto/model_pb'

export default defineComponent({
  name: 'CapacityDataTable',
  props: {
    group: {
      type: Object as PropType<Group>,
      required: true,
    },
    dataTable: {
      type: Object as PropType<CapacityDataTable>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    isOperatingEnvelopeEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isCommunicationStatusEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { CeDataTable, OperatingEnvelopeBadge, CommunicationStatusBadge },
  setup() {
    const lastMonth = DateTime.local().startOf('month').minus({ months: 1 })
    const tableMonth = lastMonth.toLocaleString({ month: 'long' })
    return {
      TEXT_NO_VALUE,
      TOP_BAR_HEIGHT,
      Columns,
      bgColor: WHITE.hex,
      lastMonth,
      tableMonth,
      OperatingEnvelopeStatus,
    }
  },
  data() {
    return { options: createInitialOptions() }
  },
  computed: {
    headers() {
      return createHeaders(this.group, this.isCommunicationStatusEnabled)
    },
  },
  methods: {
    handleNewOptions(newValue: CustomOptions): void {
      this.options = newValue
      this.$emit('new-options', newValue)
    },
  },
})
</script>
