// See //frontend/tile/tile.go for more details about this RPC

// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=js+dts"
// @generated from file frontend/proto/tile.proto (package ritta.frontend.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { TileAtTimeRequest, TileRequest, TileResponse } from "./tile_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service ritta.frontend.proto.Tile
 */
export const Tile = {
  typeName: "ritta.frontend.proto.Tile",
  methods: {
    /**
     * Returns one or more layers to show for the given tile.
     *
     * @generated from rpc ritta.frontend.proto.Tile.GetTile
     */
    getTile: {
      name: "GetTile",
      I: TileRequest,
      O: TileResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc ritta.frontend.proto.Tile.GetTileAtTime
     */
    getTileAtTime: {
      name: "GetTileAtTime",
      I: TileAtTimeRequest,
      O: TileResponse,
      kind: MethodKind.Unary,
    },
  }
};

