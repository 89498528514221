// gRPC services decribing subsets of an electrical system

// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file frontend/proto/grid.proto (package ritta.frontend.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { FilterBy, FilterOptions, GroupBy, OrderBy } from "./pagination_pb.js";
import { Resource } from "../../pb/resource_pb.js";
import { TimeSeries } from "./tsdb_pb.js";

/**
 * Scope identifies a subset of a grid
 *
 * @generated from message ritta.frontend.proto.Scope
 */
export const Scope = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.Scope",
  () => [
    { no: 1, name: "downline", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * GroupStats describes a collection of a single resource type.
 *
 * @generated from message ritta.frontend.proto.GroupStats
 */
export const GroupStats = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.GroupStats",
  () => [
    { no: 1, name: "resources", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "rated_wattage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * SummaryStats describes a portion of the grid consisting of different types of resources.
 *
 * @generated from message ritta.frontend.proto.SummaryStats
 */
export const SummaryStats = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.SummaryStats",
  () => [
    { no: 1, name: "meter", kind: "message", T: GroupStats },
    { no: 2, name: "solar", kind: "message", T: GroupStats },
  ],
);

/**
 * SummaryStatsAll returns all statistics for substations and feeders.
 *
 * @generated from message ritta.frontend.proto.SummaryStatsAll
 */
export const SummaryStatsAll = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.SummaryStatsAll",
  () => [
    { no: 1, name: "statistics", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: SummaryStats} },
  ],
);

/**
 * @generated from message ritta.frontend.proto.Resources
 */
export const Resources = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.Resources",
  () => [
    { no: 1, name: "types", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message ritta.frontend.proto.ResourceStats
 */
export const ResourceStats = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.ResourceStats",
  () => [
    { no: 1, name: "type_stats", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: GroupStats} },
  ],
);

/**
 * @generated from message ritta.frontend.proto.TableRequest
 */
export const TableRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.TableRequest",
  () => [
    { no: 1, name: "table_type", kind: "enum", T: proto3.getEnumType(TableRequest_Type) },
    { no: 2, name: "limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "offset", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "order_by", kind: "message", T: OrderBy },
    { no: 6, name: "search", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "filter_by", kind: "message", T: FilterBy, repeated: true },
    { no: 7, name: "group_by", kind: "message", T: GroupBy, repeated: true },
  ],
);

/**
 * The table type (DER, Substation, Transformer??)
 *
 * @generated from enum ritta.frontend.proto.TableRequest.Type
 */
export const TableRequest_Type = /*@__PURE__*/ proto3.makeEnum(
  "ritta.frontend.proto.TableRequest.Type",
  [
    {no: 0, name: "NONE"},
    {no: 1, name: "DERS"},
    {no: 2, name: "SUBSTATIONS"},
  ],
);

/**
 * @generated from message ritta.frontend.proto.TableResponse
 */
export const TableResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.TableResponse",
  () => [
    { no: 1, name: "rows", kind: "message", T: TableResponse_TableRow, repeated: true },
    { no: 2, name: "next_offset", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "total_rows", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "export_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "filter_options", kind: "message", T: FilterOptions, repeated: true },
  ],
);

/**
 * rows of Resource + TimeSeries
 *
 * @generated from message ritta.frontend.proto.TableResponse.TableRow
 */
export const TableResponse_TableRow = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.TableResponse.TableRow",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "extended_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "resource", kind: "message", T: Resource },
    { no: 4, name: "time_series", kind: "message", T: TimeSeries },
  ],
  {localName: "TableResponse_TableRow"},
);

/**
 * @generated from message ritta.frontend.proto.DownlineRequest
 */
export const DownlineRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.DownlineRequest",
  () => [
    { no: 1, name: "upline_resource", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "resource_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "start", kind: "message", T: Timestamp },
    { no: 4, name: "end", kind: "message", T: Timestamp },
    { no: 5, name: "limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "offset", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "order_by", kind: "message", T: OrderBy },
    { no: 8, name: "filter_by", kind: "message", T: FilterBy, repeated: true },
  ],
);

/**
 * @generated from message ritta.frontend.proto.DownlineResponse
 */
export const DownlineResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.DownlineResponse",
  () => [
    { no: 1, name: "rows", kind: "message", T: DownlineResponse_TableRow, repeated: true },
    { no: 2, name: "next_offset", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "total_rows", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "export_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "filter_options", kind: "message", T: FilterOptions, repeated: true },
  ],
);

/**
 * rows of Resource + TimeSeries
 *
 * @generated from message ritta.frontend.proto.DownlineResponse.TableRow
 */
export const DownlineResponse_TableRow = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.DownlineResponse.TableRow",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "resource", kind: "message", T: Resource },
    { no: 3, name: "ratings_battery", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "ratings_charger", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "ratings_solar", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "stats", kind: "message", T: DownlineResponse_MeterStats },
  ],
  {localName: "DownlineResponse_TableRow"},
);

/**
 * @generated from message ritta.frontend.proto.DownlineResponse.MeterStats
 */
export const DownlineResponse_MeterStats = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.DownlineResponse.MeterStats",
  () => [
    { no: 2, name: "min_power", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "max_power", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "mean_power", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "over_voltage_pct", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "under_voltage_pct", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
  {localName: "DownlineResponse_MeterStats"},
);

