import { debounce } from 'lodash-es'

/**
 * A simple polyfill for the `Promise.withResolvers` method that is brand new
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/withResolvers
 */
export function withResolvers<T>(): PromiseWithResolvers<T> {
  if (Promise.withResolvers) {
    return Promise.withResolvers<T>()
  }
  const result = {} as PromiseWithResolvers<T>
  result.promise = new Promise((resolve, reject) => {
    result.resolve = resolve
    result.reject = reject
  })
  return result
}

/**
 * Create an AsyncIterable that returns the result of each promise as it is
 * resolved or rejected. This is similar to `Promise.race` but returns a new
 * Promise each time one is settled.
 */
export async function* raceAll<T>(input: Promise<T>[]) {
  const proxies = [] as PromiseWithResolvers<T>[]
  let next = 0

  for (const p of input) {
    // Create a proxy promise for each input promise
    proxies.push(withResolvers<T>())
    // When the input promise is settled, resolve or reject the next proxy promise
    p.then(
      (value) => proxies[next++].resolve(value),
      (reason) => proxies[next++].reject(reason)
    )
  }

  for (const p of proxies) {
    yield p.promise
  }
}

type AsyncFn = (...args: any[]) => Promise<any>

/**
 * Return a function which debounces an async function.
 * The debounce is "trailing", which means only the last invocation is made.
 * If you want a leading debounce, you can use lodash `debounce` directly.
 */
export function debounceAsync<T extends AsyncFn>(func: T, wait: number) {
  type V = Awaited<ReturnType<T> | undefined>
  let lastPromise: PromiseWithResolvers<V> | undefined
  // A trailing debounce cannot return a value, since it is invoked in a timeout.
  // So we need to resolve the last promise we gave out.
  const funcDebounced = debounce(
    (...args: Parameters<T>) => {
      lastPromise!.resolve(func(...args))
      // Clear the state for the next call
      lastPromise = undefined
    },
    wait,
    { trailing: true }
  )
  // So wrap the `undefined` with a Promise so it can be used with `await`
  return (...args: Parameters<T>) => {
    funcDebounced(...args) // will always return undefined since it's a trailing call
    // The previous call's promise is resolved
    lastPromise?.resolve(undefined)
    // and we create a new one for the next call
    lastPromise = withResolvers()

    return lastPromise.promise
  }
}
