<template>
  <div class="c-PeakDemandDataTable">
    <!-- Data table -->
    <v-data-table
      :headers="dataTableHeaders"
      :items="dataTableItems"
      :item-value="itemValue"
      :row-props="generateRowProps"
      :items-per-page="15"
      :loading="isLoading ? 'primary' : undefined"
      show-expand
    >
      <!-- Custom "Label" cell -->
      <template v-slot:[`item.label`]="{ item }">
        <span :style="cellLabelTextColor">
          <b>{{ item.label }}</b>
        </span>
      </template>

      <!-- Custom "Forecast demand" cell -->
      <template v-slot:[`item.demandForecast`]="{ item }">
        <router-link v-if="item.to" :to="item.to">
          {{ item.demandForecast }}
        </router-link>
        <span v-else>
          {{ item.demandForecast }}
        </span>
      </template>

      <!-- Expanded slot -->
      <template v-slot:expanded-row="{ item }">
        <tr>
          <td class="border-0"></td>
          <td :colspan="dataTableHeaders.length - 1" class="pa-0">
            <ce-data-table
              :headers="expandedHeaders"
              :table="createExpandedDataTable(item)"
              :show-pagination="false"
              ultra-dense
              class="py-12 px-6"
            >
              <template v-slot:above-actions>
                <h3>Forecasted</h3>
              </template>
            </ce-data-table>
          </td>
        </tr>
      </template>

      <!-- Hide Footer -->
      <template v-slot:bottom></template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { DateTime } from 'luxon'
import { defineComponent, PropType } from 'vue'
import { LocationAsRelativeRaw as VueRouterLocation } from 'vue-router'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { DataTableHeader as VuetifyDataTableHeader } from '@/types/vuetify3'
import { formatPeakHours } from '@/model/forecast/HistoricalContextChartDataProvider'
import { ColumnType } from '@/model/tables/column'
import {
  Header as ExpandedHeader,
  DataTable as ExpandedDataTable,
} from '@/model/tables/DataTable'
import {
  SystemPeakDemand,
  SystemPeakDemandDetail_HourlyData as HourlyData,
  SystemPeakDemandResponse,
} from 'rfs/frontend/proto/forecast_pb'
import { Date as GDate } from 'rfs/pb/calendar_pb'
import { PeakDemandLabel, populateLabels } from '@/services/forecast.service'
import { Format, MEGA_OPTIONS } from '@/utils/format'
import { offsetNameShort } from '@/utils/time'
import CeDataTable from '@/components/common/CeDataTable.vue'

export type PeakDemandDataTableItem = {
  id: string
  label: string
  date: string
  peakHoursForecast: string
  peakHoursObserved: string
  demandForecast: string
  demandActual: string
  likelihood: string
  cssClass?: string | string[]
  to?: VueRouterLocation
}

const MAX_FORECAST_ROWS = 7
const MAX_HISTORIC_ROWS = 5

export default defineComponent({
  name: 'PeakDemandDataTable',
  props: {
    response: {
      type: Object as PropType<SystemPeakDemandResponse>,
      required: true,
    },
    yearly: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { CeDataTable },
  setup(props) {
    const expandedHeaders: ExpandedHeader[] = [
      { title: 'Rank', key: 'rank', width: '128px' },
      {
        title: 'Hour',
        key: 'hour',
        value: (item) => formatPeakHours([item.hour]),
      },
      {
        title: 'Load (MW)',
        key: 'forecastDemand',
        valueType: ColumnType.MEGA_W,
        sortable: false,
      },
      {
        title: '% of daily peak',
        key: 'percentOfPeak',
        valueType: ColumnType.PERCENT,
        sortable: false,
      },
      {
        title: 'MW diff from peak',
        key: 'wattsDiffFromPeak',
        valueType: ColumnType.MEGA_W,
        sortable: false,
      },
    ]

    const getDetails = (parentItem: PeakDemandDataTableItem) => {
      return props.response.details[parentItem.date]
    }

    const timezoneShortString = offsetNameShort()

    const dataTableHeaders: VuetifyDataTableHeader[] = [
      {
        title: '',
        value: 'label',
        sortable: false,
      },
      {
        title: `Date (${timezoneShortString})`,
        value: 'date',
        sortable: false,
      },
      {
        title: `Peak hour (${timezoneShortString}), forecasted`,
        value: 'peakHoursForecast',
        sortable: false,
      },
      {
        title: `Peak hour (${timezoneShortString}), observed`,
        value: 'peakHoursObserved',
        sortable: false,
      },
      {
        title: 'Demand, forecasted',
        value: 'demandForecast',
        sortable: false,
      },
      {
        title: 'Demand, observed',
        value: 'demandActual',
        sortable: false,
      },
      {
        title: 'Peak classification',
        value: 'likelihood',
        sortable: false,
      },
      {
        title: '',
        key: 'data-table-expand',
        cellProps: (opts: { item: PeakDemandDataTableItem }) => {
          return !getDetails(opts.item)?.rankedData.length
            ? { class: 'hide-expand-btn' }
            : {}
        },
      },
    ]

    return {
      dataTableHeaders,
      expandedHeaders,
      getDetails,
      cellLabelTextColor: 'color: rgba(0, 0, 0, 0.6)',
      itemValue: 'id', // property name.
      noValueString: TEXT_NO_VALUE,
      labels: {
        MTD_PEAK: 'Month-to-date peak',
        YTD_PEAK: 'Year-to-date peak',
        FORECAST: 'Forecast',
        EMPTY: '',
      },
      ids: {
        MTD_PEAK: 'mtdpeak',
        FORECAST: 'forecast',
        HISTORICAL: 'historical',
      },
      cssClasses: {
        MTD: 'row-mtd',
        FORECAST: 'row-forecast',
        FORECAST_LAST_ITEM: 'row-forecast__last-row',
        HISTORICAL: 'row-historical',
        LIKELY_OR_VERY_LIKELY: 'row-likely',
      },
    }
  },
  computed: {
    peakDemandItems(): SystemPeakDemand[] {
      return populateLabels(this.response.data)
    },
    dataTableItems(): PeakDemandDataTableItem[] {
      return [
        this.createMTDPeakItem(),
        ...this.createForecastItems(),
        ...this.createHistoricalItems(),
      ]
    },
  },
  methods: {
    createExpandedDataTable(
      parentItem: PeakDemandDataTableItem
    ): ExpandedDataTable {
      let rankedData = this.getDetails(parentItem)?.rankedData ?? []
      // Get the original item to get compute the ratio of peak demand
      const peakDemandItem = this.peakDemandItems.find(
        (p) => this.formatDate(p.date) === parentItem.date
      )
      // Adjust the hourly demand to match the peak demand in the main table
      rankedData = adjustDemand(peakDemandItem!, rankedData)

      return {
        rows: rankedData.map((rd, index) => {
          const rank = (index + 1).toString()

          return { ...rd, rank, id: rank }
        }),
      }
    },
    generateRowProps(row: { item: PeakDemandDataTableItem }): {
      [key: string]: any
    } {
      return { class: row.item.cssClass }
    },
    createMTDPeakItem(): PeakDemandDataTableItem {
      const { MTD_PEAK, YTD_PEAK } = this.labels
      // The peak demand item is the first item in the list
      const mtdPeakItem = this.peakDemandItems[0]

      // NOTE(rafael): darker border-bottom to separate MTD item from Forecast items.
      const cssClass: string[] = [this.cssClasses.MTD]

      if (mtdPeakItem === undefined) {
        return this.createDataTableItem({
          id: this.ids.MTD_PEAK,
          label: this.yearly ? YTD_PEAK : MTD_PEAK,
          cssClass,
        })
      } else {
        return this.createDataTableItem({
          id: this.ids.MTD_PEAK,
          label: this.yearly ? YTD_PEAK : MTD_PEAK,
          date: this.formatDate(mtdPeakItem.date),
          peakHoursForecast: formatPeakHours(mtdPeakItem.peakHoursForecast),
          peakHoursObserved: formatPeakHours(mtdPeakItem.peakHoursObserved),
          demandForecast: this.formatDemand(mtdPeakItem.forecastDemand),
          demandActual: this.formatDemand(mtdPeakItem.actualDemand),
          likelihood: mtdPeakItem.monthlyPeak,
          cssClass,
        })
      }
    },
    createForecastItems(): PeakDemandDataTableItem[] {
      // Limit the number of items we show to 4 days
      const forecastItems = this.peakDemandItems
        .filter((p) => p.label === PeakDemandLabel.FORECAST)
        .slice(-MAX_FORECAST_ROWS)

      const rows: PeakDemandDataTableItem[] = []

      // When no items, create at least one empty row so
      // the data table shows with the expected layout.
      if (!forecastItems.length) {
        rows.push(
          this.createDataTableItem({
            id: this.ids.FORECAST,
            label: this.labels.FORECAST,
            // NOTE(rafael): Makes the "Forecast" row to have darker
            // border-bottom.
            cssClass: [this.cssClasses.FORECAST_LAST_ITEM],
          })
        )
      } else {
        for (const [index, item] of forecastItems.entries()) {
          const cssClass: string[] = [this.cssClasses.FORECAST]

          if (
            item.monthlyPeak === 'Likely' ||
            item.monthlyPeak === 'Very Likely'
          ) {
            cssClass.push(this.cssClasses.LIKELY_OR_VERY_LIKELY)
          }

          // NOTE(rafael): Makes the "Forecast" row to have darker
          // border-bottom.
          const isLastItem = index === forecastItems.length - 1
          if (isLastItem) {
            cssClass.push(this.cssClasses.FORECAST_LAST_ITEM)
          }

          const row = this.createDataTableItem({
            id: `${this.ids.FORECAST}-${index.toString()}`,
            // NOTE(rafael): Layout requirement: there should be a label only
            // on the first item.
            label: index === 0 ? this.labels.FORECAST : this.labels.EMPTY,
            date: this.formatDate(item.date),
            peakHoursForecast: formatPeakHours(item.peakHoursForecast),
            peakHoursObserved: formatPeakHours(item.peakHoursObserved),
            demandForecast: this.formatDemand(item.forecastDemand),
            demandActual: this.noValueString,
            likelihood: item.monthlyPeak,
            cssClass,
          })

          rows.push(row)
        }
      }

      return rows
    },
    createHistoricalItems(): PeakDemandDataTableItem[] {
      // Also limit the number of historic items since 1CP has 365 total items
      const historicalItems = this.peakDemandItems
        .filter((p) => p.label === PeakDemandLabel.OBSERVED)
        .slice(0, MAX_HISTORIC_ROWS)

      const rows: PeakDemandDataTableItem[] = []

      // When no items, create at least one empty row so
      // the data table shows with the expected layout.
      if (!historicalItems.length) {
        rows.push(
          this.createDataTableItem({
            id: this.ids.HISTORICAL,
            label: this.createHistoricalLabel(1),
          })
        )
      } else {
        for (const [index, item] of historicalItems.entries()) {
          const row = this.createDataTableItem({
            id: `${this.ids.HISTORICAL}-${index.toString()}`,
            // NOTE(rafael): Layout requirement: there should be a label only
            // on the first item.
            label:
              index === 0
                ? this.createHistoricalLabel(historicalItems.length)
                : this.labels.EMPTY,
            date: this.formatDate(item.date),
            peakHoursForecast: formatPeakHours(item.peakHoursForecast),
            peakHoursObserved: formatPeakHours(item.peakHoursObserved),
            demandForecast: this.formatDemand(item.forecastDemand),
            demandActual: this.formatDemand(item.actualDemand),
            likelihood: item.monthlyPeak,
            cssClass: this.cssClasses.HISTORICAL,
          })

          rows.push(row)
        }
      }

      return rows
    },
    createDataTableItem(params: {
      id: string
      label: string
      date?: string
      peakHoursForecast?: string
      peakHoursObserved?: string
      demandForecast?: string
      demandActual?: string
      likelihood?: string
      cssClass?: string | string[]
      to?: VueRouterLocation
    }): PeakDemandDataTableItem {
      return {
        id: params.id,
        label: params.label,
        date: params.date ?? this.noValueString,
        peakHoursForecast: params.peakHoursForecast ?? this.noValueString,
        peakHoursObserved: params.peakHoursObserved ?? this.noValueString,
        demandForecast: params.demandForecast ?? this.noValueString,
        demandActual: params.demandActual ?? this.noValueString,
        likelihood: params.likelihood ?? this.noValueString,
        cssClass: params.cssClass,
        to: params.to,
      }
    },
    createHistoricalLabel(numberOfItems: number) {
      if (numberOfItems <= 1) {
        return `Observed, last 1 day`
      }
      return `Observed, last ${numberOfItems} days`
    },
    formatDate(value: GDate | undefined): string {
      if (value === undefined) return this.noValueString

      const { year, month, day } = value
      return DateTime.local(year, month, day).toFormat('yyyy-MM-dd')
    },
    formatDemand(value: number): string {
      const formatted = Format.fmtWatts(value, MEGA_OPTIONS)

      if (value <= 0 || formatted === '') {
        return this.noValueString
      } else {
        return formatted
      }
    },
  },
})

function adjustDemand(
  peakItem: SystemPeakDemand,
  rankedData: HourlyData[]
): HourlyData[] {
  // If rankedData is empty, the ratio won't be used
  const ratio = peakItem.forecastDemand / rankedData[0]?.forecastDemand

  return rankedData.map((hd) => {
    hd = hd.clone()
    hd.forecastDemand *= ratio
    hd.wattsDiffFromPeak *= ratio
    // The `percentOfPeak` doesn't need an adjustment
    return hd
  })
}
</script>

<style lang="scss">
.c-PeakDemandDataTable {
  /* Vuetify's Data table custom styles */
  .v-data-table {
    tbody tr {
      &:hover {
        background-color: var(--ce-primary-color-very-low-opacity);
      }
    }

    // Bottom border of the table.
    table {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
    }

    // Makes the table's header row, the "MTD" row and the last "Forecast" row
    // to have darker border-bottom.
    table {
      th,
      tr.row-forecast__last-row > td,
      tr.row-mtd > td {
        border-bottom: thin solid rgba(0, 0, 0, 0.36) !important;
      }
    }

    // Removes the border bottom of the first cells of the Forecast and Historical
    // items.
    tbody {
      // Preserves the border-bottom of the last "Forecast" row.
      tr.row-forecast:not(.row-forecast__last-row),
      tr.row-historical {
        td:first-of-type {
          border-bottom: thin solid white !important;
        }
      }
    }

    // Makes the "Forecast" rows italic text.
    tbody {
      tr.row-forecast {
        font-style: italic;
      }
    }

    // Removes padding-left of the second column (Date) cells.
    thead {
      tr {
        th:nth-of-type(2) {
          padding-left: 0;
        }
      }
    }
    tbody {
      tr {
        td:nth-of-type(2) {
          padding-left: 0;
        }
      }
    }

    // Different background-color for "Likely" cells.
    tbody {
      tr.row-likely {
        td:nth-of-type(6) {
          background-color: #faecc2;
        }
      }
    }

    // Makes the cell "Monthly peak?"  of the MTD row italic.
    tbody {
      tr:first-of-type {
        td:nth-of-type(6) {
          font-style: italic;
        }
      }
    }
  }

  td.hide-expand-btn {
    button {
      display: none;
    }
  }
}
</style>
