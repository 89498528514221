<template>
  <div class="c-RecurringWaypointForm">
    <!-- Table -->
    <ce-data-table
      v-if="table.rows.length"
      :headers
      :table
      :bg-color="bgColor"
      :show-pagination="false"
      :sticky-offset="TOP_BAR_HEIGHT"
      sticky
      dense
      border-bottom
      class="pb-4"
    >
      <!-- Custom rows -->
      <template v-slot:item="{ item, index }">
        <automatic-control-row-edit
          v-if="indexOfWaypointToEdit === index && formFields"
          :index="index"
          :metric="metric"
          v-model:start-time="formFields.startTime"
          v-model:end-time="formFields.endTime"
          v-model:target-value="formFields.targetValue"
          :validation="formValidation"
          @cancel="cancelEdit"
          @save="saveEditted"
        />
        <automatic-control-row
          v-else
          :row="item"
          :is-disabled="isSubmitting || isRowBeingEditted"
          @edit="() => handleClickEdit(index)"
          @delete="() => handleClickDelete(index)"
        />
      </template>
    </ce-data-table>

    <!-- Overlap errors -->
    <alert-error
      data-test="overlap"
      v-for="(err, index) of overlapErrors"
      :key="index"
      class="mb-2"
    >
      {{ err }}
    </alert-error>

    <!-- Server Failed -->
    <alert-error v-if="failedMsg" data-test="failedMsg" class="mb-2">
      {{ failedMsg }}
    </alert-error>

    <!-- Action buttons -->
    <form-actions
      ref="formActions"
      :deviceType
      :metric
      :isSubmitting
      :is-editting="isRowBeingEditted"
      :is-touched="hasTouchedWaypoints || hasTouchedEnvelopeOpts"
      :has-overlaps="Boolean(overlapErrors.length)"
      @add="addNewWaypoint"
      @cancel="$emit('cancel')"
      @submit="openSubmitConfirmDialog"
    >
      <!-- Operating Envelope -->
      <envelope-status-radio-group
        v-if="currentOEStatus !== undefined && showEnvelopeStatusForm"
        :model-value="selectedRadioOption"
        :currentOEStatus
        :isSubmitting
        @update:model-value="handleNewEnvelopeOption"
      />
    </form-actions>

    <!-- Modal Confirmation -->
    <dialog-confirm
      v-if="dialogState"
      :title="dialogState.title"
      :body="dialogState.body"
      @cancel="() => closeDialog('cancel')"
      @confirm="() => closeDialog('confirm')"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, shallowReactive } from 'vue'
import { Interval } from 'luxon'
import { useGlobalSnackbar } from '@/stores/globalSnackbar'
import { NEUTRAL_50 } from '@/constants/colors'
import { TOP_BAR_HEIGHT } from '@/constants/topBar'
import type { OperatingEnvelopeStatus } from '@/model/control/operatingEnvelope'
import {
  Metric,
  validateRecurringOverlapping,
  sortRecurringByStartTime,
  getSubmitScheduleConfirmationMessage,
  confirmationTitle,
  changeRouteMessage,
  getScheduleSuccessMessage,
} from '@/model/control/waypoint'
import {
  createAutomaticControlDataTable,
  createAutomaticControlHeaders,
} from '@/model/control/WaypointDataTable'
import { WaypointFormFields } from '@/model/control/waypointFormFields'
import AlertError from '@/components/common/AlertError.vue'
import CeDataTable from '@/components/common/CeDataTable.vue'
import AutomaticControlRow from '@/components/control/AutomaticControlRow.vue'
import AutomaticControlRowEdit from '@/components/control/AutomaticControlRowEdit.vue'
import FormActions from '@/components/control/FormActions.vue'
import DialogConfirm from '@/components/common/DialogConfirm.vue'
import EnvelopeStatusRadioGroup, {
  RADIO_OPTION_ACTIVATE,
  RADIO_OPTION_RETAIN,
  type RadioOption,
} from '@/components/control/EnvelopeStatusRadioGroup.vue'
import { Policy, Params as PolicyParams } from 'rfs/control/proto/policy_pb'
import { ScheduleWaypoint } from 'rfs/control/proto/control_service_pb'
import type { Device, DeviceType } from 'rfs/control/proto/model_pb'

type DialogProps = {
  title: string
  body: string
  confirmCb: Function
  cancelCb?: Function
}

const DEFAULT_ERR_MSG = 'Something went wrong.'

// NOTE(rafael): These are the metrics that this component supports for now.
export const validMetrics = [
  Metric.METRIC_MAX_CHARGE_POWER_WATTS,
  Metric.METRIC_SOC_PERCENT,
  Metric.METRIC_SOC_WATT_HOURS,
  Metric.METRIC_ACTIVE_POWER_WATTS,
]

export default defineComponent({
  name: 'RecurringWaypointForm',
  props: {
    groupId: {
      type: String,
      required: true,
    },
    futureInterval: {
      type: Object as PropType<Interval>,
      required: true,
    },
    metric: {
      type: Number as PropType<Metric>,
      required: true,
      validator(value, _props) {
        return validMetrics.includes(value as Metric)
      },
    },
    deviceType: {
      type: Number as PropType<DeviceType>,
      required: true,
    },
    initialWaypoints: {
      type: Array as PropType<ScheduleWaypoint[]>,
      required: false,
      default: () => [],
    },
    powerRating: {
      type: Number,
      required: false,
    },
    devices: {
      type: Array as PropType<Device[]>,
      required: false,
      default: () => [],
    },
    currentOEStatus: {
      type: String as PropType<OperatingEnvelopeStatus>,
      required: false,
    },
  },
  components: {
    AlertError,
    CeDataTable,
    AutomaticControlRow,
    AutomaticControlRowEdit,
    FormActions,
    DialogConfirm,
    EnvelopeStatusRadioGroup,
  },
  setup() {
    return {
      TOP_BAR_HEIGHT,
      globalSnackbarStore: useGlobalSnackbar(),
      bgColor: NEUTRAL_50.hex,
    }
  },
  data() {
    return shallowReactive({
      isSubmitting: false,
      hasSubmittingFailed: false,
      failedMsg: null as null | string,
      selectedRadioOption: RADIO_OPTION_RETAIN as RadioOption,
      isAddingNewWaypoint: false,
      hasTouchedWaypoints: false,
      hasTouchedEnvelopeOpts: false,
      indexOfWaypointToEdit: undefined as undefined | number,
      dialogState: null as null | DialogProps,
      waypoints: [...this.initialWaypoints],
      //
      formFields: undefined as
        | undefined
        | ReturnType<typeof shallowReactive<WaypointFormFields>>,
      formValidation: undefined as
        | undefined
        | ReturnType<typeof WaypointFormFields.prototype.validate>,
    })
  },
  computed: {
    table() {
      return createAutomaticControlDataTable({
        metric: this.metric,
        recurringWaypoints: this.waypoints,
      })
    },
    headers() {
      return createAutomaticControlHeaders({
        metric: this.metric,
        recurring: true,
        powerRating: this.powerRating,
        deviceType: this.deviceType,
      })
    },
    overlapErrors(): string[] {
      return validateRecurringOverlapping(this.waypoints)
    },
    isRowBeingEditted(): boolean {
      return this.indexOfWaypointToEdit !== undefined
    },
    showEnvelopeStatusForm(): boolean {
      const cond1 = this.currentOEStatus !== undefined
      const cond2 = this.isAddingNewWaypoint && this.waypoints.length - 1
      const cond3 = !this.isAddingNewWaypoint && this.waypoints.length
      return Boolean(cond1 && (cond2 || cond3))
    },
  },
  watch: {
    initialWaypoints(): void {
      if (this.hasTouchedWaypoints || this.isRowBeingEditted) return

      this.resetState()
    },
  },
  mounted(): void {
    this.focusBtnAddWaypoint()
  },
  methods: {
    shouldChangeRoute(next: Function): void {
      if (this.hasTouchedWaypoints) {
        this.dialogState = {
          title: confirmationTitle,
          body: changeRouteMessage,
          cancelCb: () => next(false),
          confirmCb: () => next(),
        }
      } else {
        next()
      }
    },
    handleClickEdit(index: number): void {
      const recurringWaypoint = this.waypoints?.[index]

      if (!recurringWaypoint) throw new Error('recurring waypoint expected')

      this.indexOfWaypointToEdit = index

      this.formFields = shallowReactive(
        new WaypointFormFields({
          recurringWaypoint,
          metric: this.metric,
          deviceType: this.deviceType,
          powerRating: this.powerRating,
          config: {
            getNow: () => this.futureInterval.start,
            getMinStart: () => this.futureInterval.start,
            getMaxEnd: () => this.futureInterval.end,
          },
        })
      )

      this.formValidation = undefined
    },
    cancelEdit(): void {
      // When the user chooses to cancel the addition of a new Waypoint,
      // delete the new Waypoint from the list of Waypoints since
      // it's an empty Waypoint.
      if (this.isAddingNewWaypoint) {
        const indexOfLastWaypoint = this.waypoints.length - 1
        const newWaypoints = [...this.waypoints]
        newWaypoints.splice(indexOfLastWaypoint, 1)
        this.waypoints = newWaypoints
        this.isAddingNewWaypoint = false
      }

      this.indexOfWaypointToEdit = undefined
      this.formFields = undefined
      this.formValidation = undefined

      this.focusBtnAddWaypoint()
    },
    saveEditted(): void {
      if (!this.formFields) {
        throw new Error(
          'RecurringWaypointForm.saveEditted: unavailable formFields'
        )
      }

      const validation = this.formFields.validate()

      if (!validation.isValid) {
        this.formValidation = validation
        return
      }

      const edittedWaypoint = this.formFields.generateRecurringWaypoint()

      const newWaypoints = [...this.waypoints]

      if (this.indexOfWaypointToEdit === undefined) {
        throw new Error(
          'RecurringWaypointForm.saveEditted: unexpected "undefined" index'
        )
      }

      newWaypoints[this.indexOfWaypointToEdit] = edittedWaypoint

      this.waypoints = newWaypoints
      this.indexOfWaypointToEdit = undefined
      this.formFields = undefined
      this.formValidation = undefined
      this.isAddingNewWaypoint = false

      this.handleTouchedWaypoints()

      this.focusBtnAddWaypoint()
    },
    focusBtnAddWaypoint(): void {
      ;(this.$refs.formActions as any)?.focusBtnAdd?.()
    },
    addNewWaypoint(): void {
      this.isAddingNewWaypoint = true

      const pristineWaypoint = new ScheduleWaypoint()

      const newWaypoints = [...this.waypoints, pristineWaypoint]

      this.waypoints = newWaypoints

      const lastIndex = newWaypoints.length - 1

      this.$nextTick(() => {
        this.handleClickEdit(lastIndex)
      })
    },
    handleNewEnvelopeOption(newOption: RadioOption): void {
      this.hasTouchedEnvelopeOpts = true
      this.selectedRadioOption = newOption
    },
    handleClickDelete(index: number): void {
      const newWaypoints = [...this.waypoints]
      newWaypoints.splice(index, 1)
      this.waypoints = newWaypoints
      this.handleTouchedWaypoints()
    },
    async handleTouchedWaypoints(): Promise<void> {
      this.hasTouchedWaypoints = true

      if (this.overlapErrors.length) return

      this.$emit('new-waypoints', sortRecurringByStartTime(this.waypoints))
    },
    resetState(): void {
      this.waypoints = [...this.initialWaypoints]
      this.hasTouchedWaypoints = false
      this.indexOfWaypointToEdit = undefined
      this.formFields = undefined
      this.formValidation = undefined
    },
    closeDialog(opt: 'cancel' | 'confirm'): void {
      if (opt === 'confirm') {
        this.dialogState?.confirmCb()
      } else if (opt === 'cancel') {
        this.dialogState?.cancelCb?.()
      } else {
        throw new Error('RecurringWaypointForm.closeDialog: unexpected option')
      }

      this.dialogState = null
    },
    openSubmitConfirmDialog(): void {
      this.dialogState = {
        title: confirmationTitle,
        body: getSubmitScheduleConfirmationMessage({
          metric: this.metric,
          deviceType: this.deviceType,
          hasWaypoints: !!this.waypoints.length,
        }),
        confirmCb: this.submitNewRecurringSchedule,
      }
    },
    async submitNewRecurringSchedule(): Promise<void> {
      if (this.overlapErrors.length) return

      this.$emit('submitting', true)
      this.isSubmitting = true
      this.hasSubmittingFailed = false
      this.failedMsg = null

      try {
        // Operating Envelope.
        await this.submitOperatingEnvelope()

        const { schedule } = await this.$services.control.createSchedule({
          groupId: this.groupId,
          metric: this.metric,
          timezone: this.$rittaConfig.customer.timeZone,
          waypoints: this.waypoints,
        })

        if (!schedule) throw new Error('`schedule` was not created as expected')

        await this.$services.control.setGroupPolicy({
          ids: [this.groupId],
          policy: Policy.AUTOMATIC_CONTROL_EVENT,
          params: new PolicyParams({
            paramsOneof: {
              case: 'automaticControlEvent',
              value: { scheduleId: schedule.id },
            },
          }),
        })

        this.handleSubmitSuccess()
      } catch (err) {
        this.hasSubmittingFailed = true
        const failedMsg = (err as any).message || DEFAULT_ERR_MSG
        this.failedMsg = failedMsg
        this.globalSnackbarStore.openSnackbar(failedMsg, 'error')
        console.error(
          'RecurringWaypointForm.submitNewRecurringSchedule: %o',
          err
        )
      } finally {
        this.$emit('submitting', false)
        this.isSubmitting = false
      }
    },
    async submitOperatingEnvelope(): Promise<void> {
      // Do nothing.
      if (
        !this.currentOEStatus === undefined ||
        this.selectedRadioOption === RADIO_OPTION_RETAIN
      ) {
        return
      }

      if (!this.devices.length) throw new Error('no devices available')

      // Submit.
      await this.$services.control.updateDevices({
        updates: this.devices.map((d) => {
          return {
            deviceId: d.id,
            oeEnabled: this.selectedRadioOption === RADIO_OPTION_ACTIVATE,
          }
        }),
      })
    },
    handleSubmitSuccess(): void {
      this.globalSnackbarStore.openSnackbar(
        getScheduleSuccessMessage({
          metric: this.metric,
          deviceType: this.deviceType,
          isRecurring: true,
        }),
        'info'
      )
      this.$emit('success')
    },
  },
})
</script>
