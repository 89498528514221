<template>
  <section aria-label="Impacts">
    <!-- Resources Downline table -->
    <resource-impacts-summary-table :impact :is-loading />

    <!-- Charts -->
    <resource-impacts-charts
      :is-loading
      :resource
      :impact
      :interval
      @new-interval="handleNewInterval"
    />

    <!-- Divider -->
    <v-divider class="my-6" :color="NEUTRAL_200.hex" style="opacity: 1" />

    <!-- Downline Meters -->
    <resource-impacts-meters-table
      :resource
      :impact
      :interval
      @new-interval="handleNewInterval"
    />
  </section>
</template>

<script lang="ts">
import { defineComponent, shallowReactive, PropType } from 'vue'
import { mapState } from 'pinia'
import { Interval } from 'luxon'
import { NEUTRAL_200 } from '@/constants/colors'
import { Timestamp } from '@/services/timestamp_pb'
import { useGlobalSnackbar } from '@/stores/globalSnackbar'
import { useGridImpactsStore } from '@/stores/gridImpacts'
import { ResourceImpactResponse } from 'rfs/frontend/proto/analysis_pb'
import type { Resource } from 'rfs/pb/resource_pb'
import { intervalLastPeriod, periodFromLastInterval } from '@/utils/time'
import ResourceImpactsSummaryTable from './ResourceImpactsSummaryTable.vue'
import ResourceImpactsCharts from './ResourceImpactsCharts.vue'
import ResourceImpactsMetersTable from './ResourceImpactsMetersTable.vue'

const errNoImpactData = `AMI aggregations not available for this device
 because connectivity could not be established for the feeder,
 or because this device type is not part of the system connectivity model.`

export default defineComponent({
  name: 'GridImpactResource',
  props: {
    resource: {
      type: Object as PropType<Resource>,
      required: true,
    },
  },
  components: {
    ResourceImpactsSummaryTable,
    ResourceImpactsCharts,
    ResourceImpactsMetersTable,
  },
  setup() {
    return { NEUTRAL_200 }
  },
  data() {
    // On first render, we copy the values from the Grid Store into local state.
    // Subsequent changes will be applied locally only,
    // without affecting the global store.
    const store = useGridImpactsStore()

    // The initial interval is based on the table's period (last 30 days or last 12 months).
    return shallowReactive({
      interval: intervalLastPeriod(store.period, this.$observationTime()),
      isLoading: false,
      impact: new ResourceImpactResponse(),
    })
  },
  computed: {
    ...mapState(useGridImpactsStore, ['period', 'modelParams']),
  },
  watch: {
    resource: {
      immediate: true,
      handler: function () {
        this.fetchData({ isNewResource: true })
      },
    },
    interval() {
      this.fetchData()
    },
  },
  methods: {
    handleNewInterval(newInterval: Interval): void {
      this.interval = newInterval
    },
    async fetchData(opts?: { isNewResource: boolean }): Promise<void> {
      this.isLoading = true

      // When the resource or interval changes, clear the impact data,
      // since it is no longer relevant
      this.impact = new ResourceImpactResponse()

      try {
        const request = {
          resource: this.resource.id,
          start: Timestamp.fromDateTime(this.interval.start),
          end: Timestamp.fromDateTime(this.interval.end),
          // If the interval matches a period, we can request it.
          fixedInterval: periodFromLastInterval(this.interval),
        }
        if (opts?.isNewResource) {
          // For a new resource, fetch the data right away
          this.impact =
            await this.$services.analysisService.resourceDERImpact(request)
        } else {
          // Otherwise, use the debounced method so that we make a request
          // when the user has settled on a new date range.
          const maybeImpact =
            await this.$services.analysisService.resourceDERImpact_DB(request)
          if (maybeImpact) {
            this.impact = maybeImpact
          }
        }
      } catch (err: any) {
        if (err.message.includes('no impact data')) {
          useGlobalSnackbar().openSnackbar(errNoImpactData, 'info')
        } else {
          console.error('GridImpactResource.fetchData: %o', err)
        }
      } finally {
        // Clear the loading state only if there's data
        if (this.impact.data) {
          this.isLoading = false
        }
      }
    },
  },
})
</script>
